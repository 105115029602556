import { forwardRef } from "react";

import { Input, InputProps } from "@headlessui/react";

import { cn } from "@/lib/utils.ts";

import { Icon, IconType } from "../icon";

import { InputSize } from "./types";

export interface InputV2Props extends Omit<InputProps, "size"> {
  iconLeft?: IconType;
  iconLeftClassName?: string;
  iconRight?: IconType;
  iconRightClassName?: string;
  containerClassName?: string;
  size?: InputSize;
}

export const InputV2 = forwardRef<HTMLInputElement, InputV2Props>(function InputV2(props, ref) {
  const {
    iconLeft,
    iconLeftClassName = "",
    iconRight,
    iconRightClassName = "",
    containerClassName,
    value,
    size = "sm",
    ...inputProps
  } = props;

  return (
    <div className={cn("relative flex w-full items-center", containerClassName)}>
      {iconLeft && (
        <Icon
          aria-hidden="true"
          type={iconLeft}
          className={cn("absolute mx-2 size-4", value ? "text-gray-900" : "text-gray-400", iconLeftClassName)}
        />
      )}
      <Input
        ref={ref}
        {...inputProps}
        value={value}
        className={cn(
          "flex-1 overflow-hidden border border-gray-300 bg-white px-4 font-medium text-gray-900 placeholder:text-gray-500 hover:bg-gray-300 disabled:bg-gray-300",
          "transition-colors duration-300 ease-in",
          size === "xs" && "h-6 rounded-xxs text-xs",
          size === "sm" && "h-8 rounded-xxs text-sm",
          size === "md" && "h-10 rounded-xs text-sm",
          iconLeft && "pl-8",
          iconRight && "pr-8",
          props.className,
        )}
      />
      {iconRight && (
        <Icon
          aria-hidden="true"
          type={iconRight}
          className={cn("absolute right-2 size-4", value ? "text-gray-900" : "text-gray-400", iconRightClassName)}
        />
      )}
    </div>
  );
});
