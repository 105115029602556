// @ts-nocheck

import { Children, isValidElement, useMemo } from "react";

import { cn } from "@/lib/utils.ts";

import { Body } from "./components/table-body.tsx";
import { Cell } from "./components/table-cell.tsx";
import { Footer } from "./components/table-footer.tsx";
import { HeaderCell } from "./components/table-header-cell.tsx";
import { Header } from "./components/table-header.tsx";
import { Pagination } from "./components/table-pagination.tsx";
import { Row } from "./components/table-row.tsx";
import { TableContext } from "./table-context.tsx";
import { isCustomTable, TableProps, TableRowData } from "./table-new.types.ts";

/**
 * This can works as simple component which just needs table instance to render a default table:
 * <TableNew
 *    className={cn("w-full")}
 *    table={table}
 *    options={{
 *      enablePagination: true,
 *    }}
 *  />
 *
 *  -------- or you can specify which components you want to render -----------
 *
 *  <TableNew
 *    className="w-full"
 *    table={table}
 *    options={{
 *      enablePagination: true,
 *    }}
 *  >
 *    <TableNew.Body />
 *    <TableNew.Pagination />
 *  </TableNew>
 *
 * -------- or you can go further and customize the logic/inner components ----
 *
 *  <TableNew
 *    className="w-full"
 *    table={table}
 *    options={{
 *      enablePagination: true,
 *    }}
 *  >
 *    <TableNew.Header>
 *                     {table.getHeaderGroups().map((headerGroup) => (...)}
 *    </TableNew.Header>
 *    ...
 *  </TableNew>
 *
 * @param props
 * @constructor
 */
export const TableNew = <TData extends TableRowData>(props: TableProps<TData>) => {
  const contextValue = useMemo(() => {
    return {
      table: props.table,
      options: props.options,
    };
  }, [props.table, props.options]);

  if (isCustomTable(props)) {
    const { children, className } = props;

    const tableChildren = Children.map(children, (child) =>
      isValidElement(child) && child.type !== Pagination ? child : null
    );
    const pagination = Children.map(children, (child) =>
      isValidElement(child) && child.type === Pagination ? child : null
    );

    return (
      <TableContext.Provider value={contextValue}>
        <div ref={props.scrollRoot} className="inline-flex mx-[-12px] w-[calc(100%+24px)] lg:mx-[-20px] lg:w-[calc(100%+40px)] size-full flex-col overflow-x-auto align-middle">
          <table className={cn("table-fixed", className)}>{tableChildren}</table>
        </div>
        {pagination}
      </TableContext.Provider>
    );
  }

  return (
    <TableContext.Provider value={contextValue}>
      <div ref={props.scrollRoot}  className="inline-flex mx-[-12px] w-[calc(100%+24px)] lg:mx-[-20px] lg:w-[calc(100%+40px)] size-full flex-col overflow-x-auto align-middle">
        <table className={cn("h-full table-fixed divide-y divide-gray-300", props.className)}>
          <Header />
          <Body className="divide-y divide-gray-300" />
        </table>
      </div>
      <Pagination />
    </TableContext.Provider>
  );
};

TableNew.Header = Header;
TableNew.HeaderCell = HeaderCell;
TableNew.Body = Body;
TableNew.Row = Row;
TableNew.Cell = Cell;
TableNew.Footer = Footer;
TableNew.Pagination = Pagination;
