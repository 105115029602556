// @ts-nocheck

import { useNavigate } from "react-router";
import { useState } from "react";
import { ModalHeader, ModalWebFrame } from "@/app/screens/modal/components";
import { SearchCompany } from "@/app/screens/modal/actions/add-sentiment/components/search-company";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { observer } from "mobx-react";

export const CompanyLink = observer(() => {
  const navigate = useNavigate();
  const [selectedCompany, setCompany] = useState(null);
  const {
    navState: { companyType, company, additionalSearchFilter, FooterComp },
  } = globalModalStore.state;

  return (
    <ModalWebFrame containerClassName={"bg-neutral-100 h-[600px]"}>
      <>
        <ModalHeader title={"Choose Company"} />
        <SearchCompany
          defaultSelected={company?.name}
          companySearchType={companyType}
          selectCompany={(comp) => setCompany(comp)}
          additionalSearchFilter={additionalSearchFilter}
        />
      </>
      <FooterComp selectedCompany={selectedCompany} companyType={companyType} />
    </ModalWebFrame>
  );
});
