import { Suspense, useEffect } from "react";

import { observer } from "mobx-react";
import { useLocation, useParams } from "react-router";
import { Navigate, Outlet } from "react-router-dom";

import { UserRoleType } from "@/gql/graphql.ts";

import { useBreakpoints } from "@/lib/hooks";
import { useMembersMapped, usePcgCompanies, useTopInvestors } from "@/lib/service/members.ts";

import { DevModeBanner } from "@/app/components/dev-tools/dev-mode-banner";
import { LoadingOverLay } from "@/app/components/loading/loading-overlay";
import { MobileHeader, VerticalNavbar } from "@/app/components/navbar";
import { Routes } from "@/app/constants";
import { GlobalModalProvider } from "@/app/providers";
import { CompleteSentimentModalResponsive } from "@/app/screens/modal";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { GlobalModal } from "@/app/screens/modal/global-modal.tsx";
import { GlobalNativeModal } from "@/app/screens/modal/global-native-modal.tsx";
import { SlideOver } from "@/app/screens/modal/slideover.tsx";
import { useMyRolesSuspenseQuery } from "@/app/service/user.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

import { DevTools } from "../components/dev-tools/dev-tools";
import { PresentationModeBanner } from "../components/presentation-mode/presentation-mode-banner";
import modalStore from "../stores/modal.store";

const AppLayout = () => {
  usePrivateLayout();

  return (
    <>
      <div className={"relative flex flex-col lg:flex-row"}>
        <DevModeBanner />
        <PresentationModeBanner />
        <DevTools />
        <MobileHeader />
        <VerticalNavbar />
        <Suspense fallback={<LoadingOverLay className="h-screen" />}>
          <Outlet />
        </Suspense>
      </div>
      <GlobalNativeModal />
      <GlobalModal />
      <GlobalModalProvider />
      <SlideOver />
      {/*TODO: Find a different feedback mechanism - until then just commenting feedback.*/}
      {/*<Feedback />*/}
    </>
  );
};

export const PrivateLayout = observer(() => {
  if (!authStore.user || Object.keys(authStore.user).length == 0) {
    return <Navigate to="/login" state={{ next: location.pathname }} replace />;
  }

  return <AppLayout />;
});

const underConstructionRoutes = [];

const useUserRoles = () => {
  const data = useMyRolesSuspenseQuery();
  authStore.userRoles = (data?.data?.myRoles?.roles as UserRoleType[]) ?? [];
};

const usePrivateLayout = () => {
  useUserRoles();
  useMembersMapped();
  usePcgCompanies();
  useTopInvestors();

  const location = useLocation();
  const { id } = useParams();
  const { isBigTablet } = useBreakpoints();

  const shouldShowForms = location.pathname.startsWith(`${Routes.forms}/`);

  useEffect(() => {
    if (shouldShowForms && id) {
      modalStore.open(CompleteSentimentModalResponsive, {
        props: {
          label: "Complete Sentiment",
          id,
        },
      });
    }
    if (!isBigTablet && underConstructionRoutes.some((route) => location.pathname.includes(route))) {
      globalModalStore.navigate({
        modalType: "UnderConstruction",
        navState: { detent: "content-height" },
      });
    }
  }, [location, id]);
};
