import { observer } from "mobx-react";

import { Button, Popover } from "@/app/components";
import { companyCopilotStore, type ToolsType, ToolType } from "@/app/screens/modal/copilot";
import { FilesTooltip, VariablesTooltip, WebSearchTooltip } from "@/app/screens/modal/copilot/components";

const toolsTooltips: Record<ToolsType, () => JSX.Element> = {
  variables: observer(() => <VariablesTooltip />),
  websearch: () => <WebSearchTooltip />,
  files: () => <FilesTooltip />,
};

export const Toolbar = observer(function () {
  const {
    state: { tools },
    setState,
  } = companyCopilotStore;
  return (
    <div className={"flex w-full p-2"}>
      <div className="flex w-full flex-row items-center justify-between">
        <div className={"flex items-center space-x-2"}>
          {Object.entries(tools).map(([id, { title, icon, selected, disabled }]) => {
            const Tooltip = toolsTooltips[id as ToolsType];
            return (
              <Popover key={id} placement={"top"} childrenClassName={"w-60 z-30"} childrenContent={<Tooltip />}>
                <Button
                  disabled={disabled}
                  iconLeft={icon}
                  variant={selected ? "secondary" : "outline"}
                  key={id}
                  onClick={() => {
                    setState({ tools: { [id]: { selected: !selected } } as Record<ToolsType, Partial<ToolType>> });
                  }}
                  size={"sm"}
                >
                  {title}
                </Button>
              </Popover>
            );
          })}
        </div>
      </div>
    </div>
  );
});
