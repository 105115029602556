import { Action } from "@/gql/graphql.ts";

import { useActionMutation } from "@/app/hooks";

export const useSnooze = (form, companyId: string, onSuccess?: () => void) => {
  const { onSubmit: snooze, loading: snoozeLoading, error: snoozeError } = useActionMutation({ onSuccess });

  const onSubmit = async () => {
    return snooze({
      successMessage: "Company moved in Pipeline!",
      action: Action.Snooze,
      entityId: companyId,
      feedbackEmail: form.values().feedback_email,
      other: "not-available" /*form.values().other*/,
      resurface: "not-available" /*form.values().resurface*/,
      reason: ["not-available"] /*form.values().reason*/,
    });
  };
  return { onSubmit, snoozeLoading, snoozeError };
};
