import React, { forwardRef, MouseEvent, ReactNode } from "react";

import { cn } from "@/lib/utils.ts";

import { Icon, IconType, ProgressSpinner } from "@/app/components";

export type ButtonVariant = "gradient" | "primary" | "secondary" | "outline" | "text" | "link";
type ButtonSizeType = "xs" | "sm" | "md" | "lg";

export type ButtonProps = {
  size?: ButtonSizeType;
  className?: string;
  text?: string;
  variant?: ButtonVariant;
  children?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  iconRight?: IconType;
  iconLeft?: IconType;
  type?: HTMLButtonElement["type"];
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    className = "",
    iconRight,
    iconLeft,
    disabled = false,
    variant = "primary",
    loading = false,
    onClick,
    size = "md",
    children,
    text,
    ...props
  },
  ref,
) {
  return (
    <button
      ref={ref}
      onClick={onClick}
      className={cn(
        `flex w-fit select-none items-center justify-center gap-2 text-sm font-semibold disabled:pointer-events-none`,
        "transition-colors duration-200 ease-in",
        // sizes
        size === "lg" && "h-12 rounded-xs p-4",
        size === "md" && "h-10 rounded-xs px-4 py-3",
        size === "sm" && "h-8 rounded-xxs px-3 py-2",
        size === "xs" && "h-6 rounded-xxs px-2 py-1 text-xs",

        // variants
        variant === "gradient" &&
          "cursor-pointer bg-gradient-to-r from-primary via-[#E93E62] to-secondary text-white hover:from-[#FF3B76] hover:to-[#FF792D] hover:opacity-90 active:from-[#CC2F5E] active:to-[#CC6124E5]/90 disabled:bg-gray-300 disabled:bg-none disabled:text-gray-500",
        variant === "primary" &&
          "bg-white text-gray-900 hover:bg-gray-400 active:bg-gray-500 disabled:bg-gray-300 disabled:text-gray-500",
        variant === "secondary" &&
          "bg-gray-300 text-gray-900 hover:bg-gray-400 active:bg-gray-500 disabled:bg-gray-300 disabled:text-gray-500",
        variant === "outline" &&
          "border border-gray-300 bg-white text-gray-900 hover:bg-gray-400 active:bg-gray-500 disabled:border-none disabled:bg-gray-300 disabled:text-gray-500",
        variant === "text" &&
          "bg-transparent text-gray-900 hover:bg-gray-400 active:bg-gray-500 disabled:text-gray-500",
        variant === "link" && "bg-transparent text-gray-900 hover:underline active:underline disabled:text-gray-500",

        className,
      )}
      disabled={loading || disabled}
      {...props}
    >
      {iconLeft && !loading && <Icon type={iconLeft} className={cn("size-4", size === "lg" && "mr-1 size-5")} />}
      {loading && <ProgressSpinner className={cn("size-4", size === "lg" && "mr-1 size-5")} />}
      {text && <span className="truncate text-nowrap">{text}</span>}
      {children}
      {iconRight && <Icon type={iconRight} className={cn("size-4", size === "lg" && "ml-1 size-5")} />}
    </button>
  );
});
