import { observer } from "mobx-react";

import { UserRoleType } from "@/gql/graphql.ts";

import { authStore } from "@/app/stores/auth.store.tsx";

import { NetworkContactLookup } from "./contactLookup";
import { NetworkContactLookup as NetworkContactLookupV2 } from "../network-v2/contactLookup";
import { NetworkDashboardComing } from "./dashboardComing";
import { ConnectionsContext, useConnectionsDict } from "../network-v2/connectionsDict";

// TODO: get rid of observer after auth/user refactor
export const NetworkDashboard = observer(function NetworkDashboard() {
  const { userRoles: myRoles } = authStore;
  const connectionsDict = useConnectionsDict();

  // not loaded yet
  if (myRoles === null) return null;
  if ((myRoles as any)?.includes("USER_FEAT_NETWORK_NEXT")) {
    return <ConnectionsContext.Provider value={connectionsDict}>
       <NetworkContactLookupV2 />
    </ConnectionsContext.Provider>;
  }

  if (myRoles?.includes(UserRoleType.UserRoleNetwork)) {
    return <NetworkContactLookup />;
  }

  return <NetworkDashboardComing />;
});
