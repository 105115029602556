import { Icon, IconType, Popover, PopoverPlacement } from "@/app/components";

import React, { HTMLInputTypeAttribute, useRef } from "react";

import "./input.css";
import { cn } from "@/lib/utils.ts";

export type InputProps = {
  type?: HTMLInputTypeAttribute;
  label?: string;
  name?: string;
  labelClassName?: string;
  className?: string;
  iconRight?: IconType;
  iconRightOnClick?: () => void;
  iconRightClassName?: string;
  iconRightPopover?: React.ReactNode;
  iconRightPopoverLocation?: PopoverPlacement;
  iconLeft?: IconType;
  iconLeftComponent?: React.ReactNode;
  iconLeftOnClick?: () => void;
  iconLeftClassName?: string;
  containerClassName?: string;
  pills?: string[];
  placeholder?: string;
  error?: string;
  errorClassName?: string;
  disabled?: boolean;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, inputRef?: HTMLInputElement) => void;
};

export const TW_INPUT_TEXT = "w-full text-sm placeholder:text-gray-400";

export const TW_INPUT_CORE =
  "w-full outline-none text-left whitespace-nowrap truncate transition duration-100 rounded-sm flex flex-nowrap border py-3 overflow-hidden";

export const TW_INPUT_CORE_HOVER = "lg:hover:ring-gray-300 lg:hover:bg-gray-300";

export const TW_INPUT_CORE_FOCUS = "border-gray-300 text-tremor-content-emphasis";

/**
 * @deprecated use InputV2 instead with FormField
 */
export function Input({
  name,
  label = "",
  placeholder = "",
  onChange,
  className = "",
  labelClassName = "",
  containerClassName = "",
  iconLeft,
  iconLeftOnClick,
  iconLeftClassName,
  iconLeftComponent,
  iconRight,
  iconRightOnClick,
  iconRightClassName,
  iconRightPopover,
  iconRightPopoverLocation = "right-end",
  error = "",
  errorClassName,
  type = "text",
  value = undefined,
  disabled = false,
  required = false,
  ...props
}: InputProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={`flex flex-col items-start focus:ring-2 focus:ring-tremor-brand-muted ${containerClassName}`}>
      {label && (
        <div className={"flex pb-2"}>
          <span
            className={cn("text-xs font-medium", disabled ? "text-neutral-400" : "text-neutral-600", labelClassName)}
          >
            {label}
          </span>
          {required && <span className={" ml-1 text-xs font-medium text-red"}>*</span>}
        </div>
      )}
      <div className="relative w-full">
        {iconLeft && (
          <div className={"absolute inset-y-0 left-0 flex items-center pl-4"} onClick={iconLeftOnClick}>
            {iconLeftComponent ? (
              iconLeftComponent
            ) : (
              <Icon
                type={iconLeft}
                className={cn("size-4", disabled ? "text-neutral-300" : "text-primary", iconLeftClassName)}
              />
            )}
          </div>
        )}
        <input
          ref={inputRef}
          id={name}
          type={type}
          name={name}
          value={value}
          disabled={disabled}
          onChange={(event) => onChange && onChange(event, inputRef?.current || undefined)}
          placeholder={placeholder}
          className={cn(
            `${TW_INPUT_TEXT} ${TW_INPUT_CORE} ${TW_INPUT_CORE_FOCUS}`,
            `${iconLeft ? "pl-11" : "pl-4"}`,
            `${iconRight ? "pr-11" : "pr-4"}`,
            disabled ? "text-neutral-300" : TW_INPUT_CORE_HOVER,
            className,
          )}
          {...props}
        />
        {iconRight && (
          <div className={"absolute inset-y-0 right-0 flex items-center pr-3.5"} onClick={iconRightOnClick}>
            {iconRightPopover && (
              <Popover childrenContent={iconRightPopover} placement={iconRightPopoverLocation}>
                <Icon
                  type={iconRight}
                  className={`size-5 ${disabled ? "text-neutral-300" : "text-primary"} ${iconRightClassName}`}
                />
              </Popover>
            )}
            {!iconRightPopover && (
              <Icon
                type={iconRight}
                className={`size-5 ${disabled ? "text-neutral-300" : "text-primary"} ${iconRightClassName}`}
              />
            )}
          </div>
        )}
      </div>
      {error && (
        <span className={cn("ml-4 mt-1 text-xs text-red", disabled ? "hidden" : "", errorClassName)}>{error}</span>
      )}
    </div>
  );
}
