import { useCallback, useEffect, useRef, useState } from "react";

import { AvatarV2, Button, Checkbox, Icon } from "app/components";

import { Column, Filters, SortDirection, sortNodes, FlagFilterKey, columns } from "./columns";
import { LandingLadder } from "./landingLadder";
import { Settings } from "./settings";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { Connectivity } from "./connectivity";
import { gql, useQuery } from "@apollo/client";

type BaseQueryValue = {
  q: string;
};

type LookupHeaderProps = {
  lookedUp: boolean;
  loading: boolean;
  resultsDisplayed?: number;
  resultsTotal?: number;
  filters: Filters;
  sortColumn: Column;
  sortDirection: SortDirection;
  onEngagedClick: (event: React.MouseEvent<HTMLInputElement>) => void;
  onQueryChange: (value: string) => void;
  baseQueryValue: BaseQueryValue;
  domains: any;
};

export function LookupHeader(props: LookupHeaderProps) {
  const {
    lookedUp,
    loading,
    filters,
    onEngagedClick,
    onQueryChange,
    sortColumn,
    sortDirection,
    baseQueryValue,
    resultsDisplayed,
    resultsTotal,
    domains,
  } = props;
  const [sortModalOpened, setSortModalOpened] = useState(false);
  const handleSortModalOpen = useCallback(() => setSortModalOpened(true), []);
  const handleSortModalClose = useCallback(() => setSortModalOpened(false), []);

  let inputWrapperClasses: string;
  let inputTitleClasses: string;
  let inputInnerWrapperClasses: string;
  let engagedClasses: string;
  let sortModalCtrlClasses: string;

  if (!lookedUp) {
    inputWrapperClasses = "flex flex-col items-center flex-grow px-4";
    inputTitleClasses = "text-[32px] font-semibold mb-2 text-center";
    inputInnerWrapperClasses = "w-full";
    engagedClasses = "hidden";
    sortModalCtrlClasses = "hidden";
  } else {
    inputWrapperClasses = "sticky mb-1 px-2 @5xl:mb-0 @5xl:px-4 top-[98px] lg:top-[48px]  top-0 z-10 bg-white";
    inputTitleClasses = "hidden";
    inputInnerWrapperClasses = "w-full";
    engagedClasses = "mr-2";
    sortModalCtrlClasses = "p-2 w-[32px] @5xl:hidden";
  }

  const inputIconType = loading ? "Loader" : "Search";
  const labelStyle = loading ? "absolute top-[19px] left-[11px]" : "absolute top-[21px] left-[13px]";
  const sortProps = {
    onClose: handleSortModalClose,
    node: {
      title: "Sort By",
      nodes: sortNodes.map((e) => {
        if (e.column === sortColumn) {
          return { ...e, selectedText: columns.get(e.column)![`${sortDirection}Text`] };
        }
        return e;
      }),
    },
  };
  return (
    <>
      <div className={inputWrapperClasses} data-lookup-search>
        <div className={inputInnerWrapperClasses}>
          <div className="relative w-full">
            <label htmlFor="network-search-input" className={labelStyle}>
              <Icon type={inputIconType} width={20} />
            </label>
            <SearchInput
              loading={loading}
              baseQueryValue={baseQueryValue}
              lookedUp={lookedUp}
              onChange={onQueryChange}
              resultsDisplayed={resultsDisplayed}
              resultsTotal={resultsTotal}
              domains={domains}
            />

            {!lookedUp ? (
              <div className="">
                <LandingLadder />
              </div>
            ) : null}
            <div className="absolute right-2 top-0 flex flex-wrap items-center">
              <div className={engagedClasses}>
                <Checkbox
                  checked={filters.get(FlagFilterKey.EngagedOnly)?.value}
                  onClick={onEngagedClick}
                  label={"Engaged Only"}
                  containerClassName={"my-2"}
                  labelClassName={"text-black font-medium"}
                />
              </div>

              <div className={sortModalCtrlClasses} onClick={handleSortModalOpen}>
                <Icon type="Sort" className="size-full" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {lookedUp && sortModalOpened ? <Settings {...sortProps} /> : null}
    </>
  );
}

const numberFormat = Intl.NumberFormat("en-GB");

export type SearchInputProps = {
  baseQueryValue: BaseQueryValue;
  onChange: (value: string) => void;
  lookedUp: boolean;
  loading: boolean;
  resultsDisplayed?: number;
  resultsTotal?: number;
  domains: any;
};

export function SearchInput(props: SearchInputProps) {
  const { onChange, lookedUp, baseQueryValue, resultsDisplayed, resultsTotal, loading, domains } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const debounceTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [value, setValue] = useState("");

  const [params] = useSearchParams();

  useEffect(() => {
    if (inputRef.current !== null) {
      inputRef.current.value = baseQueryValue.q;
      setValue(baseQueryValue.q);
    }
  }, [baseQueryValue]);

  useEffect(() => {
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    function handlePopState() {
      const params = new URLSearchParams(window.location.search);
      const q = params.get("q") || ""; // Reads ?key=value
      setValue(q);
      onChange(q);
      if (inputRef?.current) inputRef.current.value = q;
    }
    window.addEventListener("popstate", handlePopState);

    return () => window.removeEventListener("popstate", handlePopState);
  }, [onChange, inputRef]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      onChange(event.target.value);
    }, 100);
  }, []);

  let inputClasses: string;
  if (!lookedUp) {
    inputClasses = "my-2 w-full rounded-sm border border-neutral-300 px-3 py-2 pl-10 mb-0";
  } else {
    inputClasses = "my-2 w-full rounded-sm border border-neutral-300 px-3 py-2 pl-10 mb-0 pr-[170px]";
  }

  const paramValue = params.get("q");
  const metaClasses =
    !loading && paramValue === value && resultsDisplayed && value.length > 1 && resultsTotal
      ? " opacity-1"
      : " opacity-0";
  let domainLookupClasses = "";
  let offset = "";
  domainLookupClasses = " h-[0px]";

  const displayDomainSelection = value.length > 1 && domains?.length;
  const displayMatched = domains?.find((e) => e.domain === value);
  if (displayMatched) {
    offset = "company";
    domainLookupClasses = " h-[63px] my-4";
  } else if (displayDomainSelection) {
    offset = "companies";
    domainLookupClasses = " h-[35px] my-4";
  }
  return (
    <>
      <input
        ref={inputRef}
        id="network-search-input"
        type="text"
        autoComplete="off"
        autoFocus
        onChange={handleChange}
        className={inputClasses}
        defaultValue={baseQueryValue.q}
        placeholder="Search by name, email address or domain."
      />
      <div
        data-lookup-search-offset={offset}
        className={"absolute top-[17px] left-[41px] pointer-events-none text-gray-500 transition-all " + metaClasses}
      >
        <span className="opacity-0">{value}</span>{" "}
        <span className="ml-1">
          {resultsDisplayed} out of {numberFormat.format(resultsTotal || 0)}
        </span>
      </div>
      <div
        className={
          "relative flex flex-row gap-2 w-full overflow-y-auto no-scrollbar transition-all " + domainLookupClasses
        }
      >
        <AnimatePresence>
          {displayMatched ? (
            <MatchedDomain domain={displayMatched} />
          ) : displayDomainSelection ? (
            domains.map((domain) => {
              return (
                <DomainButton
                  onClick={() => {
                    setValue(domain.domain);
                    onChange(domain.domain);
                    if (inputRef?.current) inputRef.current.value = domain.domain;
                  }}
                  domain={domain.domain}
                />
              );
            })
          ) : null}
        </AnimatePresence>
        <div className="absolute top-0 right-0 h-full w-10 bg-gradient-to-l from-white to-transparent pointer-events-none"></div>
      </div>
    </>
  );
}

function DomainButton(props) {
  const { domain, onClick } = props;
  const classes = "whitespace-nowrap";
  return (
    <motion.div
      key={domain.domain}
      initial={{ opacity: 0.3 }}
      animate={{ opacity: 1, transition: { duration: 0.25 } }}
      className="flex"
    >
      <Button size="sm" variant="outline" onClick={onClick}>
        <div className={classes}>{domain}</div>
      </Button>
    </motion.div>
  );
}

const getCompanyMeta = gql`
  query GetNetworkCompanyMeta($companyId: UUID) {
    company: networkGetCompanyMeta(companyId: $companyId) {
      name
      createdAt
      mean
    }
  }
`;
export function MatchedDomain(props) {
  const { domain } = props;

  return (
    <div className="flex gap-4 relative overflow-hidden">
      <AvatarV2 size="lg" src={`https://www.google.com/s2/favicons?sz=128&domain=${domain.domain}`} type="company" />
      <div className="flex flex-col gap-3">
        <div className="flex flex-col @3xl:flex-row @3xl:items-center gap-3 mb-[-1px]">
          <div className="text-[24px] font-semibold">{domain.domain}</div>
          <Connectivity value={domain.connStrength} />
          <div className="gap-3 hidden @3xl:flex">
            {domain.tags?.map((tag) => (
              <span key={tag} className="flex   capitalize bg-gray-300 text-gray-600  rounded-[2px] px-2 text-[12px]">
                {tag}
              </span>
            ))}
          </div>
        </div>
        <div className="flex flex-col @3xl:flex-row mt-[-8px] gap-1 @3xl:gap-2">
          <div className="flex gap-1 @3xl:gap-2">
            {domain.portfolioCompanyId ? (
              <MatchedPortfolio companyId={domain.portfolioCompanyId} />
            ) : (
              <div className="text-[14px] text-gray-900 overflow-hidden whitespace-nowrap text-ellipsis w-full">
                {domain.snippet}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const dateFormat = new Intl.DateTimeFormat("en-GB", {
  day: "numeric",
  month: "short",
  year: "numeric",
});

type MatchedPortfolioType = {
  companyId: string;
};

function MatchedPortfolio(props: MatchedPortfolioType) {
  const { companyId } = props;
  const { data } = useQuery(getCompanyMeta, { variables: { companyId } });
  if (!data?.company) return;

  const { company } = data;
  return (
    <div className="text-[14px] flex flex-row gap-2 items-center">
      <Icon width={14} height={14} type="CornerDownRight" className="flex" />
      <span className="font-medium text-gray-600">Company</span>
      <Link to={`/company/${companyId}`} className="text-semibold underline underline-offset-4">
        {company.name}
      </Link>{" "}
      •{" "}
      {company.createdAt ? (
        <span className="flex flex-row gap-1">
          <span className="font-medium text-gray-600">Excitement</span> {(company.mean || 0).toFixed(1)}
          <span>at</span>
          <span>{dateFormat.format(new Date(company.createdAt))}</span>
        </span>
      ) : null}
    </div>
  );
}
