import { cn } from "@/lib/utils";

import { ModalHeader } from "../../components";

export const ActionModalHeader = ({
  title = "",
  containerClassName,
}: {
  title?: string;
  containerClassName?: string;
}) => {
  return <ModalHeader title={title} containerClassName={cn("gap-4 text-left xl:mb-6", containerClassName)} />;
};
