import * as Sentry from "@sentry/react";
import { print } from "graphql";

import { getEnv } from "@/app/env/env";
import { RefreshTokenDocument } from "@/app/screens/login/login.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

export async function refreshTokenCall() {
  const refreshToken = authStore.refreshToken;

  try {
    const response = await fetch(`${getEnv("NAZARE_API_DOMAIN")}/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${refreshToken}`,
      },
      body: JSON.stringify({
        query: print(RefreshTokenDocument),
        variables: { token: refreshToken },
      }),
    });

    const { data } = await response.json();
    if (data.refreshToken) {
      const result = data.refreshToken;

      authStore.refreshToken = result.refreshToken;
      authStore.accessToken = result.accessToken;
      authStore.expiresAt = result.expiresAt;
      authStore.user = result.user;

      return result.accessToken;
    } else {
      Sentry.captureException(new Error("Failed to refresh token. There was no token in the response"));
      authStore.logout();
    }
  } catch (error) {
    console.error("Error refreshing token:", error);
    Sentry.captureException(error);
    authStore.logout();

    return null;
  }
}
