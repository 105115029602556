// @ts-nocheck

import { Button, Checkbox, Icon, IosTransitionEnter } from "@/app/components";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";

import { opportunityFilterStore } from "@/app/screens/opportunities";
import { observer } from "mobx-react";
import Sheet from "react-modal-sheet";

import { cn } from "@/lib/utils.ts";
import { ModalTitle } from "@/app/screens/modal/opportunities/components/modal-title.tsx";

export const FilterMobile = observer(() => {
  const { navState } = globalModalStore.state;

  const navigate = (label: string, type: "multiselect" | "range") =>
    globalModalStore.navigate({
      modalType: "OpportunityFilterComponent",
      navState: {
        label,
        type,
      },
    });

  return (
    <IosTransitionEnter type={navState?.transitionAnimation || "static"}>
      <div className={"h-full pb-[20%]"}>
        <Sheet.Scroller className={"no-scrollbar overflow-x-clip"}>
          <div className={"mx-6 my-5 flex h-full flex-col justify-between"}>
            <div className={"flex flex-col"}>
              <ModalTitle
                title={"Filters"}
                secondaryAction={{
                  text: "Clear",
                  onClick: () => opportunityFilterStore.clearAll(),
                }}
              />
              <span className={"my-3 self-start text-xs font-semibold text-neutral"}>Tags</span>
              <div className={"flex flex-col"}>
                {opportunityFilterStore.tagKeys.map((label) => {
                  const labelValue = opportunityFilterStore.getChoice({
                    category: "tag",
                    label,
                  });

                  return (
                    <div className={"-ml-3 flex w-full items-center justify-between"}>
                      <Checkbox
                        label={label}
                        ripple={true}
                        onClick={() => {
                          opportunityFilterStore.changeChoice({
                            category: "tag",
                            label,
                            value: labelValue ? null : true,
                          });
                        }}
                        className={
                          "checked:bg-gray-900 checked:border-none checked:hover:bg-gray-900 ring-none border-gray-900"
                        }
                        labelClassName={"text-black font-medium"}
                        checked={labelValue !== null}
                      />
                      {labelValue !== null && (
                        <div className={"flex rounded-xs bg-neutral-100"}>
                          <span
                            onClick={() => {
                              opportunityFilterStore.changeChoice({
                                category: "tag",
                                label,
                                value: true,
                              });
                            }}
                            className={cn(
                              "cursor-pointer select-none p-2 text-xs font-semibold transition-all ease-in-out",
                              labelValue === true ? "text-black" : "text-neutral-400",
                            )}
                          >
                            True
                          </span>
                          <span
                            onClick={() => {
                              opportunityFilterStore.changeChoice({
                                category: "tag",
                                label,
                                value: false,
                              });
                            }}
                            className={cn(
                              "cursor-pointer select-none p-2 text-xs font-semibold transition-all ease-in-out",
                              labelValue === false ? "text-black" : "text-neutral-400",
                            )}
                          >
                            False
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <span className={"my-4 self-start text-xs font-semibold text-neutral"}>Company</span>
              <div className={"flex w-full flex-wrap justify-between"}>
                {opportunityFilterStore.multiselectKeys.map((label) => {
                  let multiselectValues = opportunityFilterStore.getChoice({
                    category: "multiselect",
                    label: label,
                  });
                  if (multiselectValues === null) multiselectValues = [];
                  return (
                    <div
                      className={"my-2.5 flex w-full cursor-pointer select-none items-center justify-between"}
                      onClick={() => navigate(label, "multiselect")}
                    >
                      <div className={"flex items-center"}>
                        <Icon
                          type={label}
                          width={20}
                          height={20}
                          className={cn(
                            "mr-2 h-5 w-5",
                            multiselectValues.length ? "text-gray-900" : "text-neutral-400",
                          )}
                        />
                        <span className={"font-medium hover:text-neutral-700"}>{label}</span>
                      </div>
                      {Boolean(multiselectValues.length) && (
                        <div
                          className={
                            "self-justify-end mr-2 flex h-4 w-4 items-center justify-center rounded-full bg-primary"
                          }
                        >
                          <span className={"text-xs font-semibold text-white"}>{multiselectValues.length}</span>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <span className={"my-4 self-start text-xs font-semibold text-neutral"}>Range</span>
              <div className={"flex flex-col"}>
                {opportunityFilterStore.rangeKeys.map((label) => {
                  const rangeValues = opportunityFilterStore.getChoice({
                    category: "range",
                    label,
                  });
                  const text = rangeValues ? `${rangeValues[0]}-${rangeValues[1]}` : label;
                  return (
                    <div
                      className={"my-2.5 flex w-full cursor-pointer select-none items-center justify-between"}
                      onClick={() => navigate(label, "range")}
                    >
                      <div className={"flex items-center"}>
                        <Icon
                          type={label}
                          width={20}
                          height={20}
                          className={cn("mr-2 h-5 w-5", rangeValues ? "text-primary" : "text-neutral-400")}
                        />
                        <span className={"text-sm font-medium hover:text-neutral-700"}>{label}</span>
                      </div>
                      {Boolean(rangeValues && rangeValues?.[0] !== 0) && (
                        <Icon type={"FillCheck"} width={16} height={16} className={"mr-1 text-primary"} />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Sheet.Scroller>
        <div className={"absolute bottom w-full px-5"}>
          <Button
            text={"Apply"}
            onClick={() => {
              globalModalStore.toggleModal();
            }}
            className="w-full"
          />
        </div>
      </div>
    </IosTransitionEnter>
  );
});
