// @ts-nocheck

import MobxReactForm from "mobx-react-form";
import * as z from "zod";

import { FormType, InitialValues, getFormFields, getPlugins, zodSchema } from "./config";

export interface Field {
  group_id?: string;
  field_id?: string;
  validations?: { required?: boolean };
  type?: string;
}

export const getZodFieldSchema = (field: Field) => {
  let zodFieldSchema: any;
  const isRequired = field.validations?.required && field.group_id !== "name";

  switch (field.type) {
    case "opinion_scale":
    case "rating":
      zodFieldSchema = z.number(isRequired ? { required_error: "Please select a 0-10 rating." } : undefined);

      break;
    case "short_text":
    case "text":
      zodFieldSchema = z.string(isRequired ? { required_error: "Field is required!" } : undefined);
      break;
    case "dropdown":
    case "multiple_choice":
      zodFieldSchema = z.array(z.string());

      if (isRequired) zodFieldSchema = zodFieldSchema.nonempty("Select at least one option!");
      break;
    default:
      zodFieldSchema = null;
  }

  if (!isRequired && zodFieldSchema) zodFieldSchema = zodFieldSchema.optional();
  return zodFieldSchema;
};
export const transformSchema = (fields: Array<Field>) =>
  Object.fromEntries(fields.map((field) => [field.field_id, getZodFieldSchema(field)]).filter(([_, schema]) => schema));

export const createForm = ({
  formId,
  initial,
}: {
  formId: FormType;
  formConfig?: any;
  initial?: InitialValues;
}) => {
  const fields = getFormFields(initial)[formId];

  return new MobxReactForm(
    { ...fields },
    {
      plugins: getPlugins({ schema: zodSchema[formId] }),
      options: {
        validateOnChange: true,
        validateOnSubmit: true,
        validateOnInit: true,
        showErrorsOnChange: true,
        showErrorsOnSubmit: true,
      },
    },
  );
};

export const createOptionalForm = ({
  formId,
  initial,
}: {
  formId: FormType;
  formConfig?: FormConfig;
  initial?: InitialValues;
}) => {
  const fields = getFormFields(initial)[formId];

  if (!fields) return;

  return new MobxReactForm(
    { ...fields },
    {
      plugins: getPlugins({ schema: zodSchema[formId] }),
      options: {
        validateOnChange: true,
        validateOnSubmit: true,
        validateOnInit: true,
        showErrorsOnChange: true,
        showErrorsOnSubmit: true,
      },
    },
  );
};
