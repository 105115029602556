import * as Sentry from "@sentry/react";
import { toast } from "react-hot-toast";

import { Icon, ResponsiveModal, TextArea } from "@/app/components";
import modalStore from "@/app/stores/modal.store.tsx";

export const EmailFeedback = ({ company, responses }) => {
  try {
    const result = extractFirstArray(responses);
    return (
      <ResponsiveModal
        isOpen={true}
        handleClose={modalStore.close}
        bodyClassName={"min-h-[85dvh] min-w-[70vw]"}
        bodyContentClassName="flex flex-1 flex-col"
      >
        <div className={"flex w-full flex-col items-center"}>
          <span
            className={"mb-5 self-center text-xl font-semibold"}
          >{`All done! Our AI has generated 2 email templates for ${company.name}`}</span>
          <span className={"font-medium text-neutral-700"}>
            {"Feel free to copy them to your clipboard or generate new text"}
          </span>
        </div>
        <div className={"mt-5 flex h-full flex-1 flex-col gap-y-2"}>
          {result.map((text, index) => (
            <TextArea
              key={index}
              onChange={() => null}
              value={text}
              label={`#${index + 1} Template`}
              containerClassName={"w-full h-full flex-grow flex-1 relative"}
              icons={
                <div
                  className={
                    "absolute bottom-8 right-3 flex cursor-pointer select-none items-center space-x-1 rounded-sm border bg-neutral-200 p-0.5 px-1 hover:bg-neutral-100"
                  }
                  onClick={() => {
                    navigator.clipboard.writeText(text).then(() => {
                      toast.success("Copied to clipboard");
                    });
                  }}
                >
                  <Icon type={"Copy"} className={"size-3"} />
                  <span className={"text-sm font-medium"}>Copy</span>
                </div>
              }
              className={"h-full flex-1"}
              showTranscribe={false}
            />
          ))}
        </div>
      </ResponsiveModal>
    );
  } catch (e) {
    Sentry.captureException(e);
    return (
      <ResponsiveModal
        isOpen={true}
        handleClose={modalStore.close}
        bodyClassName={"min-h-[25dvh] max-w-[33dvw] p-4"}
        bodyContentClassName="flex flex-1 flex-col"
      >
        <div className={"flex w-full flex-col items-center space-y-2"}>
          <span className="self-center text-title font-semibold">
            {`Oops! 😕 Something went wrong, and we couldn't generate the feedback email for ${company.name}.`}
          </span>
          <span>{`Our team has been notified, and we're working on a fix. A retry button is on our roadmap 🚀, so stay tuned!`}</span>
        </div>
      </ResponsiveModal>
    );
  }
};

function extractFirstArray(jsonString: string): any {
  const startIndex = jsonString.indexOf("[");
  if (startIndex === -1) {
    throw new Error("No array-like structure found in the input.");
  }
  const len = jsonString.length;

  let bracketCount = 0;
  let endIndex = -1;
  let inString = false;
  let escape = false;

  for (let i = startIndex; i < len; i++) {
    const code = jsonString.charCodeAt(i);

    if (inString) {
      // when inside a string literal, handle escape sequences.
      if (escape) {
        escape = false;
      } else if (code === 92) {
        // \
        escape = true;
      } else if (code === 34) {
        // "
        inString = false;
      }
      // no bracket processing while in a string.
      continue;
    }

    // not inside a string literal.
    if (code === 34) {
      // "
      inString = true;
    } else if (code === 91) {
      // [
      bracketCount++;
    } else if (code === 93) {
      // ]
      bracketCount--;
      if (bracketCount === 0) {
        endIndex = i;
        break;
      }
    }
  }

  if (endIndex === -1) {
    throw new Error("No matching closing bracket found for the array.");
  }

  const arrayStr = jsonString.slice(startIndex, endIndex + 1);

  try {
    return JSON.parse(arrayStr);
  } catch (err) {
    throw new Error("Error parsing JSON array: " + err);
  }
}
