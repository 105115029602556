import React from "react";

import { useDroppable } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { cn } from "@/lib/utils";

import { Icon } from "@/app/components";

// Droppable container component
export const DroppableContainer = ({ id, children }: { id: string; children: React.ReactNode }) => {
  const { setNodeRef, isOver } = useDroppable({
    id,
    data: {
      type: "container",
      containerId: id,
    },
  });

  return (
    <div className={cn("size-full min-h-[200px] pb-4", isOver && "rounded-sm bg-gray-100")} ref={setNodeRef}>
      {children}
      {isOver && (
        <div className="flex h-20 w-full items-center justify-center rounded-xs border border-gray-400 bg-gray-200 opacity-50">
          <Icon type="Add" className="size-10 opacity-10" />
        </div>
      )}
    </div>
  );
};

// Draggable item component
export const DraggableItem = ({
  id,
  containerId,
  children,
  className,
}: {
  id: string;
  containerId: string;
  children: React.ReactNode;
  className?: string;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isOver } = useSortable({
    id,
    data: {
      type: "item",
      containerId,
    },
    attributes: {
      role: "div",
      roleDescription: "Draggable item",
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={cn("group/draggable-item flex items-center", className, isOver && "opacity-20")}
      {...listeners}
      {...attributes}
    >
      {children}
    </div>
  );
};
