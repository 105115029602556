import React from "react";

import { AttachmentsCommentsCard } from "@/app/screens/opportunities/opportunity/components/highlights/attachments-comments/attachments-comments-card.tsx";
import { CapitalConcentration } from "@/app/screens/opportunities/opportunity/components/highlights/capital-concentration/capital-concentration.tsx";
import { CashProfile } from "@/app/screens/opportunities/opportunity/components/highlights/cash-profile/cash-profile.tsx";
import { EvReturnCard } from "@/app/screens/opportunities/opportunity/components/highlights/ev-return-card.tsx";
import { ExcitementScoreCard } from "@/app/screens/opportunities/opportunity/components/highlights/excitement-score/excitement-score-card.tsx";
import { InvestedBasecampFundsCard } from "@/app/screens/opportunities/opportunity/components/highlights/invested-basecamp-funds/invested-basecamp-funds-card.tsx";
import { LatestReturnProfileCard } from "@/app/screens/opportunities/opportunity/components/highlights/latest-return-profile/latest-return-profile-card.tsx";
import { RevenueCard } from "@/app/screens/opportunities/opportunity/components/highlights/revenue-card.tsx";
import {
  HighlightsProps,
  useHighlights,
} from "@/app/screens/opportunities/opportunity/components/highlights/use-highlights.tsx";

export const Highlights = ({ company, signal, portCo }: HighlightsProps) => {
  const { excitementScore, latestReturnProfile, investedBasecampFunds, attachmentsComments } = useHighlights({
    company,
    signal,
  });
  return (
    <section data-cy="details-page-highlights" className="@container">
      <div className="grid grid-flow-row-dense grid-cols-1 gap-2 @lg:grid-cols-2 @4xl:grid-cols-6 @6xl:grid-cols-8 lg:gap-3">
        <ExcitementScoreCard data={excitementScore} />
        <div className="col-span-1 @lg:col-span-2 @4xl:col-span-3">
          <LatestReturnProfileCard data={latestReturnProfile} />
        </div>
        {portCo && (
          <div className="col-span-1 @4xl:col-span-2">
            <CashProfile company={company} />
          </div>
        )}
        <div className="col-span-1 @4xl:col-span-3 @6xl:col-span-2">
          <InvestedBasecampFundsCard investedBasecampFunds={investedBasecampFunds} />
        </div>
        <RevenueCard company={company} className="col-span-1 @lg:col-span-2 @4xl:col-span-3 @6xl:col-span-2" />
        <EvReturnCard company={company} className="col-span-1 @4xl:col-span-2" />
        {portCo && (
          <div className="col-span-1 @4xl:col-span-2 @6xl:col-span-2">
            <CapitalConcentration company={company} />
          </div>
        )}
        <div className="col-span-1 @4xl:col-span-2">
          <AttachmentsCommentsCard data={attachmentsComments} />
        </div>
      </div>
    </section>
  );
};
