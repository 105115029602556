import { Link } from "react-router-dom";

import { cn, parseUrl } from "@/lib/utils.ts";

import { Card, Icon } from "@/app/components";
import { Button } from "@/app/components/button";
import { ChipV2 } from "@/app/components/v2/chip-v2";
import { GetCompanyEmailDocument, useDeclineCompanyFromEmailMutation } from "@/app/service/company-email.gql.ts";

import { CompanyRawData } from "./add-company-via-email.types";

type CompanyFromEmailCardProps = {
  id: string;
  name: string;
  websiteUrl?: string | null;
  tagline?: string | null;
  image?: string | null;
  nazareUrl?: string | null;
  dealroomUrl?: string | null;
  onAdd?: (company: { name?: string; url?: string }) => void;
  onSelect?: () => void;
  addActionLabel?: string;
  enableDecline?: boolean;
  hideNazareTag?: boolean;
  status: CompanyRawData["status"];
};

export const CompanyFromEmailCard = ({
  id: companyId,
  name,
  websiteUrl,
  image,
  onAdd,
  nazareUrl,
  addActionLabel = "Review and add",
  enableDecline,
  hideNazareTag,
  onSelect,
  status,
}: CompanyFromEmailCardProps) => {
  const [declineCompany, { loading: decliningCompany }] = useDeclineCompanyFromEmailMutation();

  const handleDecline = (companyId: string) => {
    declineCompany({
      variables: {
        input: {
          candidateCompanyId: companyId,
        },
      },
      refetchQueries: [GetCompanyEmailDocument],
    });
  };

  return (
    <div onMouseEnter={onSelect}>
      <Card
        className={cn(
          "group relative flex select-none flex-col items-center gap-y-2 bg-gray-100 transition-colors hover:bg-gray-200",
        )}
      >
        <Card.Header className="space-x-2 overflow-hidden">
          <Card.Image src={parseUrl(image)} size={"sm"} type="company" />
          <Card.Title title={name} styles={{ className: "min-w-0 shrink", titleClassName: "!text-sm mr-0" }} />
          <div className="!ml-auto">
            {!hideNazareTag && !!nazareUrl && (
              <Link
                to={nazareUrl}
                target="_blank"
                rel="noreferrer"
                className="flex shrink-0 items-center gap-1 rounded-xxs border border-gray-300 bg-white px-2 py-1 text-xs"
              >
                <Icon type="ExternalLink" className="size-3" />
                <span className="font-semibold">Already in Nazare</span>
              </Link>
            )}
            {status === "COMPLETED" && !!nazareUrl && (
              <a href={nazareUrl} target="_blank" rel="noreferrer">
                <ChipV2 iconLeft="ExternalLink" label="Added" color="green" />
              </a>
            )}
            {status === "DECLINED" && <ChipV2 label="Skipped" color="gray" />}
          </div>
        </Card.Header>
        <Card.Body>
          {!!websiteUrl && (
            <a href={websiteUrl} target="_blank" rel="noreferrer" className="text-sm text-day-blue-700 hover:underline">
              {websiteUrl}
            </a>
          )}
        </Card.Body>
        {(enableDecline || onAdd) && (
          <Card.Footer className="mt-auto">
            <div className="flex justify-between gap-4">
              {enableDecline && (
                <Button
                  size="sm"
                  className="flex-1"
                  variant="outline"
                  onClick={() => handleDecline(companyId)}
                  loading={decliningCompany}
                >
                  Skip
                </Button>
              )}
              {onAdd && (
                <Button
                  size="sm"
                  className="flex-1"
                  variant="outline"
                  disabled={decliningCompany}
                  onClick={() => onAdd({ name, url: websiteUrl ?? undefined })}
                >
                  {addActionLabel}
                </Button>
              )}
            </div>
          </Card.Footer>
        )}
      </Card>
    </div>
  );
};
