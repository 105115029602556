import posthog from "posthog-js";
import { getEnv } from "../env/env";

export const initPosthog = () => {
  const postHogKey = getEnv("POSTHOG_KEY");
  const postHogHost = getEnv("POSTHOG_HOST");

  if(!postHogKey || !postHogHost) {
    return;
  }

  posthog.init(postHogKey, {
    api_host: postHogHost,
    autocapture: true,
    on_xhr_error: () => null,
  });
}
