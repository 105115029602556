import { useState } from "react";

import Highlighter from "react-highlight-words";

import { getLinkedinProfileId, isValidLinkedinProfileURL } from "@/lib/linkedin.utils";
import { cn, getInitials, parseUrl } from "@/lib/utils";

import { AvatarV2, LoadingOverLay } from "@/app/components";
import {
  Autocomplete,
  AutocompleteInput,
  AutocompleteOption,
  AutocompleteOptions,
  AutocompleteProgressSpinner,
  AutocompleteToggleButton,
} from "@/app/components/forms/autocomplete";
import { InputV2 } from "@/app/components/forms/input-v2";
import { Icon } from "@/app/components/icon";

import { FounderChangeData, FounderSearchResult, isDealroomFounder } from "./founder-search-v2.types";
import { founderSearchCharacterLimit, useFounderSearchV2 } from "./use-founder-search-v2";

type FounderSearchV2Props = {
  onChange: (founder: FounderChangeData) => void;
  value?: string;
  selectedFounder?: FounderSearchResult | null;
  placeholder?: string;
};

const FounderSearchInput = ({ handleSearch, loading, placeholder }) => {
  const displayValue = (founder: FounderSearchResult | string | null): string => {
    return typeof founder === "string" ? founder : founder?.name ?? "";
  };

  return (
    <div className="group/select relative">
      <AutocompleteInput
        as={InputV2}
        className="focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
        displayValue={displayValue}
        onChange={(event) => {
          handleSearch(event.target.value);
        }}
        autoComplete="off"
        placeholder={placeholder}
      />
      <div className="absolute inset-y-0 right-1.5 flex items-center gap-2">
        {loading && <AutocompleteProgressSpinner />}
        <AutocompleteToggleButton />
      </div>
    </div>
  );
};

export const FounderSearchV2 = (props: FounderSearchV2Props) => {
  const { onChange, value, placeholder = "Search for founder or paste LinkedIn url" } = props;
  const { results, loading, search } = useFounderSearchV2();
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (searchVal: string) => {
    search(searchVal);
    setSearchTerm(searchVal);
  };

  const handleSelect = (founder: FounderSearchResult | string | null) => {
    onChange(founder);
  };

  const displayResults = searchTerm.length >= founderSearchCharacterLimit;

  return (
    <Autocomplete
      loading={loading}
      value={value}
      onQueryChange={handleSearch}
      onChange={handleSelect}
      multiple={false}
      inputComponent={<FounderSearchInput loading={loading} handleSearch={handleSearch} placeholder={placeholder} />}
      options={
        <AutocompleteOptions
          transition
          className={cn(
            "w-[var(--input-width)] rounded-xs border border-gray-300 bg-white [--anchor-gap:var(--spacing-1)]",
            "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0",
            "z-10",
            "absolute shadow-sm",
            "flex max-h-64 flex-col [--anchor-max-height:50vh]",
          )}
        >
          {!displayResults && (
            <div className="sticky top-0 z-[1] bg-white px-3 py-2">
              <AutocompleteOption
                value={null}
                className={cn("select-none rounded-xxs px-3 py-1.5 text-center text-sm text-gray-600")}
                disabled={true}
              >
                Start typing to see the results...
              </AutocompleteOption>
            </div>
          )}
          <div className={cn("relative overflow-y-auto", loading && "min-h-20 overflow-hidden")}>
            {loading && <LoadingOverLay className="rounded-none" />}
            {displayResults &&
              results.map((founder) => (
                <AutocompleteOption
                  key={founder.id ?? founder.name}
                  value={founder}
                  className={cn(
                    "flex select-none items-center gap-2 px-3 py-1 text-sm",
                    "data-[focus]:!bg-gray-300 data-[selected]:bg-gray-200 data-[disabled]:opacity-50",
                    "@container/founder-search-option",
                  )}
                >
                  <AvatarV2
                    type="user"
                    src={parseUrl(founder.image)}
                    className="!size-8 !min-w-8 basis-8 rounded-xxs bg-gray-200"
                    initials={founder.name ? getInitials(founder.name) : "N/A"}
                  />
                  <div className="grid w-full flex-col items-center gap-x-3 gap-y-1 overflow-hidden text-sm @lg/founder-search-option:grid-cols-2">
                    <div className="flex items-center gap-2 overflow-hidden text-sm">
                      <Highlighter
                        searchWords={[searchTerm]}
                        textToHighlight={founder.name}
                        className="truncate"
                        highlightClassName="font-semibold bg-transparent"
                        autoEscape={true}
                      />
                      {isDealroomFounder(founder) && <Icon type="Dealroom" className="size-[1em] text-gray-500" />}
                    </div>
                    <Highlighter
                      searchWords={[searchTerm, getLinkedinProfileId(searchTerm)]}
                      textToHighlight={founder.linkedinUrl}
                      className="truncate text-xs text-gray-600"
                      highlightClassName="font-semibold bg-transparent"
                      autoEscape={true}
                    />
                  </div>
                </AutocompleteOption>
              ))}
          </div>
          {displayResults && !loading && (
            <div className="px-3 py-2">
              <AutocompleteOption
                value={searchTerm}
                className={cn(
                  "select-none rounded-xxs bg-gray-300 px-3 py-1.5 text-center text-sm font-medium text-gray-900",
                  "data-[focus]:!bg-gray-400 data-[selected]:bg-gray-300 data-[disabled]:opacity-50",
                )}
              >
                Can&apos;t find founder? Add manually
              </AutocompleteOption>
            </div>
          )}
        </AutocompleteOptions>
      }
    ></Autocomplete>
  );
};
