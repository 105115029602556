import { useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import z from "zod";

import { linkedinProfileUrlRegex } from "@/lib/linkedin.utils.ts";
import { cn, getInitials, parseUrl } from "@/lib/utils.ts";

import { AvatarV2, Button, Icon } from "@/app/components";
import { FormField } from "@/app/components/forms/form-field.tsx";
import { InputV2 } from "@/app/components/forms/input-v2.tsx";
import { FounderData } from "@/app/screens/opportunities/opportunity/components/founders/founders.types.ts";

import { FounderSearchV2 } from "../../../components/founder-search/founder-search-v2";
import { FounderChangeData, FounderSearchResult } from "../../../components/founder-search/founder-search-v2.types";

const founderSchema = z.object({
  name: z.string().min(1, "Name is required"),
  url: z
    .string()
    .regex(linkedinProfileUrlRegex, "A valid LinkedIn url is required")
    .optional()
    .nullable()
    .or(z.literal("")),
});

export type FounderFormData = z.infer<typeof founderSchema>;

const useFounderEditForm = (defaultValues?: FounderData) => {
  return useForm<FounderFormData>({
    resolver: zodResolver(founderSchema),
    mode: "all",
    defaultValues: {
      name: defaultValues?.name ?? "",
      url: defaultValues?.linkedinUrl,
    },
  });
};

const FounderPreview = ({
  founder,
  onClear,
  errors,
}: {
  founder: FounderSearchResult;
  onClear: () => void;
  errors?: string;
}) => {
  return (
    <div>
      <div className="flex items-center gap-2 rounded-sm border border-gray-300 p-2">
        <AvatarV2
          size="lg"
          type="user"
          src={parseUrl(founder.image)}
          initials={founder.name ? getInitials(founder.name) : "N/A"}
        />
        <span className="text-lg font-semibold">{founder.name}</span>
        {founder.linkedinUrl && (
          <a
            className={"flex cursor-pointer select-none items-center text-sm font-semibold text-linkedin underline"}
            href={founder.linkedinUrl}
            target="_blank"
            rel="noreferrer"
          >
            <Icon type={"Linkedin"} className="size-4" />
            <span className="ml-0.5">{(founder.linkedinUrl.split("/in/").at(-1) ?? "").replace("/", "")}</span>
          </a>
        )}
        <Button onClick={onClear} type="button" variant="outline" className="ml-auto">
          Clear
        </Button>
      </div>
      {!!errors && <div className="mt-2 rounded-xs bg-red-300 p-4 text-sm">{errors}</div>}
    </div>
  );
};

export const FounderEdit = ({
  founder,
  onClose,
  onSubmit,
  className,
}: {
  founder?: FounderData;
  onSubmit: (data: FounderFormData, selectedFounder?: FounderSearchResult | null) => void;
  onClose: () => void;
  className?: string;
}) => {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useFounderEditForm(founder);
  const [selectedFounder, setSelectedFounder] = useState<FounderSearchResult | null>(null);

  const [shouldCreateNew, setShouldCreateNew] = useState(false);

  const handleCancel = () => {
    reset();
    onClose();
  };

  const handleClearSelected = () => {
    setSelectedFounder(null);
    reset();
  };

  const errorMessage = Object.values(errors)
    .map((error) => error.message)
    .filter(Boolean)
    .join("; ");

  const submit = handleSubmit((data) => {
    onSubmit(data, selectedFounder);
  });

  const onFounderChange = (founder: FounderChangeData) => {
    if (founder && typeof founder === "object") {
      setSelectedFounder(founder);
      setValue("name", founder.name, { shouldValidate: true });
      setValue("url", founder.linkedinUrl, { shouldValidate: true });
    } else if (typeof founder === "string") {
      setShouldCreateNew(true);

      const isLinkedinUrl = founder.includes("linkedin.com");

      setValue("name", isLinkedinUrl ? "" : founder, { shouldValidate: true });
      setValue("url", isLinkedinUrl ? founder : "", { shouldValidate: true });
    }
  };

  return (
    <form
      className={cn(
        "flex flex-col justify-between rounded-sm border border-gray-300 p-4 shadow-sm @container/founder-edit",
        className,
        shouldCreateNew && "@3xl/founders:row-span-3",
      )}
      onSubmit={submit}
    >
      <h3 className="mb-3 text-lg font-semibold text-gray-900">Add new founder</h3>
      {!!selectedFounder && (
        <FounderPreview founder={selectedFounder} onClear={handleClearSelected} errors={errorMessage} />
      )}
      {!selectedFounder && !shouldCreateNew && (
        <div className="w-full lg:max-w-[700px]">
          <FounderSearchV2 onChange={onFounderChange} />
        </div>
      )}
      {!selectedFounder && shouldCreateNew && (
        <div className="flex w-full flex-col gap-3">
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <FormField label="Founder name" error={fieldState.error?.message} required>
                <InputV2
                  placeholder="Enter founder name"
                  value={field.value || ""}
                  onChange={({ target }) => field.onChange(target.value)}
                  disabled={field.disabled}
                />
              </FormField>
            )}
          />
          <Controller
            name="url"
            control={control}
            render={({ field, fieldState }) => (
              <FormField label="LinkedIn URL" error={fieldState.error?.message}>
                <InputV2 {...field} placeholder={"https://www.linkedin.com/in/user-name"} value={field.value || ""} />
              </FormField>
            )}
          />
          <Button
            className="w-full self-end"
            variant="outline"
            size="sm"
            onClick={() => setShouldCreateNew(false)}
            iconLeft="Search"
          >
            Back to search
          </Button>
        </div>
      )}
      <div className="mt-4 flex justify-end gap-4">
        <Button className={"w-full min-w-40 lg:w-auto"} variant="outline" size={"md"} onClick={handleCancel}>
          Cancel
        </Button>
        <Button className={"w-full min-w-40 lg:w-auto"} size={"md"} variant="gradient" disabled={!isValid}>
          Save
        </Button>
      </div>
    </form>
  );
};
