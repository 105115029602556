import React from "react";

import { getInitials, parseUrl } from "@/lib/utils";

import { AvatarV2, Button } from "@/app/components";
import { IconButtonV2 } from "@/app/components/button/icon-button-v2";

export const FounderPreview = ({
  founder,
  onRemove,
  disabled,
}: {
  founder: { id: string; linkedinUrl?: string | null; name?: string; image?: string; titles?: { name: string }[] };
  onRemove?: () => void;
  disabled?: boolean;
}) => {
  const titles = typeof founder === "object" && "titles" in founder ? founder.titles : null;

  return (
    <div className={"flex w-full flex-1 items-center rounded-xs border border-gray-300 p-1 pr-2 @container"}>
      <AvatarV2
        src={parseUrl(founder.image)}
        type="user"
        size={"sm"}
        initials={founder.name ? getInitials(founder.name) : ""}
      />
      <div className="ml-2 flex flex-1 flex-col overflow-hidden @md:flex-row @md:items-center">
        <span className="text-nowrap text-sm font-medium">{founder.name}</span>
        {!!titles?.length && (
          <div className="truncate text-sm font-medium text-gray-600 @md:mr-1">
            {titles.map((title) => (
              <React.Fragment key={title.name}>
                <span className="mx-2 first:hidden @md:first:inline">•</span>
                {title?.name}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
      <div className="ml-auto flex shrink-0 items-center gap-3">
        {founder.linkedinUrl && (
          <a href={founder.linkedinUrl} target="_blank" rel="noreferrer">
            <Button className="hidden @md:block" text="Open LinkedIn" size="xs" variant="outline" type="button" />
            <IconButtonV2 className="@md:hidden" size="xs" variant="outline" type="button" icon="Linkedin" />
          </a>
        )}
        {onRemove && (
          <IconButtonV2 icon="X" className="hidden lg:block" size="xs" onClick={onRemove} disabled={disabled} />
        )}
      </div>
    </div>
  );
};
