import { observer } from "mobx-react";

import { Button, Popover } from "@/app/components";
import { SelectV2 } from "@/app/components/forms/select-v2.tsx";
import { AvailableModels, companyCopilotStore, models } from "@/app/screens/modal/copilot";

export const Header = observer(() => {
  const {
    state: { route },
  } = companyCopilotStore;

  if (route !== "messages") {
    return null;
  }
  const {
    goBack,
    currentConversation: {
      modelSettings: { model },
    },
    editConversation,
    addConversation,
  } = companyCopilotStore;

  return (
    <div className="flex items-center justify-between border-b px-4 py-3">
      <div className="flex items-center">
        <Button iconLeft={"ArrowLeft"} size={"sm"} variant={"text"} onClick={() => goBack()}>
          <span className="ml-2 select-none text-sm font-semibold">Back</span>
        </Button>
      </div>
      <div className="flex items-center space-x-4">
        <Popover
          placement={"bottom"}
          childrenClassName={"w-60 z-30"}
          childrenContent={
            <span>
              Try to keep contexts separate. LLMs predict the next token based on the current conve rsation, so
              frequently switching topics can reduce accuracy.
            </span>
          }
        >
          <Button
            iconLeft={"ChatBubble"}
            size={"sm"}
            variant={"outline"}
            onClick={() => {
              addConversation();
            }}
          >
            New Chat
          </Button>
        </Popover>
        <div className={"flex items-center space-x-1"}>
          <Popover
            placement={"bottom"}
            childrenClassName={"w-60 z-30"}
            childrenContent={
              <span className={"text-sm"}>
                Play to each model’s strengths! You can switch LLM providers anytime, and they’ll keep the full context,
                so feel free to experiment.
              </span>
            }
          >
            <span className={"select-none text-sm font-normal"}>Model:</span>
          </Popover>
          <SelectV2
            buttonClassName={"w-40 h-8"}
            value={model}
            onChange={(model) => {
              editConversation({ modelSettings: { model: model as AvailableModels } });
            }}
            options={models}
          />
        </div>
        <Popover
          placement={"bottom"}
          childrenClassName={"w-60 z-30"}
          childrenContent={<span>The system prompt guides the AI’s behavior, tone, and response style.</span>}
        >
          <Button
            iconLeft={"Code"}
            size={"sm"}
            variant={"outline"}
            onClick={() => companyCopilotStore.setState({ route: "system" })}
          >
            System Prompt
          </Button>
        </Popover>
      </div>
    </div>
  );
});
