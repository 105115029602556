import { observer } from "mobx-react";
import toast from "react-hot-toast";

import { Button, TextAreaV2 } from "@/app/components";
import { companyCopilotStore } from "@/app/screens/modal/copilot";

export const SystemPrompt = observer(() => {
  const {
    currentConversation: { systemPrompt },
    editConversation,
    setState,
  } = companyCopilotStore;
  const tokenCount = Math.floor(systemPrompt.length / 5);

  return (
    <div className="flex flex-col space-y-4">
      <TextAreaV2
        className={"max-h-[80dvh] min-h-[70dvh] overflow-y-auto"}
        value={systemPrompt}
        onChange={(e) => editConversation({ systemPrompt: e.target.value })}
      />
      <div className={"flex w-full items-center justify-between"}>
        <div className={"mx-2 flex space-x-2"}>
          <Button
            iconLeft={"Check"}
            size={"sm"}
            variant={"gradient"}
            onClick={() => {
              toast.success("System prompt updated.");
              setState({ route: "messages" });
            }}
          >
            Save
          </Button>
          <Button iconLeft={"Reset"} size={"sm"} variant={"secondary"}>
            Reset
          </Button>
        </div>
        <span className={"mx-2 text-sm font-semibold"}>{tokenCount} tokens</span>
      </div>
    </div>
  );
});
