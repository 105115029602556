import React from "react";

import { observer } from "mobx-react";
import { useNavigate } from "react-router";

import { cn } from "@/lib/utils.ts";

import { investmentStore, investmentFunds } from "@/app/screens/investments";

export const MtpHeader = observer(() => {
  const navigate = useNavigate();
  const activeFund = investmentStore.state.activeFund;
  return (
    <div className="fixed z-10 flex h-fit w-full flex-row items-center justify-between px-4 backdrop-blur-md">
      <div className={"no-scrollbar flex w-full items-center gap-x-6 overflow-auto bg-opacity-[45] py-3 pl-2"}>
        {investmentFunds.map(({ value, label }, index) => {
          const isSelected = activeFund.value === value;
          return (
            <div key={index} className={"flex w-fit items-center text-nowrap"}>
              <span
                className={cn(
                  "w-fit cursor-pointer transition-all ease-in-out lg:text-sm 2xl:text-sm",
                  "select-none hover:text-neutral-600",
                  isSelected ? "hover:text-neutral-900" : "",
                  isSelected ? "text-neutral-900" : "text-neutral",
                  isSelected ? "font-semibold" : "font-medium",
                )}
                onClick={() => {
                  navigate(`/fund-overview/${value}`);
                }}
              >
                {label}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
});
