import { Switch } from "@headlessui/react";
import { motion } from "framer-motion";

import { cn } from "@/lib/utils.ts";

import { ProgressSpinner } from "@/app/components";

export type BtnToggleProps = {
  value?: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  size?: "sm" | "md" | "lg";
  labelRight?: string;
  labelLeft?: string;
  loading?: boolean;
  className?: string;
  "data-cy"?: string;
};

export function BtnToggle({
  value = false,
  onChange,
  disabled,
  size = "md",
  labelRight = "Yes",
  labelLeft = "No",
  loading,
  className,
  ...props
}: BtnToggleProps) {
  if (className?.includes("p-") || className?.includes("w-") || className?.includes("h-")) {
    console.warn("A custom sizing used inside switch toggle");
  }

  return (
    <Switch
      data-cy={props["data-cy"]}
      checked={value}
      disabled={disabled || loading}
      onChange={onChange}
      className={cn(
        "relative grid shrink-0 cursor-pointer grid-cols-2 items-center overflow-hidden border-transparent bg-gray-400 p-px  transition-colors duration-300 ease-in hover:bg-gray-500 focus:outline-none focus:ring-offset-2",
        size === "lg" && "h-10 rounded-[7px] text-sm",
        size === "md" && "h-8 rounded-[5px] text-sm",
        size === "sm" && "h-6 rounded-[3px] text-xs",
        "disabled:cursor-not-allowed disabled:bg-gray-500",
        className,
      )}
    >
      <span
        className={cn(
          "px-4 text-center font-semibold text-gray-600 opacity-100 transition-opacity",
          !value && "opacity-0",
        )}
      >
        {labelLeft}
      </span>
      <span className={cn("px-4 font-semibold text-gray-600 opacity-100", value && "opacity-0")}>{labelRight}</span>
      <div className={cn("absolute flex size-full justify-start", value && "justify-end")}>
        <motion.div
          className={cn("pointer-events-none h-full w-1/2 overflow-hidden p-px")}
          layout
          transition={{
            type: "spring",
            stiffness: 600,
            damping: 30,
          }}
        >
          <div
            className={cn(
              "flex h-full flex-1 items-center justify-center bg-white",
              size === "lg" && "rounded-xs text-sm",
              size === "md" && "rounded-xxs text-sm",
              size === "sm" && "rounded-[2px] text-xs",
            )}
          >
            {loading ? (
              <ProgressSpinner className={cn("size-4/5")} />
            ) : (
              <span className="font-semibold text-gray-900">{value ? labelRight : labelLeft}</span>
            )}
          </div>
        </motion.div>
      </div>
    </Switch>
  );
}
