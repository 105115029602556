import React, { isValidElement, Key, PropsWithChildren, ReactNode } from "react";

import {
  Combobox,
  ComboboxButton,
  ComboboxButtonProps,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  ComboboxProps,
} from "@headlessui/react";

import { cn } from "@/lib/utils.ts";

import { Icon, ProgressSpinner } from "@/app/components";
import { IconButtonV2 } from "@/app/components/button";

import { InputV2 } from "./input-v2";

// re-exporting components just in case we want to replace @headlessui with something else later
export const AutocompleteOptions = ComboboxOptions;
export const AutocompleteOption = ComboboxOption;
export const AutocompleteInput = ComboboxInput;
export const AutocompleteButton = ComboboxButton;

export const AutocompleteToggleButton = ({ ...props }: ComboboxButtonProps<"div">) => (
  <AutocompleteButton as="div" {...props} className={cn("group/select-btn size-6")}>
    <IconButtonV2
      icon={<Icon type="Chevron Down" className="size-full transition group-data-[open]/select-btn:-scale-100" />}
      size="xs"
      className="transition duration-300 ease-in group-hover/select:brightness-90"
    />
  </AutocompleteButton>
);

export const AutocompleteClearButton = ({ onClick }: { onClick?: () => void }) => (
  <IconButtonV2
    icon="X"
    size="xs"
    onClick={onClick}
    className="transition duration-300 ease-in group-hover/select:brightness-90"
  />
);

export const AutocompleteProgressSpinner = () => <ProgressSpinner className="size-4 text-inherit" />;

type GetDisplayValueFn<TValue, TMultiple extends boolean | undefined> = TMultiple extends true
  ? (value: TValue) => string
  : TValue extends object
    ? (value: TValue) => string
    : never;

type AutocompleteOption<TOptionValue> = {
  label: string;
  value: TOptionValue;
  disabled?: boolean;
};

export type AutocompleteProps<TValue, TMultiple extends boolean | undefined, TOptionValue = TValue> = {
  multiple?: TMultiple;
  value: ComboboxProps<TValue, TMultiple>["value"];

  onQueryChange?: (value: string) => void;
  onChange: ComboboxProps<TValue, TMultiple>["onChange"];
  onClear?: () => void;

  options?: AutocompleteOption<TOptionValue>[] | ReactNode;
  inputComponent?: NonNullable<ReactNode>;

  compareBy?: ComboboxProps<TValue, TMultiple>["by"];
  getDisplayValue?: GetDisplayValueFn<TValue, TMultiple>;

  loading?: boolean;
  clearOnClose?: boolean;

  placeholder?: string;
} & (TOptionValue extends object
  ? {
      getOptionKey: (optionValue: TOptionValue) => string | number;
    }
  : {
      getOptionKey?: (optionValue: TOptionValue) => string | number;
    });

const isCustomElement = <T,>(element: ReactNode | T): element is ReactNode => {
  return isValidElement(element);
};

export const Autocomplete = <TValue, TMultiple extends boolean | undefined, TOptionValue>({
  value,
  onChange,
  onClear,
  multiple,
  options,
  onQueryChange,
  getDisplayValue,
  getOptionKey,
  loading,
  inputComponent,
  compareBy,
  placeholder,
  clearOnClose = true,
}: PropsWithChildren<AutocompleteProps<TValue, TMultiple, TOptionValue>>) => {
  const getKey = (option: AutocompleteOption<TOptionValue>, index: number): Key => {
    if (typeof getOptionKey === "function") {
      return getOptionKey(option.value);
    }

    return typeof option.value === "string" || typeof option.value === "number" ? option.value : index;
  };

  return (
    <Combobox
      value={value}
      multiple={multiple}
      onChange={onChange}
      immediate={true}
      by={compareBy}
      onClose={() => {
        clearOnClose && onQueryChange?.("");
      }}
    >
      {isCustomElement(inputComponent) ? (
        inputComponent
      ) : (
        <div className="group/select relative w-full">
          <AutocompleteInput
            as={InputV2}
            className={cn(
              "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25",
              "pr-10", // for the chevron icon
            )}
            displayValue={getDisplayValue}
            onChange={(event) => {
              onQueryChange?.(event.target.value);
            }}
            placeholder={placeholder}
            autoComplete="off"
          />
          <div className="absolute inset-y-0 right-1.5 flex items-center gap-2">
            {loading && <AutocompleteProgressSpinner />}
            {value && onClear && <AutocompleteClearButton onClick={onClear} />}
            <AutocompleteToggleButton />
          </div>
        </div>
      )}
      {isCustomElement(options) ? (
        options
      ) : (
        <AutocompleteOptions
          anchor="bottom"
          transition
          className={cn(
            "w-[var(--input-width)] rounded-xxs border border-gray-300 bg-white [--anchor-gap:var(--spacing-1)] empty:invisible",
            "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0",
            "z-10", // needed because of broken location input
          )}
        >
          {options?.map((option, index) => (
            <AutocompleteOption
              key={getKey(option, index)}
              value={option.value}
              className={cn(
                "group flex select-none items-center gap-2 p-3 text-sm font-medium",
                "data-[focus]:!bg-gray-200 data-[selected]:bg-gray-100 data-[disabled]:opacity-50",
              )}
            >
              <div className="text-sm">{option.label}</div>
            </AutocompleteOption>
          ))}
        </AutocompleteOptions>
      )}
    </Combobox>
  );
};
