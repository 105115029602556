import { Button } from "@/app/components";
import { RequestSentimentModal } from "@/app/screens/modal";
import { EmptySection } from "@/app/screens/opportunities/opportunity/components";
import { OpportunityMaximise } from "@/app/screens/opportunities/opportunity/components/maximise/maximise.tsx";
import { SentimentTable } from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-table.tsx";
import { useSentiment } from "@/app/screens/opportunities/opportunity/components/sentiment/use-sentiment.tsx";
import modalStore from "@/app/stores/modal.store.tsx";

import { SentimentChart } from "./sentiment-chart.tsx";
import { SentimentContextProvider } from "./sentiment-context.tsx";

export const Sentiment = ({ company }) => {
  return (
    <SentimentContextProvider>
      <SentimentContent company={company} />
    </SentimentContextProvider>
  );
};

const SentimentContent = ({ company }) => {
  const { maximised, setMaximised, sentimentData, showEmptyState } = useSentiment();

  if (showEmptyState) {
    return (
      <EmptySection
        className="col-span-2 @3xl/founders:grid-cols-3"
        text={"No Sentiment Forms Filled"}
        Action={
          <Button
            text="Add Sentiment"
            size="sm"
            variant="outline"
            className="mt-4"
            onClick={() => {
              modalStore.open(RequestSentimentModal, {
                props: {
                  type: "sentiment",
                  company,
                },
              });
            }}
          />
        }
      />
    );
  }

  return (
    <>
      <div className={"pointer-events-auto flex flex-col items-center justify-between rounded-md bg-white pb-3"}>
        <SentimentChart sentimentData={sentimentData} />
        <SentimentTable sentimentData={sentimentData} />
      </div>
      <OpportunityMaximise setMaximised={setMaximised} maximised={maximised}>
        <SentimentChart sentimentData={sentimentData} />
      </OpportunityMaximise>
    </>
  );
};
