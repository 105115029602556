export enum Routes {
  forms = "/forms",
  company = "/company",
  addCompany = "/add-company",

  portfolio = "/portfolio",
  prospects = "/prospects",
  pipeline = "/pipeline",

  investments = "/investments",
  "fund-overview" = "/fund-overview",

  metricsExtraction = "/metrics-extraction",

  network = "/network",

  settings = "/settings",
  home = "/home",

  kanban = "/kanban",
}
