import { getEnv } from "@/app/env/env.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

const path = "copilot/generate_title";

export const getTitle = async (initialMessage: string) => {
  const req = await fetch(`${getEnv("NAZARE_API_DOMAIN")}/${path}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authStore.accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      message: initialMessage,
    }),
  });
  const res = await req.json();
  console.log({ req, res });
  return res;
};
