// @ts-nocheck

import React, { PropsWithChildren, useEffect } from "react";

import { Link } from "@tiptap/extension-link";
import { Placeholder } from "@tiptap/extension-placeholder";
import { BubbleMenu, EditorContent, Extensions, FloatingMenu, useEditor, UseEditorOptions } from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";

import { cn } from "@/lib/utils.ts";

import { useMentionsExtension, RichTextEditorToolbar } from "@/app/components";

import "./rich-text-editor.css";

const defaultExtensions: Extensions = [
  StarterKit,
  Link.configure({
    openOnClick: false,
    autolink: true,
    defaultProtocol: "https",
  }),
];

type RichTextEditorProps = UseEditorOptions & {
  className?: string;
  placeholder?: string;
};

export const RichTextEditor = ({
  children,
  className,
  placeholder,
  ...options
}: PropsWithChildren<RichTextEditorProps>) => {
  const mentionExtension = useMentionsExtension();

  const editorExtensions =
    options.extensions ??
    [
      ...defaultExtensions,
      mentionExtension,
      placeholder
        ? Placeholder.configure({
            placeholder: placeholder,
          })
        : null,
    ].filter(Boolean);

  const editor = useEditor({
    extensions: editorExtensions,
    ...options,
  });

  // update inner content when external content changes since it's probably cached
  useEffect(() => {
    if (editor && options.content) {
      editor.commands.setContent(options.content);
    }
  }, [options.content]);

  if (!editor) {
    return null;
  }

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { editor });
    }
    return child;
  });

  return (
    <div data-cy="comment-editor" className={cn("relative", className)}>
      <EditorContent data-cy="content-editor" editor={editor} />
      <BubbleMenu editor={editor} tippyOptions={{ maxWidth: 400, placement: "top-start" }}>
        <RichTextEditorToolbar editor={editor} className="border shadow-sm" />
      </BubbleMenu>
      <FloatingMenu editor={editor} tippyOptions={{ maxWidth: 400, placement: "top-start" }}>
        <RichTextEditorToolbar editor={editor} className="border shadow-sm" />
      </FloatingMenu>

      {childrenWithProps}
    </div>
  );
};
