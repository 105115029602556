import { gql } from "@apollo/client";

export const contactsLookupQuery = gql`
  query networkContactsLookupV2($q: String, $sort: String, $filters: String) {
    contacts: networkContactsLookupV2(q: $q, sort: $sort, filterS: $filters) {
      nodes {
        email
        primaryName 
        totalTo
        totalFrom
        lastTo
        lastFrom
        connections
        connectionStrength
      }
    }
  }
`;

export const contactsNDomainsLookupQuery = gql`
  query networkContactsLookupV2($q: String, $sort: String, $filters: String) {
   domains: networkDomainSimpleLookup(q: $q, max: 10) {
      nodes {
        domain
        snippet
        lastSent
        lastReceived
        connStrength
        tags
        portfolioCompanyId
      }
    }

    meta: networkContactsLookupMeta(q: $q, filterS: $filters) {
      total
    }

    contacts: networkContactsLookupV2(q: $q, sort: $sort, filterS: $filters) {
      nodes {
        email
        primaryName 
        totalTo
        totalFrom
        lastTo
        lastFrom
        connections
        connectionStrength
      }
    }
  }
`;

export const relatedContactsLookupQuery = gql`
  query networkRelatedContactsLookupV2($contactEmail: String, $sort: String, $filters: String) {
    contacts: networkRelatedContactsLookupV2(contactEmail: $contactEmail, sort: $sort, filterS: $filters) {
      nodes {
        email
        primaryName 
        totalTo
        totalFrom
        lastTo
        lastFrom
        connections
        connectionStrength
      }
    }
  }
`;


export const portfolioContactsLookupQuery = gql`
  query portfolioContactsLookupQuery($companyId: String, $sort: String, $filters: String) {
    contacts: networkPortfolioContactsLookupV2(companyId: $companyId, sort: $sort, filterS: $filters) {
      nodes {
        email
        primaryName 
        totalTo
        totalFrom
        lastTo
        lastFrom
        connections
        connectionStrength
      }
    }
  }
`;
