// @ts-nocheck

import { ReactNode, useEffect, useMemo } from "react";

import { Controller, UseFormReturn } from "react-hook-form";

import { cn } from "@/lib/utils.ts";

import { BaseToggle } from "@/app/components";
import { FormField } from "@/app/components/forms/form-field.tsx";
import { InputV2 } from "@/app/components/forms/input-v2.tsx";
import { SelectV2 } from "@/app/components/forms/select-v2.tsx";
import { TextAreaV2 } from "@/app/components/forms/textarea-v2.tsx";
import { CompanySignalSchema } from "@/app/screens/add-company/company-signals-step/company-signals-form.ts";
import { NetworkSearchAutocomplete } from "@/app/screens/opportunities/components/network-search-autocomplete.tsx";
import {
  getSourceOptionByValue,
  sourceOptions,
} from "@/app/screens/opportunities/opportunity/components/network-signals/network-signal-utils.ts";

import { CompanySignalToggles } from "../add-company-view.types.tsx";

const toggles: Array<{ name: CompanySignalToggles; label: string; description?: string }> = [
  // { name: "introAvailable", label: "Direct intro available?" },
  { name: "activelyRaising", label: "Is the company actively fundraising?" },
  { name: "highPriority", label: "Is this a high priority?" },
];

type FormData = Omit<CompanySignalSchema, "fund">;

export type SignalFormFieldProps = {
  form: UseFormReturn<FormData>;
  extraTogglesLeft?: ReactNode;
  extraTogglesRight?: ReactNode;
  componentProps?: Partial<{
    attributes: {
      className?: string;
    };
  }>;
};

// TODO: this component is shared between edit and add screens so we should probably find better placement for it
// todo move and rename file
export const SignalFormFields = ({
  form,
  extraTogglesLeft,
  extraTogglesRight,
  componentProps,
}: SignalFormFieldProps) => {
  const control = form.control;
  const selectedSource = form.watch("sourceType");
  const selectedSourceOption = useMemo(() => getSourceOptionByValue(selectedSource), [selectedSource]);

  useEffect(() => {
    if (selectedSourceOption?.standalone) {
      form.setValue("sourceName", "");
    }
  }, [selectedSourceOption]);

  return (
    <div className="flex w-full flex-col space-y-7 @container">
      <div className={cn("grid grid-cols-1 gap-4 @lg:grid-cols-2 @3xl:flex", componentProps?.attributes?.className)}>
        {extraTogglesLeft}
        {toggles.map(({ name, label }) => (
          <Controller
            key={name}
            name={name}
            control={control}
            render={({ field }) => (
              <FormField label={label} className="shrink-0">
                <BaseToggle value={field.value ?? false} onChange={(value) => field.onChange(value)} />
              </FormField>
            )}
          />
        ))}
        {extraTogglesRight}
      </div>

      <div className="grid gap-2 lg:grid-cols-2">
        <Controller
          name="sourceType"
          control={control}
          render={({ field, fieldState }) => (
            <FormField label="How did you hear about this company?" error={fieldState.error?.message} required>
              <SelectV2
                options={sourceOptions}
                onChange={(value) => field.onChange(value ?? "")}
                value={field.value}
                placeholder="Select source type"
                multiple={false}
              />
            </FormField>
          )}
        ></Controller>
        {selectedSource && !selectedSourceOption?.standalone && (
          <Controller
            name="sourceName"
            control={control}
            render={({ field, fieldState }) => {
              const selected = sourceOptions.find((source) => source.value === selectedSource);

              return (
                <FormField
                  label={selected?.subLabel ?? `Which ${selected?.label ?? selectedSource}?`}
                  error={fieldState.error?.message}
                  required
                >
                  {["event", "other", "outbound"].includes(selectedSource) ? (
                    <InputV2
                      placeholder={"Enter the name"}
                      value={typeof field.value === "string" ? field.value : field.value.name}
                      onChange={field.onChange}
                    />
                  ) : (
                    <NetworkSearchAutocomplete
                      onChange={field.onChange}
                      value={field.value}
                      placeholder="Enter the name"
                    />
                  )}
                </FormField>
              );
            }}
          />
        )}
      </div>

      <Controller
        name="note"
        control={control}
        render={({ field, fieldState }) => (
          <FormField label="Do you have any additional context you want to share?" error={fieldState.error?.message}>
            <TextAreaV2 {...field} placeholder={"Enter the context you want to share..."} value={field.value || ""} />
          </FormField>
        )}
      />
    </div>
  );
};
