import { Icon } from "@/app/components/icon";

export const CompanyStats = ({
  excitementScore,
  commentCount,
  fileCount,
}: {
  excitementScore: number | null;
  commentCount: number | null;
  fileCount: number | null;
}) => {
  return (
    <div className="flex items-center gap-2">
      {excitementScore != null && !isNaN(Number(excitementScore)) && (
        <div className="flex items-center gap-1">
          <Icon type="Sentiment" className="size-3.5 text-gray-500" />
          <span className="text-xs text-gray-900">{Number(excitementScore).toFixed(1)}</span>
        </div>
      )}
      {commentCount != null && (
        <div className="flex items-center gap-1">
          <Icon type="Comment" className="size-3.5 text-gray-500" />
          <span className="text-xs text-gray-900">{commentCount}</span>
        </div>
      )}
      {fileCount != null && (
        <div className="flex items-center gap-1">
          <Icon type="Attach" className="size-3.5 text-gray-500" />
          <span className="text-xs text-gray-900">{fileCount}</span>
        </div>
      )}
    </div>
  );
};
