// @ts-nocheck

import { useState } from "react";

import { Control, Controller } from "react-hook-form";

import { cn } from "@/lib/utils.ts";

import { Button } from "@/app/components";
import { IconButtonV2 } from "@/app/components/button/icon-button-v2.tsx";
import { FormField } from "@/app/components/forms/form-field.tsx";
import { InputV2 } from "@/app/components/forms/input-v2.tsx";
import {
  CompanyDataSchema,
  FoundersFieldArray,
} from "@/app/screens/add-company/company-data-step/company-data-form.ts";

import { FounderSearchV2 } from "../../opportunities/components/founder-search/founder-search-v2";
import {
  FounderChangeData,
  isDealroomFounder,
  isLgFounder,
} from "../../opportunities/components/founder-search/founder-search-v2.types";

import { FounderPreview } from "./company-founder-preview";

const FounderInputField = ({
  control,
  index = 0,
  handleRemove,
  disableRemove,
}: {
  control: Control<CompanyDataSchema>;
  index?: number;
  handleRemove?: () => void;
  disableRemove?: boolean;
}) => {
  const [shouldCreateNew, setShouldCreateNew] = useState(false);

  return (
    <Controller
      control={control}
      name={`founders.${index}`}
      render={({ field, fieldState }) => {
        const selectedFounder = field.value.lgFounder ?? field.value.dealroomFounder;

        const hideRemoveButtons = selectedFounder ? false : disableRemove;

        const onFounderChange = (founder: FounderChangeData) => {
          if (isDealroomFounder(founder)) {
            field.onChange({ dealroomFounder: founder });
          } else if (isLgFounder(founder)) {
            field.onChange({ lgFounder: founder });
          } else if (typeof founder === "string") {
            setShouldCreateNew(true);

            const isLinkedinUrl = founder.includes("linkedin.com");

            field.onChange({
              details: {
                name: isLinkedinUrl ? "" : founder,
                url: isLinkedinUrl ? founder : "",
              },
            });
          }
        };

        return (
          <div>
            {!!selectedFounder && (
              <FounderPreview founder={selectedFounder} onRemove={handleRemove} disabled={field.disabled} />
            )}
            {!selectedFounder && (
              <div className="flex items-center justify-between gap-1 lg:gap-2">
                {shouldCreateNew ? (
                  <div
                    className={cn(
                      "grid flex-1 gap-2 rounded-xs bg-neutral-200 p-2 lg:grid-cols-2 lg:bg-transparent lg:p-0",
                    )}
                  >
                    <FormField error={fieldState.error?.details?.name?.message} className="space-y-0 lg:space-y-1">
                      <InputV2
                        placeholder="Enter founder name"
                        value={field.value?.details?.name || ""}
                        onChange={({ target }) =>
                          field.onChange({
                            ...field.value,
                            details: { ...(field.value.details ?? {}), name: target.value },
                          })
                        }
                        disabled={field.disabled}
                      />
                    </FormField>
                    <FormField error={fieldState.error?.details?.url?.message}>
                      <div className="flex items-center gap-2">
                        <InputV2
                          iconLeft={"Linkedin"}
                          placeholder="https://linkedin.com/in/user-name"
                          value={field.value?.details?.url || ""}
                          onChange={({ target }) =>
                            field.onChange({
                              ...field.value,
                              details: { ...(field.value.details ?? {}), url: target.value },
                            })
                          }
                          disabled={field.disabled}
                        />
                      </div>
                    </FormField>
                  </div>
                ) : (
                  <div className="w-full">
                    <FounderSearchV2 onChange={onFounderChange} />
                  </div>
                )}
                {!hideRemoveButtons && (
                  <IconButtonV2
                    icon="Sub"
                    variant="secondary"
                    className="hidden lg:block"
                    onClick={handleRemove}
                    disabled={field.disabled}
                  />
                )}
              </div>
            )}
            <div className="relative mt-1 flex w-full justify-center lg:hidden">
              <hr className="absolute top-1/2 w-full" />
              {!hideRemoveButtons && (
                <IconButtonV2
                  icon="Trash"
                  variant="secondary"
                  size="xs"
                  onClick={handleRemove}
                  disabled={field.disabled}
                />
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

export const CompanyFoundersFormData = ({
  control,
  fieldArray,
}: {
  fieldArray: FoundersFieldArray;
  control: Control<CompanyDataSchema>;
}) => {
  const { fields, append, remove, replace } = fieldArray;

  const handleAddNewFounder = () => {
    append({});
  };

  const handleRemoveFounder = (index: number) => {
    //   there should be at least one input
    if (fields.length === 1) {
      replace([{}]);
    } else {
      remove(index);
    }
  };

  return (
    <>
      <div className="mb-4 space-y-1 last:space-y-1">
        {fields.map((fieldArrayItem, index, fieldsArr) => (
          <FounderInputField
            key={fieldArrayItem.id}
            control={control}
            index={index}
            handleRemove={() => handleRemoveFounder(index)}
            disableRemove={fieldsArr.length < 2}
          />
        ))}
      </div>
      <div className="mx-auto lg:mx-0 lg:w-1/2 lg:pr-1">
        <Button
          className="px-6"
          type="button"
          variant={"secondary"}
          text={"Add another founder"}
          size={"md"}
          onClick={handleAddNewFounder}
        />
      </div>
    </>
  );
};
