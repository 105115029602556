import { useEffect, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";

import { IconButtonV2 } from "../button";
import { Card } from "../card";
import { Icon } from "../icon";

import { useDarkerMode } from "./presentation-mode-hooks";

const config = {
  day: "Monday", // Monday
  start: 9, // 9am
  end: 16, // 4pm
};

export const PresentationModeBanner = () => {
  const [visible, setVisible] = useState(false);
  const { isDarker, toggleDarkerMode } = useDarkerMode();

  useEffect(() => {
    const checkTimeAndDay = () => {
      const now = new Date();

      const londonDateTime = new Intl.DateTimeFormat("en-GB", {
        timeZone: "Europe/London",
        hour: "numeric",
        hour12: false,
        weekday: "long",
      }).formatToParts(now);

      const isMonday = londonDateTime.find((part) => part.type === "weekday")?.value === config.day;

      const hours = parseInt(londonDateTime.find((part) => part.type === "hour")?.value || "0", 10);
      const isWithinTimeRange = hours >= config.start && hours < config.end;

      const shouldSwitchMode = isMonday && isWithinTimeRange && !isDarker;

      if (shouldSwitchMode) {
        setVisible(true);
        toggleDarkerMode();
      }
    };

    checkTimeAndDay();
  }, []);

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          className="fixed left-1/2 top-4 z-50 flex min-w-[300px]"
          initial={{ y: -100, opacity: 0, x: "-50%" }}
          animate={{ y: 0, opacity: 1, x: "-50%", transition: { duration: 0.3, ease: "easeInOut", delay: 0.5 } }}
          exit={{ y: -100, opacity: 0, x: "-50%" }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <Card className="w-full shadow">
            <Card.Title>
              <div className="flex items-center gap-2">
                <Icon type="Info" className="size-4 shrink-0 text-geek-blue-500" />
                <span className="text-base font-semibold">Presentation mode enabled</span>
                <IconButtonV2
                  className="ml-auto shrink-0"
                  onClick={() => setVisible(false)}
                  icon="X"
                  variant="secondary"
                  size="xs"
                />
              </div>
            </Card.Title>
            <Card.Body className="mt-1 flex flex-col pl-6 pr-8 text-sm font-medium text-gray-700">
              <span>
                Auto-enabled every{" "}
                <span className="font-semibold">
                  {config.day} {config.start}:00-{config.end}:00 (London time)
                </span>
                .
              </span>
              <span>
                Can be switched off in{" "}
                <Link className="text-blue-500 underline hover:text-blue-700" to="/settings">
                  settings
                </Link>
                .
              </span>
            </Card.Body>
          </Card>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
