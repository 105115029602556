import { useEffect } from "react";

import { NativeModal, ProgressSpinner } from "@/app/components";
import modalStore from "@/app/stores/modal.store";

import { ModalHeader } from "../components";

import { ActionButtons } from "./components/action-buttons";
import { useSelectModal } from "./hooks";
import { ModalContent } from "./modal-content";

export const AddNewNativeModal = ({ company, sentimentOnly }) => {
  const { selected, founders, loading, fetchFounders, handleSelect, handleNext } = useSelectModal({ company });

  useEffect(() => {
    if (company?.id) fetchFounders({ variables: { companyId: company.id } });
  }, [company, fetchFounders]);

  return (
    <NativeModal
      isOpen={true}
      handleClose={modalStore.close}
      detent={"content-height"}
      headerContainerClassName="p-4"
      header={
        <ModalHeader title={"Add New..."} subtitle="Please choose an option and then proceed by clicking on 'Next'." />
      }
      footer={
        <ActionButtons
          disabled={!selected}
          actions={{
            handlePrimaryAction: handleNext,
            handleCancel: modalStore.close,
          }}
        />
      }
    >
      {loading ? (
        <ProgressSpinner className="mx-auto" />
      ) : (
        <ModalContent
          sentimentOnly={sentimentOnly}
          onSelect={handleSelect}
          disableFounders={company && founders.length === 0}
        />
      )}
    </NativeModal>
  );
};
