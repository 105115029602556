import { useCallback, useEffect, useRef } from "react";

import { useSearchNazareCompaniesMutation } from "@/app/service/opportunities.gql.ts";

export const START_QUERY_AFTER = 2;

export const useSearch = () => {
  const debounceTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [query, { data, loading }] = useSearchNazareCompaniesMutation();

  const search = useCallback((q: string) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      query({ variables: { q } });
    }, 100);
  }, []);

  useEffect(() => {
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);

  return {
    results: [...(data?.searchLgCompaniesV1?.lgCompanies || [])],
    loading,
    query,
    search,
  };
};
