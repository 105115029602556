import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export type CountsData = {
  sent: number;
  received: number;
  month: string;
};

export type NetworkMessagesCountsBarChartProps = {
  data: CountsData[];
  contactEmail: string;
};


function dateEndFromMonth(v: string) {
  const endOfMonth = DateTime.fromFormat(v, "yyyy-MM", { zone: "utc" }).endOf("month");
  return endOfMonth;
}

function dateStartFromMonth(v: string) {
  const startOfMonth = DateTime.fromFormat(v, "yyyy-MM", { zone: "utc" }).startOf("month");
  return startOfMonth;
}

export function NetworkMessagesCountsBarChart(props: NetworkMessagesCountsBarChartProps) {
  const { data } = props;

  const [displayData, setDisplayData] = useState<any | null>(null);
  useEffect(() => {
    if (!data?.length) {
      setDisplayData(null);
      return;
    }

    const startDate = dateStartFromMonth(data[0].month);
    const endDate = dateEndFromMonth(data.at(-1)!.month);

    const labels: string[] = [];
    const sent: number[] = [];
    const received: number[] = [];

    let i = startDate.toMillis();
    for (;;) {
      if (+i > endDate.toMillis()) {
        break;
      }

      const iDt = DateTime.fromMillis(i, { zone: "utc", locale: "en-GB" });
      labels.push(iDt.toFormat("LLL yyyy"));
      const iMonth = iDt.toFormat("yyyy-MM");
      const row = data.find((e) => e.month === iMonth);
      sent.push(row?.sent || 0);
      received.push(row?.received || 0);
      i = iDt.plus({ months: 1 }).startOf("month").toMillis();
    }

    const incoming = {
      labels,
      datasets: [
        {
          label: "Incoming",
          data: received,
          maxBarThickness: 50,
          // backgroundColor: "#e9366b",
          backgroundColor: "#13c2c2",
          borderRadius: 3,
        },
        {
          label: "Outgoing",
          data: sent,
          maxBarThickness: 50,
          // backgroundColor: "#1677ff",
          backgroundColor: "#1677ff",
          borderRadius: 3,
        },
      ],
    };

    setDisplayData(incoming);
  }, [data]);

  const options = {
    maintainAspectRatio: false, // Allows full height and width resizing
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top" as const,
      },
      /*
      title: {
        display: true,
        text: `Communication with ${contactEmail}`,
      },*/
      tooltip: {
        mode: "index" as const,
        intersect: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: false,
          text: "Date",
        },
        grid: {
          drawTicks: true,
          color: (context) => {
            if (!context.tick) return "#eee";

            const { label } = context.tick;
            if (label && typeof label === "string" && label.startsWith("Jan ")) {
              return "#aaa";
            }

            return "#eee";
          },

          tickBorderDash: [5, 5],
          tickBorderDashOffset: 5,
        },
      },
      y: {
        stacked: true,
        beginAtZero: false,
        title: {
          display: false,
          text: "Messages",
        },
      },
    },
  };

  if (!displayData) return null;
  return <Bar data={displayData} options={options} />;
}

export default NetworkMessagesCountsBarChart;
