import { useEffect } from "react";

import * as Sentry from "@sentry/react";
import { observer } from "mobx-react";
import posthog from "posthog-js";
import { Navigate, Outlet, Route } from "react-router-dom";

import { LoadingOverLay } from "@/app/components";
import { Routes } from "@/app/constants";
import { PrivateLayout } from "@/app/router/private-layout.tsx";
import { PublicLayout } from "@/app/router/public-layout.tsx";
import { Dashboard, ErrorBoundary, Login, SettingsDashboard } from "@/app/screens";
import { AddCompanyView } from "@/app/screens/add-company/add-company-view.tsx";
import { NetworkDashboard } from "@/app/screens/network/dashboard.tsx";
import { NetworkUserSettings } from "@/app/screens/network/userSettings.tsx";
import { OpportunitiesDashboard, OpportunityView } from "@/app/screens/opportunities";
import { authStore } from "@/app/stores/auth.store.tsx";

import { AddCompaniesViaEmailView } from "../screens/add-company/via-email/add-companies-via-email.tsx";
import { FundOverview, InvestmentDashboard } from "../screens/investments";
import { KanbanBoardPage } from "../screens/kanban/kanban-board-page.tsx";
import { MetricsExtractionDashboard } from "../screens/metrics-extraction/dashboard";
import { NetworkContact } from "../screens/network/contact";
import { getEnv } from "../env/env.ts";

const RedirectLayout = observer(() => {
  if (authStore.isHydrated && authStore.user) {
    return <Navigate to={`/home`} />;
  }
  return <Navigate to={"/login"} />;
});

const WaitContextsLoad = observer(() => {
  const user = authStore.user;

  useEffect(() => {
    if (user?.email) {
      posthog?.identify(user.email, {
        ...user,
        app_version: `v${getEnv("APP_VERSION")}`,
      });
      Sentry.setUser({
        id: user.id,
        email: user.email,
        username: [user.firstName, user.lastName].filter(Boolean).join(" ") || user.initials,
      });
    }
  }, [user]);

  return !authStore.isHydrated ? (
    <LoadingOverLay className="h-screen" />
  ) : (
    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
  );
});

const MainRouter = (
  <Route element={<WaitContextsLoad />}>
    <Route element={<PrivateLayout />}>
      {/*OPPORTUNITIES*/}
      <Route path={`${Routes.company}/:id`} element={<OpportunityView />} />
      <Route path={`${Routes.addCompany}`} element={<AddCompanyView />} />
      <Route path={`${Routes.company}/batch/:id`} element={<AddCompaniesViaEmailView />} />

      <Route path={Routes.pipeline} element={<OpportunitiesDashboard />} />
      <Route path={Routes.portfolio} element={<OpportunitiesDashboard />} />
      <Route path={Routes.prospects} element={<OpportunitiesDashboard />} />

      <Route path={Routes.investments} element={<InvestmentDashboard />} />
      <Route path={Routes["fund-overview"]} element={<FundOverview />} />
      <Route path={`${Routes["fund-overview"]}/:fundId`} element={<FundOverview />} />

      <Route path={Routes.network} element={<NetworkDashboard />} />
      <Route path={`${Routes.network}/contact/:email`} element={<NetworkContact />} />
      <Route path={`${Routes.network}/settings/personal-filters`} element={<NetworkUserSettings />} />
      <Route path={Routes.home} element={<Dashboard />} />

      <Route path={Routes.metricsExtraction} element={<MetricsExtractionDashboard />} />
      <Route path={`${Routes.metricsExtraction}/:id`} element={<MetricsExtractionDashboard />} />

      <Route path={Routes.forms} element={<Dashboard />} />
      <Route path={`${Routes.forms}/:id`} element={<Dashboard />} />

      <Route path={Routes.settings} element={<SettingsDashboard />} />

      <Route path={Routes.kanban} element={<KanbanBoardPage />} />
    </Route>
    <Route element={<PublicLayout />}>
      <Route path="/login" element={<Login />} />
    </Route>

    <Route path="*" element={<RedirectLayout />} />
  </Route>
);

export { MainRouter, RedirectLayout, WaitContextsLoad };
