import { format } from "date-fns";
import z from "zod";

import { authStore } from "@/app/stores/auth.store.tsx";

import zod from "../validator.tsx";

export type InitialValues<T = unknown> = Record<string, T>;

export type FormType =
  | "AddTrello"
  | "Share"
  | "Snooze"
  | "Pipeline"
  | "SentimentRequest"
  | "SearchCompany"
  | "Add"
  | "AddEmail"
  | "ConvertToPortfolio";
export interface Field {
  name: string;
  label?: string;
  placeholder?: string;
  value?: string;
  rules?: "required" | "string" | "same:password";
}
export interface FormValues {
  fields: Array<Field | string>;
  initials?: InitialValues;
}

export type FormFields = Record<FormType, FormValues>;

export const NewOpportunityDefault = (function () {
  return {
    name: "",
    company_id: "",
    about: "",
    industries: [],
    website_url: "",
    founders: [],
    location: "",
    round: [],
    target_fund: [],
    investment_type: "",
    highPriority: false,
    lgCompany: null,
    dealroomCompany: null,
    assignees: authStore.user
      ? [
          {
            label: `${authStore?.user?.firstName} ${authStore.user?.lastName}`,
            value: authStore.user?.id,
          },
        ]
      : [],
    source: null,
    assignees_notes: null,
    note: "",
    signal_id: null,
  };
})();

export const AddOpportunityFields: Field[] = [
  {
    name: "id",
    label: "Id",
  },
  {
    name: "isDealroom",
    label: "Is Dealroom",
  },
  {
    name: "name",
    label: "Name",
  },
  {
    name: "image",
    label: "Icon",
  },
  {
    name: "about",
    label: "Company Details",
  },
  {
    name: "industries",
    label: "Industries *",
  },
  {
    name: "website_url",
    label: "Website URL *",
  },
  {
    name: "location",
    label: "HQ Location *",
  },
  {
    name: "round",
    label: "Round *",
  },
  {
    name: "target_fund",
    label: "Target Fund *",
  },
  {
    name: "assignees",
    label: "Assignees *",
  },
  {
    name: "stealth",
    label: "Stealth",
  },
  {
    name: "founders",
    label: "Founders",
  },
  {
    name: "source",
    label: "Source",
  },
  {
    name: "notes",
    label: "Notes",
  },
  {
    name: "assignees_notes",
    label: "Assignees Notes",
  },
  {
    name: "is_actively_raising",
    label: "Actively Raising *",
  },
  {
    name: "intro_available",
    label: "Intro Available *",
  },
  {
    name: "high_priority",
    label: "High Priority *",
  },
  {
    name: "auto_accept",
    label: "Auto-Accept",
  },
  {
    name: "signal_id",
    label: "Signal Id",
  },
  {
    name: "company_id",
    label: "Company Id",
  },
  {
    name: "source_type",
    label: "Heard about it from *",
  },
  {
    name: "investment_type",
    label: "Select Type *",
  },
  {
    name: "ball_in_our_control",
    label: "Ball in Our Control *",
  },
];

export const getFormFields = (initials: InitialValues = {}): FormFields => ({
  AddTrello: {
    fields: [
      {
        name: "trello_column",
        label: "Which column would you like to add the company to *",
        placeholder: "Add column",
      },
      {
        name: "assignees",
        label: "Assignees *",
        placeholder: "Add assignees",
      },
    ],
    initials: {
      trello_column: null,
      assignees: [
        {
          label: `${authStore.user?.firstName} ${authStore.user?.lastName}`,
          value: authStore.user?.id,
        },
      ],
      ...initials,
    },
  },
  Share: {
    fields: [
      {
        name: "assignees",
        label: "Assignees *",
        placeholder: "Add assignees",
      },
      {
        name: "note",
        label: "Note",
        placeholder: "Add note",
      },
    ],
    initials: {
      assignees: [],
      ...initials,
    },
  },
  ConvertToPortfolio: {
    fields: [
      {
        name: "fund",
        label: "Select Fund *",
        placeholder: "Select Fund",
      },
      {
        name: "note",
        label: "Note",
        placeholder: "Add note",
      },
    ],
    initials: {
      ...initials,
    },
  },
  Pipeline: {
    fields: [
      {
        name: "feedback_email",
        label: "Details on feedback email",
      },
    ],
    initials: {
      reason: [],
      ...initials,
    },
  },
  Snooze: {
    fields: [
      {
        name: "reason",
        label: "Why are you moving this back to Track&Revisit? *",
      },
      {
        name: "other",
        label: "Other",
      },
      {
        name: "resurface",
        label: "Resurface period *",
      },
      {
        name: "feedback_email",
        label: "Details on feedback email",
      },
    ],
    initials: {
      reason: [],
      feedback_email: "skip",
      ...initials,
    },
  },
  SentimentRequest: {
    fields: [
      {
        name: "founder",
        label: "Founder *",
      },
      {
        name: "category",
        label: "Category *",
      },
      {
        name: "meetingDate",
        label: "Date *",
        placeholder: "Add Date",
      },
      {
        name: "assignees",
        label: "Assign To *",
        placeholder: "Add note",
      },
    ],
    initials: {
      meetingDate: format(new Date(), "yyyy-MM-d"),
      assignees: [
        {
          label: `${authStore.user?.firstName} ${authStore.user?.lastName}`,
          value: authStore.user?.id,
        },
      ],
      ...initials,
    },
  },
  SearchCompany: {
    fields: [
      {
        name: "name",
        label: "Name",
      },
      {
        name: "company_id",
      },
    ],
  },
  Add: {
    fields: AddOpportunityFields,
    initials: { ...NewOpportunityDefault, ...initials },
  },
  AddEmail: {
    fields: ["opportunities", ...AddOpportunityFields.map((field) => `opportunities[].${field.name}`)],
    initials: { opportunities: [], ...initials },
  },
});

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.invalid_type) {
    if (issue.expected === "object") {
      return { message: "Field required!" };
    }
  }

  return { message: ctx.defaultError };
};

z.setErrorMap(customErrorMap);

const AddOpsSchema = z
  .object({
    name: z.string().nonempty("Required !"),
    founders: z.array(
      z
        .object({
          url: z.string().url("Invalid URL"),
          name: z.string(),
        })
        .refine(({ url }) => /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/|www.)/g.test(url)),
    ),
    about: z.string().nonempty("Please enter a company description !"),
    industries: z.array(z.any()).nonempty("Add at least one industry"),
    website_url: z
      .string()
      .nonempty("Please enter website Url !")
      .refine(
        (url) => /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/|www.)/g.test(url),
        "Please enter a valid url !",
      ),
    location: z.object({}).required(),
    round: z.object({}).required(),
    target_fund: z.object({}).required(),
    assignees: z.array(z.any()).nonempty("Set at least one person to assign this"),

    intro_available: z.boolean(),
    is_actively_raising: z.boolean(),
    high_priority: z.boolean(),
    ball_in_our_control: z.boolean(),
  })
  .passthrough();

export const zodSchema = {
  AddTrello: z.object({
    trello_column: z.object({}).required(),
    assignees: z.array(z.any()).nullish(),
  }),
  Share: z.object({
    note: z.string().nullish(),
    assignees: z.array(z.any()).nonempty("Add at least one assignee"),
  }),
  Pipeline: z.object({
    reason: z.string().nullish(),
    other: z.string().nullish(),
    resurface: z.string(),
    feedback_email: z.any(),
  }),
  ConvertToPortfolio: z.object({
    reason: z.string().nullish(),
    fund: z.string(),
  }),
  SentimentRequest: z.object({
    founder: z.any(),
    category: z.any(),
    date: z.string().min(6),
    assignees: z.array(z.any()).nonempty("Add at least one assignee"),
  }),
  Add: AddOpsSchema,
  AddEmail: z
    .object({
      opportunities: z.array(AddOpsSchema),
    })
    .required(),
};

export const getPlugins = ({ schema }) => ({
  zod: zod({
    package: z,
    schema,
    extend: ({ validator, form }) => null,
  }),
});
