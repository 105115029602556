import { NavbarV2 } from "@/app/components/v2/navbar-v2";

import { KanbanBoardFundSwitcher } from "./kanban-board-fund-switcher";
import { KanbanBoardQuickSearch } from "./kanban-board-quick-search";
import { KanbanBoardViewSwitcher } from "./kanban-board-view-switcher";

export const KanbanBoardHeader = () => {
  return (
    <NavbarV2>
      <div className="flex w-full items-center justify-around gap-4 lg:gap-16">
        <KanbanBoardFundSwitcher />
        <KanbanBoardQuickSearch />
        <KanbanBoardViewSwitcher />
      </div>
    </NavbarV2>
  );
};
