import { Icon } from "@/app/components";
import { ReactNode  } from "react";
import { useLocation, useParams } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import { useContactQuery } from "./useContactQuery";

type NetworkNavBarNodeProps = {
  title: string;
  uri?: string;
  key: string;
  idx: number;
  classes?: string;
};

export function NetworkNavBar() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { data, loading } = useContactQuery(params.email || "");


  const chain: Omit<NetworkNavBarNodeProps, "idx">[] = [
    {
      key: "home",
      title: "Network",
      uri: "/network",
    },
  ];
  
  const fq = searchParams.get("fq");
  const isContactPage = location.pathname.startsWith("/network/contact");
  if (searchParams.size) {
    if (!isContactPage) {
      chain.push({
        key: "search",
        title: "Search",
      });
    } else if (fq) {
      chain.push({
        key: "search",
        title: `Search: ${fq}`,
        uri: `/network?q=${searchParams.get("fq")}&sort=${searchParams.get("fsort")}&filter=${searchParams.get("ffilter")}`,
      });
    }
  }

  if (data?.contact) {
    chain.push({
      key: "contact",
      title: data?.contact.names?.[0],
    });
  }

  return (
    <div className="relative cursor-default sticky  top-[50px] lg:top-0 left-0 z-[11] h-12 px-4 @5xl:px-10 bg-white border-b border-gray-300 mb-4">
      <div className="flex flex-row gap-1 h-full items-center">
        {chain.map((e, idx) => (
          <NetworkNavBarNode {...e} idx={idx} />
        ))}
      </div>
    </div>
  );
}


export function NetworkNavBarNode(props: NetworkNavBarNodeProps) {
  const divClasses = "py-2 px-3 text-gray-600 text-[14px] max-w-[150px] @5xl:max-w-[300px] font-semibold text-ellipsis  whitespace-nowrap overflow-hidden";

  const linkClasses =
    "rounded-xs transition-all duration-300 px-3 py-2 text-gray-900 text-[14px] max-w-[150px] @5xl:max-w-[300px] font-semibold hover:bg-gray-300 active:bg-gray-500 hover:mx-3 text-ellipsis whitespace-nowrap overflow-hidden";
  const { title, uri, idx, classes } = props;

  let node: ReactNode = null;
  let prefix: ReactNode = null;
  if (idx > 0) {
    prefix = <Icon className="mt-[2px] mx-[-12px]" color="#8c8c8c" type="Chevron Right" width={16} height={16} />;
  }
  if (uri) {
    node = (
      <Link to={uri} className={[linkClasses, classes].filter(Boolean).join(" ")}>
        {title}
      </Link>
    );
  } else {
    node = <div className={[divClasses, classes].filter(Boolean).join(" ")}>{title}</div>;
  }

  return (
    <>
      {prefix} {node}
    </>
  );
}
