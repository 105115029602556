import React, { useCallback, useEffect, useState } from "react";

import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";

import { Action, Entity, LgCompanyStageName, LgFundName } from "@/gql/graphql";

import { Icon, Toast } from "@/app/components";
import {
  GetKanbanCompaniesQueryVariables,
  useGetCompanyStagesQuery,
  useGetKanbanCompaniesQuery,
  useOpportunityActionMutation,
} from "@/app/service/opportunities.gql.ts";

import { KanbanViewType, Sort } from "./kanban-board-types";

export const useKanbanCompanies = (companyStageIds: string[], viewType: KanbanViewType, searchQuery?: string) => {
  const variables: GetKanbanCompaniesQueryVariables = {
    filter: {
      name: searchQuery ? { fuzzy: searchQuery } : undefined,
      companyStageId: {
        in: companyStageIds,
      },
      isOpCompany:
        viewType === "concentrating" ? { equalTo: true } : viewType === "picking" ? { equalTo: false } : undefined,
    },
  };

  const { data, loading } = useGetKanbanCompaniesQuery({
    variables,
    fetchPolicy: "cache-and-network",
    skip: !companyStageIds.length,
  });

  const companies = React.useMemo(() => data?.vCompanyDetails?.nodes || [], [data?.vCompanyDetails?.nodes]);

  return {
    companies,
    loading,
  };
};

type Company = NonNullable<ReturnType<typeof useKanbanCompanies>["companies"]>[number];

export const sortCompanies = (companies: Company[], sort?: Sort) => {
  if (!sort) return companies;

  return [...companies].sort((a, b) => {
    if (sort.field === "excitementScore") {
      const scoreA = a.excitementScore ?? 0;
      const scoreB = b.excitementScore ?? 0;
      return sort.direction === "asc" ? scoreA - scoreB : scoreB - scoreA;
    }

    return 0;
  });
};

export const useGroupedCompanies = (companies: Company[], columns: { id: string; name: string }[], sort?: Sort) => {
  const [items, setItems] = useState<Record<string, (Company & { loading?: boolean })[]>>({});

  useEffect(() => {
    if (companies.length) {
      // Sort companies if sort is provided
      const sortedCompanies = sortCompanies(companies, sort);

      // Group companies by their stage IDs
      const groupedCompanies = sortedCompanies.reduce(
        (acc, company) => {
          const stageId = company.companyStageId;

          // Find which column this company belongs to
          const columnId = columns.find((column) => column.id === stageId)?.id || columns[0].id;

          if (!acc[columnId]) {
            acc[columnId] = [];
          }

          acc[columnId].push(company);
          return acc;
        },
        {} as Record<string, Company[]>,
      );

      // Ensure all columns exist in the state, even if empty
      columns.forEach((column) => {
        if (!groupedCompanies[column.id]) {
          groupedCompanies[column.id] = [];
        }
      });

      setItems(groupedCompanies);
    } else {
      setItems({});
    }
  }, [companies, columns, sort]);

  const setCompanies = useCallback(
    (companiesOrUpdater: typeof items | ((prev: typeof items) => typeof items)) => {
      setItems((prevItems) => {
        // Handle function updater pattern
        const newCompanies =
          typeof companiesOrUpdater === "function" ? companiesOrUpdater(prevItems) : companiesOrUpdater;

        // Sort each column's companies by the current sort criteria
        return Object.entries(newCompanies).reduce(
          (acc, [columnId, columnCompanies]) => {
            acc[columnId] = sortCompanies(columnCompanies, sort);
            return acc;
          },
          {} as typeof items,
        );
      });
    },
    [sort],
  );

  return { items, setItems: setCompanies };
};

export const useKanbanColumns = () => {
  const [activeTab, setActiveTab] = useState<LgFundName | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: companyStages } = useGetCompanyStagesQuery({
    variables: {
      stage: LgCompanyStageName.Prospect,
    },
    fetchPolicy: "cache-and-network",
  });

  const selectFund = (fund: LgFundName) => {
    setActiveTab(fund);

    // Update URL with the selected fund
    const newParams = new URLSearchParams(searchParams);
    newParams.set("fund", fund);
    setSearchParams(newParams);
  };

  useEffect(() => {
    // Check if fund is in URL params
    const fundParam = searchParams.get("fund") as LgFundName | null;

    // If fund param exists and is valid, use it
    if (fundParam && companyStages?.lgCompanyStages?.nodes?.some((node) => node.fund === fundParam)) {
      setActiveTab(fundParam);
      return;
    }

    // Otherwise select the first fund if none is selected
    if (!activeTab && companyStages?.lgCompanyStages?.nodes?.length) {
      const funds = [...new Set(companyStages.lgCompanyStages.nodes.map((node) => node.fund) ?? [])].filter(
        (fund) => fund !== null,
      ) as LgFundName[];

      if (funds.length > 0) {
        selectFund(funds[0]);
      }
    }
  }, [companyStages?.lgCompanyStages?.nodes, searchParams]);

  return {
    all: companyStages?.lgCompanyStages?.nodes ?? [],
    funds: [...new Set(companyStages?.lgCompanyStages?.nodes?.map((node) => node.fund) ?? [])].filter(
      (fund) => fund !== null,
    ) as LgFundName[],
    selectedFund: {
      name: activeTab,
      columns: companyStages?.lgCompanyStages?.nodes?.filter((node) => node.fund === activeTab) ?? [],
    },
    setSelectedFund: selectFund,
  };
};

export const useMoveStage = () => {
  const [actionMutation] = useOpportunityActionMutation();

  const moveStage = async (companyId: string, stageId: string) => {
    await actionMutation({
      variables: {
        action: Action.AddToTrello,
        actionPayloadInput: {
          entityId: companyId,
          trelloColumn: stageId,
        },
        entity: Entity.Company,
      },
    });

    toast.custom((t) => (
      <Toast
        title="Success!"
        visible={t.visible}
        icon={<Icon type={"FillCheck"} className="text-green-500" />}
        subTitle={"Company moved to stage"}
        handleClose={() => toast.dismiss(t.id)}
      />
    ));
  };

  return { moveStage };
};

export const useSelectedViewType = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedViewType, setSelectedViewType] = useState<KanbanViewType>(null);

  useEffect(() => {
    const viewType = searchParams.get("viewType") as KanbanViewType | null;
    setSelectedViewType(viewType);
  }, [searchParams]);

  const selectViewType = (viewType: KanbanViewType) => {
    setSelectedViewType(viewType);
    const newParams = new URLSearchParams(searchParams);
    if (viewType !== null) {
      newParams.set("viewType", viewType);
    } else {
      newParams.delete("viewType");
    }
    setSearchParams(newParams);
  };

  return { selectedViewType, selectViewType };
};
