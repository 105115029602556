import "./markdown.css";

import React, { useEffect, useState, useRef, useCallback } from "react";

import Link from "@tiptap/extension-link";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { marked } from "marked";
import Papa from "papaparse";
import * as XLSX from "xlsx";

import { cn } from "@/lib/utils.ts";

import { Button, IconButtonV2 } from "@/app/components";
import { useMessages } from "@/app/screens/modal/copilot/components/messages/use-messages.tsx";

marked.setOptions({
  gfm: true,
  breaks: true,
});

interface MarkdownReaderProps {
  markdownContent: string;
  isLoading?: boolean;
}

const MarkdownReader: React.FC<MarkdownReaderProps> = ({ markdownContent, isLoading }) => {
  const [copyIcon, setCopyIcon] = useState<"Copy" | "Check">("Copy");
  const [hasTable, setHasTable] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const { addCommentLoading, addAsComment, copyToClipboard } = useMessages();
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: { levels: [1, 2, 3, 4, 5, 6] },
        codeBlock: {},
        history: false,
        dropcursor: false,
        gapcursor: false,
      }),
      Link.configure({
        openOnClick: true,
        autolink: true,
        linkOnPaste: false,
        HTMLAttributes: {
          target: "_blank",
          rel: "noopener noreferrer",
        },
      }),
      Table.configure({ resizable: false }),
      TableRow,
      TableHeader,
      TableCell,
    ],
    content: "",
    editable: false,
    editorProps: {
      attributes: {
        "aria-label": "Formatted Content Display",
        role: "article",
      },
    },
  });

  useEffect(() => {
    if (!editor || editor.isDestroyed) {
      return;
    }

    let htmlResult: string = "";
    if (markdownContent) {
      try {
        htmlResult = marked.parse(markdownContent) as string;
      } catch (parseError) {
        htmlResult = "<p><em>Error displaying content.</em></p>";
      }
    } else {
      htmlResult = "";
    }

    editor.commands.setContent(htmlResult, false);

    const timer = setTimeout(() => {
      try {
        if (contentRef.current) {
          const tableElement = contentRef.current.querySelector("table");
          const tableFound = !!tableElement;
          setHasTable(tableFound);
        } else {
          setHasTable(false);
        }
      } catch (error) {
        setHasTable(false);
      }
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, [markdownContent, editor]);

  const getTableData = useCallback((): string[][] | null => {
    if (!contentRef.current) {
      return null;
    }
    const tableElement = contentRef.current.querySelector("table");
    if (!tableElement) {
      return null;
    }

    const tableData: string[][] = [];

    const tableHead = tableElement.querySelector("thead");
    const headerRow = tableHead ? tableHead.querySelector("tr") : null;
    let headerLength = 0;
    if (headerRow) {
      const headers: string[] = [];
      headerRow.querySelectorAll("th").forEach((th) => {
        headers.push(th.textContent?.trim() || "");
      });
      if (headers.length > 0) {
        tableData.push(headers);
        headerLength = headers.length;
      }
    }

    const tableBody = tableElement.querySelector("tbody");
    if (!tableBody || !tableHead) return null;
    const bodyRows = tableBody.querySelectorAll("tr");
    bodyRows.forEach((row, rowIndex) => {
      const rowData: string[] = [];
      row.querySelectorAll("td").forEach((td) => {
        rowData.push(td.textContent?.trim() || "");
      });

      if (headerLength === 0 && rowIndex === 0 && rowData.length > 0) {
        headerLength = rowData.length;
      }

      if (rowData.some((cell) => cell !== "") && rowData.length === headerLength) {
        tableData.push(rowData);
      }
    });

    if (tableData.length <= (tableHead ? 1 : 0)) {
      return null;
    }

    return tableData;
  }, []);

  const handleDownloadCsv = () => {
    const tableData = getTableData();
    if (!tableData) {
      return;
    }

    const csvString = Papa.unparse(tableData);
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "table-export.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleDownloadXlsx = () => {
    const tableData = getTableData();
    if (!tableData) {
      return;
    }

    try {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(tableData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "table-export.xlsx");
    } catch (error) {
      console.error("handleDownloadXlsx: Error creating Excel file:", error);
    }
  };

  return (
    <div ref={contentRef}>
      <EditorContent editor={editor} className="markdown-reader-content" />
      {!isLoading && (
        <div className={"flex items-center justify-end space-x-1.5"}>
          <Button
            variant={"outline"}
            size={"xs"}
            loading={addCommentLoading}
            onClick={() => addAsComment(markdownContent)}
          >
            Add as comment
          </Button>
          {hasTable && (
            <>
              <Button onClick={handleDownloadCsv} size={"xs"} variant={"outline"}>
                CSV
              </Button>
              <Button onClick={handleDownloadXlsx} size={"xs"} variant={"outline"}>
                Excel
              </Button>
            </>
          )}
          <IconButtonV2
            icon={copyIcon}
            className={cn("size-2.5", copyIcon === "Check" ? "animate-bounce" : "")}
            onClick={() =>
              copyToClipboard(markdownContent, () => {
                setCopyIcon("Check");
                setTimeout(() => {
                  setCopyIcon("Copy");
                }, 1500);
              })
            }
          />
        </div>
      )}
    </div>
  );
};

export { MarkdownReader };
