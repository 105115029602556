import React from "react";

import { useVirtualizer } from "@tanstack/react-virtual";

import { cn } from "@/lib/utils";

import { useKanbanBoardContext } from "../kanban-board-context";
import { DraggableItem, DroppableContainer } from "../kanban-components";
import { useGroupedCompanies } from "../kanban-hooks";

import { KanbanBoardCard } from "./kanban-board-card";

type KanbanBoardColumn = ReturnType<typeof useKanbanBoardContext>["selectedFund"]["columns"][number];
type KanbanBoardCompanies = ReturnType<typeof useGroupedCompanies>["items"][string];

export const KanbanBoardColumn = ({
  column,
  companies,
}: {
  column: KanbanBoardColumn;
  companies: KanbanBoardCompanies;
}) => {
  const parentRef = React.useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: companies?.length || 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 140,
    overscan: 5,
    measureElement: (element) => element.getBoundingClientRect().height,
  });

  const items = rowVirtualizer.getVirtualItems();

  return (
    <div className="flex h-full min-w-[275px] flex-1 flex-col overflow-hidden">
      <div className="mb-1 flex items-center gap-2">
        <h2 className="text-sm font-medium">{column.name}</h2>
        <span className="ml-1 text-sm text-gray-500">({companies?.length || 0})</span>
      </div>
      <div ref={parentRef} className="mt-2 flex-1 overflow-y-auto">
        <div
          className="relative w-full"
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
          }}
        >
          <DroppableContainer id={column.id}>
            <div
              className="absolute left-0 top-0 w-full"
              style={{
                transform: `translateY(${items[0]?.start ?? 0}px)`,
              }}
            >
              {items.map((virtualRow) => {
                const item = companies?.[virtualRow.index];
                if (!item) return null;

                return (
                  <div
                    key={item.id}
                    ref={rowVirtualizer.measureElement}
                    data-index={virtualRow.index}
                    className="w-full pt-2 first:pt-0"
                  >
                    <DraggableItem
                      className={cn(
                        "cursor-grab rounded-sm border border-gray-300 bg-white transition-colors hover:border-gray-400 hover:bg-gray-100",
                        item.loading && "pointer-events-none opacity-80",
                      )}
                      id={item.id}
                      containerId={column.id}
                    >
                      <KanbanBoardCard item={item} />
                    </DraggableItem>
                  </div>
                );
              })}
            </div>
          </DroppableContainer>
        </div>
      </div>
    </div>
  );
};
