// @ts-nocheck

import React, { useMemo } from "react";

import { ColumnDef } from "@tanstack/react-table";

import { useBreakpoints } from "@/lib/hooks";
import { cn, extractFormCategory } from "@/lib/utils.ts";

import { normaliseDate } from "@/app/misc/helpers.ts";
import { SentimentParticipants } from "@/app/screens/dashboard/sections/sentiment-form-requests/sentiment-participants.tsx";
import { opportunityModal } from "@/app/screens/opportunities/actions.ts";
import opportunitiesStore from "@/app/screens/opportunities/opportunities.store.ts";
import { OpportunityTable, RowListWithSeparator, TableText } from "@/app/screens/opportunities/opportunity/components";
import { OpportunityCompany } from "@/app/screens/opportunities/opportunity/opportunity.types.ts";
import { HeaderSortIndicator } from "@/app/components/table-new/components/table-header-cell";

type SentimentColumn = {
  companyStatus: string;
  peopleFilled: string;
  peopleRemaining: { label?: string }[];
  avgExcitement: string;
  dateAdded: string;
  timestamp: number;
} & { item: any };

const useColumns = () => {
  const { isBigTablet } = useBreakpoints();

  const columns: Array<ColumnDef<SentimentColumn>> = useMemo(
    () => [
      {
        id: "companyStatus",
        accessorKey: "companyStatus",
        header: "Company Status",
        size: 200,
        cell: ({ renderValue, row: { original } }) => (
          <div
            className={cn(
              "group flex size-full cursor-pointer select-none items-center justify-start bg-white group-hover:bg-gray-50"
            )}
          >
            <p
              className={
                "flex w-full text-wrap break-words py-3.5 text-left text-[14px] font-medium text-neutral-900 hover:text-neutral-700"
              }
              onClick={() => {
                const { item } = original;
                if (item.responses.length === 0) {
                  return;
                }
                opportunityModal({
                  id: `sentiment_details`,
                  platform: window.innerWidth <= 976 ? "mobile" : "web",
                  action: "Sentiment",
                });

                opportunitiesStore.setModalState("sentiment_details", {
                  form: item,
                });
              }}
            >
              <>{renderValue()}</>
            </p>
          </div>
        ),
        enableSorting: true,
      },
      {
        accessorKey: "participants",
        header: "Participants",
        enableSorting: true,
        size: 280,
        cell: (info) => <SentimentParticipants participants={info.renderValue()} />,
        sortingFn: (a, b) => {
          return (a.original.participants?.length || 0) - (b.original.participants?.length || 0);
        }
      },
      {
        accessorKey: "avgExcitement",
        header: "Avg Excitement",
        size: 175,
        cell: (info) => <TableText text={info.renderValue()} containerClassName={"flex"} />,
      },
      {
        accessorKey: "dateAdded",
        header: "Date Added",
        size: 150,
        cell: (info) => <TableText text={info.renderValue()} containerClassName={"flex"} />,
        sortingFn: (a, b) => {
          return a.original.timestamp - b.original.timestamp;
        },
      },
    ],
    [isBigTablet]
  );

  return columns;
};

type SentimentData = NonNullable<OpportunityCompany>["sentiment"]["nodes"];

export const SentimentTable = ({ sentimentData }: { sentimentData: SentimentData }) => {
  const columns = useColumns();
  const { isBigTablet } = useBreakpoints();

  const tableData: SentimentColumn[] = sentimentData.map((item) => {
    const peopleEngaged =
      item?.responses?.map((item) => (typeof item.person === "string" ? item.person : item.person.other)) || [];

    return {
      item,
      companyStatus: extractFormCategory(item),
      participants: item.participants.nodes.length
        ? item.participants.nodes
            .map((item) => {
              return {
                ...item,
                state: item.completed
                  ? peopleEngaged.some((ppl) => ppl.toLowerCase() === item.member?.firstName.toLowerCase())
                    ? item.state
                    : "DECLINED"
                  : "NOT_ANSWERED",
              };
            })
            .toSorted((a, b) => (a.state === "COMPLETED" ? -1 : 1))
        : peopleEngaged?.map((item) => ({ state: "COMPLETED", member: { firstName: item } })) || [],
      peopleFilled: item.responses?.map((item) => ({
        label: typeof item.person === "string" ? item.person : item.person.other,
      })),
      avgExcitement: item.analytics?.excitement_opinion_scale?.mean?.toFixed(2),
      dateAdded: normaliseDate(item.createdAt)!,
      timestamp: item.createdAt ? +new Date(item.createdAt) : 0,
    };
  });

  return (
    <OpportunityTable
      columns={columns}
      data={tableData}
      containerClassName={cn("mt-4 rounded-t-none transition-all duration-500 ease-in-out")}
      headerClassName={"w-full"}
      fnOnRowClick={
        isBigTablet
          ? null
          : (original) => {
              const { item } = original;
              opportunityModal({
                id: `sentiment_details`,
                platform: "mobile",
                action: "Sentiment",
              });
              opportunitiesStore.setModalState("sentiment_details", {
                form: item,
              });
            }
      }
      tableOptions={{
        density: "extraDense",
        elementOptions: {
          row: {
            className: "hover:!bg-gray-50 group",
          },
        },
      }}
      enableColumnPinning={true}
    />
  );
};
