import { IconType } from "@/app/components";

import { SelectAction } from "./components";

export type OptionValue = "opportunity" | "sentiment" | "founderSentiment";

interface Option {
  value: OptionValue;
  label: string;
  icon?: IconType;
  checkedIcon?: IconType;
}

const Options: Option[] = [
  {
    value: "opportunity",
    label: "Company",
    icon: "Opportunity",
    checkedIcon: "GradientOpportunity",
  },
  {
    value: "sentiment",
    label: "Company Sentiment",
    checkedIcon: "GradientSentiment",
    icon: "Sentiment",
  },
  {
    value: "founderSentiment",
    label: "Founder Sentiment",
    checkedIcon: "GradientFounder",
    icon: "Founder",
  },
];

export interface ModalContentProps {
  sentimentOnly?: boolean;
  disableFounders?: boolean;
  onSelect: (selected: OptionValue) => void;
}

export const ModalContent = ({ sentimentOnly = false, disableFounders = false, onSelect }: ModalContentProps) => {
  return (
    <section className={"flex size-full flex-col lg:justify-between"}>
      <div className="size-full lg:mb-0 lg:px-0">
        <SelectAction
          onSelect={({ value }) => onSelect(value)}
          options={Options.filter(({ value }) => (sentimentOnly ? value !== "opportunity" : true)).map((value) => ({
            ...value,
            disabled: value.value === "founderSentiment" && disableFounders,
          }))}
        />
      </div>
    </section>
  );
};
