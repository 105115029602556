import { useState } from "react";

import { AnimatePresence, motion } from "framer-motion";
import toast from "react-hot-toast";
import { useParams } from "react-router";

import { Toast } from "@/app/components";
import { Button } from "@/app/components/button";
import { Icon } from "@/app/components/icon";
import { metricsStore } from "@/app/screens/metrics-extraction";
import { SearchCompanyV2, SearchCompanyV2Result } from "@/app/screens/modal/actions/add-sentiment/components";
import { useLinkCompanyEmailMutation, useRelinkCompanyEmailMutation } from "@/app/service/company-email.gql.ts";

export const LinkAnotherCompanyButton = ({ emailId, notificationId }: { emailId: string; notificationId: string }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<SearchCompanyV2Result | null>(null);
  const [relinkCompanyEmail, { loading: isRelinking }] = useRelinkCompanyEmailMutation();
  const [linkCompanyEmail, { loading: isLinking }] = useLinkCompanyEmailMutation();
  const loading = isRelinking || isLinking;

  const { id } = useParams();

  const handleApply = async () => {
    if (!selectedCompany || !emailId || !id) {
      return;
    }

    if (metricsStore.state.selectedCompanyId !== "new") {
      await relinkCompanyEmail({
        variables: {
          input: {
            emailOpportunityId: emailId,
            notificationId: notificationId!,
            nextCompanyId: selectedCompany?.id,
          },
        },
      });
      toast.custom((t) => (
        <Toast
          title="Success!"
          visible={t.visible}
          icon={<Icon type="FillCheck" className="text-green-500" />}
          subTitle="Company has been linked"
          handleClose={() => toast.dismiss(t.id)}
        />
      ));
      metricsStore.setCompany(selectedCompany?.id, selectedCompany);
      setIsEditing(false);
    } else {
      await linkCompanyEmail({
        variables: {
          input: {
            emailOpportunityId: emailId,
            companyId: selectedCompany?.id,
            notificationId: notificationId!,
          },
        },
      });
      toast.custom((t) => (
        <Toast
          title="Success!"
          visible={t.visible}
          icon={<Icon type="FillCheck" className="text-green-500" />}
          subTitle="Company has been linked"
          handleClose={() => toast.dismiss(t.id)}
        />
      ));
      metricsStore.setCompany(selectedCompany?.id, selectedCompany);
      setIsEditing(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setSelectedCompany(null);
  };

  return (
    <AnimatePresence mode="wait">
      {isEditing ? (
        <motion.div
          key="search"
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 100, opacity: 0 }}
          transition={{ duration: 0.2, ease: "easeOut" }}
          className="-my-2 flex items-center gap-2 rounded-sm bg-white p-2"
        >
          <SearchCompanyV2
            selectCompany={(company) => {
              setSelectedCompany(company ?? null);
            }}
            className="min-w-[320px]"
          />
          <AnimatePresence>
            {selectedCompany != null && (
              <motion.div
                className="shrink-0 overflow-hidden"
                initial={{ width: 0, opacity: 0 }}
                animate={{ width: "auto", opacity: 1 }}
                exit={{ width: 0, opacity: 0 }}
              >
                <Button loading={loading} variant="secondary" size="md" onClick={handleApply}>
                  Apply
                </Button>
              </motion.div>
            )}
          </AnimatePresence>
          <Button variant="outline" size="md" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>
        </motion.div>
      ) : (
        <motion.div
          key="button"
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 100, opacity: 0 }}
          transition={{ duration: 0.2, ease: "easeOut" }}
        >
          <Button variant={"primary"} size={"md"} onClick={() => setIsEditing(true)}>
            Link to another company
          </Button>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
