import { Fragment } from "react";

import { useNavigate } from "react-router";
import { Link, useLocation } from "react-router-dom";

import { UserRoleType } from "@/gql/graphql.ts";

import { cn } from "@/lib/utils.ts";

import { AvatarV2, Button, Icon } from "@/app/components";
import { AppVersion } from "@/app/router/app-version";
import { AddNewResponsiveModal } from "@/app/screens/modal/add-new";
import { Search } from "@/app/screens/modal/search";
import { opportunitiesStore } from "@/app/screens/opportunities";
import { OpportunitiesTab, OpportunitiesTabType } from "@/app/screens/opportunities/opportunities.store.ts";
import { authStore } from "@/app/stores/auth.store.tsx";
import modalStore from "@/app/stores/modal.store";

import { ChipV2 } from "../v2/chip-v2";

import { useTabs } from "./header.store";

const hiddenRoutes = ["/add-company"];

export function VerticalNavbar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user, userRoles: myRoles } = authStore;
  const { tabsDesktop } = useTabs(myRoles ?? []);

  if (hiddenRoutes.includes(pathname)) return null;

  return (
    <div data-cy="vertical-navbar" className={"group sticky z-40 hidden w-16 lg:flex"}>
      <div className="fixed inset-y-0 z-30 flex h-dvh w-16 flex-col items-center border-r border-gray-400 bg-white px-3 shadow-[12px_0px_24px_0px_rgba(0,0,0,0.01)] transition-all duration-150 ease-in-out hover:mouse-hover:border-r hover:mouse-hover:lg:w-[208px]">
        {/*LOGO*/}
        <div className={cn("relative my-16 flex h-fit items-center justify-center")}>
          <Icon
            type={"Logo"}
            className={"absolute w-[82px] opacity-0 transition-all ease-in-out group-hover:mouse-hover:opacity-100"}
          />
          <Icon
            type={"LogoShort"}
            className={"absolute w-2.5 opacity-100 transition-all ease-in-out group-hover:mouse-hover:opacity-0"}
          />
        </div>
        {/*ADD & SEARCH*/}
        <div className={"w-full space-y-3"}>
          <Button
            iconLeft="Add"
            variant="gradient"
            className={"relative h-10 justify-start group-hover:mouse-hover:w-full lg:w-10"}
            size={"sm"}
            onClick={() => {
              modalStore.open(AddNewResponsiveModal);
            }}
            data-cy="add-new"
          >
            <span
              className={
                "absolute ml-6 flex text-nowrap opacity-0 transition-opacity duration-150 group-hover:mouse-hover:opacity-100"
              }
            >
              <span className="hidden font-semibold group-hover:mouse-hover:flex lg:text-sm">Add New...</span>
            </span>
          </Button>
          <Button
            size={"sm"}
            className={"relative h-10 justify-start group-hover:mouse-hover:w-full lg:w-10"}
            onClick={() => modalStore.open(Search)}
            variant="secondary"
            data-cy="navbar-search-call"
            iconLeft="Search"
          >
            <div
              className={
                "absolute w-full opacity-0 transition-opacity duration-150 group-hover:mouse-hover:opacity-100"
              }
            >
              <div className={"flex w-full items-center justify-between"}>
                <span className="ml-6 hidden text-sm font-semibold  text-gray-900 group-hover:mouse-hover:flex">
                  Search
                </span>
                <div className="hidden pr-8 group-hover:mouse-hover:flex">
                  <Icon type="Cmd" className="size-4 text-gray-700" />
                  <Icon type="CmdKey" className="size-4 text-gray-700" />
                </div>
              </div>
            </div>
          </Button>
        </div>
        {/*NAV*/}
        <nav className="mt-10 flex w-full flex-col justify-between ">
          <ul role="list" className={cn("flex w-full flex-col space-y-2")}>
            {tabsDesktop.map((group, index) => (
              <Fragment key={index}>
                {/*{index > 0 && (*/}
                {/*  <div className={cn("w-full py-4")}>*/}
                {/*    <div className={"border-t border-t-gray-200"} />*/}
                {/*  </div>*/}
                {/*)}*/}
                {group.map(({ name, Icon, path, disabled, isDemo }) => (
                  <li
                    key={name}
                    data-cy={`navbar-link-${path}`}
                    onClick={() => {
                      if (disabled) {
                        return;
                      }
                      if (path in OpportunitiesTab) {
                        opportunitiesStore.setState({
                          currentTab: {
                            path: path as OpportunitiesTabType,
                            quickView: "all",
                          },
                        });
                      }
                      navigate(path);
                    }}
                    className={cn(
                      `z-30 flex h-10 w-full !cursor-pointer select-none items-center rounded-xs hover:bg-gray-400 group-hover:mouse-hover:px-3`,
                      pathname === `/${path}` ? "bg-gray" : "",
                    )}
                  >
                    <Link
                      to={path}
                      className={cn(
                        pathname === `/${path}` ? "font-bold" : "font-semibold",
                        "flex w-full items-center justify-center px-3 leading-6 text-white group-hover:mouse-hover:justify-start group-hover:mouse-hover:px-0",
                      )}
                    >
                      <Icon className={cn("flex !size-4 shrink-0 text-gray-900")} />
                      <span
                        className={cn(
                          "text-sm font-semibold text-gray-900 opacity-0 transition-opacity duration-300 ease-in group-hover:mouse-hover:opacity-100",
                        )}
                      >
                        <p className={"ml-3 hidden break-words group-hover:mouse-hover:flex"}>{name}</p>
                      </span>
                      {myRoles?.includes(UserRoleType.UserRoleNetwork) && path === "network" ? (
                        <ChipV2
                          label="Alpha"
                          color="geek-blue-inverted"
                          size="xs"
                          className="ml-auto hidden group-hover:mouse-hover:flex"
                        />
                      ) : null}
                      {isDemo && (
                        <ChipV2
                          label="Demo"
                          color="gray-inverted"
                          size="xs"
                          className="ml-auto hidden group-hover:mouse-hover:flex"
                        />
                      )}
                    </Link>
                  </li>
                ))}
              </Fragment>
            ))}
          </ul>
        </nav>

        {/*USER & VERSION*/}
        <div className={cn(`flex w-full flex-1 flex-col items-center justify-end pb-6`)}>
          <div className={"mt-2 flex w-full items-center group-hover:mouse-hover:space-x-3"}>
            <AvatarV2
              src={user?.avatar}
              initials={user?.initials}
              type={"user"}
              size={"md"}
              onClick={() => {
                navigate("/settings");
              }}
            />
            <div
              className={"w-1/2 opacity-0 transition-opacity duration-300 ease-in group-hover:mouse-hover:opacity-100"}
            >
              <span className="hidden w-full justify-center text-xs font-medium text-black group-hover:mouse-hover:flex 2xl:text-sm">
                <span className={"text-nowrap"}>{`${user?.firstName}`}</span>
                <span className={"truncate"}>&nbsp;{`${user?.lastName}`}</span>
              </span>
            </div>
          </div>
          <div className="flex w-full items-center justify-center">
            <AppVersion className={"mt-5 text-xs text-gray-600"} />
          </div>
        </div>
      </div>
    </div>
  );
}
