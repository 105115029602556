import { memo } from "react";

import { Link } from "react-router-dom";

import { cn } from "@/lib/utils";

import { AvatarV2 } from "@/app/components";
import { ProgressSpinner } from "@/app/components/loading/progress-spinner";

import { Tags } from "../../opportunities/components/card-list/tags";
import { CompanyStats } from "../../opportunities/components/company-stats";
import { useGroupedCompanies } from "../kanban-hooks";

import { KanbanBoardCardAssignees } from "./kanban-board-card-assignees";

type KanbanBoardCardProps = {
  item: ReturnType<typeof useGroupedCompanies>["items"][number][number];
  className?: string;
};

const CardHeader = ({ item }: KanbanBoardCardProps) => {
  return (
    <div className="flex items-center gap-2 overflow-hidden">
      <div className="relative size-10">
        {item.loading ? (
          <div className="absolute inset-0 size-10 p-1">
            <ProgressSpinner className="size-full" />
          </div>
        ) : (
          <AvatarV2 src={item.image} type="company" className="size-10" />
        )}
      </div>
      <Link
        to={`/company/${item.id}`}
        onClick={(e) => e.stopPropagation()}
        target="_blank"
        className="truncate text-sm font-medium text-gray-900 hover:underline"
      >
        {item.name || "Stealth Company"}
      </Link>
      <div className="ml-auto flex items-center gap-3">
        {/* todo */}
        {/* <Icon type="AlertTriangle" className="size-4 text-gold-400" /> */}
        {/* <Icon type="Calendar" className="size-4 text-purple-300" /> */}
        {/* <span className="text-sm text-gray-600">2d</span> */}

        {/* todo: review actions */}
        {/* <CardActions company={item} hideTrack /> */}
      </div>
    </div>
  );
};

export const KanbanBoardCard = memo(function KanbanBoardCard({ item, className }: KanbanBoardCardProps) {
  return (
    <div className={cn("flex-1 select-none overflow-hidden p-3", className)}>
      <CardHeader item={item} />
      <p className="mt-2 line-clamp-2 text-xs text-gray-800">{item.tagline}</p>
      <div className="mr-2.5 mt-3 flex items-center justify-between gap-2">
        <KanbanBoardCardAssignees assignees={item.assignees} />
        <CompanyStats
          excitementScore={item.excitementScore}
          commentCount={item.commentCount}
          fileCount={item.fileCount}
        />
      </div>
      <Tags
        city={item.city}
        country={item.country}
        badges={item.badges}
        // round={item.round}
        // industries={item.industries}
        className="mt-3"
      />
    </div>
  );
});
