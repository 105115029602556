import { cn, getInitials, parseUrl, windowOpen } from "@/lib/utils.ts";

import { AvatarV2, Button, DropdownOption, DropdownV2, Icon } from "@/app/components";

import { opportunityModal } from "../../../actions.ts";
import opportunitiesStore from "../../../opportunities.store.ts";

import { FounderSentiment } from "./founder-sentiment.tsx";
import { FounderDisplayData, FounderFromTeam } from "./founders.types.ts";

type FounderProps = {
  founder: FounderFromTeam;
  addSentiment: () => void;
  onRemove: () => void;
  onEdit?: () => void;
};

const isFounderFromTeamData = (founder: FounderDisplayData): founder is FounderFromTeam => {
  return "id" in founder;
};

const getFounderSentiment = (founder: FounderFromTeam) => {
  const sentimentData = founder?.forms ?? [];
  const firstNonNullForm = sentimentData?.find((form) => form?.responses?.length > 0);

  return {
    sentimentData,
    firstNonNullForm,
    isEmpty: !(sentimentData.length > 0 && firstNonNullForm),
  };
};

const FounderMenu = ({
  founder,
  className,
  actions,
}: {
  founder: FounderDisplayData;
  actions: DropdownOption[];
  className?: string;
}) => {
  return (
    <DropdownV2 className={cn(className)}>
      <DropdownV2.Button
        onClick={(e) => e.stopPropagation()}
        size="xs"
        className="bg-gray-100 p-1"
        buttonVariant="IconButton"
        icon="MoreVertical"
      />

      <DropdownV2.Items
        className={"max-h-40"}
        title={
          <>
            {founder.name} <span className="ml-1 text-sm text-gray-600">(Founder)</span>
          </>
        }
      >
        {actions.map(({ label, action, icon }) => (
          <DropdownV2.Item className={"cursor-pointer md:py-3"} key={label} onClick={action}>
            {icon && <Icon type={icon} className="mr-2 size-4" />}
            <span className={cn("truncate text-sm text-black")}>{label}</span>
          </DropdownV2.Item>
        ))}
      </DropdownV2.Items>
    </DropdownV2>
  );
};

export const Founder = ({ founder, onRemove, addSentiment }: FounderProps) => {
  const isFullFounderData = isFounderFromTeamData(founder);
  const { sentimentData, isEmpty } = getFounderSentiment(founder);

  let actions: DropdownOption[] = [{ label: "Add Sentiment", action: () => addSentiment(), icon: "Add" }];

  if (!isEmpty) {
    actions = [
      ...actions,
      {
        label: "View Sentiment",
        action: () => {
          opportunityModal({
            id: `sentiment_details`,
            platform: window.innerWidth <= 976 ? "mobile" : "web",
            action: "Sentiment",
          });

          opportunitiesStore.setModalState("sentiment_details", {
            form: sentimentData?.find((form) => form?.responses?.length > 0),
          });
        },
        icon: "MoveUpRight",
      },
    ];
  }

  if (founder.linkedinUrl) {
    const url = founder.linkedinUrl;
    actions = [...actions, { label: "Open LinkedIn", action: () => windowOpen(url, "_blank"), icon: "Linkedin" }];
  }

  actions = [...actions, { label: "Remove", action: onRemove, icon: "Trash" }];

  return (
    <div
      className={cn(
        "group flex items-center gap-3 self-start overflow-hidden rounded-sm border border-gray-300 bg-gray-100 p-4 lg:flex-row lg:items-center",
      )}
    >
      <AvatarV2
        size={"lg"}
        type={"user"}
        src={isFullFounderData ? parseUrl(founder.image) : null}
        initials={getInitials(founder.name)}
      />
      <div className="flex-1 space-y-3 overflow-hidden">
        <div className="flex gap-2">
          <div className="flex flex-col items-start gap-1 overflow-hidden">
            <span className="truncate text-lg font-semibold">{founder.name}</span>
            {isFullFounderData && !!founder.titles?.length && (
              <span className="w-fit truncate text-nowrap text-sm font-medium text-gray-600">
                {founder.titles.reduce((acc, item, idx) => acc + (idx ? " • " : "") + item.name, "")}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="ml-auto flex shrink-0 items-center self-baseline md:gap-1 lg:gap-2">
        <FounderSentiment sentimentData={sentimentData} isEmpty={isEmpty} />
        {founder?.linkedinUrl && (
          <Button
            variant="outline"
            onClick={() => windowOpen(founder.linkedinUrl!, "_blank")}
            size="xs"
            className="hidden lg:block"
          >
            LinkedIn
          </Button>
        )}
        <FounderMenu actions={actions} founder={founder} />
      </div>
    </div>
  );
};
