import { useState } from "react";

export const useSearchCompany = () => {
  // const [searchParams, setSearchParams] = useSearchParams();

  const [searchQuery, setSearchQuery] = useState<string>("");

  // useEffect(() => {
  //   const query = searchParams.get("companyName") || "";
  //   setSearchQuery(query);
  // }, [searchParams]);

  const updateSearchQuery = (query: string) => {
    setSearchQuery(query);
    // const newParams = new URLSearchParams(searchParams);
    // if (query) {
    //   newParams.set("companyName", query);
    // } else {
    //   newParams.delete("companyName");
    // }
    // setSearchParams(newParams);
  };

  return { searchQuery, updateSearchQuery };
};
