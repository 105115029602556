import { useCallback, useEffect, useRef } from "react";

import { AnimatePresence, motion } from "framer-motion";

import { IconButtonV2 } from "@/app/components/button/icon-button-v2";
import { InputV2 } from "@/app/components/forms/input-v2";

import { useKanbanBoardContext } from "../kanban-board-context";

export const KanbanBoardQuickSearch = () => {
  const { searchQuery, updateSearchQuery } = useKanbanBoardContext();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const debounceTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (inputRef.current !== null) {
      inputRef.current.value = searchQuery;
    }
  }, [searchQuery]);

  useEffect(() => {
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      updateSearchQuery(event.target.value);
    }, 300);
  }, []);

  return (
    <div className="flex flex-1 items-center gap-1">
      <InputV2 size="sm" ref={inputRef} onChange={handleChange} placeholder="Search by name" />
      <AnimatePresence>
        {searchQuery && (
          <motion.div
            initial={{ opacity: 0, width: 0 }}
            animate={{ opacity: 1, width: "auto" }}
            exit={{ opacity: 0, width: 0 }}
            transition={{ duration: 0.3 }}
          >
            <IconButtonV2
              icon="X"
              size="xs"
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.value = "";
                  updateSearchQuery("");
                }
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
