import Highlighter from "react-highlight-words";

import { ChipV2 } from "@/app/components/v2/chip-v2";

export const AddCompanyEmailDetails = ({
  email,
  searchWords = [],
}: {
  email: {
    subject: string;
    from?: string | null;
    to?: string | null;
    source?: string | null;
    body: string;
  };
  searchWords?: string[];
}) => {
  return (
    <div className="flex h-full flex-col gap-5 overflow-hidden text-sm">
      <div className="flex h-8 shrink-0 items-center justify-between gap-2">
        <h2 className="text-xl font-semibold">Email details</h2>
        {!!email.to && <ChipV2 label={email.to} color="gray" />}
      </div>
      <div className="flex w-full flex-wrap items-center gap-x-16 gap-y-2">
        {!!email.subject && (
          <div className="flex flex-col gap-1">
            <span className="font-semibold">Subject</span>
            <span className="line-clamp-2">{email.subject}</span>
          </div>
        )}
        {!!email.from && (
          <div className="flex flex-col gap-1">
            <span className="font-semibold">From</span>
            <span>{email.from}</span>
          </div>
        )}

        {!!email.source && (
          <div className="flex flex-col gap-1">
            <span className="font-semibold">Source</span>
            <span>{email.source}</span>
          </div>
        )}
      </div>
      <pre className="overflow-auto whitespace-pre-wrap">
        <Highlighter
          textToHighlight={email.body}
          searchWords={searchWords}
          autoEscape={true}
          highlightClassName="font-semibold bg-yellow-300"
        />
      </pre>
    </div>
  );
};
