import { Fragment } from "react";

import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { X } from "lucide-react";

import { cn } from "@/lib/utils";

import { ModalProps } from "./types";

export const Modal = ({
  children,
  isOpen,
  containerClassName,
  bodyContainerClassName,
  bodyClassName,
  bodyContentClassName,
  footerContainerClassName,
  headerContainerClassName,
  closeIconStyle,
  showX = true,
  header,
  footer,
  handleClose,
}: ModalProps) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className={cn("relative z-50", containerClassName)} onClose={handleClose}>
        <TransitionChild
          as={"div"}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div data-cy="close-modal" className="fixed inset-0 bg-black/50" onClick={handleClose} />
        </TransitionChild>
        <div data-cy="modal" className="fixed inset-0 overflow-y-hidden">
          <div
            className={cn(`flex h-screen w-dvw items-center justify-center p-4 text-center`, bodyContainerClassName)}
          >
            <DialogPanel
              className={cn(
                "relative flex max-h-[700px] min-h-fit max-w-[760px] flex-1 flex-col items-center justify-between rounded-sm bg-white p-8",
                bodyClassName,
              )}
              onClick={(e) => e.stopPropagation()}
              data-cy="modal-body"
            >
              {header && <div className={cn("w-full px-6", headerContainerClassName)}>{header}</div>}
              {showX && (
                <X
                  className={cn(
                    "absolute right z-20 -translate-x-full cursor-pointer text-gray-900 hover:text-gray-600 active:text-gray-800",
                    closeIconStyle,
                  )}
                  onClick={handleClose}
                />
              )}
              <div className={cn("size-full p-6", bodyContentClassName)}>{children}</div>
              {footer && <div className={cn("mb-4 w-full px-6 pt-6", footerContainerClassName)}>{footer}</div>}
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
