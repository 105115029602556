import { useState } from "react";

import type { ButtonVariant } from "@/app/components/button";
import { useForm } from "@/app/hooks";
import { useSnooze } from "@/app/screens/modal/opportunities/hooks/useSnooze.tsx";
import modalStore from "@/app/stores/modal.store.tsx";

import { SnoozeForm } from "../components";

export enum SnoozeActionType {
  Snooze = "Snooze",
  Pipeline = "Pipeline",
}

export interface UseSnoozeModalParams {
  id: string;
  actionType?: SnoozeActionType;
  onSuccess?: () => void;
}

const snoozeSteps = [
  {
    index: 0,
    title: "Have you let the company know we won't be progressing at this stage?",
    description:
      "Haven't informed them yet? No problem. Nazare will prepare a template note for you to send based on the team's past interactions with the company",
    mainAction: "Yes I've let them know",
    secondaryAction: "Not Yet",
    showBack: false,
    primaryBtnVariant: "gradient" as ButtonVariant,
  },
  {
    index: 1,
    title: "Nazare will generate a template note based on the team’s past interactions with the company. ",
    description:
      "If there are specific details or reasons for not proceeding with the company that you wish to emphasize, please provide them below.",
    mainAction: "Move to Pipeline & Generate Template Note",
    secondaryAction: "Cancel",
    primaryBtnVariant: "gradient" as ButtonVariant,
  },
];

export const useSnoozeOptions = ({ id, actionType = SnoozeActionType.Snooze, onSuccess }: UseSnoozeModalParams) => {
  const [step, setStep] = useState(0);
  const form = useForm(actionType);
  const { ...actions } = useSnooze(form, id, onSuccess);
  // this is always false until we decide if we want to ring back snooze_reason & resurface
  const valid = false;

  const handleSubmit = actions.onSubmit;

  const mainActionFn = () => {
    return handleSubmit();
  };

  const secondaryActionFn = () => {
    if (step === 0) {
      form.$("feedback_email").set("​");
      return setStep((prevState) => prevState + 1);
    }
    return modalStore.close();
  };

  return {
    ...actions,
    handleSubmit,
    form,
    step: {
      ...snoozeSteps[step],
      mainActionFn,
      secondaryActionFn,
      valid,
    },
    setStep,
    RenderForm: <SnoozeForm form={form} step={snoozeSteps[step]} />,
  };
};
