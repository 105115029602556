import { useQuery } from "@apollo/client";
import { GetNetworkVolumeLadder, GetNetworkVolumeLadderGroup } from "./ladder.gql.ts";
import { CSSProperties, MutableRefObject, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { AvatarV2, Button, Icon } from "@/app/components";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { LandingMetrics } from "./landingMetrics.tsx";

type LadderPeriod = "7 days" | "90 days" | "1 year" | "30 years";
type LadderCategory = "portfolio" | "basecamp" | "co-investor" | "lp" | "corporate";
type LadderType = "domain" | "email";

const periods = [
  {
    key: "7 days",
    title: (
      <span>
        <span className="hidden @3xl:inline">Last</span> 7 Days
      </span>
    ),
  },
  {
    key: "90 days",
    title: (
      <span>
        <span className="hidden @3xl:inline">Last</span> 90 Days
      </span>
    ),
  },
  {
    key: "1 year",
    title: (
      <span>
        <span className="hidden @3xl:inline">Last</span> Year
      </span>
    ),
  },
  { key: "30 years", title: "All Time" },
] as const;

type PeriodKey = (typeof periods)[number]["key"];

const volumeFormat = Intl.NumberFormat("en-US", {
  notation: "compact",
  compactDisplay: "short",
});

type LadderButtonProps = {
  selected?: boolean;
  children: ReactNode;
  onClick: () => void;
};

export function LadderButton(props: LadderButtonProps) {
  const base =
    "flex w-fit select-none items-center justify-center gap-2 text-sm font-semibold disabled:pointer-events-none transition-colors duration-200 ease-in h-8 rounded-xs px-3 py-2 border border-gray-300 bg-white text-gray-900 hover:bg-gray-300 active:bg-gray-500";
  const selected =
    "flex w-fit select-none items-center justify-center gap-2 text-sm font-semibold disabled:pointer-events-none transition-colors duration-200 ease-in h-8 rounded-xs px-3 py-2 border border-gray-300 bg-gray-300 text-gray-900 hover:bg-gray-400 hover:border-gray-400 active:bg-gray-500 active:boder-gray-500 ";

  const classes = props.selected ? selected : base;
  return (
    <Button onClick={props.onClick} className={classes}>
      {props.children}
    </Button>
  );
}

const DEFAULT_MAX = 50;
const EXPANDED_MAX = 500;

function usePreloadGroups() {
  useQuery(GetNetworkVolumeLadderGroup, { variables: { period: periods[1].key, max: DEFAULT_MAX } });
  useQuery(GetNetworkVolumeLadderGroup, { variables: { period: periods[2].key, max: DEFAULT_MAX } });
  useQuery(GetNetworkVolumeLadderGroup, { variables: { period: periods[3].key, max: DEFAULT_MAX } });
}

export function LandingLadder() {
  const [ladderExpanded, setLadderExpanded] = useState<[LadderType | "", LadderCategory | ""]>(["", ""]);
  const [period, setPeriod] = useState<PeriodKey>(periods[0].key);
  const groupDomainScrollRef = useRef<HTMLDivElement>(null);
  const groupEmailScrollRef = useRef<HTMLDivElement>(null);
  const handlePeriod = useCallback((period: PeriodKey) => {
    setPeriod(period);
  }, []);
  const busyLadderAnim = useRef(false);
  const { data, loading: groupLoading } = useQuery(GetNetworkVolumeLadderGroup, {
    variables: { period, max: DEFAULT_MAX },
  });
  const probTouchRef = useRef({ time: 0 });
  usePreloadGroups();

  useEffect(() => {
    const ejects: Array<() => void> = [];
    for (const ref of [groupDomainScrollRef, groupEmailScrollRef]) {
      const scrollContainer = ref.current;
      if (!scrollContainer) return;

      const handleWheel = (event) => {
        if (Math.abs(event.deltaX) !== 0) {
          probTouchRef.current = { time: Date.now() };
        }
        if (document.body.querySelector("[data-ladder-state='expanded']")) return;
        if (Date.now() - probTouchRef.current.time < 1000) {
          return;
        }
        event.preventDefault();
        scrollContainer.scrollBy({ left: event.deltaY, behavior: "smooth" });
      };

      const handleScroll = () => {
        const scrollLeft = scrollContainer.scrollLeft;
        const maxScroll = scrollContainer.scrollWidth - scrollContainer.clientWidth;

        const leftOpacity = scrollLeft === 0 ? "0" : "100";
        const rightOpacity = scrollLeft >= maxScroll ? "0" : "100";
        const leftFadeIn = scrollContainer.querySelector("[data-fade-left]");
        const rightFadeIn = scrollContainer.querySelector("[data-fade-right]");

        if (leftFadeIn instanceof HTMLElement) {
          leftFadeIn.style.opacity = leftOpacity;
        }

        if (rightFadeIn instanceof HTMLElement) {
          rightFadeIn.style.opacity = rightOpacity;
        }
      };

      requestAnimationFrame(handleScroll);
      scrollContainer.addEventListener("wheel", handleWheel);
      scrollContainer.addEventListener("scroll", handleScroll);
      ejects.push(() => {
        scrollContainer.removeEventListener("wheel", handleWheel);
        scrollContainer.removeEventListener("scroll", handleScroll);
      });
    }
    return () => {
      for (const eject of ejects) eject();
    };
  }, [groupDomainScrollRef.current, groupEmailScrollRef.current]);
  const handleLadderClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    if (busyLadderAnim.current === true) {
      return;
    }

    if (event.target instanceof Element) {
      event.preventDefault();
      if (event.target?.hasAttribute("data-ladder-outside")) {
        setLadderExpanded(["", ""]);
        return;
      }

      const el = event.target?.closest("[data-ladder-category]");
      const category = el?.getAttribute("data-ladder-category");
      const type = el?.getAttribute("data-ladder-type");
      const state = el?.getAttribute("data-ladder-state");

      if (type && category) {
        if (state === "collapsed") {
          setLadderExpanded([type as LadderType, category as LadderCategory]);
        }

        if (state === "expanded") {
          setLadderExpanded(["", ""]);
        }
      }
    }
  }, []);

  const categories = [
    { key: "portfolio", domainTitle: "Portfolio companies", emailTitle: "Portfolio contacts" },
    { key: "co-investor", domainTitle: "Co-investors", emailTitle: "Co-investor contacts" },
    { key: "basecamp", domainTitle: "Basecamp funds", emailTitle: "Basecamp contacts" },
    // { key: "lp", domainTitle: "LPs", emailTitle: "LP contacts" },
    // { key: "corporate", domainTitle: "Corporate", emailTitle: "Corporate contacts" },
  ] as const;

  type CategoryKey = (typeof categories)[number]["key"];

  return (
    <div className="flex gap-4 flex-col select-none" onClick={handleLadderClick}>
      <div className="flex flex-col-reverse w-full justify-center items-center @6xl:flex-row  @6xl:justify-between pt-8 pr-2 pl-0">
        <div className="flex gap-2">
          {periods.map((p) => {
            return (
              <LadderButton onClick={() => handlePeriod(p.key)} selected={p.key === period}>
                {p.title}
              </LadderButton>
            );
          })}
        </div>

        <div className="flex @6xl:ml-auto mb-3 @6xl:mb-0">
          <LandingMetrics />
        </div>
      </div>
      {!groupLoading && (
        <AnimatePresence key={period}>
          <motion.div
            className="flex flex-col gap-3"
            initial={{ opacity: 0.6 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            transition={{ duration: 0 }}
          >
            <div className="relative">
              <div className="overflow-x-auto no-scrollbar snap-mandatory" ref={groupDomainScrollRef}>
                <div className="flex gap-3 flex-nowrap w-[1556px] n3xl:w-full">
                  {categories.map((category, idx) => {
                    return (
                      <LadderItem
                        idx={idx}
                        data={data[`domain_${category.key.replace("-", "")}`]}
                        count={
                          data.meta?.nodes?.find((e) => {
                            return e.key === [category.key, period, "domain"].join(" ");
                          })?.count
                        }
                        key={category.domainTitle}
                        category={category.key}
                        title={category.domainTitle}
                        period={period}
                        entityType={"domain"}
                        ladderExpanded={ladderExpanded}
                        ladderAnimRef={busyLadderAnim}
                        groupScrollRef={groupDomainScrollRef}
                      />
                    );
                  })}
                </div>
                <div
                  data-fade-left
                  className="transition-all absolute top-0 left-0 h-full w-5 bg-gradient-to-r from-white to-transparent pointer-events-none n3xl:hidden"
                ></div>
                <div
                  data-fade-right
                  className="transition-all absolute top-0 right-0 h-full w-5 bg-gradient-to-l from-white to-transparent pointer-events-none n3xl:hidden"
                ></div>
              </div>
            </div>

            <div className="relative">
              <div className="overflow-x-auto no-scrollbar snap-mandatory" ref={groupEmailScrollRef}>
                <div className="flex gap-3 flex-nowrap  w-[1556px] n3xl:w-full">
                  {categories.map((category, idx) => {
                    return (
                      <LadderItem
                        idx={5 - idx}
                        data={data[`email_${category.key.replace("-", "")}`]}
                        count={
                          data.meta?.nodes?.find((e) => {
                            return e.key === [category.key, period, "email"].join(" ");
                          })?.count
                        }
                        key={category.domainTitle}
                        category={category.key}
                        title={category.emailTitle}
                        period={period}
                        entityType={"email"}
                        ladderExpanded={ladderExpanded}
                        ladderAnimRef={busyLadderAnim}
                        groupScrollRef={groupEmailScrollRef}
                      />
                    );
                  })}
                </div>
                <div
                  data-fade-left
                  className="absolute top-0 left-0 h-full w-5 bg-gradient-to-r from-white to-transparent pointer-events-none n3xl:hidden"
                ></div>
                <div
                  data-fade-right
                  className="absolute top-0 right-0 h-full w-5 bg-gradient-to-l from-white to-transparent pointer-events-none n3xl:hidden"
                ></div>
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
}

type LadderItemClassesStep = {
  wrapperClasses: string;
  midClasses: string;
  innerClasses: string;
  wrapperStyles?: CSSProperties;
  midStyles?: CSSProperties;
  innerStyles?: CSSProperties;
  scrollStyles?: CSSProperties;
};

function getLadderItemClassesStep(type, category, ladderExpanded): LadderItemClassesStep {
  const curLadderExpanded = type === ladderExpanded[0] && category === ladderExpanded[1];

  const curTypeExpanded = type === ladderExpanded[0];
  let wrapperClasses = "transition-all duration-[0.5s] ease-in-out-back overflow-hidden ";
  let midClasses = "transition-all duration-[0.3s] ease-in-out-back ";
  let innerClasses = "transition-all duration-[0.5s] ease-in-out-back bg-white h-[296px] flex flex-col";
  if (!curTypeExpanded) {
    wrapperClasses += " w-[380px] n3xl:w-1/3";
    midClasses += " w-full";
    innerClasses += " w-full rounded-sm border border-gray-300";
  } else if (!curLadderExpanded) {
    wrapperClasses += " w-[380px] n3xl:w-1/2";
    midClasses += " w-full";
    innerClasses += " w-full rounded-sm border border-gray-300";
  } else {
    wrapperClasses += " w-[0px] n3xl:w-[0%]";
    midClasses +=
      " fixed w-screen backdrop-blur-sm h-screen flex items-end @2xl:items-center justify-center top-0 left-0 z-20";
    innerClasses +=
      " w-[380px] transform-x-0 transform-y-0 overflow-hidden m-auto rounded-sm border border-gray-300 shadow-lg-original";
  }

  return { wrapperClasses, midClasses, innerClasses };
}

type LadderItemProps = {
  entityType: LadderType;
  period: LadderPeriod;
  category: LadderCategory;
  title: string;
  max?: number;
  ladderExpanded: [LadderType | "", LadderCategory | ""];
  ladderAnimRef: MutableRefObject<boolean>;
  groupScrollRef: MutableRefObject<HTMLDivElement | null>;
  data: any;
  idx: number;
  count?: number;
};

export function LadderItem(props: LadderItemProps) {
  const { count, data, entityType, period, category, title, ladderExpanded, ladderAnimRef, groupScrollRef, idx } =
    props;
  const ladderExpandedRef = useRef(ladderExpanded);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const midRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const lastCollapsedRef = useRef({});
  const lastScrollRef = useRef(0);
  const lastScrollWidth = useRef(0);
  const [animOpenFinished, setAnimOpenFinished] = useState(false);
  const [openedOnce, setOpenedOnced] = useState(false);

  const [containerClasses, setContainerClasses] = useState(
    getLadderItemClassesStep(entityType, category, ladderExpanded)
  );

  const curLadderExpanded = entityType === ladderExpanded[0] && category === ladderExpanded[1];
  const { data: expandedData, previousData: prevExpandedData } = useQuery(GetNetworkVolumeLadder, {
    variables: {
      type: entityType,
      period,
      category,
      max: EXPANDED_MAX,
    },
    skip: !curLadderExpanded && !openedOnce,
  });

  let dataToDisplay = data;
  if (expandedData?.ladder && animOpenFinished) {
    dataToDisplay = expandedData.ladder;
  }

  useEffect(() => {
    if (!innerRef.current || !midRef.current || !wrapperRef.current) {
      ladderExpandedRef.current = ladderExpanded;
      return;
    }
    const dHeightConst = 296;
    const rect = innerRef.current.getBoundingClientRect();
    const wRect = wrapperRef.current.getBoundingClientRect();
    const innerWidth = Math.round(rect.width);
    const innerHeight = Math.round(rect.height);
    const wWidth = window.innerWidth;
    const wHeight = window.innerHeight;
    const currentlyOpened = ladderExpandedRef.current[0] === entityType && ladderExpandedRef.current[1] === category;
    const nextOpened = ladderExpanded[0] === entityType && ladderExpanded[1] === category;
    // we are being expanded
    if (!currentlyOpened && nextOpened) {
      const step = getLadderItemClassesStep(entityType, category, ladderExpanded);
      const top = Math.round(rect.top);
      const left = Math.round(rect.left);

      const xOffset = -1 * Math.round(wWidth / 2 - innerWidth / 2 - left);
      const yOffset = -1 * Math.round(wHeight / 2 - dHeightConst / 2 - top);

      step.innerStyles = {
        width: `${innerWidth}px`,
        height: `${dHeightConst}px`,
        transform: `translate(${xOffset}px, ${yOffset}px)`,
        transition: "none",
        boxShadow: "none",
      };

      lastCollapsedRef.current = {
        width: `${innerWidth}px`,
        height: `${dHeightConst}px`,
        transform: `translate(${xOffset}px, ${yOffset}px)`,
      };

      lastScrollRef.current = groupScrollRef.current?.scrollLeft || 0;
      setContainerClasses(step);
      ladderAnimRef.current = true;
      setTimeout(() => {
        const step = getLadderItemClassesStep(entityType, category, ladderExpanded);
        step.innerStyles = {
          height: `${Math.round(wHeight * 0.7)}px`,
        };
        step.scrollStyles = {
          overflowY: "auto",
        };
        setContainerClasses(step);
        setTimeout(() => {
          ladderAnimRef.current = false;
          setAnimOpenFinished(true);
          setOpenedOnced(true);
        }, 510);
      }, 66);

      ladderExpandedRef.current = ladderExpanded;
      return;
    }

    if (currentlyOpened && !nextOpened) {
      const step = getLadderItemClassesStep(entityType, category, ladderExpandedRef.current);
      const nextStep = getLadderItemClassesStep(entityType, category, ladderExpanded);

      const top = Math.round(wRect.top);
      const left = Math.round(wRect.left);

      const xOffset = Math.round(wWidth / 2 - innerWidth / 2 - left);
      const yOffset = Math.round(wHeight / 2 - dHeightConst / 2 - top);

      step.innerStyles = {
        width: `${innerWidth}px`,
        height: `${Math.round(wHeight * 0.7)}px`,
        top: `${xOffset}px`,
        left: `${yOffset}px`,
        transition: "none",
        zIndex: 20,
        margin: 0,
      };

      step.midStyles = { transition: "none" };
      step.wrapperStyles = { transition: "none", width: 0 };

      step.wrapperClasses = nextStep.wrapperClasses;
      // step.midClasses = nextStep.midClasses;
      setContainerClasses(step);

      ladderExpandedRef.current = ladderExpanded;

      scrollRef.current?.scrollTo({ top: 0, behavior: "smooth" });
      ladderAnimRef.current = true;
      setTimeout(() => {
        step.wrapperStyles = {};
        step.midStyles = { backdropFilter: "none" };
        step.innerStyles = lastCollapsedRef.current;
        setContainerClasses({ ...step });
        setTimeout(() => {
          scrollRef.current?.scrollTo({ top: 0 });
          nextStep.midStyles = { transition: "none" };
          nextStep.innerStyles = { transition: "none" };
          nextStep.wrapperStyles = { transition: "" };

          setContainerClasses(nextStep);
          ladderAnimRef.current = false;
          setAnimOpenFinished(false);
        }, 510);
      }, 66);
      return;
    }

    setContainerClasses(getLadderItemClassesStep(entityType, category, ladderExpanded));
    ladderExpandedRef.current = ladderExpanded;
  }, [ladderExpanded]);

  return (
    <div
      key={period + category + entityType}
      ref={wrapperRef}
      className={containerClasses.wrapperClasses}
      style={{ ...containerClasses.wrapperStyles }}
    >
      <div
        ref={midRef}
        className={containerClasses.midClasses + " group"}
        data-ladder-outside
        style={containerClasses.midStyles}
      >
        <div ref={innerRef} className={containerClasses.innerClasses} style={containerClasses.innerStyles}>
          <div
            className="flex justify-between overflow-hidden items-center p-4 relative group-title cursor-pointer"
            data-ladder-state={curLadderExpanded ? "expanded" : "collapsed"}
            data-ladder-category={category}
            data-ladder-type={entityType}
          >
            <div className="text-[18px] font-semibold pointer">
              {title} <span className="text-gray-600">{volumeFormat.format(count || 0)}</span>
            </div>
            <div className="absolute right-0 top-[10px] flex items-center gap-2 font-medium text-gray-500 translate-x-[104px] duration-[0.3s] ease-in-out-back group-hover:translate-x-[-6px] transition-transform">
              <div className="transition-all duration-100 group-hover:opacity-0 text-[14px]">Msg count</div>
              <div className="ml-[16px] flex flex-row items-center gap-2 py-1 px-2 rounded-xs">
                <div className="transition-all opacity-0  group-hover:opacity-100 text-gray-600 text-[14px]">
                  {curLadderExpanded ? "See less" : "See more"}
                </div>{" "}
                <Icon
                  color="black"
                  width={14}
                  height={14}
                  type={curLadderExpanded ? "Minimise" : "Maximise"}
                  size="xs"
                />
              </div>
            </div>
          </div>
          <div className="overflow-y-hidden no-scrollbar h-full" ref={scrollRef} style={containerClasses.scrollStyles}>
            <div style={{ minHeight: Math.min(EXPANDED_MAX, count || 0) * 40 }}>
              {dataToDisplay.nodes.map((item, i) => {
                return (
                  <LadderItemEntity
                    key={item.entityValue}
                    entityType={entityType}
                    volume={item.volume}
                    displayName={item.displayName}
                    entityValue={item.entityValue}
                    image={item.image}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

type LadderItemEntityProps = {
  entityType: "email" | "domain";
  displayName?: string;
  entityValue: string;
  volume: number;
  image?: string;
};

export function LadderItemEntity(props: LadderItemEntityProps) {
  const { entityType, displayName, entityValue, volume, image } = props;

  if (entityType === "domain") {
    return (
      <Link
        to={`/network?q=${entityValue}&fr=l`}
        className="transition-all duration-[0.3s] p-4 hover:bg-neutral-100 flex h-[40px] items-center justify-between text-[14px] overflow-hidden"
      >
        <div className="mr-2">
          <AvatarV2 size="xs" src={`https://www.google.com/s2/favicons?sz=128&domain=${entityValue}`} type="company" />
        </div>
        <div className="flex flex-1 font-semibold ">
          <div className="overflow-hidden whitespace-nowrap text-ellipsis line-clamp-1">{entityValue}</div>
        </div>
        <div className="text-right font-medium ml-2">{volumeFormat.format(volume)}</div>
      </Link>
    );
  }

  if (entityType === "email") {
    const initials = displayName?.match(/\b\w/g)?.slice(0, 2).join("");
    return (
      <Link
        to={`/network/contact/${entityValue}?fr=l`}
        className="p-4 hover:!bg-gray-50 flex h-[40px] items-center justify-between text-[14px] overflow-hidden"
      >
        <div className="mr-2">
          <AvatarV2 size="xs" type="user" initials={initials} />
        </div>
        <div className="flex-1 text-gray-700 font-semibold overflow-hidden whitespace-nowrap text-ellipsis mw-full">
          {displayName} <span className="text-gray-600 font-medium">({entityValue})</span>
        </div>
        <div className="text-right font-medium ml-2">{volumeFormat.format(volume)}</div>
      </Link>
    );
  }
}
