import { action, computed, makeObservable, observable } from "mobx";
import { hydrateStore, isHydrated, makePersistable } from "mobx-persist-store";

import type { IconType } from "@/app/components";
import { getEnv } from "@/app/env/env.ts";
import { system_prompt } from "@/app/screens/modal/copilot/prompts.ts";

export type AvailableModels = "gpt-4o" | "claude-3.7" | "mistral-large" | "gemini-pro";
export const models: Array<{ value: AvailableModels; label: AvailableModels }> = [
  { value: "gpt-4o", label: "gpt-4o" },
  { value: "claude-3.7", label: "claude-3.7" },
  { value: "gemini-pro", label: "gemini-pro" },
  { value: "mistral-large", label: "mistral-large" },
];

const defaultModelSettings: ModelSettings = {
  model: "gpt-4o",
  temperature: 0.2,
  topP: 0.95,
};
const tools: Record<ToolsType, ToolType> = {
  variables: {
    title: "Variables",
    icon: "Info",
    selected: false,
    disabled: true,
  },
  websearch: {
    title: "WebSearch",
    icon: "Search",
    selected: false,
    disabled: true,
  },
  files: {
    title: "Files",
    icon: "Attach",
    selected: false,
    disabled: true,
  },
};
const defaultConversation: ConversationType = {
  title: "New Conversation",
  messages: [],
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  modelSettings: clone(defaultModelSettings),
  systemPrompt: system_prompt,
  companyMetadata: {
    companyId: "",
    companyName: "",
    sentiments: [],
    comments: [],
    metrics: [],
    files: [],
  },
};

class CompanyCopilotStore {
  version = "0.0.1"; // use this to force a refresh of the store if schema updates
  history: Array<RouteType> = [];
  state: StateType = {
    companyId: "",
    companyName: "",
    conversationId: "",
    isLoading: false,
    route: Routes.conversations,
    tools: clone(tools),
    followUpQuestions: [],
  };
  conversations: Record<string, ConversationType> = {};
  messages: Array<MessageType> = [];
  isHydrated = false;

  constructor() {
    makeObservable(this, {
      state: observable,
      messages: observable,
      conversations: observable,

      goBack: action,
      addMessage: action,
      editMessage: action,
      addConversation: action,
      editConversation: action,
      saveConversation: action,
      deleteConversation: action,
      setState: action,
      onOpenChat: action,

      currentConversation: computed,
    });

    makePersistable(
      this,
      {
        stringify: true,
        debugMode: getEnv("MOBX_DEBUG") === "true",
        name: "copilot",
        storage: window.localStorage,
        properties: ["conversations", "state", "messages", "version"],
      },
      { fireImmediately: true },
    ).catch((e) => console.error("Error hydrating store", e));

    this.waitForHydration().catch();
  }

  async waitForHydration() {
    if (isHydrated(this)) {
      this.isHydrated = true;
      return;
    }

    await hydrateStore(this);
    this.isHydrated = true;
  }

  addMessage = (newMessages: Array<MessageType> | MessageType) => {
    if (Array.isArray(newMessages)) {
      this.messages = [...this.messages, ...newMessages];
      return;
    }
    this.messages = [...this.messages, newMessages];
  };

  editMessage = (id: string, newContent: string) => {
    const messageIndex = this.messages.findIndex((message) => message.id === id);
    if (messageIndex !== -1) {
      this.messages[messageIndex] = { ...this.messages[messageIndex], content: newContent };
    }
  };

  addConversation = (partialConversation: Partial<ConversationType> = {}) => {
    const newId = crypto.randomUUID();
    this.messages = [];
    this.conversations = {
      ...this.conversations,
      [newId]: {
        ...clone(defaultConversation),
        companyMetadata: {
          companyId: this.state.companyId,
          companyName: this.state.companyName,
        },
        title: `New Conversation`,
        messages: this.messages,
        ...partialConversation,
      },
    };
    this.state = { ...this.state, conversationId: newId, route: "messages" };
  };

  saveConversation = () => {
    if (!this.state.conversationId) {
      this.state.conversationId = crypto.randomUUID();
    }
    this.conversations[this.state.conversationId] = {
      ...this.conversations[this.state.conversationId],
      companyMetadata: {
        ...this.conversations[this.state.conversationId].companyMetadata,
        companyName: this.state.companyName,
        companyId: this.state.companyId,
      },
      messages: clone(this.messages),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };
    this.messages = [];
  };

  editConversation = (newState: Partial<ConversationType>) => {
    if (!this.state.conversationId) return;
    const conversation = this.conversations?.[this.state.conversationId];
    if (!conversation) return;
    this.conversations[this.state.conversationId] = deepMerge(conversation, newState);
  };

  deleteConversation = (id: string) => {
    if (this.conversations[id]) {
      delete this.conversations[id];
    }
  };

  selectConversation = (id: string) => {
    const conversation = this.conversations?.[id];
    if (!conversation) return;
    this.messages = clone(conversation.messages);
    this.state = {
      ...this.state,
      conversationId: id,
      route: Routes.messages,
      companyName: conversation.companyMetadata.companyName!,
      companyId: conversation.companyMetadata.companyId!,
    };
  };

  setState = (newState: Partial<StateType>) => {
    if (newState.route && (this.history[this.history.length - 1] !== newState.route || this.history.length === 0)) {
      this.history.push(newState.route);
    }

    this.state = deepMerge(this.state, newState);
  };

  goBack = () => {
    if (this.state.route === Routes.system) {
      this.state.route = Routes.messages;
      return;
    }

    if (this.state.route === Routes.messages) {
      if (this.messages.length >= 0) {
        this.saveConversation();
      }
      this.state.route = Routes.conversations;
      this.state.conversationId = "";
      return;
    }
    if (this.history.length <= 1) {
      this.state.route = Routes.conversations;
      return;
    }
    this.state.route = Routes.messages;
  };

  clearHistory = () => {
    this.history = [];
  };

  onOpenChat = ({
    copilotState,
    companyMetadata,
  }: {
    companyMetadata: Partial<CompanyMetadata>;
    copilotState: Partial<StateType>;
  }) => {
    const { companyId, companyName } = companyMetadata;
    const recentConversationByCompanyId = Object.entries(this.conversations)
      .filter(([, conversation]) => conversation.companyMetadata.companyId === companyId)
      .toSorted((a, b) => b[1].createdAt.localeCompare(a[1].createdAt));
    const lastRecentConvByIdEmpty = Boolean(recentConversationByCompanyId?.[0]?.[1]?.messages?.length === 0);

    if (this.history.length === 0 && copilotState.route === "messages" && !lastRecentConvByIdEmpty) {
      this.addConversation({ companyMetadata });
    }
    if (lastRecentConvByIdEmpty) {
      this.selectConversation(recentConversationByCompanyId[0][0]);
    }
    this.setState({ ...copilotState, companyId, companyName });
  };

  get currentConversation(): ConversationType {
    return this.conversations[this.state.conversationId];
  }
}

export const companyCopilotStore = new CompanyCopilotStore();

export type ToolsType = "variables" | "websearch" | "files";

export type RouteType = "messages" | "system" | "conversations";

enum Routes {
  messages = "messages",
  system = "system",
  conversations = "conversations",
}

export type StateType = {
  isLoading: boolean;
  conversationId: string;
  followUpQuestions: string[];
  route: RouteType;
  tools: Record<ToolsType, Partial<ToolType>>;
  companyId: string;
  companyName: string;
};

export type ToolType = {
  title: string;
  icon: IconType;
  selected: boolean;
  disabled: boolean;
};

export type ModelSettings = {
  model: AvailableModels;
  topP: number;
  temperature: number;
};

export type ConversationType = {
  title: string;
  systemPrompt: string;
  messages: Array<MessageType>;
  modelSettings: Partial<ModelSettings>;
  companyMetadata: Partial<CompanyMetadata>;
  createdAt: string;
  updatedAt: string;
};

export type CompanyMetadata = {
  companyId: string;
  companyName: string;
  sentiments: Array<{ id: string; timestamp: string }>;
  comments: Array<{ id: string; timestamp: string }>;
  metrics: Array<{ id: string; timestamp: string }>;
  files: Array<{ id: string; timestamp: string }>;
};

export type MessageType = {
  id: string;
  role: "user" | "assistant" | "system";
  content: string;
  timestamp: string;
};

function deepMerge<T extends Record<string, unknown>>(target: T, source: Partial<T>): T {
  const output = { ...target } as T;
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      const k = key as keyof T;
      if (isObject(source[k])) {
        if (!(k in target)) {
          output[k] = source[k] as T[keyof T];
        } else {
          output[k] = deepMerge(
            target[k] as Record<string, unknown>,
            source[k] as Record<string, unknown>,
          ) as T[keyof T];
        }
      } else {
        output[k] = source[k] as T[keyof T];
      }
    });
  }

  return output;
}

function clone(obj: Record<string, unknown> | Array<unknown> | string) {
  return JSON.parse(JSON.stringify(obj));
}

function isObject(item: unknown): item is Record<string, unknown> {
  return item !== null && typeof item === "object" && !Array.isArray(item);
}
