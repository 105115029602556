import { gql } from "@apollo/client";

export const connectionsDictQuery = gql`
  query GetConnectionsDict {
    dict: getConnectionsDict {
      nodes {
        email
        initials
        name
        image
      }
    }
  }
`;
