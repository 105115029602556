// @ts-nocheck

import { IconButtonV2, TextAreaV2 } from "@/app/components";
import { useTextAreaCopilot } from "@/app/screens/modal/copilot/hooks/use-send-message.tsx";

export const TextAreaCopilot = () => {
  const { input, inputRef, setInput, handleKeyDown, handleSendMessage, isLoading } = useTextAreaCopilot();

  return (
    <div className="flex items-center space-x-2 px-2">
      <TextAreaV2
        autoFocus={true}
        ref={inputRef}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Type your message..."
      />

      <IconButtonV2
        icon={"PaperPlane"}
        className="size-5"
        onClick={handleSendMessage}
        disabled={isLoading || !input.trim()}
      />
    </div>
  );
};
