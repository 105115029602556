import { useCallback, useState } from "react";

import { observer } from "mobx-react";
import { useParams } from "react-router";

import { Button, Card } from "@/app/components";
import { SelectV2 } from "@/app/components/forms/select-v2.tsx";
import { useDeclineAddCompanyEmailMutation, useGetCompanyEmailQuery } from "@/app/service/company-email.gql.ts";
import modalStore from "@/app/stores/modal.store.tsx";

import { ConfirmModal } from "../../modal/confirm/index.ts";
import {
  NetworkSignalType,
  sourceOptions,
} from "../../opportunities/opportunity/components/network-signals/network-signal-utils.ts";

import { AddViaEmailHeader } from "./add-companies-via-email-header.tsx";
import { AddOpportunitiesViaEmailList } from "./add-companies-via-email-list.tsx";
import { AddViaEmailMessage } from "./add-companies-via-email-message.tsx";
import { AddCompanyEmailDetails } from "./add-company-email-details.tsx";
import { AddCompanyViaEmailStatus } from "./add-company-via-email.types.ts";

const SkipAllButton = observer(function SkipAllButton({
  canDecline,
  notificationId,
  refetchEmail,
  className,
}: {
  canDecline: boolean;
  notificationId: string;
  refetchEmail: () => void;
  className?: string;
}) {
  const [decline, { loading: decliningEmail }] = useDeclineAddCompanyEmailMutation();

  const handleDecline = () => {
    modalStore.open(ConfirmModal, {
      props: {
        title: "Are you sure you want to decline this email?",
        subTitle: "By confirming, you will skip all opportunities from this email",
        handleConfirm: () => {
          if (notificationId) {
            decline({
              variables: {
                input: {
                  notificationId,
                },
              },
            }).then(() => {
              refetchEmail();
            });
          }
        },
      },
    });
  };

  if (!canDecline) {
    return null;
  }

  return (
    <Button
      className={className}
      size="sm"
      loading={decliningEmail}
      text="Skip all"
      variant="secondary"
      onClick={handleDecline}
    />
  );
});

const getEmailStatusLabel = (notificationStatus?: string) => {
  if (notificationStatus === "COMPLETED") {
    return "Completed";
  }

  if (notificationStatus === "DECLINED") {
    return "Declined";
  }

  return "To review";
};

export const AddCompaniesViaEmailView = () => {
  const { id } = useParams();

  const { data, refetch: refetchEmail } = useGetCompanyEmailQuery({
    fetchPolicy: "cache-and-network",
    variables: { id },
    skip: !id,
  });

  const [selectedSource, setSelectedSource] = useState<{
    name: string;
    type: NetworkSignalType;
  } | null>(null);

  const [selectedCompany, setSelectedCompany] = useState<{ name: string; url?: string } | null>(null);

  const handleSelectCompany = useCallback((company: { id: string; name: string; websiteUrl?: string }) => {
    setSelectedCompany({ name: company.name, url: company.websiteUrl });
  }, []);

  const opportunityEmail = data?.nzrNotification?.nzrOpportunityEmail;

  const emailId = opportunityEmail?.id;

  const isCompleted = data?.nzrNotification?.status === "COMPLETED";
  const isDeclined = data?.nzrNotification?.status === "DECLINED";

  if (!id || !emailId) {
    return;
  }

  // can't decline whole email if some company was completed
  const canDecline =
    !isCompleted &&
    !isDeclined &&
    !data?.nzrNotification?.nzrOpportunityEmail?.nzrOpportunityEmailCandidatesByEmailId.nodes.filter(
      (candidate) => candidate.status === "COMPLETED",
    ).length;

  return (
    <div className="flex h-screen w-full flex-col items-center gap-4 overflow-hidden bg-background">
      <AddViaEmailHeader className="px-2 pb-2 sm:px-4 lg:px-6 2xl:px-8" />
      <div className="flex size-full flex-col gap-4 overflow-hidden px-2 pb-2 sm:px-4 lg:px-6 lg:pb-6 2xl:px-8">
        <div className="grid h-full flex-1 grid-cols-1 gap-4 overflow-hidden md:grid-cols-12">
          <div className="col-span-12 flex flex-col gap-4 overflow-hidden md:col-span-6 xl:col-span-8">
            <Card className="hidden h-full overflow-hidden md:block">
              <Card.Body className="h-full overflow-hidden">
                {data?.nzrNotification?.nzrOpportunityEmail && (
                  <AddCompanyEmailDetails
                    email={data?.nzrNotification?.nzrOpportunityEmail}
                    searchWords={
                      selectedCompany ? [selectedCompany.name, selectedCompany.url].filter((val) => val != null) : []
                    }
                  />
                )}
              </Card.Body>
            </Card>
            <AddViaEmailMessage
              status={data?.nzrNotification?.status as AddCompanyViaEmailStatus}
              errorMessage={data?.nzrNotification?.nzrOpportunityEmail?.metadata?.error}
              identifiedCompaniesNumber={data?.nzrNotification?.payload?.email?.no_opps}
              title={data?.nzrNotification?.payload?.title}
            />
          </div>

          <Card className="col-span-12 flex h-full flex-col overflow-hidden !px-0 md:col-span-6 xl:col-span-4">
            <Card.Header>
              <div className="flex w-full justify-between gap-4 px-3 lg:px-5">
                <div className="flex shrink-0 flex-wrap items-center justify-between gap-1">
                  <h2 className="text-xl font-medium">{getEmailStatusLabel(data?.nzrNotification?.status)}</h2>
                  {!isCompleted && !isDeclined && (
                    <h2 className="text-xl font-medium text-gray-600">
                      {opportunityEmail?.nzrOpportunityEmailCandidatesByEmailId.nodes?.filter(
                        (company) => !company.status,
                      ).length ?? 0}
                    </h2>
                  )}
                  <SkipAllButton
                    canDecline={canDecline}
                    notificationId={data?.nzrNotification?.id}
                    refetchEmail={refetchEmail}
                    className="ml-3"
                  />
                </div>
                {!isCompleted && !isDeclined && (
                  <SelectV2
                    buttonClassName="max-w-[300px] h-8"
                    options={sourceOptions}
                    onChange={(value) =>
                      setSelectedSource({
                        name: data?.nzrNotification?.nzrOpportunityEmail?.source ?? "",
                        type: value!,
                      })
                    }
                    value={selectedSource?.type}
                    placeholder="Select source type"
                    multiple={false}
                  />
                )}
              </div>
            </Card.Header>
            <Card.Body className="mt-3 flex h-full min-h-0 flex-col gap-4 overflow-hidden lg:mt-5 ">
              <AddOpportunitiesViaEmailList
                data={data}
                selectedSource={selectedSource}
                className="h-full overflow-auto px-3 lg:px-5"
                onSelectCompany={handleSelectCompany}
              />
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};
