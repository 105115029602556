import { gql, useApolloClient, useQuery } from "@apollo/client";

const NetworkContactTV2_Fragment = gql`
  fragment SearchFragment on NetworkContactTV2 {
    email
    primaryName 
    totalTo
    totalFrom
    lastTo
    lastFrom
    connections
    connectionStrength
  }
`;

const networkContactQuery = gql`
  query GetNetworkContact($contactEmail: String, $q: String) {
    tags: getNetworkEntityTags(value: $contactEmail) {
      nodes {
        type
      }
    }
    contact: getNetworkContact(contactEmail: $contactEmail) {
      email
      internalContacts
      sent
      received
      lastSent
      lastReceived
      connStrength
      tags
      names
      domain
      type
    }
    
    domains: networkDomainSimpleLookup(q: $q, max: 10) {
      nodes {
        domain
        snippet
        lastSent
        lastReceived
        connStrength
        tags
        portfolioCompanyId
      }
    }




  }
`;





export function useContactQuery(email:string) {
  const client = useApolloClient();
  const readContactFromCache = () => {
    const data = client.readFragment({
      id: `NetworkContactTV2:{"email":"${email}"}`, 
      fragment: NetworkContactTV2_Fragment,
    });

    return data;
  };
  const { data, loading } = useQuery(networkContactQuery, { variables: { contactEmail: email, q: email.split("@")?.[1] } });
  
  let res;
  if (data) {
    res = data;
  } else {
    const fromCache = readContactFromCache();
    if (fromCache) {
      res = { contact: {
        email: fromCache.email,
        names: [fromCache.primaryName],
        sent: fromCache.totalFrom,
        received: fromCache.totalTo,
        lastSent: fromCache.lastFrom,
        lastReceived: fromCache.lastTo,
        connStrength: fromCache.connectionStrength,
        connections: fromCache.connections
      } };
    }
  }
  return { data: res, loading };
}
