import { zodResolver } from "@hookform/resolvers/zod";
import { useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";

import { linkedinProfileUrlRegex } from "@/lib/linkedin.utils.ts";

export const companyDataSchema = z
  .object({
    name: z.string().optional().nullable(),
    image: z.string().optional().nullable(),
    websiteUrl: z
      .union([
        z.literal(""),
        z.string().regex(/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/\S*)?$/, {
          message: "Please enter a valid url",
        }),
      ])
      .optional()
      .nullable(),
    founders: z
      .array(
        z.object({
          details: z
            .object({
              name: z.string().min(1, "Name is required").optional().nullable().or(z.literal("")),
              url: z
                .string()
                .regex(linkedinProfileUrlRegex, "Please enter a valid url")
                .optional()
                .nullable()
                .or(z.literal("")),
            })
            .refine((data) => !data.url || !!data.name, {
              message: "Name is required when LinkedIn URL is provided",
              path: ["name"],
            })
            .optional(),
          dealroomFounder: z
            .object({
              id: z.string().min(1, "id is required"),
              name: z.string().min(1, "Name is required"),
              image: z.string().optional().nullable(),
              linkedinUrl: z.string().optional().nullable(),
              titles: z.array(z.any()).optional().nullable(),
            })
            .optional(),
          lgFounder: z
            .object({
              id: z.string().min(1, "id is required"),
              name: z.string().min(1, "Name is required"),
              linkedinUrl: z.string().optional().nullable(),
            })
            .optional(),
        }),
      )
      .optional()
      .nullable(),
    dealroomCompany: z.string().optional().nullable(),
    scrapeData: z
      .object({
        websiteUrl: z.string().nullable().optional(),
        name: z.string().nullable().optional(),
        image: z.string().nullable().optional(),
        // remove this until we have visual llm extraction
        // founders: z.array(
        //   z.object({
        //     name: z.string().nullable().optional(),
        //     linkedinUrl: z.string().nullable().optional(),
        //     titles: z.array(z.any()).optional().nullable(),
        //     image: z.string().nullable().optional(),
        //   }),
        // ),
      })
      .optional()
      .nullable(),
  })
  .refine(
    (data) =>
      data.name ||
      data.websiteUrl ||
      data.founders?.some((value) => value.lgFounder || value.dealroomFounder || value?.details?.name) ||
      !!data.dealroomCompany,
    {
      message: "Please provide at least one of these three - name, website url or founders info",
      path: ["_required"],
    },
  );

export type CompanyDataSchema = z.infer<typeof companyDataSchema>;
export type CompanyFoundersSchema = CompanyDataSchema["founders"];

export const useCompanyDataForm = () => {
  const form = useForm<CompanyDataSchema>({
    resolver: zodResolver(companyDataSchema),
    mode: "all",
    defaultValues: {
      founders: [{}],
    },
  });

  const founders = useFieldArray({
    name: "founders",
    control: form.control,
  });

  return {
    form,
    founders,
  };
};

export type FoundersFieldArray = ReturnType<typeof useCompanyDataForm>["founders"];
