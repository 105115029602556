import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { GetForwardedCompaniesDocument } from "@/gql/graphql.ts";

import { cn } from "@/lib/utils.ts";

import { Card } from "@/app/components";
import { Routes } from "@/app/constants";
import { ConfirmModal } from "@/app/screens/modal";
import { useDeclineAddCompanyEmailMutation } from "@/app/service/company-email.gql.ts";
import modalStore from "@/app/stores/modal.store.tsx";
import refetchStore from "@/app/stores/refetch-store.ts";

import { DateColumn } from "../../components/email-opportunity/date-column.tsx";
import { DetailsColumn } from "../../components/email-opportunity/details-column.tsx";
import { EmailAuthorColumn } from "../../components/email-opportunity/email-author-column.tsx";
import { EmailOpportunityActions } from "../../components/email-opportunity/email-opportunity-actions.tsx";
import { NotificationStatus } from "../../dashboard.types.ts";

import { useCompaniesForwarded } from "./use-companies-forwarded.ts";

type CompaniesForwardedItemData = ReturnType<typeof useCompaniesForwarded>["items"][number];

export const CompaniesForwardedItem = ({
  notification,
  className,
}: {
  notification: CompaniesForwardedItemData;
  className?: string;
}) => {
  const navigate = useNavigate();
  // todo this is incomplete. should mark candidate companies as declined as well
  const [decline, { loading: decliningEmail }] = useDeclineAddCompanyEmailMutation();

  const title = notification.nzrOpportunityEmailAsReference?.subject ?? "No Subject";
  const author = notification.nzrOpportunityEmailAsReference?.senderEmail ?? "No Author";
  const date = notification.createdUtc;

  const stats = notification.nzrOpportunityEmailAsReference?.companiesStats?.nodes[0];

  const isCompleted = [NotificationStatus.COMPLETED, NotificationStatus.DECLINED].includes(
    notification.status as NotificationStatus,
  );

  const handleRemove = () => {
    const refetchQueries = refetchStore.getRefetchQueries([GetForwardedCompaniesDocument]);

    modalStore.open(ConfirmModal, {
      props: {
        title: "Are you sure?",
        subTitle: `By confirming, you will decline this notification`,
        handleConfirm: async () => {
          if (notification.id) {
            await decline({
              variables: {
                input: {
                  notificationId: notification.id,
                },
              },
              refetchQueries,
            });
          }

          toast.success("Successfully declined email");
          modalStore.close();
        },
      },
    });
  };

  return (
    <Card
      data-cy="dashboard-forwarded-item"
      className={cn(
        "group relative flex select-none flex-col items-start justify-between gap-4 gap-y-2 transition hover:scale-[1.01]",
        className,
      )}
    >
      <Card.Body className="grid grid-cols-2 items-center gap-4 md:grid-cols-12 md:gap-2">
        <div className="col-span-2 flex justify-between md:col-span-4">
          <EmailAuthorColumn
            author={author}
            label={title}
            styles={{
              labelStyle: "text-black text-sm font-semibold line-clamp-1",
            }}
          />
          <DateColumn
            date={date}
            styles={{ container: "text-xs md:hidden min-w-[80px]", labelStyle: "w-full text-end" }}
          />
        </div>

        <DateColumn date={date} label="Date" styles={{ container: "hidden col-span-2 md:flex" }} />
        <div
          className={cn(
            "col-span-2 flex justify-between gap-2 md:col-span-5 xl:col-span-4 md:justify-normal md:gap-10 lg:gap-16 xl:gap-30",
            isCompleted && "justify-normal gap-20",
          )}
        >
          {!isCompleted && (
            <DetailsColumn label="To Review" styles={{ container: "w-fit" }}>
              <span className="w-full text-center">{stats?.toReview ?? 0}</span>
            </DetailsColumn>
          )}
          <DetailsColumn label="Added" styles={{ container: "w-fit" }}>
            <span className="w-full text-center">{stats?.completed ?? 0}</span>
          </DetailsColumn>
          <DetailsColumn label="Skipped" styles={{ container: "w-fit" }}>
            <span className="w-full text-center">{stats?.declined ?? 0}</span>
          </DetailsColumn>
        </div>
        <EmailOpportunityActions
          className="col-span-2 md:col-span-3 xl:col-span-2"
          actions={{
            primary: {
              title: isCompleted ? "View" : "Open",
              action: () => navigate(`${Routes.company}/batch/${notification.id}`),
            },
            secondary: !isCompleted
              ? {
                  title: "Remove",
                  loading: decliningEmail,
                  action: handleRemove,
                }
              : undefined,
          }}
        />
      </Card.Body>
    </Card>
  );
};
