// @ts-nocheck

import { cn, windowOpen } from "@/lib/utils.ts";

import { IconType } from "@/app/components";
import { IconButtonV2 } from "@/app/components/button";
import { IconButtonProps } from "@/app/components/button/icon-button-v2.tsx";

type SocialMediaIconsProps = {
  icons: Array<{ name: IconType; url?: string | null }>;
  className?: string;
  variant: IconButtonProps["variant"];
};

export const SocialMediaIcons = ({ icons, className, variant }: SocialMediaIconsProps) => {
  return (
    <div className={cn("flex gap-2", className)}>
      {icons
        .filter((icon) => Boolean(icon?.url))
        .map(({ name, url }, index) => (
          <IconButtonV2 key={index + name} variant={variant} icon={name} onClick={() => windowOpen(url!, "_blank")} />
        ))}
    </div>
  );
};
