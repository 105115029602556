import { ButtonHTMLAttributes, forwardRef, ReactElement, SyntheticEvent } from "react";

import { cn } from "@/lib/utils";

import { Icon, IconType } from "@/app/components";
import { isIconType } from "@/app/components/icon";

export type IconButtonProps = {
  icon: Exclude<IconType, ""> | ReactElement;
  onClick?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  size?: "xs" | "sm" | "md" | "lg";
  variant?: "gradient" | "primary" | "secondary" | "outline" | "text";
  className?: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  disabled?: boolean;
};

export const IconButtonV2 = forwardRef<HTMLButtonElement, IconButtonProps>(function IconButton(
  { icon, size = "sm", variant = "primary", onClick, className, type = "button", disabled }: IconButtonProps,
  ref,
) {
  return (
    <button
      ref={ref}
      type={type}
      className={cn(
        "group/icon-button relative cursor-pointer overflow-hidden",
        "transition-colors duration-200 ease-in",
        size === "xs" && "size-6 rounded-xxs p-1",
        size === "sm" && "size-8 rounded-xxs p-2",
        size === "md" && "size-10 rounded-xs p-3",
        size === "lg" && "size-12 rounded-xs p-4",
        // variants
        variant === "gradient" &&
          "bg-gradient-to-r from-primary via-[#E93E62] to-secondary text-white hover:from-[#FF3B76] hover:to-[#FF792D] hover:opacity-90 active:from-[#CC2F5E] active:to-[#CC6124E5]/90 disabled:bg-gray-200 disabled:bg-none disabled:text-gray-500",
        variant === "primary" &&
          "bg-white text-gray-900 hover:bg-gray-400 active:bg-gray-500 disabled:bg-gray-300 disabled:text-gray-500",
        variant === "secondary" &&
          "bg-gray-300 text-gray-900 hover:bg-gray-400 active:bg-gray-500 disabled:bg-gray-300 disabled:text-gray-500",
        variant === "outline" &&
          "border border-gray-400 bg-white text-gray-900 hover:bg-gray-400 active:bg-gray-500 disabled:border-none disabled:bg-gray-300 disabled:text-gray-500",
        variant === "text" &&
          "bg-transparent text-gray-900 hover:bg-gray-400 active:bg-gray-500 disabled:text-gray-500",
        className,
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {isIconType(icon) ? <Icon type={icon} className="size-full" /> : icon}
      <div className="group-active/icon-button:ripple pointer-events-none absolute left-1/2 top-1/2 size-1 -translate-x-1/2 -translate-y-1/2 opacity-0 backdrop-brightness-125"></div>
    </button>
  );
});
