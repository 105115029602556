import { gql } from "@apollo/client";

const ladderFields = `
fragment LadderFields on NetworkVolumeLadder {
    id
    entityValue
    volume
    displayName
    image
}`;

export const GetNetworkVolumeLadder = gql`
  ${ladderFields}
  query GetNetworkVolumeLadder($type: String, $period: String, $category: String, $max: Int) {
    ladder: getNetworkVolumeLadder(type: $type, period: $period, category: $category, max: $max) {
      nodes {
        ...LadderFields
      }
    }
  }
`;

function getLadderChunk(type: string, category: string) {
  const chunk = `${type}_${category.replaceAll("-", "")}: getNetworkVolumeLadder(type: "${type}", period: $period, category: "${category}", max: $max) {
      nodes { ...LadderFields }
    }`;
  return chunk;
}

export const GetNetworkVolumeLadderGroup = gql`
  ${ladderFields}
  query GetNetworkVolumeLadder($period: String, $max: Int) {
    ${["domain", "email"]
      .map((type) =>
        ["portfolio", "basecamp", "co-investor", "lp" /*"corporate"*/]
          .map((category) => getLadderChunk(type, category))
          .join(" \n ")
      )
      .join(" \n ")}

      meta: getNetworkVolumeLadderMeta(period: $period) {
        nodes {
          key
          count
        }
      }

  }
`;

export const GetNetworkVolumeLadderGroupBE = gql`
  ${ladderFields}
  query GetNetworkVolumeLadder($period: String, $max: Int) {
    groups: getNetworkVolumeLadder(period: $period, max: $max) {
      nodes {
        ...LadderFields
      }
    }
  }
`;
