import { useEffect, useState } from "react";

const enableDarkerMode = () => {
  document.documentElement.setAttribute("data-darker", "true");
};

const disableDarkerMode = () => {
  document.documentElement.removeAttribute("data-darker");
};

export const isDarkerModeEnabled = (): boolean => {
  return document.documentElement.getAttribute("data-darker") === "true";
};

export const useDarkerMode = () => {
  const [isDarker, setIsDarker] = useState(isDarkerModeEnabled());

  const toggleDarkerMode = () => {
    const isDarkerMode = document.documentElement.getAttribute("data-darker") === "true";

    if (isDarkerMode) {
      disableDarkerMode();
      setIsDarker(false);
    } else {
      enableDarkerMode();
      setIsDarker(true);
    }
  };

  useEffect(() => {
    setIsDarker(isDarkerModeEnabled());

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "attributes" && mutation.attributeName === "data-darker") {
          setIsDarker(isDarkerModeEnabled());
        }
      });
    });

    observer.observe(document.documentElement, { attributes: true, attributeFilter: ["data-darker"] });

    return () => {
      observer.disconnect();
    };
  }, []);

  return { isDarker, toggleDarkerMode };
};
