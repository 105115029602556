import { Icon } from "@/app/components";

export const KanbanBoardComingSoon = () => {
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center gap-4">
      <Icon type="SquareKanban" className="size-16" />
      <h3 className="text-xl font-semibold text-gray-800">Kanban Board Coming Soon</h3>
      <p className="max-w-md text-center text-gray-600">
        We're working on a powerful Kanban board to help you visualize and manage your workflow more efficiently. Stay
        tuned for updates!
      </p>
    </div>
  );
};
