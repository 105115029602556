// @ts-nocheck

import React from "react";

import { Group } from "@visx/group";
import letterFrequency, { LetterFrequency } from "@visx/mock-data/lib/mocks/letterFrequency";
import { Point } from "@visx/point";
import { useParentSize } from "@visx/responsive";
import { scaleLinear } from "@visx/scale";
import { Circle } from "@visx/shape";

import { capitalize, cn } from "@/lib/utils.ts";

import { colors } from "@/app/constants";

const orange = "rgb(218,16,39)";
export const pumpkin = "rgba(235,53,68,0.5)";
export const pumpkinNoAlpha = "rgba(235,53,68)";
const silver = "#d9d9d9";
export const background = "#FFFFFF";

const degrees = 360;
const data = letterFrequency.slice(2, 11);

const y = (d: LetterFrequency) => d.frequency;

const genAngles = (length: number) =>
  [...new Array(length + 1)].map((_, i) => ({
    angle: i * (degrees / length) + (length % 2 === 0 ? 0 : degrees / length / 2),
  }));

const genPoints = (length: number, radius: number) => {
  const step = (Math.PI * 2) / length;
  const offset = Math.PI;

  return [...new Array(length)].map((_, i) => ({
    x: radius * Math.sin(i * step + offset),
    y: radius * Math.cos(i * step + offset),
  }));
};

function genPolygonPoints<Datum>(dataArray: Datum[], scale: (n: number) => number, getValue: (d: Datum) => number) {
  const step = (Math.PI * 2) / dataArray.length;
  const points: { x: number; y: number }[] = new Array(dataArray.length).fill({ x: 0, y: 0 });
  const pointString: string = new Array(dataArray.length + 1).fill("").reduce((res, _, i) => {
    if (i > dataArray.length) return res;
    const xVal = scale(getValue(dataArray[i - 1])) * Math.sin(i * step + Math.PI);
    const yVal = scale(getValue(dataArray[i - 1])) * Math.cos(i * step + Math.PI);

    if (xVal == 0 && yVal == 0) return res; // disable this to allow points to go through origin

    points[i - 1] = { x: xVal, y: yVal };
    res += `${xVal},${yVal} `;
    return res;
  });

  return { points, pointString };
}

function controlPoints(p, t = 0.2) {
  // given the points array p calculate the control points for the cubic Bezier curves

  const pc = [];
  for (let i = 1; i < p.length - 1; i++) {
    const dx = p[i - 1].x - p[i + 1].x; // difference x
    const dy = p[i - 1].y - p[i + 1].y; // difference y
    // the first control point
    const x1 = p[i].x - dx * t;
    const y1 = p[i].y - dy * t;
    const o1 = {
      x: x1,
      y: y1,
    };

    // the second control point
    const x2 = p[i].x + dx * t;
    const y2 = p[i].y + dy * t;
    const o2 = {
      x: x2,
      y: y2,
    };

    // building the control points array
    pc[i] = [];
    pc[i].push(o1);
    pc[i].push(o2);
  }
  return pc;
}

function drawCurve(p) {
  const pc = controlPoints(p); // the control points array

  let d = `M${p[0].x},${p[0].y}
Q${pc[1][1].x},${pc[1][1].y}, ${p[1].x},${p[1].y}
`;
  if (p.length > 2) {
    // central curves are cubic Bezier
    for (let i = 1; i < p.length - 2; i++) {
      d += `C${pc[i][0].x}, ${pc[i][0].y}, ${pc[i + 1][1].x}, ${pc[i + 1][1].y}, ${p[i + 1].x},${p[i + 1].y}`;
    }

    // the first & the last curve are quadratic Bezier
    const n = p.length - 1;
    d += `Q${pc[n - 1][0].x}, ${pc[n - 1][0].y}, ${p[n].x}, ${p[n].y}`;
  }
  return d;
}

const defaultMargin = { top: 35, left: 0, right: 0, bottom: 15 };

export type RadarProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  levels?: number;
  fontSize?: string | number;
};

function SampleChart({ data, width, height, levels = 5, margin = defaultMargin, fontSize = "0.95rem" }: RadarProps) {
  const { first, second } = data;
  const pointLength = Math.max(first?.length || 0, second?.length || 0);

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const radius = Math.min(xMax, yMax) / 2;

  const radialScale = scaleLinear<number>({
    range: [0, Math.PI * 2],
    domain: [degrees, 0],
  });

  const yScale = scaleLinear<number>({
    range: [0, radius],
    domain: [0, 10],
  });
  const labelPoints = genPoints(pointLength, radius + 18);
  const firstPolygonPoints = genPolygonPoints(
    first,
    (d) => yScale(d) ?? 0,
    ({ value }) => parseFloat(value),
  );
  const secondPolygonPoints = second
    ? genPolygonPoints(
        second,
        (d) => yScale(d) ?? 0,
        ({ value }) => parseFloat(value),
      )
    : null;

  return (
    <svg height={height} width={width}>
      <rect fill={background} width={width} height={height} rx={14} />
      <Group top={height / 2} left={width / 2}>
        {[...new Array(levels / 2)].map((_, i) => (
          <Circle
            key={`web-${i}`}
            r={((i * 2 + 1) * radius) / levels}
            fill="none"
            stroke={silver}
            strokeDasharray="8"
            strokeWidth={2}
            strokeOpacity={0.8}
            strokeLinecap="round"
          />
        ))}
        {[...new Array(levels / 2)].map((_, i) => (
          <text
            key={`radar-label-${i}`}
            className={"text-xss text-gray-400"}
            y={-((i + 1) * 2 * radius) / levels + 5}
            x={0}
            style={{ dominantBaseline: "middle", textAnchor: "middle", fontSize: "0.8em" }}
          >
            {(i + 1) * 2}
          </text>
        ))}
        <polygon
          points={firstPolygonPoints.pointString}
          fill={orange}
          fillOpacity={0.3}
          stroke={orange}
          strokeWidth={1}
        />
        {firstPolygonPoints.points.map((point, i) => (
          <circle
            key={`radar-point-${i}`}
            cx={point.x}
            cy={point.y}
            r={4}
            fill={pumpkinNoAlpha}
            strokeWidth={2}
            stroke="white"
          />
        ))}
        {secondPolygonPoints && (
          <>
            <polygon
              points={secondPolygonPoints.pointString}
              fill={orange}
              fillOpacity={0.3}
              stroke={orange}
              strokeWidth={1}
            />
            {secondPolygonPoints.points.map((point, i) => (
              <circle
                key={`radar-point-${i}`}
                cx={point.x}
                cy={point.y}
                r={4}
                fill={pumpkinNoAlpha}
                strokeWidth={2}
                stroke="white"
              />
            ))}
          </>
        )}

        {/* Adding labels at the end of each radar chart line */}
        {labelPoints.reverse().map((point, i) => (
          <text
            key={`radar-label-${i}`}
            x={point.x}
            y={point.y}
            className={"!font-semibold"}
            style={{
              dominantBaseline: "middle",
              textAnchor: "middle",
              color: colors.gray[400],
              fontSize: "12px",
              fontWeight: 600,
              fontFamily: "Barlow",
            }}
          >
            {capitalize(first[i].label)}
          </text>
        ))}
      </Group>
    </svg>
  );
}

export const SentimentRadarChart = ({
  radarChartData,
  tooltipChartData,
  className,
  style,
  chartMargin,
  chartFontSize,
}) => {
  const { parentRef, width, height } = useParentSize({
    debounceTime: 250,
  });

  return (
    <div ref={parentRef} className={cn("", className)} style={style}>
      <SampleChart
        data={{ first: radarChartData, second: tooltipChartData }}
        levels={10}
        fontSize={chartFontSize}
        margin={chartMargin}
        width={width}
        height={height}
      />
    </div>
  );
};
