// @ts-nocheck

import { useCallback, useEffect, useMemo, useRef } from "react";

import { ComboboxInput } from "@headlessui/react";

import { cn } from "@/lib/utils.ts";

import { Icon, IconType, Label } from "@/app/components";
import { TW_INPUT_CORE, TW_INPUT_CORE_FOCUS } from "@/app/components/input";
import { OptionsType } from "@/app/screens/opportunities/opportunities.store.ts";

export type MultiInputProps = {
  inputPosition?: "right" | "left";
  iconLeft?: IconType;
  iconLeftClassName?: string;
  containerClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  placeholder?: string;
  selectedOptions?: Array<OptionsType>;
  disableFocus?: boolean;
  canDelete?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  allowManualInput?: boolean;
  query?: string;
  onQueryChange?: (query: string) => void;
  onRemove?: (option: OptionsType) => void;
  onClick?: () => void;
  onEnterPress?: () => void;
};

export const MultiInput = ({
  iconLeft,
  containerClassName = "",
  inputClassName = "",
  labelClassName = "",
  selectedOptions = [],
  inputPosition = "right",
  disableFocus = false,
  autoFocus = false,
  disabled = false,
  canDelete = true,
  placeholder = "Select",
  allowManualInput = true,
  query = "",
  onQueryChange,
  onRemove,
  onClick,
  onEnterPress,
  ...props
}: MultiInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const scrollIntoViewLastElement = useRef<HTMLLabelElement>(null);

  const handleClick = useCallback(() => {
    if (inputRef.current) inputRef.current.focus();

    if (onClick) onClick();
  }, [onClick]);

  const handleQueryChange = useCallback(
    (event) => {
      const { value } = event.target;

      if (onQueryChange) onQueryChange(value);
    },
    [onQueryChange],
  );

  const handleKeyCapture = useCallback(
    (e, queryLength: number = 0) => {
      e.preventDefault();
      const option = selectedOptions[selectedOptions.length - 1];

      if (e.code === "Backspace" && queryLength === 0 && option && onRemove) {
        onRemove(option);
      }

      if (e.code === "Enter" && onEnterPress) onEnterPress();
    },
    [selectedOptions, onRemove, onEnterPress],
  );

  useEffect(() => {
    if (scrollIntoViewLastElement?.current && !disableFocus) {
      scrollIntoViewLastElement?.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [selectedOptions, disableFocus]);

  const values = useMemo(() => {
    if (selectedOptions.length === 0 && query.length === 0) {
      return (
        <span
          onClick={handleClick}
          className={"pointer-events-none absolute bottom-1/2 translate-y-1/2 text-sm text-neutral-400"}
        >
          {placeholder}
        </span>
      );
    }

    return selectedOptions.map((option, i) => (
      <Label
        key={option.label + i}
        description={option.label}
        textColor={"text-black"}
        onIconRightClick={() => canDelete && onRemove?.(option)}
        iconRight={canDelete ? "X" : undefined}
        containerClassName={cn("cursor-pointer select-none", labelClassName)}
        ref={selectedOptions.length - 1 === i ? scrollIntoViewLastElement : null}
      />
    ));
  }, [canDelete, placeholder, labelClassName, selectedOptions, query.length, handleClick, onRemove]);

  const input = useMemo(
    () =>
      allowManualInput ? (
        <ComboboxInput
          className={cn(
            `relative flex min-w-10 shrink text-sm text-neutral-600 flex-grow ${inputClassName}`,
            selectedOptions?.length > 0 ? "ml-2" : "",
          )}
          onChange={handleQueryChange}
          ref={inputRef}
          autoFocus={autoFocus}
          value={query}
          onKeyUpCapture={(event) => handleKeyCapture(event, query.length)}
          displayValue={(person: OptionsType) => person?.label}
        />
      ) : null,
    [
      query,
      inputRef,
      allowManualInput,
      autoFocus,
      inputClassName,
      selectedOptions.length,
      handleKeyCapture,
      handleQueryChange,
    ],
  );

  return (
    <button
      {...props}
      disabled={disabled}
      className={cn(
        TW_INPUT_CORE,
        TW_INPUT_CORE_FOCUS,
        `relative h-[47px] cursor-pointer items-center px-4 ${containerClassName}`,
        {
          "text-neutral-300": disabled,
        },
      )}
      onClick={handleClick}
    >
      {iconLeft && <Icon type={iconLeft} className={"z-10 mx-2 size-4 text-primary lg:ml-0 lg:size-5"} />}
      {inputPosition === "left" && input}
      <div
        className={"no-scrollbar z-10 flex space-x-0.5 overflow-auto lg:space-x-2"}
        onClick={(e) => e.stopPropagation()}
      >
        {values}
      </div>
      {inputPosition === "right" && input}
    </button>
  );
};
