import { useState } from "react";

import { AnimatePresence, motion } from "framer-motion";
import toast from "react-hot-toast";

import { GetCompanyUpdatesDocument } from "@/gql/graphql.ts";

import { cn } from "@/lib/utils.ts";

import { Button, Card, Icon, Toast } from "@/app/components";
import { ChipV2 } from "@/app/components/v2/chip-v2.tsx";
import {
  SearchCompanyV2,
  SearchCompanyV2Result,
} from "@/app/screens/modal/actions/add-sentiment/components/search-company-v2";
import { useLinkCompanyEmailMutation, useRelinkCompanyEmailMutation } from "@/app/service/company-email.gql.ts";

import { CompanyLink } from "../../components/company-link.tsx";

const LinkedCompany = ({
  company,
}: {
  company: { id: string; name: string; image: string; websiteUrl?: string } | null;
}) => {
  return (
    <div className="flex items-center gap-x-2">
      {company !== null ? (
        <CompanyLink id={company.id} name={company.name} titleClassName="text-sm">
          <Card.Image
            type="company"
            size="sm"
            src={
              company.image ||
              (company.websiteUrl ? `https://www.google.com/s2/favicons?sz=128&domain=${company.websiteUrl}` : "")
            }
            className="mr-2"
          />
        </CompanyLink>
      ) : (
        <ChipV2 label="Company not linked" size="lg" color="red" />
      )}
    </div>
  );
};

type CompanyUpdatesSelectorProps = {
  company: { id: string; name: string; image: string } | null;
  notificationId: string;
  emailId?: string;
  enableRelink?: boolean;
};

export const CompanyUpdatesSelector = ({
  company,
  emailId,
  notificationId,
  enableRelink = false,
}: CompanyUpdatesSelectorProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [relinkCompanyEmail, { loading: isRelinking }] = useRelinkCompanyEmailMutation();
  const [linkCompanyEmail, { loading: isAccepting }] = useLinkCompanyEmailMutation();
  const loading = isRelinking || isAccepting;

  const handleSelectCompany = async (selectedCompany?: SearchCompanyV2Result) => {
    if (!selectedCompany || !emailId) {
      console.warn("No company or emailId provided");
      return;
    }

    // if there's no company, we should link. Otherwise, relink and move the comments and files to the new company
    if (!company) {
      await linkCompanyEmail({
        variables: {
          input: {
            emailOpportunityId: emailId,
            companyId: selectedCompany?.id,
            notificationId: notificationId!,
          },
        },
        refetchQueries: [GetCompanyUpdatesDocument],
      });
    } else {
      await relinkCompanyEmail({
        variables: {
          input: {
            emailOpportunityId: emailId,
            notificationId: notificationId!,
            nextCompanyId: selectedCompany?.id,
          },
        },
        refetchQueries: [GetCompanyUpdatesDocument],
      });
    }

    toast.custom((t) => (
      <Toast
        title="Success!"
        visible={t.visible}
        icon={<Icon type="FillCheck" className="text-green-500" />}
        subTitle="Company has been linked"
        handleClose={() => toast.dismiss(t.id)}
      />
    ));

    setIsEditing(false);
  };

  return (
    <div className="relative w-full">
      <AnimatePresence mode="wait" initial={false}>
        {isEditing ? (
          <motion.div
            key="search"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            className={cn("absolute inset-0 flex items-center gap-x-2", loading && "pointer-events-none")}
          >
            <SearchCompanyV2 selectCompany={handleSelectCompany} defaultSelected={company as SearchCompanyV2Result} />
            <Button
              loading={loading}
              text={loading ? "" : "Cancel"}
              variant="outline"
              onClick={() => setIsEditing(false)}
            />
          </motion.div>
        ) : (
          <motion.div
            key="company"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            className="absolute inset-0 flex items-center gap-x-2"
          >
            <LinkedCompany company={company} />
            {enableRelink && (
              <Button
                className="group/btn overflow-hidden"
                variant="text"
                onClick={() => setIsEditing(true)}
                size="sm"
                iconLeft="Link"
              >
                <span className="w-0 overflow-auto truncate opacity-0 transition-all duration-300 group-hover/btn:w-10 group-hover/btn:opacity-100">
                  {company ? "Relink" : "Link"}
                </span>
              </Button>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
