import { observer } from "mobx-react";
import { useNavigate } from "react-router";

import { UserRoleType } from "@/gql/graphql.ts";

import { cn } from "@/lib/utils.ts";

import { Card } from "@/app/components";
import { useDashboardSettings } from "@/app/screens/settings/hooks";
import { authStore } from "@/app/stores/auth.store.tsx";

export const SettingsDashboard = observer(() => {
  const { name, tabs, TabComponent, activeTab, setActiveTab } = useDashboardSettings();
  const navigate = useNavigate();
  const { userRoles: myRoles } = authStore;
  const networkSettings = myRoles?.includes(UserRoleType.UserRoleNetwork) ? (
    <span
      className={"cursor-pointer select-none text-sm font-medium text-gray hover:text-gray-600"}
      onClick={() => navigate("/network/settings/personal-filters")}
    >
      Personal Network Filters
    </span>
  ) : null;

  return (
    <div className={"relative min-h-dvh w-full bg-background px-[7dvw] lg:px-[14dvw]  lg:pt-0"}>
      <Card className={"my-8 flex w-full flex-col"}>
        <div>
          <p className="text-3xl font-semibold">{name}</p>
          <p className="mt-3 text-sm font-semibold text-gray">Manage your details and personal preferences here.</p>
        </div>
        <div className={"my-12 flex space-x-4.5"}>
          {tabs.map((tab) => {
            const isActive = tab === activeTab;
            return (
              <span
                key={tab}
                className={cn(
                  "cursor-pointer select-none text-sm",
                  isActive ? "font-bold" : "font-medium",
                  isActive ? "text-gray-900" : "text-gray hover:text-gray-600",
                )}
                onClick={() => setActiveTab(tab)}
              >
                {tab.replace(/([A-Z])/g, " $1").trim()}
              </span>
            );
          })}
          {networkSettings}{" "}
        </div>
        <TabComponent />
      </Card>
    </div>
  );
});
