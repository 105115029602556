import { useState } from "react";

import { AnimatePresence, motion } from "framer-motion";

import { Button } from "../button";
import { PresentationModeToggle } from "../presentation-mode/presentation-mode-toggle";
import { getEnv } from "@/app/env/env";

const isDev = getEnv("ENVIRONMENT") === "development";

// if it annoys you, you can disable it by setting the localStorage item to true
const isDisabled = localStorage.getItem("dev-tools-disabled") === "true";

export const DevTools = () => {
  const [hidden, setHidden] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  if (isDisabled || !isDev || hidden) {
    return null;
  }

  return (
    <div className="fixed bottom-4 right-4 z-20">
      <Button
        iconLeft="Settings"
        text="Developer Tools"
        size="sm"
        variant="gradient"
        onClick={() => setIsOpen(!isOpen)}
      />

      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="absolute bottom-12 right-0 w-64 rounded-md border border-gray-200 bg-white shadow"
            initial={{ opacity: 0, scale: 0.9, y: 10 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.9, y: 10 }}
            transition={{ duration: 0.2 }}
          >
            <div className="flex select-none flex-col">
              <h3 className="border-b px-4 py-2 text-sm font-medium text-gray-700">Developer Tools</h3>
              <PresentationModeToggle
                label="Presentation Mode"
                className="flex w-full cursor-pointer items-center justify-between gap-1 px-4 py-2 hover:bg-gray-200"
              />
              <div className="flex flex-col gap-1 border-t px-4 py-2">
                <Button
                  size="xs"
                  className="w-full"
                  text="Hide Developer Tools"
                  variant="outline"
                  onClick={() => setHidden(true)}
                />
                <span className="w-full text-center text-xxs text-gray-600">
                  This will hide the developer tools button. It will be shown again when you reload the page.
                </span>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
