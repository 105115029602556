import { cn } from "@/lib/utils";

import { Icon, Popover, TooltipInfo } from "@/app/components";

export interface EngagementOverviewProps {
  showAll?: boolean;
  isSad?: boolean;
  companyId: string;
  engagement?: {
    totalAttachments?: number | null;
    totalComments?: number | null;
    hasNoRecentComments?: boolean | null;
    hasNoRecentAttachments?: boolean | null;
  } | null;
}

// @deprecated
export const EngagementOverview = ({ engagement, showAll, isSad }: EngagementOverviewProps) => {
  const isOld = engagement?.hasNoRecentComments && engagement?.hasNoRecentAttachments;

  return (
    <div className="flex gap-1.5 text-sm font-semibold text-neutral-500">
      <div className="flex items-center gap-1">
        <Icon type="Attach" className="h-2 md:h-4 lg:h-3 2xl:h-4" />
        <span className="text-xxs leading-4 text-black md:text-sm lg:text-xs 2xl:text-sm">
          {engagement?.totalAttachments ?? 0}
        </span>
      </div>
      <div className="flex items-center gap-1">
        <Icon type="Comment" className="h-2 md:h-4 lg:h-3 2xl:h-4" />
        <span className="text-xxs leading-4 text-black md:text-sm lg:text-xs 2xl:text-sm">
          {engagement?.totalComments ?? 0}
        </span>
      </div>
      {isOld && showAll && (
        <Popover
          childrenContent={
            <TooltipInfo
              className="px-1"
              info={isOld}
              text="Last attachment/comment was added more than 6 months ago"
            />
          }
        >
          <Icon type="SandClock" className={cn("lg:size-4 2xl:size-4")} />
        </Popover>
      )}
    </div>
  );
};
