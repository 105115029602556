// @ts-nocheck

import { useCallback, useEffect, useState } from "react";

import { Combobox } from "@headlessui/react";
import { CheckIcon } from "lucide-react";

import { useBreakpoints } from "@/lib/hooks";
import { cn } from "@/lib/utils.ts";

import { combineTailwindStyles } from "@/app/misc/helpers.ts";
import { SelectMobile } from "@/app/screens/modal/selection/select-mobile.tsx";
import modalStore from "@/app/stores/modal.store.tsx";

import { Icon } from "../icon/icon";

export interface SelectOption {
  value: string | number;
  type?: string;
  label?: string;
}

export type SelectProps<T extends SelectOption> = {
  options?: T[];
  onChange?: (options: T) => void;
  optionsDirection?: "bottom" | "top";
  optionsClassName?: string;
  inputClassName?: string;
  className?: string;
  label?: string;
  canDelete?: boolean;
  disabled?: boolean;
  by?: string;
  iconLeft?: string;
  prefix?: string;
  prefixClassName?: string;
  buttonClassName?: string;
  value?: T["value"];
};

export function Select<T extends SelectOption>({
  className,
  optionsClassName,
  inputClassName,
  prefixClassName,
  canDelete,
  disabled,
  label = "Select Options",
  by = "label",
  prefix = "",
  options = [],
  optionsDirection = "bottom",
  iconLeft,
  onChange,
  buttonClassName,
  value,
}: SelectProps<T>) {
  const { isLaptop } = useBreakpoints();
  const [selectedOption, setSelectedOption] = useState<SelectOption | undefined>();
  const [query, setQuery] = useState("");

  const handleSelect = useCallback(
    (option) => {
      setQuery("");
      setSelectedOption(option);
      if (onChange) onChange(option);
    },
    [onChange],
  );

  useEffect(() => {
    if (value !== selectedOption?.value) {
      setSelectedOption(options.find((option) => option.value === value));
    }
  }, [value]);

  const filteredOptions =
    query === ""
      ? options
      : options.filter(({ label, value }) => {
          return (label || `${value}`).toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox
      value={selectedOption}
      defaultValue={label}
      as="div"
      nullable
      by={by}
      multiple={false}
      disabled={disabled}
      className={`relative rounded-tremor-default ${className}`}
      onChange={handleSelect}
    >
      <div className={`relative w-full ${inputClassName}`}>
        <Combobox.Button className={cn("size-full select-none px-2 text-center focus:outline-none", buttonClassName)}>
          <div
            className="flex w-full items-center justify-between text-left"
            onClick={() => {
              if (!isLaptop)
                modalStore.open(SelectMobile, {
                  props: { options, handleSelect, selectedOption, title: "Select Metric" },
                });
            }}
          >
            <div className={"flex w-full"}>
              {Boolean(prefix?.length) && <p className={cn("mr-2", prefixClassName)}>{prefix}</p>}
              <p className="text-[14px]">
                {iconLeft && <Icon type={iconLeft} className="mr-2 inline-block size-5 self-start text-primary" />}
                {selectedOption?.label || label}
              </p>
            </div>
            <Icon
              type={optionsDirection === "bottom" ? "Filter Chevron Down" : "Filter Chevron Up"}
              className="inline-block size-5 self-end text-black"
              aria-hidden="true"
            />
          </div>
        </Combobox.Button>
      </div>
      {isLaptop && (
        <Combobox.Options
          className={cn(
            `absolute z-20 mt-3 max-h-[40vh] w-full divide-y divide-neutral-200 overflow-auto rounded-md bg-white py-1 text-base shadow-sm`,
            { "bottom-14": optionsDirection === "top" },
            optionsClassName,
          )}
        >
          {filteredOptions.map((option) => (
            <Combobox.Option
              key={`${option.value}_${option.label}`}
              value={option}
              className={({ active }) =>
                combineTailwindStyles(
                  "relative select-none px-4 py-5  hover:cursor-pointer",
                  active ? "bg-neutral-100" : "",
                )
              }
            >
              {({ active, selected }) => (
                <>
                  <div className="flex items-center">
                    <span className={combineTailwindStyles("ml-3 truncate", selected && "font-bold")}>
                      {option.label}
                    </span>
                  </div>

                  {selected && canDelete && (
                    <span
                      className={combineTailwindStyles(
                        "absolute inset-y-0 right-0 flex items-center pr-4",
                        active ? "text-primary" : "text-primary",
                      )}
                    >
                      <CheckIcon className="size-5" aria-hidden="true" />
                    </span>
                  )}
                </>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      )}
    </Combobox>
  );
}
