import { useState } from "react";

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";

import { cn } from "@/lib/utils";

import { Icon } from "@/app/components";
import { ProgressBar } from "@/app/components/loading/progress-bar";
import modalStore from "@/app/stores/modal.store";

import { SnoozeResponsiveModal } from "../../modal";
import { SnoozeActionType } from "../../modal/actions/convert-to-prospects/hooks/use-snooze-form";
import { useKanbanBoardContext } from "../kanban-board-context";
import { useGroupedCompanies, useMoveStage } from "../kanban-hooks";

import { KanbanBoardCard } from "./kanban-board-card";
import { KanbanBoardColumn } from "./kanban-board-column";

const DroppableContainer = ({ id, className, text }: { id: string; className?: string; text?: string }) => {
  const { setNodeRef, isOver } = useDroppable({
    id,
    data: {
      type: "container",
      containerId: id,
    },
  });

  return (
    <>
      {isOver && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
          <span className="text-5xl font-bold text-gray opacity-20">{text}</span>
        </div>
      )}
      <div
        className={cn(
          "flex flex-col items-center justify-center rounded-sm border-2 border-dashed border-gray-500 bg-gray-300 px-8 py-4 transition-all duration-500",
          isOver && "z-50 scale-105 border-primary bg-gray-200 shadow-md",
          className,
        )}
        ref={setNodeRef}
      >
        <Icon type="ArrowBigDown" className={cn("size-8", isOver ? "text-primary" : "text-gray-500")} />
        <span className={cn("text-center text-sm font-medium", isOver ? "text-primary" : "text-gray-600")}>{text}</span>
      </div>
    </>
  );
};

export const KanbanBoard = () => {
  const { selectedFund, companies: items, loading, sort } = useKanbanBoardContext();
  const columns = selectedFund.columns;
  const [activeId, setActiveId] = useState<string | null>(null);

  const { items: companies, setItems: setCompanies } = useGroupedCompanies(items, columns, sort);

  const { moveStage } = useMoveStage();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id as string);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    setActiveId(null);

    if (!over) return;

    const activeId = active.id as string;

    // If the item was dropped in a different container
    if (active.data.current?.containerId !== over.data.current?.containerId) {
      const activeContainerId = active.data.current?.containerId;
      const overContainerId = over.data.current?.containerId;

      if (!activeContainerId || !overContainerId) return;

      const companyId = active.id;
      const newStatusId = overContainerId;

      // todo: review
      if (overContainerId === "pipeline") {
        modalStore.open(SnoozeResponsiveModal, {
          props: {
            id: companyId as string,
            title: "Move Back to Pipeline",
            actionType: SnoozeActionType.Pipeline,
            onSuccess: () => {
              setCompanies((prev) => {
                const activeItems = [...prev[activeContainerId]];

                const activeItemIndex = activeItems.findIndex((item) => item.id === activeId);
                if (activeItemIndex === -1) return prev;
                activeItems.splice(activeItemIndex, 1);

                return {
                  ...prev,
                  [activeContainerId]: activeItems,
                };
              });
              modalStore.close();
            },
          },
        });

        return;
      }

      // Update UI optimistically
      setCompanies((prev) => {
        const activeItems = [...prev[activeContainerId]];
        const overItems = [...prev[overContainerId]];

        // Find the item
        const activeItemIndex = activeItems.findIndex((item) => item.id === activeId);
        if (activeItemIndex === -1) return prev;

        // Remove from original container
        const [item] = activeItems.splice(activeItemIndex, 1);

        // Add to new container with loading state
        const itemWithLoading = { ...item, loading: true };

        // For sortable items, we need to insert at the correct position
        const overItemIndex = over.data.current?.sortable?.index ?? overItems.length;
        overItems.splice(overItemIndex, 0, itemWithLoading);

        return {
          ...prev,
          [activeContainerId]: activeItems,
          [overContainerId]: overItems,
        };
      });

      try {
        // Try to update the stage on the server
        await moveStage(companyId as string, newStatusId);

        // Set loading to false after successful move
        setCompanies((prev) => {
          const overItems = [...prev[overContainerId]];
          const itemIndex = overItems.findIndex((item) => item.id === activeId);

          if (itemIndex !== -1) {
            overItems[itemIndex] = { ...overItems[itemIndex], loading: false };
          }

          return {
            ...prev,
            [overContainerId]: overItems,
          };
        });
      } catch (error) {
        console.error("Failed to move stage:", error);

        // Revert the UI change if the server update fails
        setCompanies((prev) => {
          const activeItems = [...prev[activeContainerId]];
          const overItems = [...prev[overContainerId]];

          const overItemIndex = overItems.findIndex((item) => item.id === activeId);
          if (overItemIndex === -1) return prev;

          const [item] = overItems.splice(overItemIndex, 1);

          // Set loading to false on error
          item.loading = false;
          activeItems.push(item);

          return {
            ...prev,
            [activeContainerId]: activeItems,
            [overContainerId]: overItems,
          };
        });
      }
    } else {
      // Handle sorting within the same container
      const containerId = active.data.current?.containerId;
      if (!containerId) return;

      const activeIndex = active.data.current?.sortable?.index;
      const overIndex = over.data.current?.sortable?.index;

      if (activeIndex === undefined || overIndex === undefined) return;

      setCompanies((prev) => {
        const containerItems = [...prev[containerId]];
        const [movedItem] = containerItems.splice(activeIndex, 1);
        containerItems.splice(overIndex, 0, movedItem);

        return {
          ...prev,
          [containerId]: containerItems,
        };
      });
    }
  };

  return (
    <>
      <ProgressBar className={cn("invisible opacity-0", loading && "visible opacity-100")} />

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <div className="flex w-full flex-1 gap-4 overflow-hidden overflow-x-auto p-4">
          {Object.values(columns).map((column) => (
            <KanbanBoardColumn key={column.id} column={column} companies={companies[column.id]} />
          ))}
        </div>

        {activeId && (
          <DroppableContainer
            id="pipeline"
            className="fixed bottom-6 left-1/2 min-h-[100px] min-w-[200px] -translate-x-1/2"
            text="Move Back to Pipeline"
          />
        )}

        <DragOverlay>
          {activeId ? (
            <div className="cursor-grabbing rounded-sm border border-primary bg-white shadow-lg">
              {Object.values(companies).map((containerItems) => {
                const item = containerItems.find((item) => item.id === activeId);
                return item ? <KanbanBoardCard key={item.id} item={item} className="pointer-events-none" /> : null;
              })}
            </div>
          ) : null}
        </DragOverlay>
      </DndContext>
    </>
  );
};
