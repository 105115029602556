import { action, makeObservable, observable } from "mobx";
import { makePersistable } from "mobx-persist-store";

import { getEnv } from "@/app/env/env";
import { FormType, createOptionalForm } from "@/app/stores/form";

export type ModalRoutesType =
  | ""
  | "AddNew"
  | "OpportunityAddSentiment"
  | "OpportunityActionsList"
  | "OpportunityInfoMobile"
  | "CompanyLink"
  | "OpportunityAdd"
  | "OpportunityAddSignal"
  | "OpportunitySort"
  | "OpportunityShare"
  | "OpportunitySnooze"
  | "OpportunityAddTrello"
  | "OpportunitySentiment"
  | "OpportunitySentimentRequest"
  | "OpportunityFilterComponent"
  | "OpportunityFilter"
  | "UnderConstruction"
  | "Success"
  | "ExcitementSummary";

export type SlideOverRoutesType = "" | "Settings" | "OpportunityEmail" | "CompanyCopilot";

type State = {
  isOpen: boolean;
  snap: number;
  modalType: ModalRoutesType;
  navState: Record<string, any>;
  flow: Array<Record<string, any>>;
};

type SlideOverState = {
  isOpen: boolean;
  slideOverType: SlideOverRoutesType;
  navState: { title?: string; [key: string]: any };
};

class GlobalModal {
  lgTeam = [];

  state: State = {
    isOpen: false,
    modalType: "",
    snap: 0,
    navState: {
      form: null,
    },
    flow: [],
  };
  slideOverState: SlideOverState = {
    isOpen: false,
    slideOverType: "",
    navState: {},
  };
  navbarState = {
    isOpen: true,
  };

  constructor() {
    makeObservable(this, {
      lgTeam: observable,

      // Global Modal
      state: observable,
      toggleModal: action,
      navigate: action,

      // Slide Over
      slideOverState: observable,
      toggleSlideOver: action,

      navbarState: observable,
      toggleNavBar: action,
    });

    makePersistable(
      this,
      {
        stringify: true,
        debugMode: getEnv("MOBX_DEBUG") === "true",
        name: "navbar",
        storage: window.localStorage,
        properties: ["navbarState"],
      },
      { fireImmediately: true },
    ).catch((e) => console.error("Error hydrating store", e));
  }

  progress(navState, flow) {
    this.state = {
      ...this.state,
      navState: { ...this.state.navState, ...navState },
      flow: [...this.state.flow, flow],
    };
  }

  goBack(navState?: Record<string, any>) {
    if (navState) {
      this.state.navState = { ...this.state.navState, ...navState };
    }
    this.state?.flow?.pop();
  }

  navigate({ modalType, navState = {} }: { modalType: ModalRoutesType; navState?: Record<string, unknown> }) {
    const form = createOptionalForm({
      formId: modalType.replaceAll("Opportunity", "") as FormType,
    });

    this.state = {
      ...this.state,
      isOpen: true,
      modalType,
      navState: { ...navState, form },
    };
  }

  toggleModal(partial?: {
    isOpen?: boolean;
    navState?: Record<string, unknown>;
    flow?: Array<Record<string, any>>;
    modalType?: ModalRoutesType;
  }) {
    const { navState = {}, flow, modalType, isOpen } = partial || {};
    // debugger;
    this.state = {
      ...this.state,
      isOpen: isOpen === undefined ? false : this.state?.isOpen,
      navState: { ...this.state.navState, ...(navState || {}) },
      modalType: modalType || this.state.modalType,
      flow: flow || this.state.flow,
    };
  }

  toggleSlideOver(slideOverState: Partial<SlideOverState>) {
    let isOpen = {};
    if (slideOverState.isOpen === undefined) {
      isOpen = { isOpen: !this.slideOverState.isOpen };
    }
    this.slideOverState = { ...this.slideOverState, ...isOpen, ...slideOverState };
  }

  toggleNavBar() {
    this.navbarState = {
      ...this.navbarState,
      isOpen: !this.navbarState.isOpen,
    };
  }
}

export default new GlobalModal();
