import { cn } from "@/lib/utils";

import { BaseToggle } from "../select-controls/base-toggle";

import { useDarkerMode } from "./presentation-mode-hooks";

export const PresentationModeToggle = ({ className, label }: { className?: string; label?: string }) => {
  const { isDarker, toggleDarkerMode } = useDarkerMode();

  return (
    <div className={cn("flex cursor-pointer items-center gap-1", className)} onClick={toggleDarkerMode}>
      {label && <span className="select-none text-sm font-medium">{label}</span>}
      <BaseToggle value={isDarker} size="sm" showLabels={false} />
    </div>
  );
};
