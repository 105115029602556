// @ts-nocheck

import { Fragment, useLayoutEffect, useRef, useState } from "react";

import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { observer } from "mobx-react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";

import { useCustomBreakpoints } from "@/lib/hooks";
import { capitalizeWords, cn } from "@/lib/utils.ts";

import { Button, DropdownOption, DropdownV2, Icon } from "@/app/components";
import { Sort } from "@/app/screens/opportunities/components/sort.tsx";
import filterStore, { singleSelections } from "@/app/screens/opportunities/filter.store.ts";
import opportunitiesStore from "@/app/screens/opportunities/opportunities.store.ts";
import { categoryFilterOptions, extraOptions, FilterCategories, filterToApiMap } from "@/app/stores/filter.store.tsx";

import { Filters } from "./filters";

export type FilterRouteType = {
  type: "dashboard" | "list" | "range";
  category: FilterCategories | null;
  route: string | null;
  open: boolean;
  extra?: { singleSelect?: boolean };
};

const QuickViewSwitch = ({ onChange }: { onChange: (view: string) => void }) => {
  const isSmallLaptop = useCustomBreakpoints({ max: 1400 });

  const items = opportunitiesStore.opportunityTab;
  if (isSmallLaptop) {
    const options: DropdownOption[] = items.map((tab) => ({
      label: tab.label,
      action: () => {
        onChange(tab.value);
      },
    }));

    const selected = items.find(({ value }) => value === opportunitiesStore?.state?.currentTab.quickView)?.label ?? "";

    return (
      <DropdownV2>
        <DropdownV2.Button size="sm" onClick={(e) => e.stopPropagation()} text={selected} iconRight="Chevron Down" />
        <DropdownV2.Items className="z-30" anchor="bottom start">
          {options.map(({ label, action }) => (
            <DropdownV2.Item key={label} onClick={action}>
              {label}
            </DropdownV2.Item>
          ))}
        </DropdownV2.Items>
      </DropdownV2>
    );
  }

  return opportunitiesStore.opportunityTab.map(({ value, label }, index) => {
    const isSelected = opportunitiesStore?.state?.currentTab.quickView === value;
    return (
      <div key={index + value} className={"flex w-fit flex-1 items-center text-nowrap"}>
        <Button
          size="sm"
          variant="secondary"
          className={cn(isSelected && "bg-gray-400")}
          onClick={() => onChange(value)}
        >
          {label}
        </Button>
      </div>
    );
  });
};

export const SubHeader = observer(function SubHeader({ containerClass, canSort = true }) {
  const [filterRoute, setFilterRoute] = useState<FilterRouteType>({
    type: "dashboard",
    route: null,
    category: null,
    open: false,
    extra: {},
  });

  const {
    currentTab: { path },
  } = opportunitiesStore.state;

  const itemsCount =
    opportunitiesStore.state.totalOpportunityCount && path !== "investments"
      ? `${opportunitiesStore.state.opportunitiesLength} out of ${String(
          opportunitiesStore.state.totalOpportunityCount,
        ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
      : "";
  const listType = opportunitiesStore.state.listType;

  const handleSwitchQuickView = (view: string) => {
    opportunitiesStore.setState({
      currentTab: {
        ...opportunitiesStore.state.currentTab,
        quickView: view,
      },
    });
  };

  return (
    <div className={cn(containerClass, "hidden w-full lg:flex lg:flex-col")}>
      {/*Quick Views*/}
      <div className={"my-2 flex w-full items-center justify-between lg:px-8 2xl:px-12"}>
        <div className={cn("flex items-center py-2", "gap-x-2")}>
          <QuickViewSwitch onChange={handleSwitchQuickView} />
        </div>
        <div className={"flex items-center"}>
          {canSort && <Sort onClick={() => setFilterRoute((prevState) => ({ ...prevState, open: false }))} />}
          <Filters filterRoute={filterRoute} setFilterRoute={setFilterRoute} />
          {path !== "investments" && (
            <div className={"mx-2 my-1 flex min-w-18 items-center justify-center"}>
              <span
                className={
                  "mr-2 w-28 select-none text-nowrap text-right text-sm font-medium text-neutral-400 lg:text-xs 2xl:text-sm"
                }
              >
                {itemsCount}
              </span>
              <>
                <Icon
                  className={cn(
                    "mx-2 cursor-pointer transition-all lg:size-3 2xl:size-4 ",
                    listType !== "card" ? "text-neutral-300 hover:text-neutral-600" : "",
                  )}
                  type="CardMenu"
                  onClick={() => opportunitiesStore.setState({ listType: "card" })}
                  data-cy="card-view-toggle"
                />
                <Icon
                  className={cn(
                    "cursor-pointer transition-all lg:size-3 2xl:size-4",
                    listType !== "list" ? "text-neutral-300 hover:text-neutral-600" : "",
                  )}
                  type="Burger4"
                  onClick={() => opportunitiesStore.setState({ listType: "list" })}
                  data-cy="list-view-toggle"
                />
              </>
            </div>
          )}
        </div>
      </div>

      {/*Filter bar*/}
      <FilterSubHeader setFilterRoute={setFilterRoute} />
    </div>
  );
});

const FilterSubHeader = observer(({ setFilterRoute }) => {
  const scrollRef = useRef<null | HTMLDivElement>(null);
  const showSubHeader = opportunitiesStore?.state?.currentTab.quickView !== "all" || filterStore.filtersActive > 0;

  useLayoutEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollWidth,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <div
      className={cn(
        "flex w-full items-center border-t border-[#D9D9D9] pl-8 pr-2 transition-all duration-300 ease-in-out 2xl:pl-11",
        showSubHeader ? "h-12 py-1 opacity-100" : "opacity-0",
      )}
    >
      {/*Reset Filter Button*/}
      {!filterStore.showRestorePreset && (
        <div
          className={cn(
            "group mr-4 flex cursor-pointer select-none items-center gap-2 rounded-sm border border-neutral-300 py-2 pl-2 transition-all duration-200 ease-in-out hover:bg-white",
            showSubHeader ? "flex" : "hidden",
          )}
          onClick={() => filterStore.currentTabChange()}
        >
          <Icon type={"Reset"} className={"text-gray-900 group-hover:text-primary-400"} width={14} height={14} />
          <span className={cn("w-24 text-nowrap text-xs font-semibold")}>Restore Preset</span>
        </div>
      )}

      {/*Filter Group*/}
      <div
        ref={scrollRef}
        className={cn("no-scrollbar flex w-[700px] grow overflow-auto", showSubHeader ? "flex" : "hidden")}
      >
        <div className={"flex grow gap-2"}>
          {filterStore.mapChoice.map(([filterTag, values]) => {
            const { name: filterKey, category } = filterToApiMap[filterTag] || {};
            const filterRouteType = category === "multiselect" ? "list" : category === "range" ? "range" : "dashboard";
            if (typeof values === "boolean") {
              values = [String(values)];
            }
            if (!values || !filterKey) {
              return null;
            }
            if (typeof values === "object" && !values?.length) {
              return null;
            }

            return (
              <FilterGroup
                key={filterKey + filterTag}
                {...{
                  setFilterRoute,
                  filterKey,
                  values,
                  category,
                  filterRouteType,
                }}
              />
            );
          })}
        </div>
        <div className=" absolute bottom-1 right-1  h-10 w-32 rotate-180 rounded-r-lg bg-gradient-to-r from-background to-transparent group-hover:hidden" />
      </div>
    </div>
  );
});

const filterKeyToLabel = (filterKey: string) => {
  switch (filterKey) {
    case "follow":
      return "Tracking";
    default:
      return filterKey;
  }
};

const FilterGroup = observer(({ setFilterRoute, filterKey, values, category, filterRouteType }) => {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  const categoryFilterValue = filterStore.getChoiceOption({
    category,
    label: filterKey,
  });
  const extraFilterOptions = extraOptions[category]?.includes(filterKey) ? ["Is all of"] : [];
  return (
    <div className={"flex items-center text-nowrap rounded-xs bg-neutral-200 px-3 py-1"}>
      <span
        className={
          "relative cursor-pointer select-none text-xs font-semibold text-neutral hover:text-neutral-700 lg:text-xxs 2xl:text-xs"
        }
        onClick={() => {
          setFilterRoute((prevState) => ({
            ...prevState,
            type: filterRouteType,
            category,
            route: filterKey,
            open: !prevState.open,
          }));
        }}
      >
        {filterKeyToLabel(filterKey)}
      </span>

      <Popover>
        {() => (
          <>
            <PopoverButton
              ref={setReferenceElement}
              disabled={Boolean(categoryFilterOptions[category]?.length < 2)}
              className={
                "ml-3 mr-2 flex cursor-pointer select-none items-center gap-x-1 text-nowrap outline-none hover:text-primary"
              }
            >
              <span className={"text-xs font-semibold lg:text-xxs 2xl:text-xs"}>
                {categoryFilterValue.toLowerCase()}
              </span>
              {Boolean(categoryFilterOptions[category]?.length > 1) && (
                <Icon type={"Chevron Down"} className={"lg:size-2.5 2xl:size-3 "} />
              )}
            </PopoverButton>

            {ReactDOM.createPortal(
              <>
                <PopoverPanel
                  className="!z-[100] mt-4 rounded-xs shadow-xs"
                  ref={setPopperElement}
                  style={styles.popper}
                  {...attributes.popper}
                >
                  <div className={"z-20 flex flex-col rounded-sm bg-white p-1"}>
                    <Icon
                      type={"Triangle"}
                      width={15}
                      color={"white"}
                      fill={"white"}
                      height={15}
                      className={cn("absolute inset-x-0 -top-3 z-10 mx-auto")}
                    />
                    {[
                      ...extraFilterOptions,
                      ...categoryFilterOptions[singleSelections.includes(filterKey) ? "tag" : category],
                    ].map((categoryFilterOption, i) => {
                      return (
                        <div
                          key={i}
                          onClick={() => {
                            filterStore.changeChoiceOption({
                              category,
                              label: filterKey,
                              value: categoryFilterOption,
                            });
                          }}
                          className={
                            "my-1.5 ml-1 mr-2 flex min-w-16 cursor-pointer items-center justify-between rounded-xs hover:bg-neutral-100"
                          }
                        >
                          <span className={"text-nowrap px-3 py-1 text-xs font-semibold lg:text-xxs 2xl:text-xs"}>
                            {categoryFilterOption.toLowerCase()}
                          </span>
                          {categoryFilterValue === categoryFilterOption && (
                            <Icon type={"FillCheck"} width={12} height={12} className={"mr-2 text-primary"} />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </PopoverPanel>
              </>,
              document.querySelector("#filter-popper")!,
            )}
          </>
        )}
      </Popover>

      <div className={"z-30 mx-1 flex items-center space-x-1"}>
        {(values as string[])?.slice(0, 2).map((groupValue, index) => {
          const label = String(groupValue);
          return (
            <Fragment key={filterKey + index}>
              <div
                className={"flex cursor-pointer select-none items-center gap-x-1 rounded-xs bg-white py-1 pl-3 pr-2"}
                onClick={() => {
                  setFilterRoute((prevState) => ({
                    ...prevState,
                    category,
                    type: filterRouteType,
                    route: filterKey,
                    open: !prevState.open,
                  }));
                }}
              >
                <span className={"text-nowrap text-xs font-semibold lg:text-xxs 2xl:text-xs"}>
                  {capitalizeWords(label)}
                </span>
                <Icon
                  type={"X"}
                  width={12}
                  height={12}
                  className={"text-neutral-600"}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    filterStore.changeChoice({
                      category,
                      label: filterKey,
                      value: category === "tag" ? null : category === "range" ? [0, 0] : groupValue,
                    });
                  }}
                />
              </div>
              {category === "range" && index === 0 && <span className="text-xs font-semibold">and</span>}
            </Fragment>
          );
        })}
        {values.length > 2 && (
          <div
            key={filterKey}
            className={"flex cursor-pointer select-none items-center justify-center rounded-xs bg-white px-2 py-1"}
            onClick={() => {
              setFilterRoute((prevState) => ({
                ...prevState,
                category,
                type: filterRouteType,
                route: filterKey,
                open: !prevState.open,
              }));
            }}
          >
            <span className={"text-nowrap text-xs font-semibold"}>{`+${values.length - 2}`}</span>
          </div>
        )}
      </div>
      <Icon
        type={"X"}
        width={12}
        height={12}
        className={"ml-2 cursor-pointer text-neutral-600"}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          filterStore.changeAllChoice({
            category,
            label: filterKey,
            action: "deselect",
          });
        }}
      />
    </div>
  );
});
