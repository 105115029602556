// @ts-nocheck

import { useRef, useCallback, useEffect, useState } from "react";

import { X } from "lucide-react";

import { cn } from "@/lib/utils";

import { AvatarV2 } from "@/app/components";
import { SearchRef, Company } from "@/app/hooks";

import { Icon, IconType } from "../icon/icon";

import { SearchBox } from ".";

export interface SearchBarProps {
  collapse?: boolean;
  placeholder?: string;
  onQueryChange?: (query: string) => void;
  onReset?: () => void;
  handleSelect?: (value: Company) => void;
  className?: string;
  containerClassName?: string;
  inputClassName?: string;
  iconClassName?: string;
  iconType?: IconType;
  closeIcon?: string;
  loading?: boolean;
  selected?: string;
  results?: any;
  clearIcon?: boolean;
  showEmptyState?: boolean;
  clearAfterSelection?: boolean;
}

export const SearchBar = ({
  collapse,
  className = "",
  containerClassName = "",
  placeholder = "Search...",
  inputClassName,
  iconType = "LoopGradient",
  iconClassName,
  selected,
  loading,
  results,
  onQueryChange,
  handleSelect,
  onReset,
  clearIcon,
  showEmptyState,
  clearAfterSelection,
}: SearchBarProps) => {
  const [query, setQuery] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // This is because headlessui component used in the app gets in the way of the autoFocus
    const id = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 500);

    return () => clearTimeout(id);
  }, []);

  const handleReset = useCallback(() => {
    setQuery("");

    if (onQueryChange) onQueryChange("");
    if (onReset) onReset();
  }, [onQueryChange, onReset]);

  const handleChange = useCallback(
    ({ target: { value } }) => {
      setQuery(value);

      if (onQueryChange) onQueryChange(value);
    },
    [onQueryChange],
  );
  return (
    <div className={cn("relative", containerClassName)}>
      <div
        className={`relative flex h-12 items-center rounded-sm border border-neutral-200 hover:bg-gray-300 ${className}`}
      >
        {selected ? (
          <AvatarV2 type={"company"} size={"sm"} className={cn("absolute left-2.5")} src={selected?.image || ""} />
        ) : (
          <Icon
            type={loading ? "Loader" : iconType}
            className={cn("absolute left-3 text-primary-500 md:left-4", iconClassName)}
            height={18}
            width={18}
          />
        )}
        {!collapse && (
          <input
            ref={inputRef}
            onChange={handleChange}
            value={selected?.name || query}
            placeholder={placeholder}
            className={cn("size-full bg-transparent px-12 placeholder:text-neutral-400", inputClassName)}
            data-cy="search-bar-input"
          />
        )}
        {(query || selected) && (
          <button
            className="absolute right-3.5 cursor-pointer bg-none text-xs font-semibold text-neutral outline-none hover:text-neutral-700"
            onClick={handleReset}
            data-cy="search-bar-clear"
          >
            {clearIcon ? <X size={"18"} color={"black"} /> : "clear"}
          </button>
        )}
      </div>
      {results?.length ? (
        <SearchBox
          results={results}
          handleReset={handleReset}
          searchTerm={query}
          containerClassName="absolute mt-2 w-full bg-white max-h-[300px] rounded-sm shadow-card z-10"
          handleSelect={(value) => {
            if (handleSelect) {
              handleSelect(value);
              if (clearAfterSelection) handleReset();
            }
          }}
          showEmptyState={showEmptyState}
        />
      ) : null}
    </div>
  );
};
