// @ts-nocheck

import React, { CSSProperties, useContext, useRef } from "react";

import { curveMonotoneX } from "@visx/curve";
import { GlyphDot } from "@visx/glyph";
import { LinearGradient } from "@visx/gradient";
import { useParentSize } from "@visx/responsive";
import { scaleLinear } from "@visx/scale";
import { BoxPlot } from "@visx/stats";
import {
  AnimatedAxis,
  AnimatedGlyphSeries,
  AnimatedGrid,
  AnimatedLineSeries,
  DataContext,
  GlyphProps,
  lightTheme,
  Tooltip,
  XYChart,
} from "@visx/xychart";
import { quantileSorted } from "d3-array";
import { differenceInDays } from "date-fns";
import { DateTime } from "luxon";

import { useBreakpoints } from "@/lib/hooks";
import { cn, extractFormCategory } from "@/lib/utils.ts";

import { customColors, colors } from "@/app/constants";
import { normaliseDate } from "@/app/misc/helpers.ts";
import { RowListWithSeparator } from "@/app/screens/opportunities/opportunity/components";
import { useSentimentContext } from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-context.tsx";
import { SentimentAttribute } from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-header.tsx";

const accesors = {
  xAccessor: (data) => data?.date,
  yAccessor: (data) => data?.metric,
};

type SentimentData = {
  x: string;
  y: number;
  bins?: { value: number; count: number }[];
  form: any; // todo
  people?: string[];
  metric?: number;
  boxPlot?: { min?: number; max?: number; q25?: number; q50?: number; q75?: number };
};

// right-aligned -> 0 -> number of days
//  new Date(minDate).getTime() -> 0
// all other points  -> anyDate - minDate in days

const RenderGlyph = ({
  x,
  y,
  datum,
  size,
  onPointerMove,
  onPointerOut,
  onPointerUp,
  color,
}: GlyphProps<SentimentData>) => {
  const handlers = { onPointerMove, onPointerOut, onPointerUp };
  const { yScale } = useContext(DataContext) || {};
  const width = 22;

  let yScaleCopy = function (d) {
    return yScale(d) - yScale(datum?.metric);
  };

  yScaleCopy = Object.assign(yScaleCopy, { range: yScale?.range });

  return (
    <>
      {datum?.bins?.length ? (
        <>
          <BoxPlot
            min={datum?.boxPlot?.min}
            max={datum?.boxPlot?.max}
            left={-width / 2}
            firstQuartile={datum?.boxPlot?.q50}
            thirdQuartile={datum?.boxPlot?.q50}
            median={datum?.boxPlot?.q50}
            medianProps={{ className: "hidden" }}
            boxWidth={width}
            fill={color.gradient}
            fillOpacity={0.9}
            stroke={color.to}
            strokeWidth={1.5}
            valueScale={yScaleCopy}
          />
        </>
      ) : null}
      <GlyphDot
        {...handlers}
        left={x}
        top={y}
        fill={color.gradient}
        stroke={"white"}
        strokeWidth={1}
        r={size / 2 + 1}
        cursor={"pointer"}
      />
    </>
  );
};

export const lineColors = {
  0: { gradient: "url(#line0)", from: customColors.primary.DEFAULT, to: customColors.secondary.DEFAULT },
  1: { gradient: "url(#line1)", from: "#722ED1", to: "#B37FEB" },
  2: { gradient: "url(#line2)", from: "#eb2f96", to: "#ff85c0" },
  3: { gradient: "url(#line3)", from: "#13c2c2", to: "#5cdbd3" },
  4: { gradient: "url(#line4)", from: "#1677ff", to: "#69b1ff" },
};

function SentimentChart({ data, analytics, width, height, setActiveTooltip, maximised = false, onKeyPointClicked }) {
  const globalMin = useRef(Infinity);
  const { isLaptop } = useBreakpoints();
  const { dataAlignment } = useSentimentContext();
  return (
    <XYChart
      xScale={{ type: "linear", zero: false }}
      yScale={{
        type: "linear",
        domain: [analytics.min - 0.5, Math.max(analytics.max + 0.5, 10)],
        zero: false,
        round: true,
      }}
      theme={lightTheme}
      width={width}
      margin={{ top: 25, right: 30, bottom: 40, left: 30 }}
      height={height}
      onPointerUp={onKeyPointClicked}
    >
      <AnimatedGrid
        key={`grid-xy`}
        rows={false}
        columns={true}
        strokeDasharray={"8"}
        lineStyle={{ stroke: colors.neutral[400], strokeWidth: 1 }}
      />
      <AnimatedAxis
        animationTrajectory={maximised ? "min" : "max"}
        key={`time-axis`}
        orientation="bottom"
        axisLineClassName={"stroke-neutral-400"}
        strokeWidth={1}
        strokeDasharray={"8"}
        numTicks={isLaptop ? 6 : 2}
        hideTicks={true}
        tickLabelProps={{
          className: cn("!font-semibold"),
          color: colors.neutral[400],
          fontWeight: 600,
          fontFamily: "Barlow",
        }}
        tickFormat={(value, ...rest) => {
          if (
            data?.length !== 1 &&
            (dataAlignment.value === "Left-Right Align" || dataAlignment.value === "Right Align")
          )
            return;
          if (data?.length !== 1 && dataAlignment.value === "Left Align") return value ? `${value} days` : value;
          return normaliseDate(DateTime.fromMillis(value).toFormat("yyyy-MM-dd")) as Parameters<any>[0];
        }}
      />
      <AnimatedAxis
        key={`right-axis`}
        strokeWidth={1}
        strokeDasharray={"8"}
        hideTicks={true}
        tickLabelProps={{
          className: cn("!font-semibold"),
          color: colors.neutral[400],
          fontWeight: 600,
          fontFamily: "Barlow",
        }}
        orientation={"left"}
        tickValues={[...Array.from({ length: 11 }, (_, idx) => idx)]}
      />

      {data.map((dataPoint, idx) => {
        const isLineFlat = dataPoint.every((datum) => datum.metric === dataPoint[0].metric);
        const { minDate, maxDate } = dataPoint.reduce(
          (acc, curr) => {
            globalMin.current = Math.min(globalMin.current, curr.date);
            return {
              minDate: Math.min(acc.minDate, curr.date),
              maxDate: Math.max(acc.maxDate, curr.date),
            };
          },
          { minDate: Infinity, maxDate: -Infinity },
        );
        let scaleDate = (arg) => arg;

        if (data?.length >= 2 && dataAlignment.value === "Left Align") {
          scaleDate = (startDate: string) => {
            return differenceInDays(new Date(startDate), new Date(minDate));
          };
        }

        if (data?.length >= 2 && dataAlignment.value === "Left-Right Align") {
          scaleDate = scaleLinear({
            range: [0, 1000],
            domain: [new Date(minDate).getTime(), new Date(maxDate).getTime()],
          });
        }

        if (data?.length >= 2 && dataAlignment.value === "Right Align") {
          scaleDate = (startDate: string) => {
            return differenceInDays(new Date(startDate), new Date(globalMin.current));
          };
        }

        return (
          <>
            <AnimatedLineSeries
              dataKey={`line${idx}`}
              strokeWidth={3.5}
              stroke={isLineFlat ? lineColors[idx].from : lineColors[idx].gradient}
              fillOpacity={0.9}
              data={dataPoint.map(({ date, metric, ...rest }) => ({
                ...rest,
                date: scaleDate(date),
                metric,
                dateLabel: date,
              }))}
              curve={curveMonotoneX}
              {...accesors}
            />
            <LinearGradient id={`line${idx}`} from={lineColors[idx].from} to={lineColors[idx].to} />
            <AnimatedGlyphSeries
              dataKey={`line${idx}`}
              renderGlyph={(props) => <RenderGlyph {...props} color={lineColors[idx]} />}
              data={dataPoint.map(({ date, metric, ...rest }) => ({
                ...rest,
                date: scaleDate(date),
                metric,
                dateLabel: date,
              }))}
              {...accesors}
            />
          </>
        );
      })}

      <Tooltip<SentimentData>
        showSeriesGlyphs
        snapTooltipToDatumX
        snapTooltipToDatumY
        applyPositionStyle={true}
        className={"pointer-events-auto cursor-pointer"}
        showHorizontalCrosshair
        horizontalCrosshairStyle={{ strokeDasharray: "8" }}
        renderGlyph={(props) => {
          return (
            <>
              <GlyphDot
                {...props}
                r={6}
                fill={`url(#${props.key})`}
                stroke={"white"}
                cursor={"pointer"}
                className={"cursor-pointer"}
              />
            </>
          );
        }}
        style={{
          borderRadius: "8px",
          boxShadow: "2px 8px 12px rgba(0, 0, 0, 0.08)",
          borderStyle: "solid",
          borderColor: colors.gray?.[300],
          borderWidth: "0.5px",
        }}
        renderTooltip={({ tooltipData }) => {
          setActiveTooltip(tooltipData?.nearestDatum?.datum?.form);

          return (
            <div className={"flex cursor-pointer flex-col items-center justify-center rounded-[10px] bg-white p-2"}>
              <p className={"text-xss font-medium text-neutral-700"}>
                {`${extractFormCategory(tooltipData?.nearestDatum?.datum?.form)}`}&nbsp;on&nbsp;
                {normaliseDate(tooltipData?.nearestDatum?.datum?.dateLabel)}
              </p>
              <p className="my-2 text-[16px] font-semibold text-neutral-900">
                {accesors.yAccessor(tooltipData?.nearestDatum?.datum)?.toFixed(2)}
              </p>
              {tooltipData?.nearestDatum?.datum?.boxPlot?.min != tooltipData?.nearestDatum?.datum?.boxPlot?.max && (
                <p className={"text-xss font-medium text-neutral-700"}>
                  {tooltipData?.nearestDatum?.datum?.boxPlot?.min?.toFixed(2)} (min) -{" "}
                  {tooltipData?.nearestDatum?.datum?.boxPlot?.max?.toFixed(2)} (max)
                </p>
              )}

              <RowListWithSeparator
                containerClassName={"mt-1 text-xss font-medium text-neutral-700"}
                list={tooltipData?.nearestDatum?.datum?.people?.map((label) => ({ label })) || []}
              />
            </div>
          );
        }}
      />
    </XYChart>
  );
}

export function SentimentHistory({
  companySentiments,
  referenceField,
  className,
  style,
  setActiveTooltip,
  onKeyPointClicked,
}: {
  companySentiments: any; // todo
  referenceField: SentimentAttribute;
  className?: string;
  style: CSSProperties;
  setActiveTooltip: any; // todo
  maximised?: boolean;
  onKeyPointClicked?: any;
}) {
  const data = companySentiments?.map((sentiment) => {
    const chronologicalForms = [...sentiment].filter(({ responses }) => responses?.length > 0).reverse();
    const data = chronologicalForms
      .filter(({ responses, createdAt, participants, type }) => {
        // TODO: optimise company query to get sentiments separate!
        if (type === "founder") return false;
        // the old sentiment forms have a different structure and we need to handle them differently
        if (createdAt < "2023-12-31") return responses?.length > 0;
        // we want to filter out the forms where all participants have declined
        return !participants?.nodes?.every((item) => item.state === "DECLINED");
      })
      .map((form) => {
        const responseArray = form?.responses
          ?.map((response) => Number(response.answers.find((answer) => answer.field_id === referenceField)?.value))
          .toSorted((a, b) => a - b);

        const min = Math.min(...responseArray);
        const max = Math.max(...responseArray);

        let bins = Object.entries(
          responseArray.reduce((prev, curr) => ({ ...prev, [curr]: 1 + (prev[curr] || 0) }), {}),
        );
        bins =
          bins.length === 1
            ? []
            : bins
                .toSorted((a, b) => Number(a[0]) - Number(b[0]))
                .map(([value, count]) => ({ value: Number(value), count: count + 1 }))
                .toSorted((a, b) => Number(a.value) - Number(b.value));

        return {
          metric: form?.analytics?.[referenceField]?.mean,
          id: form?.id,
          form,
          date: DateTime.fromISO(form.createdAt).toMillis(),
          formResults: form?.responses?.map((response) =>
            Number(response.answers.find((answer) => answer.field_id === referenceField)?.value),
          ),
          boxPlot: {
            min,
            q25: quantileSorted(responseArray, 0.25),
            q50: quantileSorted(responseArray, 0.5),
            q75: quantileSorted(responseArray, 0.75),
            max,
          },
          bins,
          people: Object.keys(form.analytics.person).filter((item) => item != "id" && item != "other"),
        };
      });

    const analytics = {
      min: Math.min(
        ...(data.map((datum) => Math.min(...(datum.formResults || [0]))).filter((item) => !isNaN(item)) || []),
      ),
      max: Math.max(
        ...(data.map((datum) => Math.max(...(datum.formResults || [10]))).filter((item) => !isNaN(item)) || []),
      ),
      dateMin: Math.min(...data.map((datum) => datum.date)),
      dateMax: Math.max(...data.map((datum) => datum.date)),
    };

    return { data, analytics };
  });

  const analytics = {
    min: Math.min(...data.map((datum) => datum.analytics.min)),
    max: Math.max(...data.map((datum) => datum.analytics.max)),
    dateMin: Math.min(...data.map((datum) => datum.analytics.dateMin)),
    dateMax: Math.max(...data.map((datum) => datum.analytics.dateMin)),
  };

  const { parentRef, width, height } = useParentSize({
    debounceTime: 250,
  });

  return (
    <div ref={parentRef} className={cn("h-full overflow-hidden lg:min-h-[400px]", className)} style={style}>
      <SentimentChart
        data={data.map((datum) => {
          return datum.data;
        })}
        analytics={analytics}
        width={width}
        onKeyPointClicked={onKeyPointClicked}
        height={height}
        setActiveTooltip={setActiveTooltip}
      />
    </div>
  );
}
