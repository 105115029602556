import { configurePersistable } from "mobx-persist-store";
import { getEnv } from "../env/env";

export const configureMobxPersistable = () => {
  configurePersistable(
    {
      storage: window.localStorage,
      stringify: false,
      debugMode: getEnv("MOBX_DEBUG") === "true",
    },
    { delay: 200, fireImmediately: false },
  );
}
