import { useCallback, useEffect, useRef } from "react";

import { useLazyQuery } from "@apollo/client";

import {
  SearchCompaniesDealroomDocument,
  SearchCompaniesDealroomQuery,
  SearchCompaniesLiteDocument,
  SearchCompaniesLocalGlobeDocument,
  SearchCompaniesLocalGlobeQuery,
  SearchCompaniesLocalGlobeQueryVariables,
  SearchCompaniesWithFoundersDocument,
} from "@/app/service/opportunities.gql.ts";

export const START_QUERY_AFTER = 2;

export type SearchType = keyof typeof dict;

const dict = {
  lite: SearchCompaniesLiteDocument,
  founders: SearchCompaniesWithFoundersDocument,
  lg: SearchCompaniesLocalGlobeDocument,
  dealroom: SearchCompaniesDealroomDocument,
};

export const useSearch = (companyType: SearchType = "lg") => {
  const debounceTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [query, { data, loading }] = useLazyQuery<
    SearchCompaniesLocalGlobeQuery | SearchCompaniesDealroomQuery,
    SearchCompaniesLocalGlobeQueryVariables
  >(dict?.[companyType] || SearchCompaniesDealroomDocument, {
    fetchPolicy: "network-only",
  });

  const search = useCallback((filter) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      query({ variables: { filter } });
    }, 100);
  }, []);

  useEffect(() => {
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);

  return {
    results: [...(data?.results?.nodes || [])],
    loading,
    query,
    search,
  };
};
