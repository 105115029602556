import { PropsWithChildren } from "react";

import { RadioGroup, Radio, RadioGroupProps, RadioProps } from "@headlessui/react";

const Group = ({ children, ...props }: PropsWithChildren<RadioGroupProps>) => {
  return <RadioGroup {...props}>{children}</RadioGroup>;
};

const Option = ({ value, ...props }: RadioProps) => {
  return <Radio value={value} {...props} />;
};

export const RadioInput = Object.assign(Group, { Option });
