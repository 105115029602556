import { LgFundName } from "@/gql/graphql";

import { Button } from "@/app/components";

import { useKanbanBoardContext } from "../kanban-board-context";

const fundOptions: Record<LgFundName, { label: string; value: LgFundName }> = {
  [LgFundName.Lg]: { label: "Localglobe", value: LgFundName.Lg },
  [LgFundName.Lt]: { label: "Latitude", value: LgFundName.Lt },
  [LgFundName.Sr]: { label: "Solar", value: LgFundName.Sr },
};

export const KanbanBoardFundSwitcher = () => {
  const { funds, selectedFund, setSelectedFund } = useKanbanBoardContext();

  return (
    <div className="flex gap-2">
      {funds.map((fund) => (
        <Button
          key={fund}
          variant={selectedFund.name === fund ? "secondary" : "outline"}
          size="sm"
          onClick={() => {
            setSelectedFund?.(fund);
          }}
        >
          {fundOptions[fund]?.label ?? fund}
        </Button>
      ))}
    </div>
  );
};
