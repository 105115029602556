import { observer } from "mobx-react";

import { companyCopilotStore } from "@/app/screens/modal/copilot";
import { ChatMessages, SystemPrompt, Conversations } from "@/app/screens/modal/copilot/components";

export const Body = observer(() => {
  const {
    state: { route },
  } = companyCopilotStore;

  return (
    <div className="flex flex-1 flex-col space-y-4 p-4">
      {route === "messages" && <ChatMessages />}
      {route === "system" && <SystemPrompt />}
      {route === "conversations" && <Conversations />}
    </div>
  );
});
