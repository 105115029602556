// @ts-nocheck

import { getInitials } from "@/lib/utils";

import { AvatarV2, Icon, ResponsiveHorizontalList } from "@/app/components";
import { TooltipGlobal } from "@/app/components/tooltip-global/tooltip-global";

import { useGroupedCompanies } from "../kanban-hooks";

type Assignees = ReturnType<typeof useGroupedCompanies>["items"][number][number]["lgAssigneesByCompanyId"]["nodes"];

export const KanbanBoardCardAssignees = ({ assignees }: { assignees: Assignees }) => {
  return (
    <ResponsiveHorizontalList
      className="flex min-w-0 items-center gap-1"
      popoverProps={{
        disabled: true,
      }}
    >
      {assignees
        .filter((assignee) => assignee?.member)
        .map(({ member, status }) => {
          const { id, firstName, lastName, avatar } = member!;
          return (
            <TooltipGlobal
              key={id}
              content={
                <>
                  {firstName} {lastName} {status ? <span className="capitalize">({status})</span> : ""}
                </>
              }
            >
              <AvatarV2
                key={id}
                type="user"
                size="xs"
                initials={getInitials(`${firstName} ${lastName}`)}
                src={avatar}
              />
              {status === "lead" && (
                <div className="absolute -bottom-0.5 -left-0.5 rounded-full bg-white p-px">
                  <Icon type={"Lead"} className="size-2" />
                </div>
              )}
            </TooltipGlobal>
          );
        })}
    </ResponsiveHorizontalList>
  );
};
