import React, { PropsWithChildren } from "react";

import { observer } from "mobx-react";

import { Button } from "@/app/components";
import { MarkdownReader } from "@/app/screens/modal/copilot/components";
import { EmptyConversation } from "@/app/screens/modal/copilot/components/messages/empty-conversation.tsx";
import { useMessages } from "@/app/screens/modal/copilot/components/messages/use-messages.tsx";
import { useTextAreaCopilot } from "@/app/screens/modal/copilot/hooks/use-send-message.tsx";

export const ChatMessages = observer(function () {
  const { shownMessages, messagesEndRef, isLoading, followUpQuestions } = useMessages();

  if (shownMessages.length === 0) {
    return <EmptyConversation />;
  }
  return (
    <div className={"my-4 flex h-full flex-1 flex-col p-2"}>
      {shownMessages.map((message, index, list) => (
        <div key={message.id} className={`flex ${message.role === "user" ? "justify-end" : "justify-start"}`}>
          <div
            className={`group relative max-w-[95%] rounded-sm px-4 py-2 ${
              message.role === "user"
                ? "rounded-br-none bg-light-green-100 text-gray-800"
                : "rounded-bl-none bg-white text-gray-800"
            }`}
          >
            <div className="whitespace-pre-wrap">
              {message.role === "user" ? (
                <UserMessage>{message.content}</UserMessage>
              ) : (
                <>
                  <AssistantMessage isLoading={isLoading}>{message.content}</AssistantMessage>
                  {isLoading && list.length - 1 === index && <ChatLoading />}
                </>
              )}
            </div>
            <div className={`mt-1 text-xs ${message.role === "user" ? "text-gray-800" : "text-gray-500"}`}>
              {new Date(message.timestamp).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
            </div>
          </div>
        </div>
      ))}
      <FollowUpQuestions questions={followUpQuestions} />
      <div ref={messagesEndRef} />
    </div>
  );
});

function UserMessage({ children }: PropsWithChildren) {
  const { handleSendMessage } = useTextAreaCopilot();

  const handleRetry = () => {
    if (typeof children === "string") {
      handleSendMessage(children).catch(console.error);
    }
  };

  const handleEdit = () => {
    // This would typically open an editor or set the message in the input field
    // Implementation depends on how editing is handled in the application
    if (typeof children === "string") {
      // For now, we'll just resend the message as an example
      handleSendMessage(children).catch(console.error);
    }
  };

  return (
    <div className="group relative">
      <div className="whitespace-pre-wrap">{children}</div>
      {/*<div className="absolute bottom-0 opacity-0 transition-opacity group-hover:opacity-100">*/}
      {/*  <Button variant="text" className="p-1 text-xs text-gray-500 hover:text-gray-700" onClick={handleEdit}>*/}
      {/*    Edit*/}
      {/*  </Button>*/}
      {/*</div>*/}
    </div>
  );
}

function AssistantMessage({ children, isLoading }: { isLoading?: boolean } & PropsWithChildren) {
  if (typeof children !== "string") return <span>{children}</span>;
  return <MarkdownReader markdownContent={children} isLoading={isLoading} />;
}

function FollowUpQuestions({ questions }: { questions: string[] }) {
  const { handleSendMessage } = useTextAreaCopilot();
  if (!questions || questions.length === 0) return null;
  return (
    <div className="mt-4">
      <ul className="flex list-disc flex-col items-center">
        {questions.map((question, index) => (
          <Button
            variant={"text"}
            key={index}
            onClick={() => {
              handleSendMessage(question).catch();
            }}
          >
            {question}
          </Button>
        ))}
      </ul>
    </div>
  );
}

export function ChatLoading() {
  return (
    <div className="mt-4 flex justify-start">
      <div className="rounded-lg rounded-bl-none text-gray-800">
        <div className="flex space-x-1">
          <div className="size-2 animate-bounce rounded-full bg-gray-500" />
          <div className="size-2 animate-bounce rounded-full bg-gray-500" style={{ animationDelay: "150ms" }} />
          <div className="size-2 animate-bounce rounded-full bg-gray-500" style={{ animationDelay: "300ms" }} />
        </div>
      </div>
    </div>
  );
}
