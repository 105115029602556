import { AvatarV2, Button } from "@/app/components";
import { gql, useQuery } from "@apollo/client";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import NetworkMessagesCountsBarChart from "./contactMessagesChart";
import { Table, Contact } from "./lookupTable";
import { Column, SortDirection, sortPairValueFromString, sortPairValueToString } from "./columns";
import { relatedContactsLookupQuery } from "./contactsLookupQuery.gql";
import { ConnectionsContext } from "./connectionsDict";
import { formatDate, formatTZDate } from "../format";
import { NetworkNavBar } from "./navbar";
import { useContactQuery } from "./useContactQuery";
import { AnimatePresence, motion } from "framer-motion";
import { Connectivity } from "./connectivity";
import { MatchedDomain } from "./lookupHeader";

export function getRequestInroLink(conn, contact) {
  const contactName = `${contact.names?.[0] || ""} (${contact.email})`;
  const lastTouched = formatDate(conn.last_communication);
  const body = `I saw on Nazare that you were connected to ${contactName} and last in touch on ${lastTouched}.

Would you be able to reach out to them? I'm keen to {enter reason} and would really appreciate the intro if you're comfortable with it.

Let me know what you think!`;
  const link = `mailto:${conn.internal_email}?subject=Intro%20Request&body=${encodeURI(body)}`;
  return link;
}

const networkContactConnectionsQuery = gql`
  query GetNetworkContactConnections($contactEmail: String) {
    connections: getNetworkContactConnectionsAggregated(contactEmail: $contactEmail) {
      nodes {
        id
        timestamp
        fromEmail
        toEmails
      }
    }
  }
`;

const networkContactMessagesCountsQuery = gql`
  query GetNetworkContactMessagesCounts($contactEmail: String, $connectionEmail: String) {
    counts: getNetworkContactConnectionMessagesCounts(contactEmail: $contactEmail, connectionEmail: $connectionEmail) {
      nodes {
        sent
        received
        month
      }
    }
  }
`;

type MessageCountChartProps = {
  contactEmail: string;
  connectionEmail: string;
};

export function MessagesCountChart(props: MessageCountChartProps) {
  const { contactEmail, connectionEmail } = props;
  const { data, loading } = useQuery(networkContactMessagesCountsQuery, {
    variables: { contactEmail, connectionEmail },
  });
  return <NetworkMessagesCountsBarChart contactEmail={contactEmail} data={data?.counts?.nodes} />;
}

const numberFormat = Intl.NumberFormat("en-GB");

export function NetworkContact() {
  const params = useParams();
  const contactEmail = params.email;
  const { data, loading } = useContactQuery(contactEmail || "");
  const [connectionsExtended, setConnectionsExtended] = useState(false);
  const [lookupExtended, setLookupExtended] = useState(false);

  const [sortPair, setSortPair] = useState<[Column, SortDirection]>([Column.Connectivity, SortDirection.Desc]);
  const {
    data: relatedData,
    previousData: relatedPrevData,
    loading: relatedLoading,
  } = useQuery(relatedContactsLookupQuery, {
    variables: { contactEmail, sort: sortPairValueToString(...sortPair) },
  });

  const handleConnectionsExtend = useCallback(() => {
    setConnectionsExtended(!connectionsExtended);
  }, [connectionsExtended]);

  const handleLookupExtend = useCallback(
    (e) => {
      e.preventDefault();
      setLookupExtended(!lookupExtended);
    },
    [lookupExtended]
  );

  useEffect(() => {
    document.querySelector("body")?.scrollTo({ top: 0, behavior: "instant" });
    // window.scrollTo({ top: 0, behavior: "smooth" });
  }, [contactEmail]);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const fq = searchParams.get("fq");
  const handleLookupTableClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    if (event.target instanceof Element) {
      // Perform sorting
      const nextPairString = event.target?.closest("[data-column-next-sort]")?.getAttribute("data-column-next-sort");
      if (nextPairString) {
        const nextPair = sortPairValueFromString(nextPairString);
        event.preventDefault();
        setSortPair(nextPair);
      }

      // Navigate to contact view
      const clickedContact = event.target?.closest("[data-contact-email]")?.getAttribute("data-contact-email");
      if (clickedContact) {
        event.preventDefault();
        const params: string =
          event.target.closest("[data-contact-view-search-params]")?.getAttribute("data-contact-view-search-params") ||
          "";
        navigate(`/network/contact/${clickedContact}?${params}`);
      }
    }
  }, []);

  let contactsToDisplay: Contact[] = [];
  if (relatedData) contactsToDisplay = relatedData?.contacts?.nodes as Contact[];
  else if (relatedLoading && relatedPrevData) contactsToDisplay = relatedPrevData?.contacts?.nodes as Contact[];

  const initials = data?.contact?.names?.[0]?.match(/\b\w/g)?.slice(0, 2).join("") || "NN";

  let connectionsCount;
  if (data?.contact?.internalContacts) {
    connectionsCount = data.contact.internalContacts.length;
  } else if (data?.contact?.connections) {
    connectionsCount = data.contact.connections.length;
  }

  const connectionsExtendable = connectionsCount && connectionsCount > 11;
  const connectionsVisibleLimit = connectionsExtendable
    ? connectionsExtended
      ? connectionsCount
      : 10
    : connectionsCount;

  const lookupCount = contactsToDisplay.length;
  const lookupExtendable = lookupCount && lookupCount > 11;
  const lookupVisibleLimit = lookupExtendable ? (lookupExtended ? lookupCount : 10) : lookupCount;
  const domain = contactEmail?.split("@")?.[1]; 
  const matchedDomain = contactEmail
    ? data?.domains?.nodes?.find(node => node.domain === domain)
    : null;
  return (
    <div className="@container flex flex-col w-full relative" key={contactEmail}>
      <NetworkNavBar />
      <div className="flex flex-col cursor-default font-barlow w-full max-w-[100%] h-width gap-4">
        <AnimatePresence initial={true}>
          {data?.contact ? (
            <motion.div
              initial={{ y: 30, opacity: 0.6 }}
              animate={{ y: 0, opacity: 1, transition: { duration: 0.5 } }}
              exit={{ y: -20, opacity: 0.6 }}
              className="flex flex-col px-4 @5xl:px-10 w-full gap-4"
            >
              <div className="rounded-sm bg-white p-4 relative">
                <div className="flex flex-col gap-4">
                  <div className="flex gap-4 relative overflow-hidden">
                    <AvatarV2 size="lg" type="user" initials={initials} />
                    <div className="flex flex-col gap-3">
                      <div className="flex flex-col @3xl:flex-row @3xl:items-center gap-3 mb-[-1px]">
                        <div className="text-[24px] font-semibold">{data.contact.names?.[0]}</div>
                        <Connectivity value={ data?.contact?.connStrength} />
                        <div className="flex gap-3">
                          <AnimatePresence initial={true}>
                            {data.tags?.nodes?.map((node) => (
                              <motion.span
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1, transition: { duration: 0.5 } }}
                                exit={{ opacity: 0, scale: 0.8 }}
                                key={node.type}
                                className="flex   capitalize bg-gray-300 text-gray-600  rounded-[2px] px-2 text-[12px]"
                              >
                                {node.type}
                              </motion.span>
                            ))}
                          </AnimatePresence>
                        </div>
                      </div>
                      <div className="flex flex-col @3xl:flex-row mt-[-8px] gap-1 @3xl:gap-2">
                        <div className="flex gap-1 @3xl:gap-2">
                          <div className="font-medium text-gray-600 text-[14px]">Email</div>
                          <div className="text-[14px] text-gray-900 overflow-hidden whitespace-nowrap text-ellipsis">
                            {data.contact.email}
                          </div>
                        </div>

                        <div className="flex gap-1 @3xl:gap-2">
                          <div className="font-medium text-gray-600 whitespace-nowrap text-[14px]">Known Names</div>
                          <div className="text-[14px] text-gray-900 overflow-hidden whitespace-nowrap text-ellipsis">
                            {data.contact.names?.join(", ")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col @5xl:flex-row gap-4">
                    <div className="flex flex-col w-full @5xl:w-1/2 justify-center items-center bg-neutral-100 rounded-sm p-4">
                      <div className="text-[32px] font-semibold text-gray-900">
                        {numberFormat.format(+data.contact.sent)}
                      </div>

                      <div className="flex flex-col @md:inline text-center @md:text-left">
                        <div className="@md:inline">Incoming emails </div>
                        {data.contact.lastSent ? (
                          <div className="@md:inline">
                            {" "}
                            <span className="hidden @md:inline">—</span> Last received:{" "}
                            {formatDate(data.contact.lastSent)}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="flex flex-col w-full @5xl:w-1/2 justify-center items-center bg-neutral-100 rounded-sm p-4">
                      <div className="text-[32px] font-semibold text-gray-900">
                        {numberFormat.format(+data.contact.received)}
                      </div>
                      <div className="flex flex-col @md:inline  text-center @md:text-left">
                        <div className="@md:inline">Outgoing emails</div>
                        {data.contact.lastReceived ? (
                          <div className="@md:inline">
                            {" "}
                            <span className="hidden @md:inline">—</span> Last sent:{" "}
                            {formatDate(data.contact.lastReceived)}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

                  <div className="rounded-sm bg-white w-full">
                  <div className="p-4">
              {matchedDomain ? <MatchedDomain domain={matchedDomain} /> : null}
              </div>
              </div>
              {connectionsCount ? (
                <>
                  <div className="rounded-sm bg-white w-full">
                    <div className="flex flex-row gap-3">
                      <div className="p-4 pb-0 text-[20px] text-gray-900 font-semibold">
                        Connections <span className="text-gray-600">{connectionsCount}</span>
                      </div>
                    </div>
                    <NetworkContactConnectionsCompact
                      limitVisibleRows={connectionsVisibleLimit}
                      contact={data.contact}
                      connections={data.contact.internalContacts}
                      connectionsCount={connectionsCount}
                    />
                  </div>
                  {connectionsExtendable ? (
                    <div className="flex flex-row justify-center items-center mt-[-4px]">
                      {connectionsExtended ? (
                        <Button
                          className="cursor-pointer"
                          size="sm"
                          variant="primary"
                          onClick={handleConnectionsExtend}
                        >
                          Show less
                        </Button>
                      ) : (
                        <Button
                          className="cursor-pointer"
                          size="sm"
                          variant="primary"
                          onClick={handleConnectionsExtend}
                        >
                          Show more
                        </Button>
                      )}
                    </div>
                  ) : null}
                </>
              ) : (
                ""
              )}

              <div onClick={handleLookupTableClick} className="rounded-sm bg-white mb-4">
                <div className="flex flex-row gap-3">
                  <div className="p-4 pb-0 text-[20px] text-gray-900 font-semibold">
                    Related contacts <span className="text-gray-600">{contactsToDisplay.length}</span>
                  </div>
                </div>
                <Table
                  contacts={contactsToDisplay}
                  sortColumn={sortPair[0]}
                  sortDirection={sortPair[1]}
                  loading={loading}
                  noSearch={true}
                  limitVisibleRows={lookupVisibleLimit}
                  noDataMessage={"No related contacts found."}
                />
              </div>
              {lookupExtendable ? (
                <div className="flex flex-row justify-center items-center mt-[-20px] mb-4">
                  {lookupExtended ? (
                    <Button className="cursor-pointer" size="sm" variant="primary" onClick={handleLookupExtend}>
                      Show less
                    </Button>
                  ) : (
                    <Button className="cursor-pointer" size="sm" variant="primary" onClick={handleLookupExtend}>
                      Show more
                    </Button>
                  )}
                </div>
              ) : null}
            </motion.div>
          ) : null}
        </AnimatePresence>
      </div>
    </div>
  );
}

export function NetworkContactConnectionsCompact(props) {
  const [chartOpened, setChartOpened] = useState<string | null>(null);
  const [sortPair, setSortPair] = useState(["connection_strength", "Desc"]);
  const { getConnection } = useContext(ConnectionsContext);
  const { limitVisibleRows } = props;
  const handleRowClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (event.target instanceof Element) {
        if (event.target.tagName === "A") return;
        const key: string = event.target?.closest("[data-connection-key]")?.getAttribute("data-connection-key") || "";

        if (key) {
          if (chartOpened === key) {
            setChartOpened(null);
          } else {
            setChartOpened(key);
          }
        }
      }
    },
    [chartOpened]
  );

  const { connectionsCount } = props;

  const data = useMemo(() => {
    let data;
    if (sortPair[0]) {
      // string sort
      if (sortPair[0] === "name") {
        if (sortPair[1] === "Asc") {
          data = [...(props.connections || [])].sort((a, b) => {
            const aName = getConnection(a.internal_email)?.name;
            const bName = getConnection(b.internal_email)?.name;
            if (!aName) return 1;
            if (!bName) return -1;
            return aName.localeCompare(bName);
          });
        } else {
          data = [...(props.connections || [])].sort((a, b) => {
            const aName = getConnection(a.internal_email)?.name;
            const bName = getConnection(b.internal_email)?.name;

            if (!aName) return 1;
            if (!bName) return -1;

            return bName.localeCompare(aName);
          });
        }

        return data;
      }

      // date sort
      if (["last_inbound", "last_outbound"].includes(sortPair[0])) {
        if (sortPair[1] === "Asc") {
          data = [...(props.connections || [])].sort((a, b) => {
            if (!a[sortPair[0]]) return 1;
            if (!b[sortPair[0]]) return -1;
            const aDate = +new Date(a[sortPair[0]]);
            const bDate = +new Date(b[sortPair[0]]);

            if (aDate > bDate) return 1;
            if (bDate > aDate) return -1;
            return 0;
          });
        } else {
          data = [...(props.connections || [])].sort((a, b) => {
            if (!a[sortPair[0]]) return 1;
            if (!b[sortPair[0]]) return -1;
            const aDate = +new Date(a[sortPair[0]]);
            const bDate = +new Date(b[sortPair[0]]);
            if (bDate > aDate) return 1;
            if (aDate > bDate) return -1;
            return 0;
          });
        }

        return data;
      }
      //number sort
      if (sortPair[1] === "Asc") {
        data = [...(props.connections || [])].sort((a, b) => {
          if (a[sortPair[0]] > b[sortPair[0]]) return 1;
          if (b[sortPair[0]] > a[sortPair[0]]) return -1;
          return 0;
        });
      } else {
        data = [...(props.connections || [])].sort((a, b) => {
          if (b[sortPair[0]] > a[sortPair[0]]) return 1;
          if (a[sortPair[0]] > b[sortPair[0]]) return -1;
          return 0;
        });
      }
      return data;
    }

    return [...(props.connections || [])].sort((a, b) => {
      if (a.count > b.count) return -1;
      if (b.count > a.count) return 1;
      return 0;
    });
  }, [props.connections, sortPair]);

  const handleTheadClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    if (event.target instanceof Element) {
      const nextPair = event.target
        ?.closest("[data-column-next-sort]")
        ?.getAttribute("data-column-next-sort")
        ?.split(":");

      if (!Array.isArray(nextPair) || nextPair.length !== 2 || !nextPair[0]) {
        return;
      }

      event.preventDefault();
      setSortPair(nextPair);
      setChartOpened(null);
    }
  }, []);
  const thClasses =
    "text-[14px] px-4 py-4 font-medium text-neutral text-left bg-white whitespace-nowrap cursor-pointer select-none ";
  const columns = [
    { field: "name", title: "Name", classes: "" },
    { field: "connection_strength", title: "Connectivity", classes: " text-center w-[1px]" },
    { field: "last_inbound", title: "Last Incoming", classes: " text-center w-[1px]" },
    { field: "last_outbound", title: "Last Ougoing", classes: " text-center w-[1px]" },
    { field: "inbound_count", title: "# Incoming" },
    { field: "outbound_count", title: "# Outgoing" },
    { title: "", classes: " w-full" },
  ];

  const fake = !props.connections;
  return (
    <table className="divide-y w-full border-collapse flex w-full py-2 @5xl:p-0 @5xl:table @5xl:table-auto text-[14px]">
      <thead
        onClick={handleTheadClick}
        className="hidden shadow-sm @5xl:table-row-group sticky top-[47px] z-10"
      >
        <tr className="leading-tight">
          {columns.map(({ title, classes, field }) => {
            let imgStyle: Record<string, string> = {};
            let nextSortDirection = "Asc";

            if (sortPair[0] === field) {
              if (sortPair[1] === "Asc") {
                imgStyle = { transform: "scaleY(-1)" };
                nextSortDirection = "Desc";
              }
            } else {
              imgStyle = { visibility: "hidden" };
            }
            return (
              <th
                data-column-next-sort={field ? `${field}:${nextSortDirection}` : ""}
                className={thClasses + (classes || "")}
                key={title}
              >
                {title}{" "}
                <img
                  className="inline transition-transform duration-200 ease-in-out"
                  style={imgStyle}
                  src="/assets/icons/arrow-sort-desc.svg"
                />
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody
        onClick={handleRowClick}
        className="grid grid-cols-1 gap-2 mx-2 @5xl:mx-0 w-full @3xl:grid-cols-2 @5xl:grid-cols-3 @5xl:table-row-group @5xl:gap-0"
      >
        {fake ? (
          <tr>
            <td colSpan={7}>
              <div style={{ height: connectionsCount * 49.5 }}></div>
            </td>
          </tr>
        ) : data ? (
          data
            .slice(0, limitVisibleRows)
            .map((connection) => {
              return (
                <NetworkContactConnection
                  handleRowClick={handleRowClick}
                  chartOpened={connection.internal_email === chartOpened}
                  connection={connection}
                  contact={props.contact}
                />
              );
            })
            .filter(Boolean)
        ) : null}
      </tbody>
    </table>
  );
}

type NetworkContactConnectionsProps = {
  email: string;
  totalVolume: number;
  dataLoaded: boolean;
};

export function NetworkContactConnection(props) {
  const { connection, contact, chartOpened, dataLoaded } = props;
  const { getConnection } = useContext(ConnectionsContext);
  const conn = getConnection(connection.internal_email);
  
  const fakeHeaderClasses = "font-medium text-neutral text-[12px] @5xl:hidden";
  if (!conn) return;

  let chartTrClasses = "hidden @5xl:table-row";
  let chartTdClasses = "";
  let chartWrapperClasses = "";
  if (chartOpened) {
    chartTdClasses = "rounded-b-sm w-full p-0 px-4 leading-none overflow-hidden border-none";
    chartWrapperClasses =
      "transition-all duration-[0.5s] ease-in-out-back relative hidden @5xl:flex h-[350px] overflow-hidden";
  } else {
    chartTdClasses = "rounded-b-sm w-full h-0 p-0 px-4 leading-none overflow-hidden border-none";
    chartWrapperClasses =
      "hidden w-full relative @5xl:flex transition-all duration-[0.5s] ease-in-out-back  h-0 overflow-hidden";
  }

  const roundedLClasses = chartOpened ? "" : "@5xl:group-[&:nth-last-child(2)]:rounded-bl-sm";
  const roundedRClasses = chartOpened ? "" : "@5xl:group-[&:nth-last-child(2)]:rounded-br-sm";

  return (
    <>
      <tr
        key={connection.internal_email}
        data-connection-key={connection.internal_email}
        className="group cursor-pointer flex flex-wrap rounded-md border border-gray-200 @5xl:border-0 @5xl:border-t @5xl:bg-white card w-full overflow-hidden  @5xl:table-row cursor-default relative @5xl:p-0 transition-all"
      >
        <td
          className={` transition-all duration-200 flex flex-col w-full @5xl:w-auto p-2 @5xl:mr-7 overflow-hidden @5xl:px-2 @5xl:p-2 @5xl:table-cell @5xl:mr-0 @5xl:pl-4 @5xl:bg-white group-hover:bg-neutral-100 ${roundedLClasses}`}
        >
          <div className="flex items-center gap-1 @5xl:w-[273px] @7xl:w-[438px]">
            <AvatarV2 className="@5xl:flex" src={conn.image} initials={conn.initials} size="sm" type="user" />
            <div className="overflow-hidden text-ellipsis break-all whitespace-nowrap font-medium  text-gray-800 ">
              {conn.name}
            </div>
          </div>
        </td>
        <td className="flex absolute top-6 right-6 @3xl:top-4 @3xl:right-4 @5xl:px-2 @5xl:p-4 @5xl:text-center @5xl:w-[1px] @5xl:table-cell @5xl:static group-hover:bg-neutral-100 duration-200 transition-all">
        <Connectivity value={connection?.connection_strength} />
        </td>
        <td className="transition-all duration-200 flex flex-col p-2 @5xl:table-cell w-1/4 @5xl:px-2 @7xx:p-4 text-black @5xl:text-center whitespace-nowrap @5xl:w-[1px] group-hover:bg-neutral-100">
          <div className={fakeHeaderClasses}>Last Incoming</div>
          <div>{formatTZDate(connection.last_inbound)}</div>
        </td>
        <td className="transition-all duration-200 flex flex-col p-2 @5xl:table-cell w-1/4 @5xl:px-2 @5xl:p-2 text-black @5xl:text-center whitespace-nowrap @5xl:w-[1px] group-hover:bg-neutral-100">
          <div className={fakeHeaderClasses}>Last Outgoing</div>
          <div>{formatTZDate(connection.last_outbound)}</div>
        </td>
        <td className="transition-all duration-200 flex flex-col p-2 w-1/4 @5xl:pl-4 @5xl:p-2 whitespace-nowrap @5xl:w-[1px] @5xl:table-cell group-hover:bg-neutral-100 @5xl:text-right @5xl:!pr-8">
          <div className={fakeHeaderClasses}># Incoming</div>
          <div>
            {/*<img className="inline mr-1" src="/assets/icons/mail-incoming.svg" />*/}
            {numberFormat.format(connection.inbound_count)}
          </div>
        </td>{" "}
        <td className="transition-all duration-200 flex flex-col p-2 w-1/4 @5xl:pl-4 @5xl:p-2 whitespace-nowrap @5xl:w-[1px] @5xl:table-cell group-hover:bg-neutral-100 @5xl:text-right @5xl:!pr-8">
          <div className={fakeHeaderClasses}># Outgoing</div>
          <div>
            {/*<img className="inline mr-1" src="/assets/icons/mail-outgoing.svg" /> */}
            {numberFormat.format(connection.outbound_count)}
          </div>
        </td>
        <td
          className={`transition-all duration-200 flex flex-col p-2 w-full @5xl:pl-4 @5xl:p-2 whitespace-nowrap @5xl:w-auto @5xl:table-cell group-hover:bg-neutral-100  ${roundedRClasses}`}
        >
          <a
            className="ml-auto mr-2 opacity-0 group-hover:opacity-100 h-8 transition-all duration-300 flex w-fit select-none items-center justify-center text-[14px] font-semibold  rounded-xxs px-2 border border-gray-300 bg-white text-gray-900 hover:bg-gray-300 active:bg-gray-500"
            href={getRequestInroLink(connection, props.contact)}
          >
            Request Intro
          </a>
        </td>
      </tr>
      <tr className={chartTrClasses}>
        <td colSpan={7} className={chartTdClasses}>
          <div className={chartWrapperClasses}>
            <div className="absolute top-0 left-0 right-0 bottom-0 ">
              {chartOpened && (
                <MessagesCountChart connectionEmail={connection.internal_email} contactEmail={contact.email} />
              )}
            </div>
          </div>
        </td>
      </tr>
    </>
  );
}
export function NetworkContactConnections(props: NetworkContactConnectionsProps) {
  const { email, totalVolume } = props;
  const { data, loading } = useQuery(networkContactConnectionsQuery, { variables: { contactEmail: email } });

  const connections = data?.connections?.nodes;

  const totalVolumeText = loading
    ? ""
    : totalVolume >= 500
      ? `500 out of ${numberFormat.format(totalVolume)}`
      : `${numberFormat.format(connections?.length)}`;

  return (
    <div>
      <div className="p-2 text-[20px]">
        <div className="text-black font-medium">
          Recent Communication <span className="text-neutral font-normal">{totalVolumeText}</span>
        </div>
      </div>

      <div>
        <div className="sticky top-[50px] lg:top-0 flex flex-row text-[14px]  text-neutral font-medium bg-white z-10">
          <div className="p-2 w-1/2 overflow-hidden text-ellipsis line-clamp-2 ">
            <span className="text-black">{email}</span>{" "}
            <span className="whiespace-nowrap inline-block">sent to us</span>
          </div>
          <div className="p-2 w-1/2 text-right overflow-hidden text-ellipsis line-clamp-2 ">
            <span className="whiespace-nowrap inline-block">We sent to</span>{" "}
            <span className="text-black whiespace-nowrap">{email}</span>
          </div>
        </div>
        {loading ? (
          <div className="animate-pulse-original">
            <div className="space-y-3">
              <div className="h-4 bg-neutral-300 rounded col-span-2"></div>
              <div className="h-4 bg-neutral-300 rounded col-span-1"></div>
            </div>
          </div>
        ) : connections?.length ? (
          <NetworkContactMessages messages={connections as NetworkContactMessage[]} contactEmail={email} />
        ) : (
          <div>No communication found</div>
        )}
      </div>
    </div>
  );
}

enum MessageDirection {
  Incoming = "Incoming",
  Outgoing = "Outgoing",
}

type NetworkContactMessagesProps = {
  messages: NetworkContactMessage[];
  contactEmail: string;
};

export function NetworkContactMessages(props: NetworkContactMessagesProps) {
  const { messages, contactEmail } = props;
  let lastDirection: MessageDirection;
  return (
    <div>
      {messages?.map((message: NetworkContactMessage) => {
        const direction = message.fromEmail === contactEmail ? MessageDirection.Incoming : MessageDirection.Outgoing;
        const shift = lastDirection && lastDirection !== direction;
        lastDirection = direction;
        return (
          <NetworkContactMessage
            message={message as NetworkContactMessage}
            contactEmail={contactEmail}
            direction={direction}
            shift={shift}
          />
        );
      })}
    </div>
  );
}

type NetworkContactMessage = {
  id: string;
  timestamp: string;
  fromEmail: string;
  toEmails: string[];
};

type NetworkContactMessageProps = {
  contactEmail: string;
  message: NetworkContactMessage;
  direction: MessageDirection;
  shift: boolean;
};

export function NetworkContactMessage(props: NetworkContactMessageProps) {
  const { message, contactEmail, direction, shift } = props;
  const { id, timestamp, fromEmail, toEmails } = message;
  let extraClasses = "";
  if (direction === MessageDirection.Outgoing) {
    extraClasses += "@3xl:ml-auto ";
  }

  if (shift) {
    extraClasses += "@3xl:mt-[-28px] ";
  }

  const to: string[] = [];
  const cc: string[] = [];
  const bcc: string[] = [];

  for (let i = 0; i < toEmails.length; i = i + 2) {
    if (toEmails[i] == "to") {
      to.push(toEmails[i + 1]);
    } else if (toEmails[i] == "cc") {
      cc.push(toEmails[i + 1]);
    } else if (toEmails[i] === "bcc") {
      bcc.push(toEmails[i + 1]);
    }
  }

  return (
    <div key={id} className={`py-1 rounded-sm bg-gray-100 relative m-2 @3xl:w-[48%] ${extraClasses}`}>
      <div className="text-[14px]">
        <div className="absolute top-1 right-2 text-neutral font-[500]">
          {dateTimeFormat.format(new Date(timestamp))}
        </div>
        <NetworkContactMessageAddress contactEmail={contactEmail} direction="From" addresses={[fromEmail]} />
        <NetworkContactMessageAddress contactEmail={contactEmail} direction="To" addresses={to} />
        <NetworkContactMessageAddress contactEmail={contactEmail} direction="Cc" addresses={cc} />
        <NetworkContactMessageAddress contactEmail={contactEmail} direction="Bcc" addresses={bcc} />
      </div>
    </div>
  );
}

type NetworkContactMessageAddressProps = {
  direction: string;
  contactEmail: string;
  addresses: string[];
};

export function NetworkContactMessageAddress(props: NetworkContactMessageAddressProps) {
  const { direction, addresses, contactEmail } = props;
  if (!addresses?.length) return null;
  let classes = "text-left overflow-hidden text-ellipsis font-black";
  classes += direction === "From" ? " whitespace-nowrap font-bold w-[calc(100%-190px)]" : " flex-1 line-clamp-3";
  return (
    <div className="flex flex-row font-medium font-[14px]">
      <div className="w-[50px] text-right mr-1 text-neutral">{direction}</div>
      <div className={classes}>{addresses.join(", ")}</div>
    </div>
  );
}

const dateFormat = new Intl.DateTimeFormat("en-GB", {
  day: "numeric",
  month: "short",
  year: "numeric",
});

const dateTimeFormat = new Intl.DateTimeFormat("en-GB", {
  day: "numeric",
  month: "short",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
});

export function DateChip(props) {
  const value = props.value ? dateFormat.format(new Date(props.value)) : "—";
  if (props.noClasses) return value;
  const extraClasses = props.inline ? "w-auto" : "";
  return (
    <span
      className={`text-neutral w-[100px] inline-block font-[500] select-none justify-self-center rounded-sm bg-neutral-100 px-2 py-1 text-center text-xs text-neutral ${extraClasses}`}
    >
      {value}
    </span>
  );
}
