import { Button } from "@/app/components";
import { ActionsMenu } from "@/app/components/actions-menu";

import { useKanbanBoardContext } from "../kanban-board-context";
import { Sort } from "../kanban-board-types";

export const KanbanBoardSubheader = () => {
  const { sort, setSort } = useKanbanBoardContext();

  const sortOptions: ({ label: string } & Sort)[] = [
    { label: "Excitement Score (High-Low)", field: "excitementScore", direction: "desc" },
    { label: "Excitement Score (Low-High)", field: "excitementScore", direction: "asc" },
  ];

  const options = sortOptions.map((option) => ({
    label: option.label,
    action: () => {
      setSort({ field: option.field, direction: option.direction });
    },
  }));

  return (
    <div className="flex items-center justify-between border-b border-gray-200 bg-white px-3 py-2 md:px-8 lg:px-10 2xl:px-30">
      <Button iconLeft="Filter" size="xs" disabled>
        Filters
      </Button>
      <ActionsMenu
        title={`${sortOptions.find((option) => option.field === sort.field && option.direction === sort.direction)?.label}`}
        icon={sort.direction === "asc" ? "Chevron Up" : "Chevron Down"}
        options={options}
        btnClassName="text-nowrap min-w-1"
        className="ml-auto size-auto"
        btnSize="xs"
      />
    </div>
  );
};
