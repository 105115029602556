import { observer } from "mobx-react";

import { useFundsQuery } from "@/app/hooks";
import { FundsTableNew } from "@/app/screens/investments/components/funds-table/funds-table-new.tsx";
import { UnderConstruction } from "@/app/screens/investments/components/in-progress-banner.tsx";

import { SubHeader } from "../opportunities/components";

export const InvestmentDashboard = observer(() => {
  const { fundList, loading } = useFundsQuery();

  return (
    <>
      <section className={"flex h-dvh w-full flex-1 grow flex-col items-center overflow-hidden bg-background"}>
        <SubHeader
          containerClass={"fixed sticky top-0 z-20 hidden bg-background bg-opacity-70 backdrop-blur"}
          canSort={false}
        />
        <div className={"flex w-full flex-col overflow-y-auto p-2 sm:px-4 lg:px-5 lg:py-0"}>
          <UnderConstruction />
          <FundsTableNew funds={fundList} loading={loading} />
        </div>
      </section>
    </>
  );
});
