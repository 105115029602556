import { useState } from "react";

import { toast } from "react-hot-toast";

import { useBreakpoints } from "@/lib/hooks";
import { cn } from "@/lib/utils.ts";

import { AvatarV2, Button, Icon } from "@/app/components";
import { FormField } from "@/app/components/forms/form-field";
import { InputV2 } from "@/app/components/forms/input-v2.tsx";
import { PresentationModeToggle } from "@/app/components/presentation-mode/presentation-mode-toggle";
import { useGeneralSettings } from "@/app/screens/settings/hooks";
import { useUpdateUserSettingsMutation } from "@/app/service/lgMembers.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

export const General = () => {
  const { user } = useGeneralSettings();
  return (
    <div className={"flex size-full flex-col"}>
      {[{ label: "Photo", key: "avatar" }, { label: "Name" }, { label: "Email Address" }].map(({ label }, index) => {
        return (
          <div key={index} className={"flex w-full items-center border-b border-gray-200 py-4"}>
            <span key={index} className={cn("w-1/3 text-sm font-semibold text-gray-700")}>
              {label}
            </span>
            <div className={"flex w-2/3 items-center justify-between"}>
              {label === "Photo" && <PhotoSettings user={user} />}
              {label === "Name" && <NameSettings user={user} />}
              {label === "Email Address" && <span className={"text-sm font-medium text-gray-700"}>{user?.email}</span>}
            </div>
          </div>
        );
      })}
      <FeatureToggles />
      <Button
        text={"Logout"}
        iconLeft={"LogOut"}
        variant={"secondary"}
        className={"absolute bottom-[10dvh] left-[14dvw] lg:bottom-12"}
        onClick={() => authStore.logout()}
      />
    </div>
  );
};

const PhotoSettings = ({ user }) => {
  return (
    <>
      <AvatarV2 src={user?.avatar} initials={user?.initials} type={"user"} size={"sm"} />
      <div className="absolute hidden items-center justify-center opacity-0 lg:relative lg:opacity-100">
        <label htmlFor="dropzone-file">
          <div
            className={
              "flex h-8 w-fit cursor-pointer items-center justify-center rounded-xxs border border-gray-400 bg-white px-3 py-2 font-semibold  text-gray-900 hover:bg-gray-400 active:bg-gray active:text-white disabled:border-gray-100 disabled:bg-white disabled:text-gray-200"
            }
          >
            <span className="text-sm font-semibold">Edit</span>
            <input id="dropzone-file" type="file" className="hidden" />
          </div>
        </label>
      </div>
    </>
  );
};
const NameSettings = ({ user }) => {
  const { isBigTablet } = useBreakpoints();
  const [{ firstName, lastName, edit }, setData] = useState({
    firstName: user?.firstName,
    lastName: user?.lastName,
    edit: false,
  });
  const [updateSettings, { loading: updateSettingsLoading }] = useUpdateUserSettingsMutation();

  const cancel = user.firstName === firstName && user.lastName === lastName;

  const editName = () => {
    if (edit && !cancel) {
      updateSettings({
        variables: { input: { general: { firstName, lastName } } },
      })
        .then(() => {
          authStore.updateUser({ firstName, lastName });
          setData((prev) => ({ ...prev, edit: !prev.edit }));
          toast.success(`Your name is now ${firstName} ${lastName}!`);
        })
        .catch(() => {
          toast.error("Failed to update settings");
        });
      return;
    }
    setData((prev) => ({ ...prev, edit: !prev.edit }));
  };

  return (
    <>
      {edit ? (
        <div className={cn("flex space-x-2 lg:space-x-5")}>
          <FormField label={"First Name"} className={"w-1/5 lg:w-full"}>
            <InputV2
              value={firstName}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  editName();
                }
              }}
              onChange={({ target: { value } }) => {
                setData((prev) => ({ ...prev, firstName: value }));
              }}
            />
          </FormField>
          <FormField label={"Last Name"} className={"w-1/5 lg:w-full"}>
            <InputV2
              value={lastName}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  editName();
                }
              }}
              onChange={({ target: { value } }) => {
                setData((prev) => ({ ...prev, lastName: value }));
              }}
            />
          </FormField>
        </div>
      ) : (
        <span className="text-sm font-medium text-gray-700">{`${user?.firstName} ${user?.lastName}`}</span>
      )}
      {!isBigTablet && !edit && (
        <Icon
          type={"Edit"}
          className={"size-4"}
          onClick={() => {
            setData((prev) => ({ ...prev, edit: !prev.edit }));
          }}
        />
      )}
      {isBigTablet && (
        <Button
          text={edit ? (cancel ? "Cancel" : "Save") : "Edit"}
          size={"sm"}
          loading={updateSettingsLoading}
          variant={edit && !cancel ? "gradient" : "outline"}
          onClick={editName}
        />
      )}
      {edit && !isBigTablet && (
        <Button
          text={edit ? (cancel ? "Cancel" : "Save") : "Edit"}
          loading={updateSettingsLoading}
          className={"absolute bottom-10 right-6"}
          variant={edit && !cancel ? "gradient" : "outline"}
          onClick={editName}
        />
      )}
    </>
  );
};

const FeatureToggles = () => {
  return (
    <div className="flex items-center justify-between py-4">
      <PresentationModeToggle label="Presentation Mode" />
    </div>
  );
};
