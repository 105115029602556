import React, { useState } from "react";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import { useBreakpoints } from "@/lib/hooks";
import { cn, formatFundingMil } from "@/lib/utils.ts";

import { Button, Icon, Card } from "@/app/components";
import { MeetThePortfolio } from "@/app/screens/investments/components/use-meet-the-portfolio.tsx";
import { HeaderSortIndicator } from "@/app/components/table-new/components/table-header-cell";
import { HeaderContext } from "@tanstack/react-table";

export type CellType = {
  value?: number | string | null | boolean;
  change?: "increase" | "decrease";
  extra?: any;
};

export const MTPTable = ({
  data,
  columns,
  expanded,
  hoverable = true,
  tableType,
  onShowMore,
  activeFund,
  visibleItems = 5,
  fnOnRowClick,
  rowClassName = "",
  defaultSortingCol = "navToFundSizePercentage",
  containerClassName = "",
}: {
  data: Array<MeetThePortfolio>;
  columns: Array<ColumnDef<MeetThePortfolio>>;
  expanded?: boolean;
  tableType: string;
  activeFund: string;
  hoverable?: boolean;
  onShowMore: () => void;
  visibleItems?: number;
  rowClassName?: string;
  fnOnRowClick?: (...args) => void | null;
  defaultSortingCol?: string;
  containerClassName?: string;
}) => {
  const { isBigTablet } = useBreakpoints();
  const [sorting, setSorting] = useState<SortingState>([{ id: defaultSortingCol, desc: true }]);
  const table = useReactTable({
    data,
    columns,
    defaultColumn: {},
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    enableRowSelection: true,
    enableSortingRemoval: true,
    manualExpanding: true,
    state: {
      sorting,
      expanded: {},
    },
  });

  const noResults = table.getRowModel().rows.length === 0;

  return (
    <Card className={cn("relative w-full overflow-auto p-0")}>
      <div className="flow-root w-full">
        <div className={cn("min-h-[84px] w-full lg:pb-3.5 mx-[-20px] w-[calc(100%+40px)]", containerClassName)}>
          <table className={cn("relative mb-2 w-full bg-white", activeFund === "BC" ? "table-fixed" : "")}>
            <thead>
              {!noResults &&
                table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className={cn("h-fit")}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th key={header.id} className="realtive first:pl-5 last:pr-5">
                          {header.isPlaceholder ? null : (
                            <div
                              className={cn("flex cursor-pointer items-center justify-center font-medium text-neutral")}
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              <div>
                                {flexRender(header.column.columnDef.header, {
                                  ...header.getContext(),
                                  noResults,
                                  tableType,
                                  activeFund,
                                })}
                              </div>
                            </div>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
            </thead>
            <tbody className="size-full bg-white">
              <>
                {noResults ? (
                  <span className="absolute inset-x-0 bottom-0 top-10.5 flex items-center justify-center text-center text-xl font-medium text-neutral-300">{`No Results in ${tableType}`}</span>
                ) : (
                  table
                    .getRowModel()
                    .rows.slice(0, !isBigTablet || expanded ? data.length : visibleItems)
                    .map((row) => {
                      return (
                        <tr
                          key={row.id}
                          className={cn("h-fit min-h-8", hoverable ? "hover:bg-neutral-100" : "", rowClassName)}
                          onClick={() => {
                            if (fnOnRowClick) {
                              fnOnRowClick(row.original);
                            }
                          }}
                        >
                          {row.getVisibleCells().map((cell) => {
                            return (
                              <td key={cell.id} className={cn("px-2 py-1.5 first:pl-5 last:pr-5")}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                )}
              </>
            </tbody>
          </table>
        </div>
        {isBigTablet && data.length > visibleItems && (
          <Button
            size="sm"
            variant="text"
            className="w-full"
            onClick={onShowMore}
            iconLeft={expanded ? "Chevron Up" : "Chevron Down"}
            text={expanded ? "Show Less" : "Show More"}
          />
        )}
      </div>
    </Card>
  );
};

const TableNA = ({ textStyle = "", containerClassName = "" }) => {
  return (
    <div className={cn(containerClassName)}>
      <span className={cn("select-none whitespace-nowrap text-xs font-medium leading-4 !text-neutral-300", textStyle)}>
        N/A
      </span>
    </div>
  );
};

export const TableText = ({
  label,
  change,
  onClick = () => null,
  className = "",
  containerClassName = "",
}: {
  label: string | unknown;
  change?: "increase" | "decrease";
  onClick?: () => void;
  className?: string;
  containerClassName?: string;
}) => {
  if (!label || String(label).startsWith("0:") || String(label).startsWith("NaN:"))
    return <TableNA containerClassName={cn("flex items-center", containerClassName)} textStyle={className} />;

  return (
    <div className={cn("flex items-center", containerClassName)}>
      <span
        onClick={onClick}
        className={cn(
          "select-none text-nowrap font-medium text-neutral-800 hover:text-neutral-600 lg:text-xs 2xl:text-sm",
          className
        )}
      >
        {String(label)}
      </span>
      {Boolean(change) && (
        <Icon
          type={change === "increase" ? "Increase" : "Decrease"}
          className={cn("ml-0.5 size-3", change === "increase" ? "text-green" : "text-red")}
        />
      )}
    </div>
  );
};

export const TableHeader = ({
  label,
  header,
  headerDef,
  drawHeader = true,
  onClick = () => null,
  containerClassName = "",
}: {
  label: string;
  headerDef: HeaderContext<any, any>;
  header?: any;
  drawHeader?: boolean;
  onClick?: () => void;
  containerClassName?: string;
}) => {
  const isSorted = headerDef.column.getIsSorted();
  return (
    <div
      className={cn("relative  h-24 w-full flex-col", drawHeader ? "h-24" : "h-12", containerClassName)}
      onClick={onClick}
    >
      {drawHeader && <div className={"h-8 w-full border-t-2 border-white"}>{header}</div>}
      <div
        className={
          "mt-0.5 pl-1  gap-1 flex h-14 w-full items-center justify-center text-center text-sm font-medium lg:text-xxs xl:text-xs mx-2"
        }
      >
        {label}

        {/* negative mr to compensate for optionally shown arrow indicator, so text remains centered */}
        {headerDef.column.getCanSort() && (
          <div>
            <Icon
              type="ArrowUp"
              className={cn(
                "transition-transform duration-75 text-black",
                isSorted === false &&
                  "invisible opacity-0 group-hover/header-cell:visible group-hover/header-cell:opacity-20",
                isSorted === "asc" && "-scale-y-100",
                "size-2.5"
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export type ColumnHeaderType = {
  label: string;
  value: string | number;
  valueType: "percentage" | "currency" | "multiplier" | "number";
  containerClassName?: string;
  extraProps?: any;
};

export const ColumnHeader = ({ label, value, valueType, containerClassName, extraProps = {} }: ColumnHeaderType) => {
  return (
    <div className={cn("flex flex-col items-center", containerClassName)}>
      <span className={"!2xl:text-xxs w-full bg-neutral-100 py-0.5 text-center !text-xxxs font-medium"}>{label}</span>
      {valueType === "percentage" && (
        <RowWithPercentage
          label={value}
          className={"flex w-full items-center justify-center !text-xxs text-neutral-700"}
          {...extraProps}
        />
      )}
      {valueType === "currency" && (
        <RowWithCurrency
          label={value}
          className={"!text-xxs text-neutral-700"}
          currencyClassName={"!text-xxs"}
          {...extraProps}
        />
      )}
      {valueType === "multiplier" && <RowWithMultiplier label={value} {...extraProps} />}
      {valueType === "number" && <TableText label={value} className={"!text-xxs text-neutral-700"} {...extraProps} />}
    </div>
  );
};

export const RowWithCurrency = ({
  label,
  change,
  currency = "£",
  unit = "million",
  className = "",
  currencyClassName = "",
  containerClassName = "",
}: {
  label: number | string | unknown;
  change?: "increase" | "decrease";
  unit?: "million" | "billion";
  currency?: "$" | "£";
  className?: string;
  currencyClassName?: string;
  containerClassName?: string;
}) => {
  const valueOfReference = unit === "billion" ? 1_000_000_000 : 1_000_000;
  let value = formatFundingMil(Number(label) / valueOfReference, 2);
  if (unit === "billion") {
    value = value.replace("m", "b");
  }

  if (!label)
    return (
      <TableNA containerClassName={cn("flex items-center text-nowrap", containerClassName)} textStyle={className} />
    );

  return (
    <div className={cn("flex items-center text-nowrap", containerClassName)}>
      <span
        className={cn(
          "text-center text-sm font-medium text-currency lg:mr-px lg:text-xs 2xl:mr-0.5 2xl:text-sm",
          currencyClassName
        )}
      >
        {currency}
      </span>
      <span className={cn("text-center text-sm font-medium text-neutral-800 lg:text-xs 2xl:text-sm", className)}>
        {value}
      </span>
      {Boolean(change) && (
        <Icon
          type={change === "increase" ? "Increase" : "Decrease"}
          className={cn("ml-0.5 size-3", change === "increase" ? "text-green" : "text-red")}
        />
      )}
    </div>
  );
};

export const RowWithMultiplier = ({
  label,
  change,
  className = "",
  containerClassName = "",
}: {
  change?: "increase" | "decrease";
  label: number | string | unknown;
  className?: string;
  containerClassName?: string;
}) => {
  if (!label || Number(label) === 0) return <TableNA containerClassName={containerClassName} />;
  return (
    <div className={cn("flex items-center text-nowrap", containerClassName)}>
      <span
        className={cn("text-nowrap text-sm font-medium text-neutral-800 lg:text-xs 2xl:text-sm", className)}
      >{`${formatNumber(Number(label))}x`}</span>
      {Boolean(change) && (
        <Icon
          type={change === "increase" ? "Increase" : "Decrease"}
          className={cn("ml-0.5 size-3", change === "increase" ? "text-green" : "text-red")}
        />
      )}
    </div>
  );
};

export const RowWithPercentage = ({
  label,
  change,
  decimals = 2,
  className,
  containerClassName,
}: {
  label: number | string | unknown;
  change?: "increase" | "decrease";
  decimals?: number;
  className?: string;
  containerClassName?: string;
}) => {
  if (Number.isNaN(label) || Number(label) === 0)
    return <TableNA containerClassName={containerClassName} textStyle={className} />;

  return (
    <div className={cn("flex items-center text-nowrap", containerClassName)}>
      <span
        className={cn("text-nowrap text-sm font-medium text-neutral-800 lg:text-xs 2xl:text-sm", className)}
      >{`${formatNumber(Number(label) * 100, decimals)}%`}</span>
      {Boolean(change) && (
        <Icon
          type={change === "increase" ? "Increase" : "Decrease"}
          className={cn("ml-0.5 size-3", change === "increase" ? "text-green" : "text-red")}
        />
      )}
    </div>
  );
};

const colorDict = {
  primary: "bg-gradient-to-r from-primary-500 via-primary-400 to-primary-200",
  secondary: "bg-gradient-to-r from-secondary-500 via-secondary-400 to-secondary-200",
  blue: "bg-gradient-to-r from-blue-500 via-blue-400 to-blue-200",
  red: "bg-gradient-to-r from-red-500 via-red-400 to-red-200",
  green: "bg-gradient-to-r from-green-500 via-green-400 to-green-200",
  yellow: "bg-gradient-to-r from-yellow-500 via-yellow-400 to-yellow-200",
  purple: "bg-gradient-to-r from-purple-500 via-purple-400 to-purple-200",
  orange: "bg-gradient-to-r from-orange-500 via-orange-400 to-orange-200",
};

export const RowWithBar = ({ percentage, color = "red" }) => {
  return (
    <div className="flex w-full items-center">
      <div className="flex h-3.5 w-full items-center justify-end">
        <span className="mr-2 text-nowrap text-sm font-medium text-neutral-800 lg:text-xs">{`${percentage}%`}</span>
        <div className={cn(`h-full rounded-xxs`, colorDict[color])} style={{ width: `${percentage}%` }} />
      </div>
    </div>
  );
};

function formatNumber(num: number | string | unknown, decimals = 2) {
  if (typeof num !== "number") {
    num = Number(num);
  }
  const roundedNum = Math.round((num as number) * 10 ** decimals) / 10 ** decimals;
  const numStr = roundedNum.toString();
  const parts = numStr.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}
