// @ts-nocheck

import React, { Fragment, useState } from "react";

import { cn } from "@/lib/utils.ts";

import { Icon, Popover } from "@/app/components";

import { DashboardSection } from "../../components/dashboard-section.tsx";
import { HistoryToggle } from "../../components/history-toggle.tsx";
import { dashboardSections } from "../../dashboard.config.ts";
import { getListItemAnimation } from "../../utils.ts";

import { CompanyUpdatesItem } from "./company-updates-item.tsx";
import { useCompanyUpdates } from "./use-company-updates.ts";

export const CompanyUpdatesSection = () => {
  const [showArchived, setShowArchived] = useState(false);
  const data = useCompanyUpdates(showArchived);

  return (
    <DashboardSection
      id={dashboardSections.companyUpdates.id}
      title={dashboardSections.companyUpdates.label}
      count={data.totalCount}
      info={
        <Popover
          directionClassName={"border-white"}
          childrenClassName={"ml-4 overflow-visible border-0 w-[600px]"}
          childrenContent={
            <>
              <div className={"m-1 flex items-center gap-x-1.5 break-words"}>
                <p className={"select-none rounded-sm px-2 py-1.5 text-xs font-semibold text-neutral-600"}>
                  By forwarding your company updates to&nbsp;
                  <b className={"cursor-pointer"}>
                    <a href="mailto:updates@projectnazare.vc">updates@projectnazare.vc</a>
                  </b>
                  &nbsp;:
                  <br />
                  ✔️ the email's body will automatically be added to the comments section;
                  <br />
                  ✔️ attachments will be placed in the attachments section;
                  <br />
                  ✔️ any relevant metrics will be extracted for you.
                </p>
              </div>
            </>
          }
          placement={"right"}
        >
          <Icon type={"Info"} className={cn("size-4 text-gray-500")} />
        </Popover>
      }
      loading={data.loading}
      headerSlot={<HistoryToggle value={showArchived} setValue={() => setShowArchived(!showArchived)} />}
      emptyPlaceholder={{ enabled: data.totalCount === 0 && !data.loading, message: "No company updates" }}
      loadMoreBtn={{
        enabled: data.pageInfo?.hasNextPage,
        onLoadMore: data.loadMore,
        loading: data.loadingMore,
      }}
      loadLessBtn={{
        enabled: data.pageInfo?.hasPreviousPage,
        onLoadLess: data.loadLess,
        loading: data.loadingLess,
      }}
    >
      <div className="flex flex-col gap-3">
        {data.items.map((item, index) => (
          <Fragment key={item.id}>
            <CompanyUpdatesItem notification={item} className={getListItemAnimation(index)} />
          </Fragment>
        ))}
      </div>
    </DashboardSection>
  );
};
