
import { gql } from "@apollo/client";

export const getCompanyConnections = gql`
  query GetNetworkCompanyConnections($companyId: UUID) {
    connections: networkGetCompanyConnections(companyId: $companyId) {
      nodes {
        memberId
        internalEmail
        connectionStrength
      }
    }
  }
`;
