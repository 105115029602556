export type ConnectivityType = {
  value?: number;
  inline?: boolean;
  tiny?: boolean;
};

export function Connectivity(props: ConnectivityType) {
  const { value, inline, tiny } = props;
  const val = value !== undefined && Number.isFinite(value) ? (value >= 1 ? 0.999 : value) : 0;
  const connectivityIcons = ["low", "medium", "high"];
  const connectivityType = Math.min(2, Math.floor(val * 3));
  const displayedValue = Math.floor((val % 1) * 100);
  const rootClasses = inline ? "flex gap-1 justify-center items-center" : "absolute top-2 right-2 @3xl:static flex gap-1 justify-center items-center"
  const imgClasses = tiny ? "w-[14px]" : "";
  return (
    <div className={rootClasses}>
      <img
        className={imgClasses}
        src={`/assets/icons/connectivity-${connectivityIcons[connectivityType]}.svg`}
      />
      <div className="text-[14px] font-medium text-gray-800 w-[24px] text-left">{displayedValue}</div>
    </div>
  );
}
