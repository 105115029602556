  const keys = Object.freeze([
    "NAZARE_BASE_URL",
    "NAZARE_API_DOMAIN",
    "APP_VERSION",
    "POSTHOG_KEY",
    "POSTHOG_HOST",
    "NAZARE_INTERNAL_DOMAIN",
    "MOBX_DEBUG",
    "SENTRY_DSN",
    "ENVIRONMENT",
    "SHOW_DEV_MODE_BANNER"
  ] as const);

export type EnvKey = (typeof keys)[number];

function initEnv() {
  const viteDict = {};
  const windowDict = {};

  for (const key of keys) {
    viteDict[key] = import.meta.env[`VITE_${key}`];
    windowDict[key] = window["~~env"]?.[key];
  }
  

  function getEnv(variable: EnvKey): string | undefined {
    if (variable in windowDict && windowDict[variable] !== undefined) {
       return windowDict[variable];
    }

    if (variable in viteDict) {
       return viteDict[variable];
    }
  }

  return { getEnv };
}
export const getEnv = initEnv().getEnv;
