import React, { useState } from "react";

import { AnimatePresence, motion } from "framer-motion";

import { cn } from "@/lib/utils.ts";

import { Icon } from "@/app/components/icon";

export const UnderConstruction = () => {
  const [visible, setVisible] = useState(true);
  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          className={cn(
            "relative -mb-1.5 flex h-10 select-none items-center gap-1.5 text-nowrap rounded-t-sm bg-gold-500/90 px-3 py-1 text-xs",
          )}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.2, ease: "easeIn" }}
        >
          <div className={"flex w-full flex-row items-center justify-center gap-x-2 text-xs"}>
            <span>🚧</span>
            <span className={"text-xs font-bold"}>UNDER CONSTRUCTION</span>
            <span>🚧</span>
          </div>
          <Icon
            type="X"
            onClick={() => setVisible(false)}
            className="absolute right-3 size-4 cursor-pointer hover:text-gray-800"
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
