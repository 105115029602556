import React from "react";

import { useBreakpoints } from "@/lib/hooks";
import { cn } from "@/lib/utils.ts";

import { AvatarV2, Button, Icon } from "@/app/components";
import { IconButtonV2 } from "@/app/components/button";
import { lineColors } from "@/app/components/charts/sentiment-history.tsx";
import { SelectV2 } from "@/app/components/forms/select-v2";
import { Company } from "@/app/hooks";
import { restrictedFunds } from "@/app/misc/filters.ts";
import { AddNewResponsiveModal } from "@/app/screens/modal";
import { SearchCompany } from "@/app/screens/modal/actions/add-sentiment/components";
import { useSentimentContext } from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-context.tsx";
import { sentimentAttributes } from "@/app/screens/opportunities/opportunity/components/sentiment/use-sentiment.tsx";
import modalStore from "@/app/stores/modal.store";

const DASHBOARD_SELECTOR_VALUES = [
  { value: "Time Series", label: "Time Series" },
  { value: "Benchmark", label: "Benchmark" },
];

const DATA_ALIGNMENT_VALUES = [
  { value: "Normal", label: "Normal" },
  { value: "Left Align", label: "Left Align" },
  { value: "Left-Right Align", label: "Left-Right Align" },
];

const BENCHMARK_ATTRIBUTE_VALUES = [
  { value: "lastSentiment", label: "Last Sentiment" },
  { value: "last3Sentiment", label: "Last 3 Sentiment" },
  { value: "firstSentiment", label: "First Sentiment" },
  { value: "first3Sentiment", label: "First 3 Sentiment" },
  { value: "allSentiment", label: "All Sentiment" },
];

export type SentimentAttribute =
  | "excitement_opinion_scale"
  | "vision_opinion_scale"
  | "market_opinion_scale"
  | "product_opinion_scale"
  | "team_opinion_scale"
  | "fundraising_opinion_scale"
  | "timing_opinion_scale"
  | "fit_opinion_scale"
  | "opportunity_opinion_scale";

export type SentimentFieldSelectorProps = {
  value: SentimentAttribute;
  onChange: (value: SentimentAttribute) => void;
  selectedCompanies: Company[];
  setSelectedCompanies: React.Dispatch<React.SetStateAction<Company[] | null | undefined>>;
  company: any;
  className?: string;
};

const handleValueSelect = (
  selected: string | null,
  values: Array<{ value: string; label: string }>,
  setValue: React.Dispatch<{ value: string; label: string }>,
) => {
  const option = values.find(({ value }) => value === selected);

  if (option) setValue(option);
};

export const SentimentHeader = ({
  company,
  onChange,
  value: selectedValue,
  selectedCompanies,
  setSelectedCompanies,
  className,
}: SentimentFieldSelectorProps) => {
  const { isLaptop } = useBreakpoints();
  const { dataAlignment, setDataAlignment, dashboardSelector, setDashboardSelector, maximised, setMaximised } =
    useSentimentContext();

  return (
    <div className={cn("flex w-full flex-col", className)}>
      <div className={"flex w-full items-center justify-between"}>
        {!maximised && <h5 className="mr-4 text-base font-semibold lg:text-xl">{"Sentiment"}</h5>}

        <div className={"flex w-full items-center justify-between"}>
          <div className={"grid w-full max-w-96 shrink-0 grid-cols-2 gap-2"}>
            {maximised && (
              <SelectV2
                buttonClassName={"h-8"}
                value={dashboardSelector.value}
                multiple={false}
                options={DASHBOARD_SELECTOR_VALUES}
                onChange={(value) => handleValueSelect(value, DASHBOARD_SELECTOR_VALUES, setDashboardSelector)}
              />
            )}
            <SelectV2
              buttonClassName={"h-8"}
              value={selectedValue}
              options={sentimentAttributes}
              multiple={false}
              onChange={(value) => value && onChange(value)}
            />
          </div>

          {maximised && isLaptop && (
            <div className="mx-4 hidden w-full flex-row gap-4 lg:flex">
              <SearchCompany
                clearAfterSelection={true}
                showEmptyState={false}
                iconType={"Search"}
                iconClassName={"text-gray-600 size-4"}
                inputClassName={"text-[14px] font-semibold py-2 h-fit rounded-xs font-medium text-gray-900"}
                searchBarClassName={"p-0 m-0 max-h-fit rounded-xs outline outline-gray-200 border-0 h-8"}
                selectCompany={(company) => {
                  if (company) setSelectedCompanies((prevState) => (prevState ? [...prevState, company] : [company]));
                }}
                placeholder={"Compare with company..."}
              />
            </div>
          )}
          {!isLaptop && (
            <Button
              iconLeft={maximised ? "Minimise" : "Maximise"}
              size="sm"
              onClick={() => setMaximised((prevState) => !prevState)}
            >
              {maximised ? "Minimise" : "Maximise and compare"}
            </Button>
          )}
          {maximised && isLaptop && (
            <Button
              iconLeft={maximised ? "Minimise" : "Maximise"}
              variant="outline"
              size="sm"
              onClick={() => {
                setMaximised((prev) => !prev);
              }}
            >
              {maximised ? "Minimise" : "Maximise and compare"}
            </Button>
          )}
        </div>

        {!maximised && isLaptop && (
          <div className={"flex shrink-0 items-center justify-center space-x-4"}>
            <Button
              iconLeft={maximised ? "Minimise" : "Maximise"}
              variant="outline"
              size="sm"
              onClick={() => {
                setMaximised((prev: boolean) => !prev);
              }}
              className="shrink-0"
            >
              {maximised ? "Minimise" : "Maximise and compare"}
            </Button>

            <Button
              iconLeft="Add"
              variant="outline"
              size="sm"
              onClick={() => {
                modalStore.open(AddNewResponsiveModal, {
                  props: { company, sentimentOnly: true },
                });
              }}
              className="shrink-0"
            >
              Add sentiment
            </Button>
          </div>
        )}
      </div>
      {maximised && !isLaptop && (
        <SearchCompany
          clearAfterSelection={true}
          showEmptyState={false}
          iconType={"Search"}
          iconClassName={"text-gray-600 size-4"}
          inputClassName={"text-[14px] font-semibold py-2 h-fit rounded-xs font-medium text-gray-900"}
          searchBarClassName={"p-0 m-0 max-h-fit rounded-xs outline outline-gray-200 border-0 my-4"}
          selectCompany={(company) => {
            if (company) setSelectedCompanies((prevState) => (prevState ? [...prevState, company] : [company]));
          }}
          placeholder={"Compare with company..."}
        />
      )}
      {maximised && selectedCompanies.length >= 2 && (
        <div className={"mt-0 flex items-center justify-between gap-2 lg:mt-4"}>
          <div className={"no-scrollbar flex shrink-0 items-center gap-x-2 overflow-auto"}>
            {selectedCompanies?.map((company, idx) => {
              const bgGradient = `linear-gradient(160deg, ${lineColors[idx].from}, ${lineColors[idx].to})`;
              return (
                <div
                  className="flex h-8 flex-row items-center justify-between rounded-xxs bg-gray-200 py-1.5 pl-3 pr-1"
                  key={idx}
                >
                  <div className="mr-3 size-2.5 rounded-full" style={{ background: bgGradient }} />
                  <AvatarV2
                    className="mr-2"
                    src={
                      company.image ||
                      (company.websiteUrl
                        ? `https://www.google.com/s2/favicons?sz=128&domain=${company.websiteUrl}`
                        : "")
                    }
                    type="company"
                    size="xs"
                  />
                  <p className="mr-3 select-none whitespace-nowrap text-[14px] font-semibold text-gray-900">
                    {company.name}
                  </p>
                  {idx !== 0 && (
                    <IconButtonV2
                      icon={"X"}
                      variant={"secondary"}
                      size={"xs"}
                      onClick={() => {
                        if (idx === 0) return;
                        const newComps = selectedCompanies.toSpliced(idx, 1);
                        setSelectedCompanies(newComps);
                      }}
                      aria-hidden="true"
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className={"flex w-full items-center justify-end"}>
            <div className={"min-w-22"}>
              {isLaptop && (
                <SelectV2
                  value={dataAlignment.value}
                  buttonClassName={"h-8"}
                  multiple={false}
                  options={DATA_ALIGNMENT_VALUES}
                  onChange={(value) => handleValueSelect(value, DATA_ALIGNMENT_VALUES, setDataAlignment)}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {maximised && selectedCompanies.length >= 2 && !isLaptop && (
        <SelectV2
          value={dataAlignment.value}
          multiple={false}
          options={DATA_ALIGNMENT_VALUES}
          onChange={(value) => handleValueSelect(value, DATA_ALIGNMENT_VALUES, setDataAlignment)}
        />
      )}
    </div>
  );
};

export const SentimentBenchmarkHeader = ({ maximised, setMaximised }) => {
  const { isLaptop } = useBreakpoints();
  const {
    fundSelected,
    setFundSelected,
    dashboardSelector,
    setDashboardSelector,
    benchmarkAttribute,
    setBenchmarkAttribute,
  } = useSentimentContext();

  return (
    <div className={"flex w-full flex-col px-4 lg:px-6"}>
      <div className={"flex w-full items-center justify-between"}>
        <div className={"flex w-full items-center justify-between"}>
          <div className={"mr-3 flex w-full gap-2"}>
            {maximised && (
              <SelectV2
                value={dashboardSelector.value}
                buttonClassName={"h-8"}
                multiple={false}
                options={DASHBOARD_SELECTOR_VALUES}
                onChange={(value) => handleValueSelect(value, DASHBOARD_SELECTOR_VALUES, setDashboardSelector)}
              />
            )}
            <SelectV2
              value={benchmarkAttribute.value}
              buttonClassName={"h-8"}
              multiple={false}
              options={BENCHMARK_ATTRIBUTE_VALUES}
              onChange={(value) => handleValueSelect(value, BENCHMARK_ATTRIBUTE_VALUES, setBenchmarkAttribute)}
            />
            <SelectV2
              buttonClassName={"h-8"}
              value={fundSelected.value}
              multiple={false}
              options={restrictedFunds}
              onChange={(value) => handleValueSelect(value, restrictedFunds, setFundSelected)}
            />
          </div>
          {maximised && isLaptop && (
            <Button
              iconLeft={maximised ? "Minimise" : "Maximise"}
              size="sm"
              variant="outline"
              onClick={() => setMaximised((prev) => !prev)}
              className="shrink-0"
            >
              {maximised ? "Minimise" : "Maximise and compare"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
