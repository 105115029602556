import { SyntheticEvent } from "react";

import { useNavigate } from "react-router";

import { Button, Card } from "@/app/components";
import { ActionNames, useActionWrapper, useOpportunityActions } from "@/app/hooks";

import { Tags } from "../../opportunities/components/card-list/tags";
import { getAvailableOpportunityActionNames } from "../../opportunities/utils";
import { DashboardCompany } from "../dashboard.types";

type CompanyCardProps = {
  company: DashboardCompany;
  primaryAction?: ActionNames;
  defaultActions?: ActionNames[];
};

const getCompanyTitle = (company: DashboardCompany): string => {
  if (company.name) {
    return company.name;
  }

  if (company.websiteUrl) {
    return company.websiteUrl;
  }

  const founders = company.lgCompanyTeamsByCompanyId?.nodes.map((tm) => tm.member?.name).join(", ");

  return founders ? `Stealth (${founders})` : "Stealth company";
};

export const CompanyCard = ({ company, primaryAction, defaultActions = [] }: CompanyCardProps) => {
  const navigate = useNavigate();
  const actions = useOpportunityActions({ company }) || {};
  const primary = primaryAction && actions[primaryAction];
  const { loading, wrapAction } = useActionWrapper();

  const availableActionNames = getAvailableOpportunityActionNames({
    company,
    defaultActions,
    skip: primaryAction ? [primaryAction] : [],
  });

  const onPrimaryToggle = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (primary) {
      wrapAction({ ...primary, isAsync: true });
    }
  };

  const cardTitle = getCompanyTitle(company);

  return (
    <Card
      key={company.id}
      data-cy="company-card"
      className="group relative flex cursor-pointer select-none flex-col items-center gap-y-2 transition hover:scale-[1.01]"
      onClick={() => navigate(`/company/${company.id}`)}
    >
      <Card.Header>
        <Card.Image
          src={
            company.image ||
            (company.websiteUrl ? `https://www.google.com/s2/favicons?sz=128&domain=${company.websiteUrl}` : "")
          }
          size={"sm"}
          type="company"
          className="mr-3"
        />
        <Card.Title title={cardTitle} />
        {actions && (
          <Card.Actions
            containerClassName="ml-auto"
            actions={actions}
            availableActionNames={availableActionNames as string[]}
          >
            {primary && (
              <Button
                data-cy={`action-${primaryAction}`}
                size={"xs"}
                variant={"secondary"}
                className="min-w-[40px]"
                loading={loading[primary.label]}
                onClick={onPrimaryToggle}
              >
                {primary.label}
              </Button>
            )}
          </Card.Actions>
        )}
      </Card.Header>
      <Card.Body>
        <p className="line-clamp-1 min-h-10 text-sm font-medium text-neutral-700">{company.tagline}</p>
      </Card.Body>
      <Card.Footer className="mt-auto">
        <Tags city={company.city} country={company.country} badges={company.badges} />
      </Card.Footer>
    </Card>
  );
};
