import { useState } from "react";

import { AnimatePresence, motion } from "framer-motion";

import { cn } from "@/lib/utils.ts";

import { Icon } from "@/app/components/icon";

import { getEnv } from "../../env/env";

const showDevModeBanner = getEnv("SHOW_DEV_MODE_BANNER");

export const DevModeBanner = () => {
  const [visible, setVisible] = useState(true);

  if (!showDevModeBanner) {
    return null;
  }

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          className={cn(
            "fixed left-1/2 top-0 z-50 flex -translate-x-1/2 select-none items-center gap-1.5 overflow-hidden text-nowrap rounded-b-sm bg-gold-500/90 px-2 py-1 text-xs",
          )}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.2, ease: "easeIn" }}
        >
          This is a testing environment. Data may be lost.
          <Icon type="X" onClick={() => setVisible(false)} className="size-4 cursor-pointer hover:text-gray-800" />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
