// @ts-nocheck

import { useMemo } from "react";

import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

import { useBreakpoints } from "@/lib/hooks";
import { Opportunity } from "@/lib/service/opportunity.types.ts";
import { parseCompanyDetails } from "@/lib/utils.ts";

import { AvatarV2 } from "@/app/components";
import { useExcitementScores } from "@/app/hooks/use-excitement-scores.tsx";
import { AccessOverview } from "@/app/screens/opportunities/components/access-overview.tsx";
import { CardActions } from "@/app/screens/opportunities/components/card-list/card-actions.tsx";
import { Tags } from "@/app/screens/opportunities/components/card-list/tags.tsx";
import { EngagementOverview } from "@/app/screens/opportunities/components/engagement-overview.tsx";
import { ExcitementScore } from "@/app/screens/opportunities/components/excitement-score.tsx";
import { TitleWithSignal } from "@/app/screens/opportunities/components/title.tsx";
import { GetCompaniesQuery } from "@/app/service/opportunities.gql.ts";

type Company = NonNullable<GetCompaniesQuery["lgCompanies"]>["edges"][number]["node"];

const columnHelper = createColumnHelper<Company>();

const ExcitementScoreCell = ({ company }: { company: Opportunity }) => {
  const { sentiments, previouslyMet, portCo } = parseCompanyDetails(company);
  const { stats, velocity } = useExcitementScores({
    sentiments,
    previouslyMet,
    isPortco: !!portCo,
  });

  return (
    <div className="flex min-w-[100px] justify-center">
      <ExcitementScore stats={stats} velocity={velocity} isOld={stats.isOld} isSad={stats.isSad} showAll={true} />
    </div>
  );
};

export const useOpportunitiesColumns = () => {
  const { isSmallLaptop } = useBreakpoints();

  const columnDefs: ColumnDef<Company>[] = useMemo(
    () => [
      {
        header: "Name & Description",
        enableSorting: false,
        accessorKey: "name",
        size: 300,
        cell: (info) => {
          const company = info.row.original;
          const { name, image, tagline, portCo, badgeSignals, trelloSource, websiteUrl } = parseCompanyDetails(company);

          return (
            <div className="flex w-full items-center pl-4">
              <AvatarV2
                type="company"
                size="sm"
                src={image || (websiteUrl ? `https://www.google.com/s2/favicons?sz=128&domain=${websiteUrl}` : "")}
                className={"fill-white"}
              />
              <div className={"ml-4 text-left"}>
                <TitleWithSignal
                  title={name}
                  signals={badgeSignals}
                  showSignals={true}
                  showType={false}
                  isPortco={!!portCo}
                  name={trelloSource?.list?.name}
                  signalIconStyle="md:h-4"
                />
                <p className="line-clamp-1 overflow-hidden text-ellipsis text-sm font-medium  text-neutral-700 sm:text-sm lg:text-xs 2xl:text-sm">
                  {tagline || "..."}
                </p>
              </div>
            </div>
          );
        },
      },
      {
        header: "Tags",
        accessorKey: "tags",
        enableSorting: false,
        size: 0,
        minSize: 300,
        cell: (info) => {
          const company = info.row.original;
          const { city, country, badges, portCo, round, industries } = parseCompanyDetails(company);

          return (
            <div className="w-full overflow-hidden">
              <Tags
                city={city}
                country={country}
                badges={badges}
                round={round}
                portCo={portCo}
                industries={industries}
                tagStyle={"group-hover:bg-white"}
              />
            </div>
          );
        },
      },
      {
        header: "Excitement",
        accessorKey: "excitement",
        size: 130,
        cell: (info) => {
          const company = info.row.original;

          return <ExcitementScoreCell company={company} />;
        },
        meta: {
          align: "center",
        },
      },
      {
        header: "Engagement",
        accessorKey: "engagement",
        size: 150,
        cell: (info) => {
          const company = info.row.original;
          const { previouslyMet, portCo } = parseCompanyDetails(company);

          return (
            <div className="flex justify-center">
              <EngagementOverview
                companyId={company.id}
                engagement={company.engagementSummary}
                isSad={previouslyMet || !!portCo}
                showAll={true}
              />
            </div>
          );
        },
        meta: {
          align: "center",
        },
      },
      {
        header: "Access",
        accessorKey: "access",
        size: isSmallLaptop ? 250 : 300,
        cell: (info) => {
          const company = info.row.original;
          const { assignees, basecamp, previouslyMet, portCo } = parseCompanyDetails(company);

          return (
            <div className="flex w-full justify-center">
              <AccessOverview
                inTable={true}
                assignees={assignees}
                basecamp={basecamp}
                isPortco={!!portCo}
                isSad={previouslyMet || !!portCo}
                showAll={true}
              />
            </div>
          );
        },
      },
      columnHelper.display({
        id: "actions",
        size: 150,
        cell: (info) => {
          const company = info.row.original;

          return <CardActions company={company} containerClassName="justify-end" />;
        },
      }),
    ],
    [isSmallLaptop],
  );

  return columnDefs;
};
