import { action, computed, makeObservable, observable } from "mobx";
import { isHydrated, hydrateStore, makePersistable } from "mobx-persist-store";
import { OpsCompany } from "@/gql/types.ts";
import { getEnv } from "../env/env";

export type LGMember = {
  __typename?: "LgMember";
  avatar?: string | null;
  createdUtc?: any;
  email: string;
  firstName: string;
  id: any;
  initials: string;
  isInvestor?: boolean;
  lastModifiedUtc?: any;
  lastName: string;
  trelloMemberId?: string | null;
};

class MembersStore {
  public isHydrated = false;
  public lgMembers: Array<LGMember> = [];
  public pcgCompanies: OpsCompany[] = [];
  public topInvestors: { id: string; name: string }[] = [];

  constructor() {
    makeObservable(this, {
      lgMembers: observable,
      pcgCompanies: observable,
      topInvestors: observable,
      members: computed,
      updateLgMembers: action,
    });
    makePersistable(
      this,
      {
        stringify: true,
        debugMode: getEnv("MOBX_DEBUG") === "true",
        name: "membersStore",
        storage: window.localStorage,
        properties: ["lgMembers", "pcgCompanies", "topInvestors"],
      },
      { fireImmediately: true }
    ).catch((e) => console.error("Error hydrating store", e));

    this.waitForHydration();
  }
  async waitForHydration() {
    if (isHydrated(this)) {
      this.isHydrated = true;
      return;
    }

    await hydrateStore(this);
    this.isHydrated = true;
  }

  updateLgMembers = (lgMembers: Array<LGMember>) => {
    this.lgMembers = lgMembers;
  };

  getByTrelloId = (id?: string) => {
    if (!id) return undefined;
    return this?.lgMembers?.find((member) => member.trelloMemberId === id);
  };

  getByName = (name: string) => {
    if (!name) return undefined;
    return this.lgMembers?.find((member) => {
      return member.firstName === name || member.lastName === name;
    });
  };

  getByEmail = (email?: string) => {
    if (!email) return undefined;
    return this?.lgMembers?.find((member) => member.email === email);
  };

  getById = (id?: string) => {
    if (!id) return undefined;
    return this?.lgMembers?.find((member) => member.id === id);
  };

  get members() {
    return this.lgMembers;
  }
}

export default new MembersStore();
