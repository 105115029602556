import { useLayoutEffect } from "react";

import { companyCopilotStore, CompanyMetadata, StateType } from "@/app/screens/modal/copilot";

export function useCompanyCopilot({
  copilotState,
  companyMetadata,
}: {
  copilotState: Partial<StateType>;
  companyMetadata: Partial<CompanyMetadata>;
}) {
  const { setState, state, saveConversation, onOpenChat, clearHistory } = companyCopilotStore;

  useLayoutEffect(() => {
    onOpenChat({ copilotState, companyMetadata });

    return () => {
      if (state.route === "messages") {
        saveConversation();
      }
      setState({ companyId: "", companyName: "", conversationId: "", route: "conversations" });
      clearHistory();
    };
  }, []);

  if (state.route === "messages" && !state.conversationId) {
    setState({ route: "conversations" });
    return;
  }
}
