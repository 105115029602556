// @ts-nocheck

import { useEffect, useMemo, useState } from "react";

import { useBreakpoints } from "@/lib/hooks";

import {
  SentimentState,
  useSentimentContext,
} from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-context.tsx";
import { SentimentAttribute } from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-header.tsx";
import { useOpportunityContext } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";

export const attrOrder = [
  "excitement",
  "vision",
  "market",
  "product",
  "team",
  "fundraising",
  "timing",
  "fit",
  "opportunity",
];

export const useSentimentChart = ({ sentimentData }) => {
  const isLoading = false; // todo figure out why it wasn't reassigned
  const { company } = useOpportunityContext();
  const { maximised, setMaximised } = useSentimentContext();
  const { setState, dashboardSelector, setDashboardSelector, benchmarkAttribute, fundSelected } = useSentimentContext();
  const [referenceField, setReferenceField] = useState<SentimentAttribute>("excitement_opinion_scale");

  // stores value of a sentiment selected on the chart
  const [selectedSentiment, setSelectedSentiment] = useState(null);
  const [selectedCompanies, setSelectedCompanies] = useState([company]);

  useEffect(() => {
    if (isLoading) {
      setState(SentimentState.loading);
    } else {
      const dataLen = sentimentData?.length || 0;
      if (dataLen === 0) {
        setState(SentimentState.no_data);
      } else {
        setState(SentimentState.has_data);
      }
    }
  }, [sentimentData, isLoading, setState]);

  const { radarChartData, tooltipChartData } = useMemo(() => {
    const firstNonNullForm = sentimentData?.find((form) => form?.responses?.length > 0);

    const radarChartData = attrOrder.map((item) => ({
      label: item,
      value: firstNonNullForm?.analytics?.[`${item}_opinion_scale`]?.mean?.toFixed(2),
    }));

    const tooltipForm = selectedSentiment;
    const tooltipChartData = selectedSentiment
      ? attrOrder.map((item) => ({
          label: item,
          value: tooltipForm?.analytics[`${item}_opinion_scale`]?.mean?.toFixed(2),
        }))
      : null;

    const ReturnKey = Object.keys(
      firstNonNullForm?.analytics?.return_multiple_choice || {
        "N/A": true,
      },
    )?.[0];

    const ReturnKeyCompare = Object.keys(
      tooltipForm?.analytics?.return_multiple_choice || {
        "N/A": true,
      },
    )?.[0];

    return { radarChartData, tooltipChartData, ReturnKey, ReturnKeyCompare };
  }, [sentimentData, selectedSentiment]);

  const { isLaptop } = useBreakpoints();
  return {
    company,
    isLaptop,
    selectedCompanies,
    setSelectedCompanies,
    referenceField,
    setReferenceField,
    selectedSentiment,
    maximised,
    setMaximised,
    setSelectedSentiment,
    radarChartData,
    tooltipChartData,
    dashboardSelector,
    setDashboardSelector,
    benchmarkAttribute,
    fundSelected,
  };
};
