import { ReactNode } from "react";

import { cn } from "@/lib/utils.ts";

import { Icon, IconType } from "@/app/components";
import { isIconType } from "@/app/components/icon";

const colors = {
  orange: "bg-orange-200 text-orange-700",
  green: "bg-green-200 text-green-700",
  volcano: "bg-volcano-200 text-volcano-600",
  purple: "bg-purple-200 text-purple-500",
  cyan: "bg-cyan-100 text-cyan-600",
  "day-blue": "bg-day-blue-200 text-day-blue-500",
  "geek-blue": "bg-geek-blue-200 text-geek-blue-500",
  "geek-blue-inverted": "bg-geek-blue-400 text-white",
  magenta: "bg-magenta-300 text-magenta-500",
  gray: "bg-gray-300 text-gray-600",
  "gray-inverted": "bg-gray-600 text-white",
  red: "bg-red-200 text-red-500",
  "gold-inverted": "bg-gold-600 text-white",
};

export type ChipV2Props = {
  label: string;
  color: keyof typeof colors;
  className?: string;
  iconLeft?: IconType | ReactNode;
  iconRight?: IconType | ReactNode;
  size?: "xs" | "sm" | "lg";
};

export const ChipV2 = ({ label, color, className, iconRight, iconLeft, size = "sm" }: ChipV2Props) => {
  return (
    <div
      className={cn(
        "flex items-center whitespace-nowrap",
        colors[color],
        className,
        size === "xs" && "h-4 gap-0.5 rounded-[2px] px-1 text-xs font-medium",
        size === "sm" && "h-6 gap-0.5 rounded-[2px] px-2 text-xs",
        size === "lg" && "h-8 gap-1 rounded-xxs px-3 text-sm",
      )}
    >
      {isIconType(iconLeft) ? <Icon type={iconLeft} className={cn("size-3", size === "xs" && "size-2")} /> : iconLeft}
      {label}
      {isIconType(iconRight) ? (
        <Icon type={iconRight} className={cn("size-3", size === "xs" && "size-2")} />
      ) : (
        iconRight
      )}
    </div>
  );
};
