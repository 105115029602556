import { FunctionComponent, memo } from "react";

import { Database, FolderOpen, HomeIcon, LucideIcon, SquareKanban, UserIcon } from "lucide-react";

import { UserRoleType } from "@/gql/graphql";

import { Bell, Investments, MeetThePortfolioIcon, Network, Opportunity } from "@/app/components/icon/icon.tsx";

type Routes =
  | "portfolio"
  | "prospects"
  | "pipeline"
  | "investments"
  | "inbox"
  | "network"
  | "settings"
  | "home"
  | "kanban"
  | "fund-overview";

type SidebarItem = {
  name: string;
  current: boolean;
  path: Routes;
  Icon: LucideIcon | FunctionComponent;
  disabled?: boolean;
  isDemo?: boolean;
};

// all tabs
const allTabs: Record<string, SidebarItem> = {
  inbox: {
    name: "Inbox",
    current: false,
    Icon: memo(Bell),
    path: "inbox",
  },
  portfolio: {
    name: "Portfolio",
    current: false,
    Icon: memo(FolderOpen),
    path: "portfolio",
  },

  prospects: {
    name: "Prospects",
    current: false,
    Icon: memo(Database),
    path: "prospects",
  },
  pipeline: {
    name: "Pipeline",
    current: false,
    Icon: memo(Opportunity),
    path: "pipeline",
  },
  network: {
    name: "Network",
    current: false,
    Icon: memo(Network),
    path: "network",
  },
  investments: {
    name: "Investments",
    current: false,
    Icon: memo(Investments),
    path: "investments",
  },
  fundOverview: {
    name: "Fund Overview",
    current: false,
    Icon: memo(MeetThePortfolioIcon),
    path: "fund-overview",
    // webOnly: true,
  },
  settings: {
    name: "Settings",
    current: false,
    Icon: UserIcon,
    path: "settings",
  },
  home: {
    name: "Home",
    current: true,
    Icon: HomeIcon,
    path: "home",
  },
  kanban: {
    name: "Kanban",
    current: false,
    Icon: SquareKanban,
    path: "kanban",
    isDemo: true,
  },
};

export const tabsMobile: Array<SidebarItem[]> = [
  [allTabs.home],
  [allTabs.network, allTabs.portfolio, allTabs.prospects, allTabs.pipeline],
  [allTabs.fundOverview],
];

export const useTabs = (userRoles: UserRoleType[]) => {
  const tabsDesktop: Array<SidebarItem[]> = [
    [allTabs.home],
    [
      allTabs.network,
      allTabs.portfolio,
      allTabs.prospects,
      allTabs.pipeline,
      userRoles.includes(UserRoleType.UserRoleKanbanBoard) && allTabs.kanban,
    ].filter((item) => item !== false),
    [allTabs.investments, allTabs.fundOverview],
  ];

  return { tabsDesktop, tabsMobile };
};
