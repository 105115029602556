import React, { memo, useCallback } from "react";

import { motion } from "framer-motion";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";

import { cn } from "@/lib/utils.ts";

import { AvatarV2, IconButtonV2 } from "@/app/components";
import { BreadCrumbs } from "@/app/components/navbar/index.ts";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import {
  OpportunityActions,
  OpportunityActionsMobile,
} from "@/app/screens/opportunities/opportunity/components/actions/actions.tsx";
import { SocialMediaIcons } from "@/app/screens/opportunities/opportunity/components/social-media-icons.tsx";
import { OpportunityCompany } from "@/app/screens/opportunities/opportunity/opportunity.types.ts";

import { useSocialMediaIcons } from "../use-opportunity.tsx";

type OpportunityHeaderStickyProps = {
  company: OpportunityCompany;
  isOpen: boolean;
};

const variants = {
  open: {
    height: 64,
  },
  closed: {
    height: 0,
  },
};

const HeaderContent = memo(function HeaderContent({
  onBack,
  isOpen,
  company,
}: {
  onBack: () => void;
  isSidenavOpen: boolean;
  isOpen: boolean;
  company: NonNullable<OpportunityCompany>;
}) {
  const socialMediaIcons = useSocialMediaIcons(company);

  return (
    <motion.div
      className={cn(
        "flex items-center space-x-4 px-5 shadow-xs",
        // positioning:
        // there's a weird bug when using sticky with a sidenav. The header jumps breaking the layout thus using fixed
        "fixed left-0 top-0 z-20 w-full",
        "lg:px-6 lg:pl-24",
        "backdrop-blur-md",
        "bg-[rgba(255,255,255,0.1)]", // enabling blur only on larger screens because of bug in ios safari
        !isOpen ? "overflow-hidden" : "",
      )}
      variants={variants}
      initial={"closed"}
      animate={isOpen ? "open" : "closed"}
      transition={{ duration: 0.1, easeInOut: true }}
    >
      <BreadCrumbs title={"Back"} action={onBack} />
      <AvatarV2
        src={
          company.image ||
          (company.websiteUrl ? `https://www.google.com/s2/favicons?sz=128&domain=${company.websiteUrl}` : "")
        }
        type="company"
        size="sm"
      />

      <div>
        <span className="truncate text-2xl font-semibold">{company.name || "Stealth Company"}</span>
      </div>

      <SocialMediaIcons className="hidden md:flex" icons={socialMediaIcons} variant="text" />

      {/* this is needed so dropdown does not stays open when sticky header is closed */}
      {isOpen && (
        <div className="!ml-auto">
          <OpportunityActions company={company} className="hidden pt-0 lg:flex lg:pr-1 2xl:pr-4" />
          <OpportunityActionsMobile company={company} className="ml-4 lg:hidden" />
        </div>
      )}
    </motion.div>
  );
});

export const OpportunityHeaderSticky = observer(function OpportunityHeaderSticky({
  company,
  isOpen,
}: OpportunityHeaderStickyProps) {
  const { isOpen: isSidenavOpen } = globalModalStore.navbarState;
  const navigate = useNavigate();

  const handleNavigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (!company) {
    return null;
  }

  return <HeaderContent isOpen={isOpen} onBack={handleNavigateBack} isSidenavOpen={isSidenavOpen} company={company} />;
});
