import { cn } from "@/lib/utils";

import { Icon, RadioOption } from "@/app/components";
import { RadioInput } from "@/app/components/forms/radio-group-v2";

export interface AddGeneralProps<T extends string> {
  onSelect: (option: RadioOption<T>) => void;
  options: RadioOption<T>[];
}

const radioContent = ({ checked, disabled, option }) => (
  <div
    className={cn(
      "flex min-h-13 min-w-44 cursor-pointer items-center justify-center rounded-sm border border-gray-300 bg-gray-100 p-2 text-center font-semibold transition-all lg:block lg:h-44 lg:w-full",
      !disabled ? "hover:bg-white hover:shadow-radio" : "cursor-default select-none",
      checked ? "bg-white text-black" : "",
    )}
  >
    {option.icon && (
      <Icon
        type={checked ? option.checkedIcon || option.icon : option.icon}
        className={cn("mr-2 size-6 text-gray-800 lg:mx-auto lg:mt-11 lg:size-8", disabled ? "text-gray-400" : "")}
      />
    )}
    <span className={cn("text-sm font-semibold text-gray-900", disabled ? "text-gray-400" : "")}>
      {option.label}{" "}
      {disabled && option.value === "founderSentiment" && (
        <span className={"block text-xxs font-medium text-red-400 lg:mt-4"}>Missing founder info</span>
      )}
    </span>
    <div
      className={cn(
        "ml-auto mt-0 flex size-6 items-center justify-center rounded-xxl border border-gray-300 bg-white lg:mx-auto lg:my-6",
        disabled && "hidden",
      )}
    >
      {checked && <div className={cn("size-4 rounded-xxl bg-gradient-to-r from-primary-400 to-secondary-400")} />}
    </div>
  </div>
);

export const SelectAction = <T extends string>({ onSelect, options }: AddGeneralProps<T>) => {
  return (
    <section className="flex h-full flex-1 flex-col justify-center">
      <div className="mb-4 mt-auto lg:mb-0">
        <RadioInput
          className={cn(
            `grid grid-cols-1 items-stretch gap-3`,
            options.length === 2 ? "lg:grid-cols-2" : "lg:grid-cols-3",
          )}
        >
          {options.map((option) => (
            <RadioInput.Option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              onClick={() => onSelect(option)}
            >
              {(args) => radioContent({ ...args, option })}
            </RadioInput.Option>
          ))}
        </RadioInput>
      </div>
    </section>
  );
};
