import { useState } from "react";

import { BaseToggle } from "@/app/components";
import Form from "@/app/components/forms";
import { FormField } from "@/app/components/forms/form-field";
import { TextAreaV2 } from "@/app/components/forms/textarea-v2";

export const SnoozeForm = ({ step, form }) => {
  return (
    <>
      <p className={"mb-2 w-full text-left text-sm font-medium text-neutral-700"}>{step?.description}</p>
      <Form.Root form={form}>{step.index === 1 && <SnoozeFormTwo />}</Form.Root>
    </>
  );
};

// FOR NO JUST COMMENTING THIS AS WE MIGHT REVERT & CHANGE UI - if we decide to use resurface period
// const SnoozeFormOne = ({ resurfaceOption, handleResurfacePeriodChange }) => {
//   return (
//     <>
//       <Form.Field
//         fieldId={"reason"}
//         render={({ onChange, value }) => (
//           <div className="flex flex-1 flex-col items-start">
//             <Form.Label />
//             <TextAreaV2
//               containerClassName={"w-full mb-4"}
//               value={value}
//               onChange={(value) => {
//                 onChange(value);
//               }}
//             />
//           </div>
//         )}
//       />
//       <Form.Field
//         fieldId={"resurface"}
//         render={({ onChange, value }) => (
//           <div className={"flex w-full flex-col items-start "}>
//             <Form.Label />
//             <div className={"flex w-full flex-col items-start gap-y-3"}>
//               <FormSelect
//                 options={shouldResurface}
//                 defaultOptions={value ? [{ value, label: value }] : undefined}
//                 iconLeft="Snooze"
//                 placeholder={"Select period"}
//                 multiSelect={false}
//                 required
//                 onChange={([option]) => handleResurfacePeriodChange(option?.value, onChange)}
//               />
//               {resurfaceOption === "custom" && (
//                 <FormDatePicker
//                   onChange={(date) => (date ? onChange(format(date, "yyyy-MM-dd")) : onChange(""))}
//                   selected={value ? parseISO(value) : undefined}
//                   minDate={new Date()}
//                   placeholder="Select Date"
//                   className="mt-2 w-full"
//                   enableYearNavigation
//                 />
//               )}
//             </div>
//             <Form.Error />
//           </div>
//         )}
//       />
//     </>
//   );
// };

const SnoozeFormTwo = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <Form.Field
        fieldId={"feedback_email"}
        render={({ onChange, value }) => (
          <div className={"flex w-full flex-col items-start gap-3"}>
            <TextAreaV2
              autoFocus={true}
              containerClassName={"w-full"}
              onChange={(value) => {
                onChange(value);
              }}
              value={value}
              placeholder={"Add a brief reason for not progressing with the company..."}
            />

            <FormField label="Apologize for delay">
              <BaseToggle
                className={"pl-0.5"}
                value={toggle}
                onChange={(checked) => {
                  setToggle(checked);

                  if (checked) {
                    onChange(`${value}\n\n\nBegin your response with a sincere apology for the delay.`);
                    return;
                  }
                  onChange(value.replace("\n\n\nBegin your response with a sincere apology for the delay.", ""));
                }}
              />
              <Form.Error />
            </FormField>
          </div>
        )}
      />
    </>
  );
};
