import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { TargetFund as Fund } from "@/gql/graphql.ts";

// need to export this to be used in other files
export { TargetFund as Fund } from "@/gql/graphql.ts";

import {
  NetworkSignalType,
  sourceOptions,
} from "../../opportunities/opportunity/components/network-signals/network-signal-utils";

export const companySignalSchema = z
  .object({
    fund: z.nativeEnum(Fund).optional(),
    note: z.string().optional().nullable(),
    sourceName: z.union([
      z
        .string({ invalid_type_error: "Please select valid type", required_error: "Source is required" })
        .min(1, "Source is required")
        .or(
          z.object({
            name: z.string(),
            email: z.string().min(1, "Email is required"),
          }),
        ),
      z.string().optional().nullable(),
    ]),
    sourceType: z.string({ required_error: "Name is required" }).min(1, "Source type is required"),
    introAvailable: z.boolean().optional().nullable(),
    highPriority: z.boolean().optional().nullable(),
    ballInOurControl: z.boolean().optional().nullable(),
    activelyRaising: z.boolean().optional().nullable(),
    alreadyMet: z.boolean().optional().nullable(),
    isDeepTech: z.boolean().optional().nullable(),
  })
  .superRefine((data, ctx) => {
    const { sourceName, sourceType } = data;

    if (sourceName === null || sourceName === undefined || sourceName === "") {
      // Only allow empty values if source type is standalone(no name field)
      if (
        !sourceOptions
          .filter((option) => option.standalone)
          .map((option) => option.value)
          .includes(sourceType as NetworkSignalType)
      ) {
        ctx.addIssue({
          path: ["sourceName"],
          code: z.ZodIssueCode.custom,
          message: "Source is required",
        });
      }
    }
  });

export type CompanySignalSchema = z.infer<typeof companySignalSchema>;

export const useCompanySignalForm = () => {
  return useForm<CompanySignalSchema>({
    resolver: zodResolver(companySignalSchema),
    mode: "onChange",
    defaultValues: {
      fund: Fund.Lg,
      note: "",
      sourceName: "",
      sourceType: "",
      alreadyMet: false,
      introAvailable: false,
      highPriority: false,
      ballInOurControl: false,
      activelyRaising: false,
      isDeepTech: false,
    },
  });
};
