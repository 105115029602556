import { memo } from "react";

import { Icon } from "@/app/components";

import { AddCompanyViaEmailStatus } from "./add-company-via-email.types";

type AddViaEmailMessageProps<TStatus extends AddCompanyViaEmailStatus> = {
  status?: TStatus;
  errorMessage?: TStatus extends "FAILED" ? string : never;
  identifiedCompaniesNumber?: number;
  title?: string;
};

export const AddViaEmailMessage = memo(
  <TStatus extends AddCompanyViaEmailStatus>({
    title,
    status,
    errorMessage,
    identifiedCompaniesNumber,
  }: AddViaEmailMessageProps<TStatus>) => {
    const hasErrored = !!errorMessage || (status === "FAILED" && !identifiedCompaniesNumber);

    const isDeclined = status === "DECLINED";
    const isCompleted = status === "COMPLETED";

    const message = isCompleted ? "This email has been completed" : isDeclined ? "This email has been declined" : title;

    return (
      <div className="flex w-full items-center gap-2 rounded-xs border bg-white p-4 text-gray-800">
        {hasErrored ? (
          <>
            <Icon type="Shield Alert" className="size-5 text-red-400" />
            {errorMessage ??
              "It seems that the email provided does not contain any company recommendations or relevant information to extract."}
          </>
        ) : (
          <>
            <Icon type="InfoOutline" className="size-5" />
            {message}
          </>
        )}
      </div>
    );
  },
);

AddViaEmailMessage.displayName = "AddViaEmailMessage";
