import React, { createContext, useContext, useState } from "react";

import { createPortal } from "react-dom";

import { cn } from "@/lib/utils";

interface TooltipContextType {
  showTooltip: (content: React.ReactNode, position: { x: number; y: number }) => void;
  hideTooltip: () => void;
  timeoutId: ReturnType<typeof setTimeout> | null;
  setTimeoutId: (id: ReturnType<typeof setTimeout> | null) => void;
}

const TooltipContext = createContext<TooltipContextType | null>(null);

interface TooltipProviderProps {
  children: React.ReactNode;
}

export const TooltipProvider = ({ children }: TooltipProviderProps) => {
  const [tooltipContent, setTooltipContent] = useState<React.ReactNode>(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isVisible, setIsVisible] = useState(false);
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | null>(null);

  const showTooltip = (content: React.ReactNode, pos: { x: number; y: number }) => {
    setTooltipContent(content);
    setPosition(pos);
    setIsVisible(true);
  };

  const hideTooltip = () => {
    setIsVisible(false);
  };

  return (
    <TooltipContext.Provider value={{ showTooltip, hideTooltip, timeoutId, setTimeoutId }}>
      {children}
      {createPortal(
        <div
          data-tooltip-content
          className={cn(
            "fixed z-50 pb-1.5",
            "-translate-x-1/2 -translate-y-full",
            "transition-opacity duration-200 ease-in-out",
            isVisible && !!tooltipContent ? "visible block opacity-100" : "invisible hidden opacity-0",
          )}
          style={{
            left: position.x,
            top: position.y,
          }}
          onMouseEnter={() => {
            if (timeoutId) {
              clearTimeout(timeoutId);
              setTimeoutId(null);
            }
          }}
          onMouseLeave={(e) => {
            const target = e.relatedTarget as HTMLElement;
            if (target?.closest("[data-tooltip-trigger]")) {
              return;
            }
            hideTooltip();
          }}
        >
          <div className={cn("rounded-xs bg-white px-3 py-2 text-sm text-gray-900 shadow-[0_3px_10px_rgb(0,0,0,0.2)]")}>
            {tooltipContent}
          </div>
        </div>,
        document.body,
      )}
    </TooltipContext.Provider>
  );
};

interface TooltipProps {
  content: React.ReactNode;
  children: React.ReactNode;
  delay?: number;
}

export const TooltipGlobal = ({ content, children, delay = 100 }: TooltipProps) => {
  const context = useContext(TooltipContext);
  if (!context) {
    throw new Error("Tooltip must be used within a TooltipProvider");
  }

  const { showTooltip, hideTooltip, timeoutId, setTimeoutId } = context;

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const newTimeoutId = setTimeout(() => {
      showTooltip(content, {
        x: rect.left + rect.width / 2,
        y: rect.top,
      });
    }, delay);
    setTimeoutId(newTimeoutId);
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    const tooltipElement = document.querySelector("[data-tooltip-content]");
    if (tooltipElement && tooltipElement.contains(e.relatedTarget as Node)) {
      return;
    }
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
    hideTooltip();
  };

  return (
    <div data-tooltip-trigger onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
    </div>
  );
};
