import { Children, PropsWithChildren, ReactNode, useMemo } from "react";

import { cn } from "@/lib/utils.ts";

import { PopoverPlacement } from "../popover";
import { TooltipGlobal } from "../tooltip-global/tooltip-global.tsx";

import { useResponsiveItemLimit } from "./use-responsive-items-limit.ts";

type ResponsiveHorizontalListProps = PropsWithChildren<{
  className?: string;
  itemProps?: {
    className?: string;
  };
  popoverProps?: {
    placement?: PopoverPlacement;
    disabled?: boolean;
    className?: string;
    childrenClassName?: string;
    renderContent?: (hiddenItems: ReactNode[], allItems: ReactNode) => ReactNode;
  };
}>;
export const ResponsiveHorizontalList = ({
  children,
  className,
  itemProps,
  popoverProps,
}: ResponsiveHorizontalListProps) => {
  const { parentRef, itemRefs, hiddenItemIndex, hiddenItemLeftOffset } = useResponsiveItemLimit(
    Children.count(children),
  );

  const hiddenItems = useMemo(() => {
    return hiddenItemIndex > 0 ? Children.toArray(children).slice(hiddenItemIndex) : [];
  }, [hiddenItemIndex, children]);

  return (
    <div ref={parentRef} className={cn("relative flex w-full items-center gap-1", className)}>
      {Children.map(children, (child, index) => (
        <div
          key={index}
          ref={(el) => (itemRefs.current[index] = el)}
          className={cn(itemProps?.className, hiddenItemIndex > 0 && index >= hiddenItemIndex && "invisible")}
        >
          {child}
        </div>
      ))}
      {hiddenItemIndex > 0 && (
        <div className="absolute" style={{ left: hiddenItemLeftOffset }}>
          {!popoverProps?.disabled ? (
            <TooltipGlobal
              content={
                <div className={cn("flex flex-col", popoverProps?.childrenClassName)}>
                  {popoverProps?.renderContent ? popoverProps.renderContent(hiddenItems, children) : hiddenItems}
                </div>
              }
            >
              <div className={cn("cursor-pointer outline-none", popoverProps?.className)}>+{hiddenItems.length}</div>
            </TooltipGlobal>
          ) : (
            <>+{hiddenItems.length}</>
          )}
        </div>
      )}
    </div>
  );
};
