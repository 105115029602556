import { useContext, useEffect } from "react";
import { formatDate, numberFormat } from "../format";
import { Column, SortDirection, columns, sortPairValueToString } from "./columns";
import { AvatarV2 } from "@/app/components";
import { ConnectionsContext } from "./connectionsDict";
import { AnimatePresence, motion } from "framer-motion";
import { Connectivity } from "./connectivity";

export type TableHeaderCellProps = {
  column: Column;
  sortColumn: Column;
  sortDirection: SortDirection;
};

export function TableHeaderCell(props: TableHeaderCellProps) {
  const { sortColumn, sortDirection } = props;
  const columnInfo = columns.get(props.column);
  if (!columnInfo) throw new Error(`Unsupported column ${props.column}`);
  let classes =
    "px-4 pr-0 py-4 @7xl:pr-4 font-medium text-neutral text-left bg-white whitespace-nowrap cursor-pointer select-none ";
  if (columnInfo.extraTheadClasses) {
    classes += columnInfo.extraTheadClasses;
  }

  let nextDirection: SortDirection;
  let imgStyle: Record<string, string>;
  if (sortColumn === props.column) {
    if (sortDirection === SortDirection.Asc) {
      nextDirection = SortDirection.Desc;
      imgStyle = { transform: "scaleY(-1)" };
    } else {
      imgStyle = {};
      nextDirection = SortDirection.Asc;
    }
  } else {
    nextDirection = SortDirection.Asc;
    imgStyle = { visibility: "hidden" };
  }
  const nextSort = sortPairValueToString(props.column, nextDirection);

  return (
    <th className={classes} data-column-next-sort={nextSort}>
      {columnInfo.title}{" "}
      <img
        className="inline transition-transform duration-200 ease-in-out"
        style={imgStyle}
        src="/assets/icons/arrow-sort-desc.svg"
      />
    </th>
  );
}

export type TableHeaderProps = {
  sortColumn: Column;
  sortDirection: SortDirection;
  noSearch: boolean;
};

export function TableHeader(props: TableHeaderProps) {
  const { sortColumn, sortDirection, noSearch } = props;
  const classes = !noSearch
    ? "hidden shadow-sm @5xl:table-row-group sticky top-[97px] z-[9]"
    : "hidden shadow-sm @5xl:table-row-group sticky top-[46px] z-[9]";
  return (
    <thead className={classes} data-lookup-thead>
      <tr className=" leading-tight">
        {[...columns].map(([column]) => (
          <TableHeaderCell key={column} column={column} sortColumn={sortColumn} sortDirection={sortDirection} />
        ))}
      </tr>
    </thead>
  );
}

export type TableProps = {
  sortColumn: Column;
  sortDirection: SortDirection;
  contacts: Contact[];
  loading: boolean;
  noSearch?: boolean;
  limitVisibleRows?: number;
  noDataMessage?: string;
};

export function Table(props: TableProps) {
  const { sortColumn, sortDirection, contacts, loading, noSearch, limitVisibleRows, noDataMessage } = props;
  return (
    <table data-lookup-table className="divide-y flex w-full py-2 @5xl:p-0 @5xl:table @5xl:table-auto text-[14px]">
      <TableHeader noSearch={!!noSearch} sortColumn={sortColumn} sortDirection={sortDirection} />
      <tbody className="grid grid-cols-1 gap-2 mx-2 @5xl:mx-0 @3xl:grid-cols-2 @5xl:grid-cols-3 @5xl:table-row-group @5xl:divide-y @5xl:bg-white @5xl:gap-0">
        {!contacts.length && !loading ? (
          <NoData message={noDataMessage}/>
        ) : (
          contacts.slice(0, (limitVisibleRows || 100)).map((contact) => <Contact key={contact.email} {...contact} />)
        )}
      </tbody>
    </table>
  );
}

type NoDataProps = {
  message?: string;
}

export function NoData(props: NoDataProps) {
  const { message } = props;
  return (
    <tr>
      <AnimatePresence>
        <motion.td
          initial={{ y: 30, opacity: 0.6 }}
          animate={{ y: 0, opacity: 1, transition: { duration: 0.8 } }}
          exit={{ y: -20, opacity: 0.6 }}
          colSpan={8}
          className="p-8 text-center font-[18px] font-medium text-neutral rounded-b-sm"
        >
        {message ? message : "No matches found. Try adjusting your search."}
        </motion.td>
      </AnimatePresence>
    </tr>
  );
}

export type Contact = {
  email: string;
  primaryName: string;
  tags: string[];
  totalTo: number;
  totalFrom: number;
  lastTo: string;
  lastFrom: string;
  connections: string[];
  connectionStrength: number;
};

export type ContactProps = Contact & { key: string };

export function Contact(props: ContactProps) {
  const { getConnection } = useContext(ConnectionsContext);
  const fakeHeaderClasses = "font-medium text-neutral text-[12px] @5xl:hidden";

  const initials = props.primaryName?.match(/\b\w/g)?.slice(0, 2).join("") || "NN";
  return (
    <tr
      className="flex flex-wrap rounded-md border border-gray-200 card w-full overflow-hidden  @5xl:table-row cursor-pointer relative @5xl:p-0 group"
      data-contact-email={props.email}
      key={props.email}
    >
      <td className="flex flex-col w-full @5xl:w-auto p-2 @5xl:mr-7 overflow-hidden @5xl:px-2 @5xl:p-2 @5xl:table-cell @5xl:mr-0 @5xl:pl-4 group-last:rounded-bl-sm @5xl:bg-white group-hover:bg-neutral-100">
        <div className="flex @5xl:w-[140px] @7xl:w-[200px] items-center gap-2 font-semibold leading-normal text-gray-700">
          <AvatarV2 className="flex" initials={initials} size="sm" type="user" />
          <div className="overflow-hidden text-ellipsis break-all whitespace-nowrap font-medium  text-gray-800">
            {props.primaryName}
          </div>
        </div>
        <div className="@5xl:hidden overflow-hidden text-ellipsis break-all whitespace-nowrap font-semibold  text-gray-700">
          {props.email}
        </div>
      </td>
      <td className="flex absolute top-6 right-6 @3xl:top-4 @3xl:right-4 @5xl:px-2 @5xl:p-2 @5xl:text-center @5xl:w-[1px] @5xl:table-cell @5xl:static @5xl:bg-white group-hover:bg-neutral-100">
      <Connectivity value={props.connectionStrength} />
      </td>
      <td className="hidden p-2 @5xl:table-cell w-1/4 @5xl:px-4 @7xx:p-4 text-black whitespace-nowrap @5xl:w-[1px] @5xl:bg-white group-hover:bg-neutral-100">
        <div className={fakeHeaderClasses}>Email</div>
        <div className="@5xl:w-[120px] @7xl:w-[200px] overflow-hidden text-ellipsis break-all whitespace-nowrap">
          {props.email}
        </div>
      </td>
      <td className="flex flex-col p-2 @5xl:table-cell w-1/4 @5xl:px-2 @7xx:p-4 text-black @5xl:text-center whitespace-nowrap @5xl:w-[1px] @5xl:bg-white group-hover:bg-neutral-100">
        <div className={fakeHeaderClasses}>Last Incoming</div>
        <div>{formatDate(props.lastFrom)}</div>
      </td>
      <td className="flex flex-col p-2 @5xl:table-cell w-1/4 @5xl:px-2 @5xl:p-2 text-black @5xl:text-center whitespace-nowrap @5xl:w-[1px] @5xl:bg-white group-hover:bg-neutral-100">
        <div className={fakeHeaderClasses}>Last Outgoing</div>
        <div>{formatDate(props.lastTo)}</div>
      </td>
      <td className="flex flex-col p-2 w-1/4 @5xl:pl-4 @5xl:p-2 whitespace-nowrap @5xl:w-[1px] @5xl:table-cell @5xl:bg-white group-hover:bg-neutral-100 @5xl:text-right @5xl:!pr-8">
        <div className={fakeHeaderClasses}># Incoming</div>
        <div>
        { /*<img className="inline mr-1" src="/assets/icons/mail-incoming.svg" /> */ }
          {numberFormat.format(props.totalFrom)}
        </div>
      </td>{" "}
      <td className="flex flex-col p-2 w-1/4 @5xl:pl-4 @5xl:p-2 whitespace-nowrap @5xl:w-[1px] @5xl:table-cell @5xl:bg-white group-hover:bg-neutral-100 @5xl:text-right @5xl:!pr-8">
        <div className={fakeHeaderClasses}># Outgoing</div>
        <div>
        { /* <img className="inline mr-1" src="/assets/icons/mail-outgoing.svg" /> */ }
          {numberFormat.format(props.totalTo)}
        </div>
      </td>
      <td className="flex flex-col p-2 w-full @5xl:max-w-0 @5xl:pl-4 @5xl:p-2 @5xl:pr-0  @5xl:table-cell @5xl:w-full relative group-last:rounded-br-sm @5xl:bg-white group-hover:bg-neutral-100 overflow-hidden">
        <div className={fakeHeaderClasses}>Team Connections</div>
        <div className="flex gap-1 w-full overflow-hidden">
          <div className="flex justify-end w-[27px]">
            <div className="bg-gray-300 px-2 py-1 rounded-[2px] text-center text-xs text-neutral">
              {props.connections.length}
            </div>
          </div>
          <div className="flex gap-1 flex-nowrap">
            {props.connections.map((e) => {
              const conn = getConnection(e);
              if (!conn) return null;
              return <AvatarV2 type="user" src={conn.image} initials={conn.initials} size="xs" />;
            })}
          </div>
        </div>
        <div className="absolute top-0 right-0 h-full w-10 bg-gradient-to-l from-white to-transparent pointer-events-none"></div>
      </td>
    </tr>
  );
}
