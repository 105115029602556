import { ErrorResponse } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";

import { getEnv } from "../env/env";

class GQLError extends Error {
  constructor(
    message: string,
    options?: ErrorOptions,
    public name: string = "GQLError",
  ) {
    super(message, options);
  }
}

export const captureGraphqlErrors = ({
  graphQLErrors,
  operation,
  networkError,
}: Pick<ErrorResponse, "graphQLErrors" | "operation" | "networkError">) => {
  Sentry.withScope((scope) => {
    scope.setTransactionName(operation.operationName);
    scope.setFingerprint(["{{ default }}", operation.operationName]);

    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        Sentry.captureException(
          new GQLError(`GraphQLError - ${operation.operationName}`, { cause: error }, "GraphQLError"),
          {
            contexts: {
              "GraphQL Error": {
                message: error.message,
                path: error.path,
                urlPath: error.extensions?.path,
                statusCode: error.extensions?.statusCode,
                statusText: error.extensions?.statusText,
                // extract message and error code from the response body to not send any sensitive data to sentry
                responseBody: error.extensions?.responseBody &&
                  typeof error.extensions.responseBody === "object" && {
                    message:
                      "message" in error.extensions.responseBody ? error.extensions.responseBody.message : undefined,
                    error_code:
                      "error_code" in error.extensions.responseBody
                        ? error.extensions.responseBody.error_code
                        : undefined,
                  },
              },
            },
          },
        );
      });
    }

    if (networkError) {
      Sentry.captureException(
        new GQLError(
          `GraphQLNetworkError - ${operation.operationName}`,
          { cause: networkError },
          "GraphQLNetworkError",
        ),
      );
    }
  });
};

export const initSentry = () => {
  const dsn = getEnv("SENTRY_DSN");
  if (!dsn) return;

  Sentry.init({
    dsn,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", "staging.projectnazare.vc", "projectnazare.vc"],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0, // Capture 100% of the transactions

    // Disable session replays for now
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: getEnv("ENVIRONMENT"),
    normalizeDepth: 5,
  });
};
