// @ts-nocheck

import React from "react";

import { LegendItem, LegendLabel, LegendOrdinal } from "@visx/legend";
import { useParentSize } from "@visx/responsive";
import { scaleOrdinal } from "@visx/scale";

import { useBreakpoints } from "@/lib/hooks";
import { cn } from "@/lib/utils.ts";

import { Button } from "@/app/components";
import { XYDashboard } from "@/app/components/charts/xychart.tsx";
import { SelectV2 } from "@/app/components/forms/select-v2";
import { customColors } from "@/app/constants";
import { formatNumber } from "@/app/screens/opportunities/opportunity/components";

export const MetricsXYChart = ({
  data1,
  selectedMetric: [selectedMetric, setSelectedMetric],
  maximised: [maximised, setMaximised],
  metricsSelectList = [],
  legendScaleOptions = { domain: ["Manual-Entry"], range: [customColors.primary.DEFAULT] },
  navigateToMetricsExtraction,
}: {
  data1: { x: string; y: number; source?: string; prefix?: string; suffix?: string }[];
  selectedMetric: [string, (value: string | ((prevState: string) => string)) => void];
  maximised: [boolean, (value: boolean | ((prevState: boolean) => boolean)) => void];
  metricsSelectList?: { value: string; label: string }[];
  legendScaleOptions?: { domain: string[]; range: string[] };
  navigateToMetricsExtraction: () => void;
  companyId: string;
}) => {
  const legendScale = scaleOrdinal(legendScaleOptions);
  const { parentRef, height } = useParentSize({
    debounceTime: 250,
  });
  return (
    <div ref={parentRef} className={cn("mb-[60px] pb-7 thicc:mb-0", maximised ? "h-[77dvh]" : "h-[560px]")}>
      <Header
        legendScale={legendScale}
        metricsSelectList={metricsSelectList}
        maximised={maximised}
        setMaximised={setMaximised}
        selectedMetric={selectedMetric}
        setSelectedMetric={setSelectedMetric}
        navigateToMetricsExtraction={navigateToMetricsExtraction}
      />
      <XYDashboard
        height={height}
        data1={{ value: data1, label: selectedMetric }}
        maximised={maximised}
        leftAxisProps={{
          numTicks: selectedMetric === "Breakeven" ? 1 : maximised ? 16 : 8,
          tickFormat: (value) => {
            if (selectedMetric === "Breakeven") {
              return value === 1 ? "Yes" : "No";
            }
            if (selectedMetric === "Gross margin") return `${value}%`;
            return formatNumber(value);
          },
          hideZero: selectedMetric === "Breakeven",
          // axisLineClassName: "stroke-transparent",
          tickLineProps: { className: "stroke-transparent" },
        }}
      />
    </div>
  );
};

const Header = ({
  legendScale,
  metricsSelectList,
  setSelectedMetric,
  selectedMetric,
  setMaximised,
  maximised,
  navigateToMetricsExtraction,
}) => {
  const { isLaptop } = useBreakpoints();
  return (
    <>
      <div className={cn("flex items-center justify-between", maximised && "px-4 lg:px-6")}>
        <div className={"flex min-w-56 items-center space-x-4"}>
          <h5 className="text-base font-semibold lg:text-xl">Metrics</h5>
          {isLaptop && (
            <SelectV2
              buttonClassName={"h-8"}
              value={selectedMetric}
              options={metricsSelectList}
              onChange={(value) => setSelectedMetric(value)}
            />
          )}
        </div>
        <div className={"min-w-50 hidden lg:flex"}>
          <Legend scale={legendScale} glyphSize={8} labelFormat={String} />
        </div>
        <div className={"flex min-w-56 items-center justify-end space-x-4"}>
          {isLaptop && (
            <Button
              variant="outline"
              size="sm"
              onClick={() => setMaximised((prevState) => !prevState)}
              iconLeft={maximised ? "Minimise" : "Maximise"}
            >
              {maximised ? "Minimise" : "Maximise"}
            </Button>
          )}
          {!maximised && isLaptop && (
            <Button size="sm" iconLeft="Add" variant="outline" onClick={navigateToMetricsExtraction}>
              Add metrics
            </Button>
          )}
        </div>
      </div>
      {!isLaptop && (
        <p className={"flex justify-between px-4 pt-4"}>
          <SelectV2 value={selectedMetric} options={metricsSelectList} onChange={(value) => setSelectedMetric(value)} />
          <Button
            iconLeft={maximised ? "Minimise" : "Maximise"}
            size="sm"
            onClick={() => setMaximised((prevState) => !prevState)}
          >
            {maximised ? "Minimise" : "Maximise"}
          </Button>
        </p>
      )}
    </>
  );
};

const Legend = ({ scale, labelFormat, glyphSize }) => {
  return (
    <div className={cn("flex h-8 w-fit items-center rounded-xxs bg-gray-200 p-2")}>
      <p className={"mr-2 text-[14px] font-semibold text-gray-600"}>Source</p>
      <div className={"flex items-center justify-center"}>
        <LegendOrdinal scale={scale} labelFormat={labelFormat}>
          {(labels) =>
            labels.map((label, index) => (
              <LegendItem key={`legend-${label.text}`} className={"w-fit"}>
                <svg width={glyphSize} height={glyphSize}>
                  <circle fill={label.value} r={glyphSize / 2} cx={glyphSize / 2} cy={glyphSize / 2} />
                </svg>
                <LegendLabel align="left" margin={index === labels.length - 1 ? "0 0 0 4px" : "0 8px 0 4px"}>
                  <p className={"text-[12px] font-medium first-letter:uppercase"}>
                    {label.text.toLowerCase().replace(/-/g, " ")}
                  </p>
                </LegendLabel>
              </LegendItem>
            ))
          }
        </LegendOrdinal>
      </div>
    </div>
  );
};
