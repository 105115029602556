import { useCallback, useEffect, useRef, useState } from "react";

import { gql, useLazyQuery } from "@apollo/client";

import { FounderSearchResult, FounderSearchV2Result } from "./founder-search-v2.types";

const founderSearchQuery = gql`
  query FounderSearchV2($q: String!, $max: Int) {
    founderSearchV2(q: $q, max: $max) {
      nodes {
        id
        name
        source
        linkedinUrl
        image
      }
    }
  }
`;

export const founderSearchCharacterLimit = 3;

export const useFounderSearchV2 = () => {
  const debounceTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  const [results, setResults] = useState<FounderSearchResult[]>([]);

  const [query, { loading }] = useLazyQuery<FounderSearchV2Result>(founderSearchQuery, {
    context: {
      fetchOptions: {
        signal: abortControllerRef.current?.signal,
      },
    },
  });

  const search = useCallback(
    (q: string) => {
      if (q.length < founderSearchCharacterLimit) {
        // clear results when user deletes characters
        results.length && setResults([]);

        return;
      }

      // Cancel any pending timeout
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      // Cancel any in-flight request
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();

      debounceTimeoutRef.current = setTimeout(async () => {
        try {
          const { data } = await query({
            variables: { q, max: 30 },
          });

          setResults(data?.founderSearchV2?.nodes ?? []);
        } catch (error) {
          console.error(error);
          setResults([]);
        }
      }, 300);
    },
    [query],
  );

  useEffect(() => {
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  return {
    results,
    loading,
    search,
  };
};
