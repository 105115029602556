import { useCallback, useEffect, useRef, useState } from "react";

import { Checkbox, Icon } from "app/components";

import { Column, Filters, SortDirection, sortNodes, FlagFilterKey, columns } from "./columns";
import { LandingMetrics } from "./landingMetrics";
import { Settings } from "./settings";

type BaseQueryValue = {
  q: string;
};

type LookupHeaderProps = {
  lookedUp: boolean;
  loading: boolean;
  filters: Filters;
  sortColumn: Column;
  sortDirection: SortDirection;
  onEngagedClick: (event: React.MouseEvent<HTMLInputElement>) => void;
  onQueryChange: (value: string) => void;
  baseQueryValue: BaseQueryValue;
};

export function LookupHeader(props: LookupHeaderProps) {
  const { lookedUp, loading, filters, onEngagedClick, onQueryChange, sortColumn, sortDirection, baseQueryValue } =
    props;
  const [sortModalOpened, setSortModalOpened] = useState(false);
  const handleSortModalOpen = useCallback(() => setSortModalOpened(true), []);
  const handleSortModalClose = useCallback(() => setSortModalOpened(false), []);
  let inputWrapperClasses: string;
  let inputTitleClasses: string;
  let inputInnerWrapperClasses: string;
  let engagedClasses: string;
  let sortModalCtrlClasses: string;

  if (!lookedUp) {
    inputWrapperClasses = "flex flex-col items-center justify-center flex-grow";
    inputTitleClasses = "text-[32px] font-semibold mb-2 text-center";
    inputInnerWrapperClasses = "w-full max-w-[800px]";
    engagedClasses = "hidden";
    sortModalCtrlClasses = "hidden";
  } else {
    inputWrapperClasses = "sticky mb-1 p-0 @5xl:mb-0 @5xl:py-2 top-[50px] lg:top-[0px]  top-0 z-10 bg-[#f0f0f0]";
    inputTitleClasses = "hidden";
    inputInnerWrapperClasses = "w-full";
    engagedClasses = "mr-2";
    sortModalCtrlClasses = "p-2 w-[32px] @5xl:hidden";
  }

  const inputIconType = loading ? "Loader" : "Search";
  const labelStyle = loading ? "absolute top-[19px] left-[11px]" : "absolute top-[21px] left-[13px]";
  const sortProps = {
    onClose: handleSortModalClose,
    node: {
      title: "Sort By",
      nodes: sortNodes.map((e) => {
        if (e.column === sortColumn) {
          return { ...e, selectedText: columns.get(e.column)![`${sortDirection}Text`] };
        }
        return e;
      }),
    },
  };
  return (
    <>
      <div className={inputWrapperClasses}>
        <div className={inputTitleClasses}>Network. Alpha.</div>
        <div className={inputInnerWrapperClasses}>
          <div className="relative w-full">
            <label htmlFor="network-search-input" className={labelStyle}>
              <Icon type={inputIconType} width={20} />
            </label>
            <SearchInput baseQueryValue={baseQueryValue} lookedUp={lookedUp} onChange={onQueryChange} />

            {!lookedUp ? (
              <div className="">
                <LandingMetrics />
              </div>
            ) : null}
            <div className="absolute right-2 top-0 flex flex-wrap items-center">
              <div className={engagedClasses}>
                <Checkbox
                  checked={filters.get(FlagFilterKey.EngagedOnly)?.value}
                  onClick={onEngagedClick}
                  label={"Engaged Only"}
                  containerClassName={"my-2"}
                  labelClassName={"text-black font-medium"}
                />
              </div>

              <div className={sortModalCtrlClasses} onClick={handleSortModalOpen}>
                <Icon type="Sort" className="size-full" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {lookedUp && sortModalOpened ? <Settings {...sortProps} /> : null}
    </>
  );
}

export type SearchInputProps = {
  baseQueryValue: BaseQueryValue;
  onChange: (value: string) => void;
  lookedUp: boolean;
};

export function SearchInput(props: SearchInputProps) {
  const { onChange, lookedUp, baseQueryValue } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const debounceTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (inputRef.current !== null) {
      inputRef.current.value = baseQueryValue.q;
    }
  }, [baseQueryValue]);

  useEffect(() => {
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      onChange(event.target.value);
    }, 100);
  }, []);

  let inputClasses: string;
  if (!lookedUp) {
    inputClasses = "my-2 w-full rounded-md border border-neutral-300 px-3 py-2 pl-10 mb-0";
  } else {
    inputClasses = "my-2 w-full rounded-md border border-neutral-300 px-3 py-2 pl-10 mb-0 pr-[170px]";
  }
  return (
    <input
      ref={inputRef}
      id="network-search-input"
      type="text"
      autoComplete="off"
      autoFocus
      onChange={handleChange}
      className={inputClasses}
      defaultValue={baseQueryValue.q}
      placeholder="Search by name, email address or domain."
    />
  );
}
