import { Icon } from "@/app/components";
import { Suggestions } from "@/app/screens/modal/copilot/components/suggestions.tsx";

export function EmptyConversation() {
  return (
    <div className={"flex flex-1 flex-col items-center justify-center gap-x-1.5 p-2"}>
      <div className={"flex flex-row items-center justify-center gap-x-1.5 p-2"}>
        <Icon type={"ChatBubble"} className="size-3 animate-[bounce_2s_ease-in-out_infinite] text-gray-500" />
        <span className="mb-1 font-light text-gray-500">Start a conversation...</span>
      </div>
      <Suggestions />
    </div>
  );
}
