import { cn } from "@/lib/utils.ts";

interface ProgressBarProps {
  /**
   * The progress value (0-100)
   */
  progress?: number | null;

  /**
   * Additional class names
   */
  className?: string;
}

export const ProgressBar = ({ progress = null, className }: ProgressBarProps) => {
  const isIndeterminate = progress == null;

  return (
    <div className={cn("h-1 w-full rounded-full bg-neutral-200", className)}>
      <div
        className={cn(
          "h-1 rounded-full bg-neutral-600 transition-all duration-300 ease-out",
          isIndeterminate && "animate-progress-indeterminate w-1/2",
        )}
        style={!isIndeterminate ? { width: `${progress}%` } : undefined}
      />
    </div>
  );
};
