export type FounderSearchResult = {
  id: string;
  name: string;
  source: "dealroom" | "lg";
  linkedinUrl: string | null;
  image: string | null;
};

export type FounderSearchV2Result = {
  founderSearchV2: {
    nodes: FounderSearchResult[];
  };
};

export type FounderChangeData = FounderSearchResult | string | null;

export const isDealroomFounder = (
  founder: FounderChangeData,
): founder is FounderSearchResult & { source: "dealroom" } => {
  return founder !== null && typeof founder === "object" && founder.source === "dealroom";
};

export const isLgFounder = (founder: FounderChangeData): founder is FounderSearchResult & { source: "lg" } => {
  return founder !== null && typeof founder === "object" && founder.source === "lg";
};
