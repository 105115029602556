// @ts-nocheck

export type DashboardSectionData<TData> = {
  totalCount: number;
  items: TData[];
  loading: boolean;
  loadingMore: boolean;
  loadingLess: boolean;
  pageInfo?: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor?: string | null;
    endCursor?: string | null;
  } | null;
  loadMore?: () => void;
  loadLess?: () => void;
};

export type VirtualQueryData = {
  loadingMore: boolean;
  loadingLess: boolean;
  loadMore?: (variables: unknown, cursor?: string | null) => void;
  loadLess?: () => void;
};

import { GetDashboardCompaniesQuery } from "@/gql/graphql";

export type NotificationPayload = {
  email: {
    id: string;
    from: string;
    source?: null; // todo ??
    no_opps?: number; // todo ??
    subject?: string;
  };
  title: string;
};

export enum NotificationStatus {
  DECLINED = "DECLINED",
  LOADING = "LOADING",
  SEEN = "SEEN",
  COMPLETED = "COMPLETED",
  UNSEEN = "UNSEEN",
}

export enum SentimentRequestStatus {
  COMPLETED = "COMPLETED",
  DECLINED = "DECLINED",
  CREATED = "CREATED",
}

type ProcessUpdateEmailSuccessData = {
  data: {
    card_id: string;
    company_id: string;
    company_name: string;
    company_image: string;
    company_website_url: string;
  };
  error: "None";
};

type ProcessUpdateEmailFailureData = {
  data: string;
  error: string;
};

type ProcessMetricsEmailSuccessData = {
  data: {
    body: {
      metrics: any;
    };
    attachments: {
      metrics: any;
    };
  };
  error: null;
};

type ProcessMetricsEmailFailureData = {
  data: any;
  error: string;
};

export type EmailMetadata = {
  PRE_PARSER: {
    data: {
      name?: string;
      website?: string;
    };
    error: string | null;
  };
  process_update_email: ProcessUpdateEmailSuccessData | ProcessUpdateEmailFailureData;
  process_metrics_extraction_update: ProcessMetricsEmailSuccessData | ProcessMetricsEmailFailureData;
};

export type DashboardCompany = NonNullable<GetDashboardCompaniesQuery["lgCompanies"]>["edges"][number]["node"];

export const isProcessUpdateEmailSuccessData = (
  data?: EmailMetadata["process_update_email"],
): data is ProcessUpdateEmailSuccessData => {
  if (!data) {
    return false;
  }

  if (typeof data.data === "string") {
    return false;
  }

  return "company_id" in data.data;
};

export type UserDataForImage = {
  firstName: string;
  lastName: string;
  avatar?: string | null;
  initials?: string | null;
};
