import { useHotkeys } from "react-hotkeys-hook";

import { AddNewResponsiveModal } from "@/app/screens/modal";
import { Search } from "@/app/screens/modal";
import modalStore from "@/app/stores/modal.store";

import globalModalStore from "../screens/modal/global-modal.store";

export const HotKeysProvider = ({ children }) => {
  /*useHotkeys("mod+c", () => {
    const path = window.location.pathname;
    const match = path.match(/\/company\/([^/]+)/);
    globalModalStore.toggleSlideOver({
      slideOverType: "CompanyCopilot",
      navState: {
        copilotState: { route: "conversations" },
        companyMetadata: { companyId: match?.[1] || "" },
      },
    });
  });*/
  useHotkeys("mod+k", () => modalStore.open(Search));
  useHotkeys("ctrl+n", () => modalStore.open(AddNewResponsiveModal));
  useHotkeys("mod+[", () => globalModalStore.toggleNavBar());
  useHotkeys("esc", modalStore.close);

  return <div>{children}</div>;
};
