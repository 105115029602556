import toast from "react-hot-toast";

import { getEnv } from "@/app/env/env.ts";
import { companyCopilotStore, type MessageType, type ModelSettings } from "@/app/screens/modal/copilot";
import { authStore } from "@/app/stores/auth.store.tsx";

type FnArgs = { text: string; loading: boolean; done: boolean };
type CompletionArgs = {
  companyId: string;
  updateFn: (fnArgs: Partial<FnArgs> | ((fnArgs: Partial<FnArgs>) => Partial<FnArgs>)) => void;
  messages: Array<Partial<MessageType>>;
  systemMessage: string;
  modelSettings: Partial<ModelSettings>;
};

const path = "copilot/chat_completion_streaming";

export const completion = async ({ companyId, messages, systemMessage, updateFn, modelSettings }: CompletionArgs) => {
  if (!companyId) {
    toast.error("This works for companies only - please navigate to a company page");
    return;
  }
  const req = await fetch(`${getEnv("NAZARE_API_DOMAIN")}/${path}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authStore.accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      company_id: companyId,
      model_settings: modelSettings,
      messages,
      system_message: systemMessage,
      variables: {},
    }),
  });
  const reader = req.body?.getReader();
  let text = "",
    latencyText = "";
  const lastTokens: string[] = [];

  const readChunk = async () => {
    reader?.read().then(({ value, done }) => {
      if (done) {
        if (text.includes("FOLLOW_UP_QUESTIONS_JSON")) {
          const jsonString = text.split("FOLLOW_UP_QUESTIONS_JSON")[1];
          try {
            console.log({ jsonString });
            const json = JSON.parse(jsonString);
            companyCopilotStore.setState({ followUpQuestions: json, isLoading: false });
          } catch {
            companyCopilotStore.setState({ followUpQuestions: [], isLoading: false });
          }
        }
        updateFn((prevState: Partial<FnArgs>) => ({ ...prevState, done: true, loading: false }));
        return;
      }
      const chunkString = new TextDecoder().decode(value);
      text += chunkString;
      lastTokens.push(chunkString);
      if (lastTokens.length > 5) {
        latencyText += lastTokens.shift() || "";
      }
      if (!text.includes("FOLLOW_UP_QUESTIONS_JSON")) {
        updateFn({ text: latencyText, loading: true, done: false });
      }

      readChunk();
    });
  };
  await readChunk();
};
