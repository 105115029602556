import { useState } from "react";

import { AnimatePresence, motion } from "framer-motion";

import { cn } from "@/lib/utils.ts";

import { GetCompanyEmailQuery } from "@/app/service/company-email.gql.ts";

import { NetworkSignalType } from "../../opportunities/opportunity/components/network-signals/network-signal-utils.ts";

import { CompanyFromEmailCard } from "./add-company-via-email-card.tsx";
import { AddCompanyForm } from "./add-company-via-email-form.tsx";
import { CompanyRawData } from "./add-company-via-email.types.ts";

type CompanyCardData = {
  id: string;
  name: string;
  tagline?: string;
  nazareUrl?: string;
  image?: string;
  websiteUrl?: string;
  dealroomUrl?: string;
  dealroomCompanyId?: number;
  status: CompanyRawData["status"];
};

const mapCompanyCardData = (company: CompanyRawData): CompanyCardData => {
  return {
    id: company.id,
    name: company.name ?? company.lgCompany?.name ?? company.dealroomCompany?.name,
    tagline: company.description ?? company.lgCompany?.tagline ?? company.dealroomCompany?.tagline,
    nazareUrl: company.lgCompany ? `/company/${company.lgCompany.id}` : undefined,
    image: company.lgCompany?.image ?? company.dealroomCompany?.image ?? undefined,
    websiteUrl: company.url ?? company.lgCompany?.websiteUrl ?? company.dealroomCompany?.websiteUrl,
    dealroomUrl: company.dealroomCompany?.url ?? undefined,
    dealroomCompanyId: company.dealroomCompany?.id ?? undefined,
    status: company.status,
  };
};

const useCompaniesFromEmails = (data?: GetCompanyEmailQuery) => {
  const opportunityEmail = data?.nzrNotification?.nzrOpportunityEmail;
  const companiesFromEmails = opportunityEmail?.nzrOpportunityEmailCandidatesByEmailId.nodes ?? [];

  const alreadyInNazare: CompanyCardData[] = companiesFromEmails
    .filter((company) => !!company.lgCompany && !company.status)
    .map(mapCompanyCardData);
  const completed: CompanyCardData[] = companiesFromEmails
    .filter((company) => company.status === "COMPLETED" && !!company.lgCompany)
    .map(mapCompanyCardData);
  const declined: CompanyCardData[] = companiesFromEmails
    .filter((company) => company.status === "DECLINED")
    .map(mapCompanyCardData);
  const notInNazare: CompanyCardData[] = companiesFromEmails
    .filter((company) => !company.lgCompany && !company.status)
    .map(mapCompanyCardData);

  return {
    alreadyInNazare,
    completed,
    declined,
    notInNazare,
  };
};

export const AddOpportunitiesViaEmailList = ({
  data,
  selectedSource,
  className,
  onSelectCompany,
}: {
  data?: GetCompanyEmailQuery;
  selectedSource: {
    name: string;
    type: NetworkSignalType;
  } | null;
  className?: string;
  onSelectCompany?: (company: CompanyCardData) => void;
}) => {
  const { alreadyInNazare, completed, declined, notInNazare } = useCompaniesFromEmails(data);
  const [candidateCompany, setCandidateCompany] = useState<{
    id: string;
    dealroomCompanyId?: number;
    name: string;
    url?: string;
  } | null>(null);

  return (
    <div className={cn("h-full space-y-3", className)}>
      {alreadyInNazare.map((company) => (
        <CompanyFromEmailCard
          {...company}
          key={company.id ?? company.name}
          addActionLabel="Add anyway"
          enableDecline={true}
          onAdd={() =>
            setCandidateCompany({
              id: company.id,
              name: company.name,
              url: company.websiteUrl,
              dealroomCompanyId: company.dealroomCompanyId,
            })
          }
          onSelect={() => onSelectCompany?.(company)}
        />
      ))}
      {notInNazare.map((company) => (
        <CompanyFromEmailCard
          {...company}
          key={company.id ?? company.name}
          onAdd={() =>
            setCandidateCompany({
              id: company.id,
              name: company.name,
              url: company.websiteUrl,
              dealroomCompanyId: company.dealroomCompanyId,
            })
          }
          enableDecline={true}
          onSelect={() => onSelectCompany?.(company)}
        />
      ))}
      {completed.map((company) => (
        <CompanyFromEmailCard
          key={company.id ?? company.name}
          {...company}
          hideNazareTag={true}
          onSelect={() => onSelectCompany?.(company)}
        />
      ))}
      {declined.map((company) => (
        <CompanyFromEmailCard
          key={company.id ?? company.name}
          {...company}
          hideNazareTag={true}
          onSelect={() => onSelectCompany?.(company)}
        />
      ))}
      <AnimatePresence>
        {candidateCompany !== null && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={cn("fixed inset-0 z-10 flex size-full justify-center overflow-auto bg-white !mt-0")}
          >
            {candidateCompany && (
              <AddCompanyForm
                candidateCompany={candidateCompany}
                onClose={() => setCandidateCompany(null)}
                selectedSource={selectedSource}
              />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
