import { NzrSentimentForm } from "@/gql/types";

import { Button } from "@/app/components";
import { opportunityModal } from "@/app/screens/opportunities/actions.ts";
import opportunitiesStore from "@/app/screens/opportunities/opportunities.store.ts";

type SentimentData = Partial<Omit<NzrSentimentForm, "nodeId">>;

const FounderSentimentButton = ({ sentimentData }: { sentimentData: SentimentData[] }) => {
  const handleClick = () => {
    opportunityModal({
      id: `sentiment_details`,
      platform: window.innerWidth <= 976 ? "mobile" : "web",
      action: "Sentiment",
    });

    opportunitiesStore.setModalState("sentiment_details", {
      form: sentimentData?.find((form) => form?.responses?.length > 0),
    });
  };

  return (
    <Button
      onClick={handleClick}
      iconLeft={"MoveUpRight"}
      text={"View Sentiment"}
      variant="outline"
      className={"hidden xl:flex"}
      size="xs"
    />
  );
};

export const FounderSentiment = ({ sentimentData, isEmpty }: { sentimentData: SentimentData[]; isEmpty: boolean }) => {
  return !isEmpty ? <FounderSentimentButton sentimentData={sentimentData} /> : null;
};
