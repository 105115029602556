import { Controller } from "react-hook-form";

import { Button, ProgressSpinner } from "@/app/components";
import { FormField } from "@/app/components/forms/form-field.tsx";
import { InputV2 } from "@/app/components/forms/input-v2.tsx";
import { validateURL } from "@/app/misc/helpers.ts";
import { AddCompanyStep } from "@/app/screens/add-company/add-company-step.tsx";
import { CompanyDataSchema } from "@/app/screens/add-company/company-data-step/company-data-form.ts";
import { CompanyLabelBadge } from "@/app/screens/add-company/company-data-step/company-label-badge.tsx";
import { useCompanyData } from "@/app/screens/add-company/company-data-step/use-company-data.tsx";
import { CompanySearchAutocomplete } from "@/app/screens/opportunities/components/company-search-autocomplete.tsx";

import { CompanyFoundersFormData } from "./company-founders-form-data.tsx";
import { IdentifiedSources } from "./company-identified-sources.tsx";

export type AddCompanyStepProps = {
  onSubmit: (data: CompanyDataSchema) => void;
  onCancel: () => void;
  loading?: boolean;
} & ReturnType<typeof useCompanyData>;

export const CompanyData = ({ onSubmit, onCancel, ...data }: AddCompanyStepProps) => {
  const {
    selectedDealroomCompany,
    scrapeData,
    handleSelectCompanyByName,
    form,
    scrapeWebsite,
    dealroomDataLoading,
    founders,
    identifiedDealroomCompany,
  } = data;

  const { handleSubmit, control, formState } = form;

  const handleScrapeWebsite = (url?: string | null) => {
    if (!url) {
      return;
    }

    // we don't want to launch it if dealroom company is selected
    if (selectedDealroomCompany?.websiteUrl) {
      return;
    }

    if (scrapeData?.websiteUrl !== url && validateURL(url)) {
      scrapeWebsite(url);
    }
  };

  const dealroomCompanyAlreadyExists = !!selectedDealroomCompany?.srcCompany?.company;

  return (
    <AddCompanyStep onSubmit={handleSubmit(onSubmit)}>
      <AddCompanyStep.Header
        title="Add a new company"
        subtitle="Enter a company name, website and/or founder details to continue. More info can be added later."
      />
      <AddCompanyStep.Body>
        {identifiedDealroomCompany &&
          selectedDealroomCompany?.id !== identifiedDealroomCompany.id &&
          !identifiedDealroomCompany.srcCompany?.company && (
            <IdentifiedSources
              identifiedDealroomCompany={identifiedDealroomCompany}
              onSelectSource={() => {
                handleSelectCompanyByName({
                  id: identifiedDealroomCompany.id,
                  name: identifiedDealroomCompany.name,
                  website: identifiedDealroomCompany.websiteUrl,
                  source: "dealroom",
                });
              }}
            />
          )}

        <Controller
          control={control}
          name="name"
          render={({ field, fieldState }) => (
            <FormField
              label="Company name"
              error={
                fieldState.error?.message ??
                (dealroomCompanyAlreadyExists ? "This company already exists in the system" : undefined)
              }
              badge={
                <>
                  <CompanyLabelBadge
                    className="ml-2"
                    visible={Boolean(
                      !!(selectedDealroomCompany?.name ?? scrapeData?.name) &&
                        (selectedDealroomCompany?.name ?? scrapeData?.name) === field.value,
                    )}
                    text="identified"
                    infoText={
                      selectedDealroomCompany?.name
                        ? "Nazare identified the name from Dealroom"
                        : "Nazare gathered relevant information from the website"
                    }
                  />
                </>
              }
            >
              <CompanySearchAutocomplete
                value={field.value}
                selectedCompany={selectedDealroomCompany ?? scrapeData}
                onChange={handleSelectCompanyByName}
                placeholder="Enter company name"
              />
            </FormField>
          )}
        />

        <Controller
          name="websiteUrl"
          control={control}
          render={({ field, fieldState }) => (
            <FormField
              label="Website"
              error={fieldState.error?.message}
              badge={
                <CompanyLabelBadge
                  className="ml-2"
                  visible={Boolean(
                    !!(selectedDealroomCompany?.websiteUrl ?? scrapeData?.websiteUrl) &&
                      (selectedDealroomCompany?.websiteUrl ?? scrapeData?.websiteUrl) === field.value,
                  )}
                  text="identified"
                  infoText={
                    selectedDealroomCompany?.websiteUrl
                      ? "Nazare identified the name from Dealroom"
                      : "Nazare gathered relevant information from the website"
                  }
                />
              }
            >
              <InputV2
                value={field.value ?? ""}
                onChange={(e) => field.onChange(e.target.value)}
                onBlur={() => handleScrapeWebsite(field.value)}
                disabled={field.disabled}
                placeholder="https://example.com"
              />
            </FormField>
          )}
        />
        <FormField
          className="flex shrink flex-col overflow-hidden"
          label="Founders"
          badge={
            <>
              {dealroomDataLoading && <ProgressSpinner className="ml-1 size-4" />}
              <CompanyLabelBadge
                className="ml-2"
                visible={!!selectedDealroomCompany?.founders?.nodes.length}
                infoText="Nazare identified founders from Dealroom"
              />
            </>
          }
        >
          <CompanyFoundersFormData control={control} fieldArray={founders} />
        </FormField>
      </AddCompanyStep.Body>
      <AddCompanyStep.Actions>
        <Button
          text={"Continue"}
          type="submit"
          variant={"gradient"}
          disabled={!formState.isValid || dealroomCompanyAlreadyExists}
          className="w-full px-10 lg:order-last lg:w-auto"
        />
        <Button text={"Cancel"} type="button" variant="outline" onClick={onCancel} className="w-full px-10 lg:w-auto" />
      </AddCompanyStep.Actions>
    </AddCompanyStep>
  );
};
