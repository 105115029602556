import Sheet from "react-modal-sheet";

import { useBreakpoints } from "@/lib/hooks";

interface ModalHeaderWebProps {
  containerClassName?: string;
  title: string;
  subtitle?: string;
}

export const ModalHeader = ({ containerClassName, title, subtitle }: ModalHeaderWebProps) => {
  const { isBigTablet } = useBreakpoints();
  return isBigTablet ? (
    <div className={`flex flex-col lg:mb-6 xl:mb-10 ${containerClassName}`}>
      <span className="text-xl font-semibold text-gray-900">{title}</span>
      <span className={"text-sm font-medium text-gray-900"}>{subtitle}</span>
    </div>
  ) : (
    <Sheet.Header className={`my-4 flex flex-col items-center justify-center ${containerClassName}`}>
      <span className="text-lg font-semibold">{title}</span>
      <span className="text-center text-sm font-medium text-neutral-600">{subtitle}</span>
    </Sheet.Header>
  );
};
