export const linkedinProfileUrlRegex =
  /^(?:https?:\/\/)?(?:www\.|(?:[a-z]{2}\.)?)?(?:linkedin\.com)?\/?(in|pub)\/([\w\p{L}\-%]+)(?:\/)?(?:\?[^#]*)?(?:#.*)?$/u;

export function isValidLinkedinProfileURL(url: string): boolean {
  return linkedinProfileUrlRegex.test(url);
}

export function getLinkedinProfileId(url: string): string | null {
  const match = url.match(linkedinProfileUrlRegex);
  return match ? match[2] : null;
}
