import { useCallback, useEffect, useRef } from "react";

import { Combobox, ComboboxOption, ComboboxOptions } from "@headlessui/react";
import Highlighter from "react-highlight-words";

import { cn } from "@/lib/utils";

import { AvatarV2, Icon } from "@/app/components";
import { Company, START_QUERY_AFTER } from "@/app/hooks";

interface SearchResultProps {
  searchTerm: string;
  className?: string;
  containerClassName?: string;
  loading?: boolean;
  results: any;
  handleSelect?: (value: Company) => void;
  handleReset?: () => void;
  showEmptyState?: boolean;
}

export const SearchBox = ({
  results,
  searchTerm,
  handleReset,
  className = "",
  containerClassName = "",
  loading,
  handleSelect,
  showEmptyState = true,
}: SearchResultProps) => {
  const comboboxRef = useRef<HTMLDivElement>(null);

  const onSelect = useCallback(
    (value) => {
      if (handleSelect) handleSelect(value);
    },
    [handleSelect],
  );

  const isEmpty = searchTerm.length > START_QUERY_AFTER && results.length === 0 && !loading;

  useEffect(() => {
    function handleClickOutside(event) {
      if (comboboxRef?.current && !comboboxRef?.current?.contains(event.target)) handleReset?.();
    }

    document.addEventListener("mousedown", handleClickOutside, true);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [comboboxRef, handleReset]);

  if (isEmpty && showEmptyState) {
    return (
      <div className={`flex size-fit items-center justify-center py-16 ${containerClassName}`}>
        <div className={"flex flex-col space-y-3"}>
          <Icon type="LoopClouds" className="mx-auto size-16 text-gray-400" />
          <p className="text-2xl font-medium text-gray-400">No results found...</p>
        </div>
      </div>
    );
  }

  return (
    <div ref={comboboxRef} className={`overflow-hidden ${containerClassName}`}>
      <Combobox onChange={onSelect}>
        {results.length > 0 && (
          <ComboboxOptions
            static
            className={cn(`no-scrollbar h-full max-h-[300px] transform-gpu scroll-py-3 overflow-y-auto p-3`, className)}
          >
            {results.map((item) => (
              <ComboboxOption
                key={item.id}
                value={item}
                data-cy="search-result"
                className={({ active, selected }) =>
                  cn("mt-2 flex cursor-pointer select-none rounded-xs p-3", (active || selected) && "bg-gray-100")
                }
              >
                {({ active, selected }) => (
                  <div className="flex w-full items-center">
                    <div className={cn("flex size-10 flex-none items-center justify-center")}>
                      <AvatarV2 type={"company"} src={item.image} />
                    </div>
                    <div className="ml-4">
                      <p className={cn("text-left text-sm font-medium", active ? "text-gray-900" : "text-gray-700")}>
                        <Highlighter
                          textToHighlight={item.name}
                          searchWords={[searchTerm]}
                          autoEscape={true}
                          highlightClassName="font-bold bg-transparent"
                        />
                      </p>
                      <p className={cn("text-left text-xs font-medium", active ? "text-gray-900" : "text-gray-700")}>
                        {item.websiteUrl}
                      </p>
                    </div>
                    {(active || selected) && (
                      <Icon type="ArrowRight" width={24} height={24} className="ml-auto text-neutral-400" />
                    )}
                  </div>
                )}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </Combobox>
    </div>
  );
};
