import React from "react";

import { useFormContext } from "react-hook-form";

import { cn, debounce } from "@/lib/utils.ts";

import { BaseToggle, TextArea } from "@/app/components";
import { FormControl, FormField, FormItem, FormMessage } from "@/app/components/charts/form-field.tsx";
import { CompanyFlagToggle } from "@/app/screens/opportunities/opportunity/components/toggle-flags";

import { FormSelect } from "../input";

type RatingSelectProps = {
  setSelected: (value: number) => void;
  selected: number;
  properties: {
    labels: {
      left: string;
      right: string;
    };
  };
};

const RatingSelect = React.forwardRef<HTMLDivElement, RatingSelectProps>(
  ({ setSelected, selected, properties }, ref) => {
    return (
      <div className="flex flex-col" ref={ref}>
        {properties.labels && (
          <div className="mb-2 flex justify-between">
            <span className="text-xs text-neutral md:text-sm">{properties.labels.left}</span>
            <span className="text-xs text-neutral md:text-sm">{properties.labels.right}</span>
          </div>
        )}
        <div className="flex w-full justify-between gap-x-2">
          {[...Array(11).keys()].map((idx) => (
            <span
              key={idx}
              onClick={() => setSelected(idx)}
              data-cy="rating-select"
              className={cn(
                "flex size-8 cursor-pointer items-center justify-center rounded-sm bg-neutral-100 text-center text-sm font-semibold hover:bg-neutral-200 hover:text-black lg:size-14 lg:text-xl",
                {
                  "bg-neutral-800 text-white hover:bg-neutral-800 hover:text-white": selected == idx,
                },
              )}
            >
              {String(idx)}
            </span>
          ))}
        </div>
      </div>
    );
  },
);

RatingSelect.displayName = "RatingSelect";

// fixme
export const SentimentInputFormField = ({ field, visible, clearError }) => {
  const { control, getValues, setValue, watch } = useFormContext();
  watch([`company_metrics`]);

  return (
    <div className={`mb-6 flex flex-col space-y-4 ${visible ? "" : "hidden"}`}>
      <div className="flex">
        <p className="flex text-sm font-semibold leading-5 tracking-tight lg:whitespace-normal lg:text-base">
          {field.title}
          {field.validations?.required && <span className={"ml-1 text-red"}>*</span>}
        </p>
      </div>
      <div>
        {(field.type === "dropdown" || field.type === "multiple_choice") && (
          <FormField
            control={control}
            render={({ field: { onChange, onBlur, value, ref } }) => {
              const isMultiSelect = field.type === "multiple_choice" && field.properties.allow_multiple_selection;
              const allOptions = field.properties.choices.map((choice) => ({
                label: choice.label,
                value: choice.label,
              }));

              return (
                <FormItem
                  ref={(elem) => {
                    ref({
                      ...elem,
                      focus: () => elem?.scrollIntoView({ block: "start" }),
                    });
                  }}
                >
                  <FormControl onBlur={onBlur}>
                    <FormSelect
                      options={allOptions}
                      multiSelect={isMultiSelect}
                      defaultOptions={(value || []).map((val) => ({
                        value: val,
                        label: val,
                      }))}
                      onChange={(value = []) => {
                        onChange(value.map((value) => value.value));
                        if (clearError) {
                          clearError(field.field_id);
                        }
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
            name={field.field_id}
          />
        )}
        {(field.type === "rating" || field.type === "opinion_scale") && (
          <FormField
            control={control}
            render={({ field: { onChange, value, ref } }) => {
              return (
                <FormItem
                  ref={(elem) => {
                    ref({
                      ...elem,
                      focus: () => elem?.scrollIntoView({ block: "start" }),
                    });
                  }}
                >
                  <FormControl>
                    <RatingSelect
                      properties={field.properties}
                      setSelected={(val) => {
                        onChange(val);
                        if (clearError) {
                          clearError(field.field_id);
                        }
                      }}
                      selected={value}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
            name={field.field_id}
          />
        )}
        {(field.type === "short_text" || field.type === "text") && (
          <FormField
            name={field.field_id}
            control={control}
            render={({ field: props }) => (
              <FormItem>
                <FormControl>
                  <TextArea
                    {...props}
                    value={props.value || ""}
                    onChange={(text) => {
                      props.onChange({ target: { value: text } });
                      if (clearError) {
                        clearError(field.field_id);
                      }
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        {field.type === "toggle_flag" && (
          <div className="flex flex-col gap-y-4 divide-y divide-neutral-200">
            <CompanyFlagToggle
              company={field.company}
              flag={field.flag}
              showDetails
              styles={{
                container: "mx-auto h-fit grid grid-cols-2 justify-center items-center text-left font-semibold",
                toggleContainer: "flex my-4 flex-col items-end justify-between",
                detailsStyle: "text-xs text-black font-medium",
                labelStyle: "w-[110%]",
              }}
              label={field.subTitle}
              size="lg"
            />

            {/* TODO: This shouldn't have been a field type - we should not inject "server fields" on client  */}
            {/* TODO: extract this completely outside!!!  */}
            <div className={"flex h-16 w-full items-center justify-between pt-4"}>
              <span className={cn("mb-1.5 flex items-center text-left text-lg font-bold text-neutral-900")}>
                Would you like to add company metrics after submission?
              </span>
              <BaseToggle
                value={getValues?.("company_metrics")}
                size="lg"
                onChange={() => {
                  const showMetrics = getValues?.("company_metrics");
                  setValue("company_metrics", !showMetrics);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
