import { memo, useState } from "react";

import toast from "react-hot-toast";

import { Button } from "@/app/components";
import { LoadingOverLay } from "@/app/components/loading/loading-overlay.tsx";
import { ChipV2 } from "@/app/components/v2/chip-v2.tsx";
import { ConfirmModal, RequestSentimentModal } from "@/app/screens/modal";
import { FounderSearchResult } from "@/app/screens/opportunities/components/founder-search-autocomplete.tsx";
import {
  FounderEdit,
  FounderFormData,
} from "@/app/screens/opportunities/opportunity/components/founders/founder-edit.tsx";
import { Section, SectionTitle } from "@/app/screens/opportunities/opportunity/components/section.tsx";
import { useUnlinkFounderMutation, useUpsertFounderMutation } from "@/app/service/founders.gql.ts";
import modalStore from "@/app/stores/modal.store.tsx";

import { useOpportunityContext } from "../../opportunity-context.tsx";
import { EmptySection } from "../empty-section.tsx";

import { Founder } from "./founder.tsx";
import { FounderFromTeam } from "./founders.types.ts";
import { useFounders } from "./use-founders.ts";

export const Founders = memo(function Founders({ companyId }: { companyId: string }) {
  const { company } = useOpportunityContext();
  const { data: founders, loading: fetchLoading } = useFounders(companyId);
  const [unlinkFounder, { loading: unlinkLoading }] = useUnlinkFounderMutation({
    refetchQueries: ["GetCompanyFounders"],
  });
  const [upsertFounder, { loading: upsertLoading }] = useUpsertFounderMutation({
    refetchQueries: ["GetCompanyFounders"],
  });

  const loading = fetchLoading || unlinkLoading || upsertLoading;

  const [mode, setMode] = useState<"view" | "edit" | "create">("view");

  const handleAddSentiment = (id: string) => {
    if (!company) {
      return;
    }

    modalStore.open(RequestSentimentModal, {
      props: {
        company,
        founderId: id,
        type: "founderSentiment",
      },
    });
  };

  const handleRemove = async (founder: FounderFromTeam) => {
    modalStore.open(ConfirmModal, {
      props: {
        title: "Are you sure?",
        subTitle: `By confirming, you will unlink the founder from this company`,
        handleConfirm: async () => {
          await unlinkFounder({ variables: { unlinkFounderDataInput: { companyId, founderId: founder.id } } });
          toast.success("Succesfully unlinked");
          modalStore.close();
        },
      },
    });
  };

  const handleCreateFounder = async (data: FounderFormData, selectedFounder?: FounderSearchResult | null) => {
    const selectedFounderData = selectedFounder
      ? {
          [selectedFounder?.source === "lg" ? "lgFounder" : "dealroomFounder"]: selectedFounder?.id,
        }
      : {};

    const founderData = {
      company: companyId,
      details: {
        name: data.name,
        url: data.url ?? undefined,
      },
      ...selectedFounderData,
    };

    await upsertFounder({
      variables: { upsertFounderDataInput: founderData },
    });
    toast.success("Successfully linked founder");
    setMode("view");
  };

  const isIncomplete = company?.status === "LEGACY" ? false : !loading && founders.length === 0;

  return (
    <Section id={"founders"} className="@container/founders">
      <SectionTitle
        title="Founders"
        className="h-8"
        actions={
          mode === "view" && (
            <div className="flex items-center gap-2">
              {isIncomplete && <ChipV2 label="incomplete" color="orange" />}
              {!!founders.length && (
                <Button
                  size="sm"
                  variant="outline"
                  text="Add founder"
                  onClick={() => {
                    setMode("create");
                  }}
                />
              )}
            </div>
          )
        }
      />
      {loading && <LoadingOverLay className="rounded-sm" />}
      <div className="grid gap-4 @3xl/founders:auto-rows-fr @3xl/founders:grid-cols-2">
        {mode === "create" && (
          <FounderEdit
            className="@3xl/founders:row-span-2"
            onSubmit={handleCreateFounder}
            onClose={() => setMode("view")}
          />
        )}
        {!founders.length && mode === "view" ? (
          <EmptySection
            className="col-span-2 @3xl/founders:grid-cols-3"
            text={"No founders"}
            Action={
              <Button
                size="sm"
                variant="outline"
                text="Add founder"
                className="mt-4"
                onClick={() => {
                  setMode("create");
                }}
              />
            }
          />
        ) : (
          founders.map((founder) => (
            <Founder
              key={founder.id}
              founder={founder}
              onRemove={() => handleRemove(founder)}
              addSentiment={() => handleAddSentiment(founder.id)}
            />
          ))
        )}
      </div>
    </Section>
  );
});
