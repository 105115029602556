import { PropsWithChildren } from "react";

import { cn } from "@/lib/utils";

type NavbarV2Props = PropsWithChildren<{
  className?: string;
}>;

export const NavbarV2 = ({ className, children }: NavbarV2Props) => {
  return (
    <nav
      className={cn(
        "flex h-12 w-full items-center justify-between bg-white px-3 py-2",
        "md:px-8 lg:px-10 2xl:px-30",
        className,
      )}
    >
      {children}
    </nav>
  );
};
