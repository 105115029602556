import { BreadCrumbs } from "@/app/components";
import { gql, useQuery } from "@apollo/client";
import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { authStore } from "@/app/stores/auth.store.tsx";
import { UserRoleType } from "@/gql/graphql.ts";
import NetworkMessagesCountsBarChart from "./contactMessagesChart";
import {NetworkContact as NetworkContactV2 } from "../network-v2/contact.tsx";
import { observer } from "mobx-react";
import { ConnectionsContext, useConnectionsDict } from "../network-v2/connectionsDict.tsx";

const networkContactQuery = gql`
  query GetNetworkContact($contactEmail: String) {
    contact: getNetworkContact(contactEmail: $contactEmail) {
      email
      internalContacts
      sent
      received
      lastSent
      lastReceived
      connStrength
      tags
      names
      domain
      type
    }
  }
`;

const networkContactConnectionsQuery = gql`
  query GetNetworkContactConnections($contactEmail: String) {
    connections: getNetworkContactConnectionsAggregated(contactEmail: $contactEmail) {
      nodes {
        id
        timestamp
        fromEmail
        toEmails
      }
    }
  }
`;

const networkContactMessagesCountsQuery = gql`
  query GetNetworkContactMessagesCounts($contactEmail: String) {
    counts: getNetworkContactMessagesCounts(contactEmail: $contactEmail) {
      nodes {
        sent
        received
        month
      }
    }
  }
`;

type MessageCountChartProps = {
  contactEmail: string;
};

export function MessagesCountChart(props: MessageCountChartProps) {
  const { contactEmail } = props;
  const { data, loading } = useQuery(networkContactMessagesCountsQuery, { variables: { contactEmail: contactEmail } });
  return <NetworkMessagesCountsBarChart contactEmail={contactEmail} data={data?.counts?.nodes} />;
}

const numberFormat = Intl.NumberFormat("en-GB");


// TODO: get rid of observer after auth/user refactor
export const NetworkContact = observer(function NetworkContact() {
  const { userRoles: myRoles } = authStore;
  const connectionsDict = useConnectionsDict();

  // not loaded yet
  if (myRoles === null) return null;
  if ((myRoles as any)?.includes("USER_FEAT_NETWORK_NEXT")) {
    return <ConnectionsContext.Provider value={connectionsDict}>
       <NetworkContactV2 />
    </ConnectionsContext.Provider>;
  }

  if (myRoles?.includes(UserRoleType.UserRoleNetwork)) {
    return <NetworkContactV1 />;
  }
  
  return null;
});

export function NetworkContactV1() {
  const params = useParams();
  const contactEmail = params.email;
  const { data, loading } = useQuery(networkContactQuery, { variables: { contactEmail } });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const goBack = useCallback(() => {
    if (searchParams.get("fr") === "l") {
      navigate("/network");
      return;
    }
    navigate(
      `/network?q=${searchParams.get("fq")}&sort=${searchParams.get("fsort")}&filter=${searchParams.get("ffilter")}`
    );
  }, []);
  
  if (!contactEmail) return null;
  if (!data?.contact) return null;
  const connectivityIcons = ["low", "medium", "high"];
  const connectivityLabel = ["Weak Connection", "Moderate Connection", "Strong Connection"];
  const connectivityType = Math.min(2, Math.floor((data.contact.connStrength || 0) * 3));

  return (
    <div className="flex @container flex-col font-barlow w-full max-w-[1600px] p-4 lg:p-8 max-w-[100%] h-width gap-4">
      <div className="flex flex-col gap-4 px-4 lg:px-0">
        <BreadCrumbs title="Network" action={goBack} />
      </div>
      <div className="rounded-md bg-white p-4 relative">
        <div className="flex items-center absolute top-4 right-3 z-1 font-medium text-black font-[16px]">
          <img className="inline mr-2" src={`/assets/icons/connectivity-${connectivityIcons[connectivityType]}.svg`} />{" "}
          <span className="hidden @xl:inline"> {connectivityLabel[connectivityType]}</span>
        </div>
        <div className="overflow-hidden text-ellipsis whitespace-nowrap text-[24px] font-bold w-[calc(100%-60px)] @xl:w-[calc(100%-200px)] mt-[-6px]">
          {data.contact.email}
        </div>
        <div className="grid gap-2 gap-x-5 grid-cols-2 @xl:grid-cols-4 @5xl:grid-cols-5 @5xl:grid-cols-[auto_auto_auto_auto_1fr]">
          <div className="">
            <div className="font-medium text-black text-[16px]">Incoming</div>
            <div className="font-[14px]">
              <img className="inline mr-1" src="/assets/icons/mail-incoming.svg" />
              {numberFormat.format(+data.contact.sent)}
            </div>
          </div>

          <div className="">
            <div className="font-medium text-black text-[16px]">Last Incoming</div>
            <div className="font-[14px]">
              <DateChip noClasses value={data.contact.lastSent} />
            </div>
          </div>

          <div className="">
            <div className="font-medium text-black text-[16px]">Outgoing</div>
            <div className="font-[14px]">
              <img className="inline mr-1" src="/assets/icons/mail-outgoing.svg" />
              {numberFormat.format(+data.contact.received)}
            </div>
          </div>
          <div className="">
            <div className="font-medium text-black text-[16px]">Last Outgoing</div>
            <div className="font-[14px]">
              <DateChip noClasses value={data.contact.lastReceived} />
            </div>
          </div>

          <div className="col-span-2 @xl:col-span-4 @5xl:col-span-1">
            <div className="font-medium text-black text-[16px]">Known Names</div>
            <div className="font-[14px] overflow-hidden text-ellipsis line-clamp-3">
              {data.contact.names?.join(", ")}
            </div>
          </div>
        </div>
      </div>

      <div className="rounded-md bg-white p-4 w-full aspect-[2/1]">
        <div className="p-2 text-[20px] text-black font-medium">Timeline</div>
        <MessagesCountChart contactEmail={contactEmail} />
      </div>

      <div className="flex flex-col gap-4 lg:items-start @7xl:flex-row-reverse lg:gap-6">
        <div className="rounded-md bg-white p-4 w-full @7xl:w-4/12">
          <div className="p-2 text-[20px]">
            <div className="text-black font-medium">
              Connections <span className="text-neutral font-normal">{data.contact.internalContacts.length}</span>
            </div>
          </div>
          <NetworkContactConnectionsCompact connections={data.contact.internalContacts} />
        </div>

        <div className="rounded-md bg-white p-4 w-full @7xl:w-8/12">
          <NetworkContactConnections
            email={data.contact.email}
            totalVolume={+data.contact.sent + +data.contact.received}
          />
        </div>
      </div>
    </div>
  );
}

export function NetworkContactConnectionsCompact(props) {
  const data = [...props.connections].sort((a, b) => {
    if (a.count > b.count) return -1;
    if (b.count > a.count) return 1;
    return 0;
  });
  return (
    <table className="divide-y full-w">
      <thead className="sticky top-[50px] lg:top-0 z-9">
        <tr className="text-[14px] leading-none bg-white">
          <th colSpan={2} className="p-2 text-right w-[1%]">
            Volume
          </th>
          <th className="p-2 w-[1%] whitespace-nowrap">Most Recent</th>
        </tr>
      </thead>
      <tbody className="divide-y">
        {data.map((connection) => {
          return (
            <tr className="text-[14px] leading-none text-[14px]">
              <td className="py-1 p-2 font-medium text-black bg-white whiespace-nowrap overflow-hidden text-ellipsis line-clamp-1 ">
                {connection.internal_email}
              </td>
              <td className="py-1 p-2 text-neutral w-[1%] bg-white text-right whitespace-nowrap">
                {numberFormat.format(connection.count)}
              </td>
              <td className="py-1 p-2 text-neutral bg-white whitespace-nowrap text-right">
                <DateChip noClasses value={`${connection.last_communication}Z`} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

type NetworkContactConnectionsProps = {
  email: string;
  totalVolume: number;
};

export function NetworkContactConnections(props: NetworkContactConnectionsProps) {
  const { email, totalVolume } = props;
  const { data, loading } = useQuery(networkContactConnectionsQuery, { variables: { contactEmail: email } });

  const connections = data?.connections?.nodes;

  const totalVolumeText = loading
    ? ""
    : totalVolume >= 500
      ? `500 out of ${numberFormat.format(totalVolume)}`
      : `${numberFormat.format(connections?.length)}`;

  return (
    <div>
      <div className="p-2 text-[20px]">
        <div className="text-black font-medium">
          Recent Communication <span className="text-neutral font-normal">{totalVolumeText}</span>
        </div>
      </div>

      <div>
        <div className="sticky top-[50px] lg:top-0 flex flex-row text-[14px]  text-neutral font-medium bg-white z-10">
          <div className="p-2 w-1/2 overflow-hidden text-ellipsis line-clamp-2 ">
            <span className="text-black">{email}</span>{" "}
            <span className="whiespace-nowrap inline-block">sent to us</span>
          </div>
          <div className="p-2 w-1/2 text-right overflow-hidden text-ellipsis line-clamp-2 ">
            <span className="whiespace-nowrap inline-block">We sent to</span>{" "}
            <span className="text-black whiespace-nowrap">{email}</span>
          </div>
        </div>
        {loading ? (
          <div className="animate-pulse-original">
            <div className="space-y-3">
              <div className="h-4 bg-neutral-300 rounded col-span-2"></div>
              <div className="h-4 bg-neutral-300 rounded col-span-1"></div>
            </div>
          </div>
        ) : connections?.length ? (
          <NetworkContactMessages messages={connections as NetworkContactMessage[]} contactEmail={email} />
        ) : (
          <div>No communication found</div>
        )}
      </div>
    </div>
  );
}

enum MessageDirection {
  Incoming = "Incoming",
  Outgoing = "Outgoing",
}

type NetworkContactMessagesProps = {
  messages: NetworkContactMessage[];
  contactEmail: string;
};

export function NetworkContactMessages(props: NetworkContactMessagesProps) {
  const { messages, contactEmail } = props;
  let lastDirection: MessageDirection;
  return (
    <div>
      {messages?.map((message: NetworkContactMessage) => {
        const direction = message.fromEmail === contactEmail ? MessageDirection.Incoming : MessageDirection.Outgoing;
        const shift = lastDirection && lastDirection !== direction;
        lastDirection = direction;
        return (
          <NetworkContactMessage
            message={message as NetworkContactMessage}
            contactEmail={contactEmail}
            direction={direction}
            shift={shift}
          />
        );
      })}
    </div>
  );
}

type NetworkContactMessage = {
  id: string;
  timestamp: string;
  fromEmail: string;
  toEmails: string[];
};

type NetworkContactMessageProps = {
  contactEmail: string;
  message: NetworkContactMessage;
  direction: MessageDirection;
  shift: boolean;
};

export function NetworkContactMessage(props: NetworkContactMessageProps) {
  const { message, contactEmail, direction, shift } = props;
  const { id, timestamp, fromEmail, toEmails } = message;
  let extraClasses = "";
  if (direction === MessageDirection.Outgoing) {
    extraClasses += "@3xl:ml-auto ";
  }

  if (shift) {
    extraClasses += "@3xl:mt-[-28px] ";
  }

  const to: string[] = [];
  const cc: string[] = [];
  const bcc: string[] = [];

  for (let i = 0; i < toEmails.length; i = i + 2) {
    if (toEmails[i] == "to") {
      to.push(toEmails[i + 1]);
    } else if (toEmails[i] == "cc") {
      cc.push(toEmails[i + 1]);
    } else if (toEmails[i] === "bcc") {
      bcc.push(toEmails[i + 1]);
    }
  }

  return (
    <div key={id} className={`py-1 rounded-sm bg-gray-100 relative m-2 @3xl:w-[48%] ${extraClasses}`}>
      <div className="text-[14px]">
        <div className="absolute top-1 right-2 text-neutral font-[500]">
          {dateTimeFormat.format(new Date(timestamp))}
        </div>
        <NetworkContactMessageAddress contactEmail={contactEmail} direction="From" addresses={[fromEmail]} />
        <NetworkContactMessageAddress contactEmail={contactEmail} direction="To" addresses={to} />
        <NetworkContactMessageAddress contactEmail={contactEmail} direction="Cc" addresses={cc} />
        <NetworkContactMessageAddress contactEmail={contactEmail} direction="Bcc" addresses={bcc} />
      </div>
    </div>
  );
}

type NetworkContactMessageAddressProps = {
  direction: string;
  contactEmail: string;
  addresses: string[];
};

export function NetworkContactMessageAddress(props: NetworkContactMessageAddressProps) {
  const { direction, addresses, contactEmail } = props;
  if (!addresses?.length) return null;
  let classes = "text-left overflow-hidden text-ellipsis font-black";
  classes += direction === "From" ? " whitespace-nowrap font-bold w-[calc(100%-190px)]" : " flex-1 line-clamp-3";
  return (
    <div className="flex flex-row font-medium font-[14px]">
      <div className="w-[50px] text-right mr-1 text-neutral">{direction}</div>
      <div className={classes}>{addresses.join(", ")}</div>
    </div>

  );
}

const dateFormat = new Intl.DateTimeFormat("en-GB", {
  day: "numeric",
  month: "short",
  year: "numeric",
});

const dateTimeFormat = new Intl.DateTimeFormat("en-GB", {
  day: "numeric",
  month: "short",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
});

export function DateChip(props) {
  const value = props.value ? dateFormat.format(new Date(props.value)) : '—';
  if (props.noClasses) return value;
  const extraClasses = props.inline ? "w-auto" : "";
  return (
    <span
      className={`text-neutral w-[100px] inline-block font-[500] select-none justify-self-center rounded-sm bg-neutral-100 px-2 py-1 text-center text-xs text-neutral ${extraClasses}`}
    >
      {value}
    </span>
  );
}
