import { observer } from "mobx-react";

import { companyCopilotStore } from "@/app/screens/modal/copilot";
import { TextAreaCopilot, Toolbar } from "@/app/screens/modal/copilot/components";

export const Footer = observer(() => {
  const { route } = companyCopilotStore.state;
  if (route !== "messages") return null;

  return (
    <div className="sticky bottom-0 my-2 border-t bg-white pb-2 shadow-mobile-xxs">
      <Toolbar />
      <TextAreaCopilot />
    </div>
  );
});
