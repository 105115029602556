// @ts-nocheck

import { createContext, ReactNode, useContext, useState } from "react";

import { LgFundName } from "@/gql/graphql";

import { useSearchCompany } from "./kanban-board-header/kanban-board-quick-search-hooks";
import { Sort } from "./kanban-board-types";
import { useKanbanColumns, useKanbanCompanies, useSelectedViewType } from "./kanban-hooks";

export interface KanbanBoardContextType {
  funds: LgFundName[];
  selectedFund: {
    name: LgFundName | null;
    columns: Array<{
      id: string;
      name: string;
      fund: LgFundName | null;
    }>;
  };
  setSelectedFund: (fund: LgFundName) => void;

  selectedViewType: string | null;
  setSelectedViewType: (view: string | null) => void;

  searchQuery: string;
  updateSearchQuery: (query: string) => void;

  companies: ReturnType<typeof useKanbanCompanies>["companies"];
  loading: boolean;

  sort: Sort;
  setSort: (sort: Sort) => void;
}

const KanbanBoardContext = createContext<KanbanBoardContextType | undefined>(undefined);

export const useKanbanBoardContext = () => {
  const context = useContext(KanbanBoardContext);
  if (!context) {
    throw new Error("useKanbanBoardContext must be used within a KanbanBoardProvider");
  }
  return context;
};

interface KanbanBoardProviderProps {
  children: ReactNode;
}

export const KanbanBoardProvider = ({ children }: KanbanBoardProviderProps) => {
  const { selectedViewType, selectViewType } = useSelectedViewType();
  const { searchQuery, updateSearchQuery } = useSearchCompany();
  const [sort, setSort] = useState<Sort>({ field: "excitementScore", direction: "desc" });

  const { funds, selectedFund, setSelectedFund } = useKanbanColumns();
  const { companies, loading } = useKanbanCompanies(
    selectedFund.columns.map((column) => column.id),
    selectedViewType,
    searchQuery,
  );

  const value = {
    funds,
    selectedFund,
    setSelectedFund,
    companies,
    loading,
    selectedViewType,
    setSelectedViewType: selectViewType,
    searchQuery,
    updateSearchQuery,
    sort,
    setSort,
  };

  return <KanbanBoardContext.Provider value={value}>{children}</KanbanBoardContext.Provider>;
};
