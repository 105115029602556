import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";

export function LandingMetrics() {
  // HACK:TODO: data shoudl be coming from db in future
  const timestamp = +new Date("2024-11-13T20:42:00");
  const engIpmPair = useMemo(() => getIpmPair(5.5), []);
  const contactsIpmPair = useMemo(() => getIpmPair(0.05 / 60), []);
  const companiesIpmPair = useMemo(() => getIpmPair(0.001 / 60), []);
  const engInitialValue = 13206923;
  const contactsInitialValue = 93399;
  const companiesInitialValue = 47938;

  const labelClasses = "text-[14px] font-medium text-gray-600";
  const wrapperClasses = "flex flex-row gap-4 justify-center items-center";
  const itemClasses = "flex flex-col-reverse @3xl:flex-row items-center @3xl:gap-2";
  const daysPair = useMemo(() => getDaysPair(timestamp), [timestamp]);
  return (
    <div className={wrapperClasses}>
      <div className={itemClasses}>
        <div className={labelClasses}>Contacts</div>
        <AnimatedCounter
          initialValue={contactsInitialValue}
          ipmPair={contactsIpmPair}
          timestamp={timestamp}
          daysPair={daysPair}
        />
      </div>

      <div className={itemClasses}>
        <div className={labelClasses}>Companies</div>
        <AnimatedCounter
          initialValue={companiesInitialValue}
          ipmPair={companiesIpmPair}
          timestamp={timestamp}
          daysPair={daysPair}
        />
      </div>

      <div className={itemClasses}>
        <div className={labelClasses}>Connections</div>
        <AnimatedCounter
          initialValue={engInitialValue}
          ipmPair={engIpmPair}
          timestamp={timestamp}
          daysPair={daysPair}
        />
      </div>
    </div>
  );
}

type AnimatedCounterProps = {
  initialValue: number;
  ipmPair: IpmPair;
  timestamp: number;
  daysPair: DaysPair;
};

function AnimatedCounter(props: AnimatedCounterProps) {
  const { initialValue, ipmPair, daysPair, timestamp } = props;
  const minuteEntropy = useMemo(() => createMinuteEntropy(), []);
  const [count, setCount] = useState(shiftValue(initialValue, ipmPair, daysPair, timestamp, minuteEntropy));
  const minIntervalMs = 500;
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    function counterRoutine() {
      setCount(() => shiftValue(initialValue, ipmPair, daysPair, timestamp, minuteEntropy));
      timeoutId = setTimeout(counterRoutine, minIntervalMs);
    }

    counterRoutine();
    return () => clearTimeout(timeoutId);
  }, []);

  const formattedCount = Math.round(count).toLocaleString();
  const digits = formattedCount.split("");

  return (
    <div className="flex font-semibold text-[18px] text-gray-900">
      {digits.map((digit, index) => (
        <AnimatePresence mode="popLayout" key={index}>
          <motion.span
            key={`${digit}-${index}`} // Unique key for each character
            initial={{ y: -30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 20, opacity: 0 }}
            transition={{ duration: 0.8 }}
            style={{
              display: "inline-block",
              width: digit === "," ? "0.5ch" : "1ch", // Narrow width for commas
              textAlign: "center",
            }}
          >
            {digit}
          </motion.span>
        </AnimatePresence>
      ))}
    </div>
  );
}

const IS_WORKING_HOURS_FLAG: boolean = !!localStorage.NEVERSLEEP;
function shiftValue(
  initial: number,
  ipmPair: IpmPair,
  daysPair: DaysPair,
  timestamp: number,
  entropy: (sec: number) => number
): number {
  const now = new Date();
  const offset = getValueOffset(ipmPair, daysPair, timestamp);
  const currentHour = now.getUTCHours();
  const isWorkingHours = IS_WORKING_HOURS_FLAG || (currentHour >= 9 && currentHour < 17);
  const seconds = now.getSeconds();
  const entropyVal = entropy(seconds);
  const ipm = isWorkingHours ? ipmPair.workingIpm : ipmPair.nonWorkingIpm;
  const shift = entropyVal * ipm;
  return initial + offset + shift;
}

type DaysPair = {
  workingDays: number;
  nonWorkingDays: number;
};

function getDaysPair(timestamp: number): DaysPair {
  const start = new Date(timestamp);
  const now = new Date();

  let workingDays = 0;
  let nonWorkingDays = 0;

  let date = new Date(start);
  for (;;) {
    date.setDate(date.getDate() + 1);
    if (date > now) break;
    const day = date.getUTCDay();
    if (day === 0 || day === 6) {
      nonWorkingDays++;
    } else {
      workingDays++;
    }
  }

  return { workingDays, nonWorkingDays };
}

function getValueOffset(ipmPair: IpmPair, daysPair: DaysPair, timestamp: number): number {
  const now = new Date();
  // NOTE: values from the future !?
  // still return initial
  // should we throw?
  if (+now <= timestamp) {
    return 0;
  }

  const { workingIpm, nonWorkingIpm } = ipmPair;
  const { workingDays, nonWorkingDays } = daysPair;
  const minutesPerDay = 60 * 24;
  const workingHourStarts = 9;
  const workingHourEnds = 17;

  let offset = workingIpm * minutesPerDay * workingDays + nonWorkingIpm * minutesPerDay * nonWorkingDays;

  //  hours from start of the DAY UTC non working
  const currentHour = now.getUTCHours();
  offset += Array.from({ length: currentHour }, (_, hour) =>
    IS_WORKING_HOURS_FLAG || (hour >= workingHourStarts && hour < workingHourEnds)
      ? 60 * workingIpm
      : 60 * nonWorkingIpm
  ).reduce((acc, c) => acc + c, 0);

  // minutes
  const currentMinutes = now.getUTCMinutes();
  offset +=
    IS_WORKING_HOURS_FLAG || (currentHour >= workingHourStarts && currentHour < workingHourEnds)
      ? currentMinutes * workingIpm
      : currentMinutes * nonWorkingIpm;

  return offset;
}

type IpmPair = {
  workingIpm: number;
  nonWorkingIpm: number;
};

function getIpmPair(itm: number): IpmPair {
  const workingFactor = 0.9; // amount of work performed during working hours
  const notWorkingFactor = 1 - workingFactor;
  const totalDays = 31;
  const workingDays = 22;
  const minPerHour = 60;
  const hourPerDay = 24;
  const workingHours = 8;
  const minPerMonth = totalDays * hourPerDay * minPerHour;
  const workingMinPerMonth = workingDays * workingHours * minPerHour;
  const nonWorkingMinPerMonth = minPerMonth - workingMinPerMonth;
  const totalIncrementPerMonth = itm * minPerMonth;
  return {
    workingIpm: (totalIncrementPerMonth * workingFactor) / workingMinPerMonth,
    nonWorkingIpm: (totalIncrementPerMonth * notWorkingFactor) / nonWorkingMinPerMonth,
  };
}

function genEntropyPercentages() {
  const totalSeconds = 60;
  const totalPercentage = 100;
  const rawValues = Array.from({ length: totalSeconds }, (_, i) => {
    let base = Math.abs(Math.sin(i * 0.15) * 0.5 + 0.5);
    if (i % 6 === 0) base *= 2.4;
    else if (i % 4 === 0) base *= 1.7;
    else if (i % 3 === 0) base *= 0.2;
    return base;
  });
  const sumRawValues = rawValues.reduce((sum, value) => sum + value, 0);
  const percentages = rawValues.map((value) => ((value / sumRawValues) * totalPercentage) / 100);
  return percentages;
}

function createMinuteEntropy() {
  const cumulativePercentages: number[] = [];
  genEntropyPercentages().reduce((sum, percentage, i) => {
    sum += percentage;
    cumulativePercentages[i] = sum;
    return sum;
  }, 0);

  return function (second: number) {
    if (second < 0 || second >= 60) {
      // OOPSIE, this should never happen
      console.trace("MINUTE ENTROPY OUT OF BOUNDS");
      setTimeout(() => {
        throw new Error("MINUTE ENTROPY OUT OF BOUNDS");
      }, 10);
    }
    return cumulativePercentages[second];
  };
}
