import { forwardRef } from "react";

import { Textarea, InputProps } from "@headlessui/react";

import { cn } from "@/lib/utils.ts";

import { InputSize } from "./types";

export interface InputV2Props extends Omit<InputProps, "size"> {
  containerClassName?: string;
  className?: string;
  size?: InputSize;
}

export const TextAreaV2 = forwardRef<HTMLTextAreaElement | HTMLInputElement, InputV2Props>(
  function InputV2(props, ref) {
    const { className, containerClassName, value, size = "md", ...inputProps } = props;

    return (
      <div className={cn("flex w-full items-center", containerClassName)}>
        <Textarea
          ref={ref}
          {...inputProps}
          value={value}
          className={cn(
            "flex-1 overflow-hidden rounded-sm border border-gray-300 bg-white px-4 py-3 font-medium text-gray-900 placeholder:text-gray-500 hover:bg-gray-300 disabled:bg-gray-300",
            "transition-colors duration-300 ease-in",
            size === "xs" && "h-14 text-xs",
            size === "sm" && "h-14 text-sm",
            size === "md" && "h-18 text-sm",
            className,
          )}
        />
      </div>
    );
  },
);
