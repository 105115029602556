import { UserRoleType } from "@/gql/graphql";

import { authStore } from "@/app/stores/auth.store";

import { KanbanBoard } from "./kanban-board/kanban-board";
import { KanbanBoardSubheader } from "./kanban-board/kanban-board-subheader";
import { KanbanBoardComingSoon } from "./kanban-board-coming-soon";
import { KanbanBoardProvider } from "./kanban-board-context";
import { KanbanBoardHeader } from "./kanban-board-header/kanban-board-header";

export const KanbanBoardPage = () => {
  const { userRoles: myRoles } = authStore;

  if (myRoles && !myRoles.includes(UserRoleType.UserRoleKanbanBoard)) {
    return <KanbanBoardComingSoon />;
  }

  return (
    <KanbanBoardProvider>
      <div className="flex h-screen w-full flex-col overflow-hidden">
        <KanbanBoardHeader />
        <hr />
        <KanbanBoardSubheader />
        <KanbanBoard />
      </div>
    </KanbanBoardProvider>
  );
};
