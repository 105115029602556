import { useQuery } from "@apollo/client";
import { getCompanyConnections } from "@/app/screens/network-v2/smallGroup.gql";
import { ConnectionsContext, useConnectionsDict } from "@/app/screens/network-v2/connectionsDict";
import { authStore } from "@/app/stores/auth.store";
import { observer } from "mobx-react";
import { Popover, AvatarV2, DropdownV2 } from "@/app/components";
import { getInitials } from "@/lib/utils";
import { useContext } from "react";
import { Connectivity } from "./connectivity";
import { useAddAssigneeMutation } from "../opportunities/opportunity/components/assign-users/use-add-assignees";

export const NetworkAssigneesSuggest = observer(function NetworkDashboard(props) {
  const { userRoles: myRoles } = authStore;
  const connectionsDict = useConnectionsDict();

  // not loaded yet
  if (myRoles === null) return null;
  if ((myRoles as any)?.includes("USER_FEAT_NETWORK_NEXT")) {
    return (
      <ConnectionsContext.Provider value={connectionsDict}>
        <NetworkAssigneesSuggestInner {...props} />
      </ConnectionsContext.Provider>
    );
  }
  return null;
});

export function NetworkAssigneesSuggestInner(props) {
  const { companyId, assignees } = props;
  const { data } = useQuery(getCompanyConnections, { variables: { companyId } });

  const { getConnection } = useContext(ConnectionsContext);
  const connections = data?.connections?.nodes?.filter((e) => !assignees.find((a) => a.id === e.memberId));

  const { onAddAssignees, loading } = useAddAssigneeMutation();
  if (!connections?.length) return null;

  return (
    <div className="rounded-xs bg-neutral-100 p-2 py-1 flex flex-col mt-[-21px]">
      <div className="text-neutral text-[14px]">Suggested by Network</div>
      <div className="flex flex-wrap items-center gap-1">
        {connections
          .map(({ memberId, internalEmail, connectionStrength }) => {
            const connection = getConnection(internalEmail);
            if (!connection) return null;
            return (
              <DropdownV2 key={memberId} className="group relative">
                <DropdownV2.Button className={"h-6 p-0 hover:bg-transparent"}>
                  <Popover
                    closeOnClick={true}
                    placement="top-start"
                    childrenClassName="mt-2 p-2.5"
                    childrenContent={
                      <div className="flex flex-row gap-2 items-center justify-center">
                        <div className="text-sm font-medium">{connection.name}</div>{" "}
                        <Connectivity value={connectionStrength} inline tiny />
                      </div>
                    }
                  >
                    <AvatarV2
                      key={memberId}
                      type="user"
                      size="xs"
                      initials={getInitials(`${connection.name}`)}
                      src={connection.image}
                    />
                  </Popover>

                  <DropdownV2.Items className="rounded-xxs border" anchor={"bottom start"}>
                    <DropdownV2.Item className={"py-2"} onClick={() => onAddAssignees({ companyId, members: [memberId] })}>
                      Add
                    </DropdownV2.Item>
                  </DropdownV2.Items>
                </DropdownV2.Button>
              </DropdownV2>
            );
          })
          .filter(Boolean)}
      </div>
    </div>
  );
}
