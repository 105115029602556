import { observer } from "mobx-react";

import { cn } from "@/lib/utils.ts";

import { BreadCrumbs, ImageWithErrorFallback } from "@/app/components";
import { Routes } from "@/app/constants";
import { useMetricsHeader } from "@/app/screens/metrics-extraction/sections/company-header/use-metrics-header.tsx";

import { useMetricsExtraction } from "../../use-metrics-extraction";

import { LinkAnotherCompanyButton } from "./link-another-company-btn";

type EmailData = ReturnType<typeof useMetricsExtraction>["email"];

const getRelinkEnabledStatus = (email: EmailData): boolean => {
  if (!email) {
    return false;
  }

  if (!email?.metadata?.process_update_email?.data) {
    return false;
  }

  const isCompanyIdentified =
    typeof email.metadata.process_update_email.data === "object" &&
    "company_id" in email.metadata.process_update_email.data;

  const hasLinkedAttachmentsOrComments =
    !!email.lgCompanyCommentsBySourceId?.totalCount || !!email.lgCompanyFilesBySourceId?.totalCount;

  return !isCompanyIdentified || hasLinkedAttachmentsOrComments;
};

export const MetricsPageHeader = observer(
  ({
    containerClassName,
    email,
    notificationId,
  }: {
    containerClassName: string;
    email: EmailData;
    notificationId: string;
  }) => {
    const { navigate, selectedCompanyId, name, image, loading } = useMetricsHeader();

    const isRelinkAvailable = getRelinkEnabledStatus(email);

    return (
      <>
        <div className={"flex items-center justify-between"}>
          <div className={"flex gap-x-4"}>
            <BreadCrumbs
              title={"Back"}
              action={() => {
                navigate(-1);
              }}
            />
          </div>
          {email && isRelinkAvailable && (
            <LinkAnotherCompanyButton emailId={email.id} notificationId={notificationId} />
          )}
        </div>
        <div className={cn("group relative flex items-center", containerClassName)}>
          <ImageWithErrorFallback
            alt={name || "Unknown Company"}
            src={image}
            loading={loading}
            iconClassName={"rounded-sm p-2"}
            className={cn(
              "flex shrink-0 rounded-sm border border-gray-300 lg:size-12 2xl:size-14",
              selectedCompanyId === "new" ? "" : "cursor-pointer hover:opacity-80",
            )}
            onClick={() => {
              if (selectedCompanyId === "new") return;
              navigate(`${Routes.company}/${selectedCompanyId}`);
            }}
          />

          <div className={"ml-4 flex flex-col justify-center"}>
            <span
              className={cn(
                "truncate font-semibold lg:text-[18px] 2xl:text-[22px]",
                selectedCompanyId === "new" ? "hover:opacity-100" : "cursor-pointer hover:opacity-80",
              )}
              onClick={() => {
                if (selectedCompanyId === "new") return;
                navigate(`${Routes.company}/${selectedCompanyId}`);
              }}
            >
              {name || "Unknown Company"}
            </span>
          </div>
        </div>
      </>
    );
  },
);
