// @ts-nocheck

import { observer } from "mobx-react";

import { companyCopilotStore } from "@/app/screens/modal/copilot";

export const VariablesTooltip = () => {
  const {
    state: { systemPrompt },
  } = companyCopilotStore;

  return (
    <div className={"flex flex-col"}>
      <span className={"font-semibold"}>{"Coming soon 🚀🔜\n"}</span>
      <p>For now you have access to all sentiment, comments, company data, metrics & dealroom data.</p>
    </div>
  );
};

export const WebSearchTooltip = () => {
  return (
    <div className={"flex flex-col"}>
      <span className={"font-semibold"}>{"Coming soon 🚀🔜\n"}</span>
      <p>Search the web for up-to-date information.</p>
    </div>
  );
};

export const FilesTooltip = () => {
  return (
    <div className={"flex flex-col"}>
      <span className={"font-semibold"}>{"Coming soon 🚀🔜\n"}</span>
      <p>Includes files in the context.</p>
    </div>
  );
};
