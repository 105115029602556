import { Button } from "@/app/components";
import { companyCopilotStore } from "@/app/screens/modal/copilot";

import { ConversationsList } from "./conversations-list";

export function Conversations() {
  const { addConversation } = companyCopilotStore;
  return (
    <div className="flex flex-1 flex-col space-y-4 py-4">
      <div className="flex items-center justify-between border-b pb-2">
        <span className="text-lg font-semibold">Chats</span>
        <Button
          iconLeft={"ChatBubble"}
          size={"sm"}
          variant={"outline"}
          onClick={() => {
            addConversation();
          }}
        >
          New Chat
        </Button>
      </div>
      <ConversationsList />
    </div>
  );
}
