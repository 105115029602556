// @ts-nocheck

import { useState } from "react";

import Form from "mobx-react-form";

import { useSelectOption } from "@/app/hooks";
import { OptionsType } from "@/app/screens/opportunities";

import { MultiInput } from "./multi-input";

export interface SelectModalSheetProps {
  form?: Form;
  fieldId?: string;
  defaultOptions?: OptionsType[];
  options: OptionsType[];
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  multiSelect?: boolean;
  onChange?: (value: OptionsType[]) => void;
  formatValue?: (value: OptionsType[]) => any;
}

export const SelectModalSheet = ({
  form,
  defaultOptions = [],
  disabled,
  placeholder,
  options,
  label = "",
  multiSelect = false,
  formatValue,
  onChange,
  ...props
}: SelectModalSheetProps) => {
  const [selectedOptions, setSelectedOptions] = useState(defaultOptions);
  const { selectOption } = useSelectOption({ form });

  const handleRemove = (option: OptionsType) => {
    const updatedOptions = selectedOptions.filter(({ value }) => value !== option?.value);
    setSelectedOptions(updatedOptions);

    if (onChange) onChange(updatedOptions);
  };

  const handleSelect = (option: OptionsType[]) => {
    const updatedOptions = multiSelect ? [...selectedOptions, ...option] : option;
    setSelectedOptions(updatedOptions);

    if (onChange) onChange(updatedOptions);
  };

  return (
    <MultiInput
      {...props}
      containerClassName={"px-2 py-1 h-10 bg-white relative"}
      selectedOptions={selectedOptions}
      disabled={disabled}
      inputPosition={"right"}
      iconLeft="LoopGradient"
      placeholder={placeholder}
      allowManualInput={false}
      onRemove={handleRemove}
      onClick={() =>
        selectOption({
          label,
          options,
          multiSelect,
          defaultSelected: selectedOptions,
          formatValue,
          onChange: handleSelect,
        })
      }
    />
  );
};
