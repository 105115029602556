import { useEffect, useRef, useState } from "react";

import { useResizeObserver } from "usehooks-ts";

export const useResponsiveItemLimit = (childCount?: number) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  const [hiddenItemIndex, setHiddenItemIndex] = useState<number>(-1);
  const [hiddenItemLeftOffset, setHiddenItemLeftOffset] = useState<number>();

  const calculateBoundaries = () => {
    // no need to calculate boundaries if there are no items
    if (!itemRefs.current || itemRefs.current.length === 0) {
      hiddenItemIndex !== -1 && setHiddenItemIndex(-1);
      hiddenItemLeftOffset !== undefined && setHiddenItemLeftOffset(undefined);
      return;
    }

    requestAnimationFrame(() => {
      const parentElBounds = parentRef.current?.getBoundingClientRect();
      if (parentElBounds && itemRefs.current) {
        // filter null values from array
        const itemIndex = itemRefs.current.findIndex((item) => {
          if (!item) {
            return false;
          }

          const itemBounds = item.getBoundingClientRect();

          if (itemBounds.right + 32 > parentElBounds.right) {
            return true;
          }
        });

        if (itemIndex !== hiddenItemIndex) {
          setHiddenItemIndex(itemIndex);
          setHiddenItemLeftOffset(itemRefs.current[itemIndex]?.offsetLeft);
        }
      }
    });
  };

  useResizeObserver({
    ref: parentRef,
    onResize: calculateBoundaries,
  });

  // recalculate when number of children changes
  useEffect(() => {
    calculateBoundaries();
  }, [childCount]);

  return { hiddenItemIndex, hiddenItemLeftOffset, parentRef, itemRefs };
};
