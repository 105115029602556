import { observer } from "mobx-react";

import { Modal } from "@/app/components";
import modalStore from "@/app/stores/modal.store.tsx";

import { ActionModalHeader } from "../components";

import { ActionButtons } from "./components/action-buttons";
import { AddSentimentProps, FormStep, useRequestSentiment } from "./hooks/use-request-sentiment";

export const AddSentimentWeb = observer(({ type, company: defaultCompany, founderId }: AddSentimentProps) => {
  const { isValid, loading, step, content, primaryAction, secondaryAction } = useRequestSentiment({
    type,
    company: defaultCompany,
    founderId,
  });

  return (
    <Modal
      isOpen
      handleClose={modalStore.close}
      showX
      bodyClassName="p-8"
      bodyContentClassName="p-0"
      footerContainerClassName="px-0 mb-0 pt-8"
      headerContainerClassName="px-0"
      closeIconStyle="size-5 right-0 top-5"
      header={<ActionModalHeader title={step === FormStep.SEARCH ? "Search Company" : "Create a New Sentiment Form"} />}
      footer={
        <ActionButtons
          multiStep={!defaultCompany || modalStore.history.length > 1}
          disabled={!isValid}
          loading={loading}
          stepIndex={step === FormStep.SUBMIT ? 1 : 0}
          primaryButtonTitle={step === FormStep.SUBMIT ? "Create Form" : "Next"}
          actions={{
            handlePrimaryAction: primaryAction,
            handleSecondaryAction: secondaryAction,
            handleCancel: modalStore.close,
          }}
        />
      }
    >
      {content}
    </Modal>
  );
});
