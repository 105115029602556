import { useLayoutEffect } from "react";

import { useLocation, useNavigate } from "react-router";

import { metricsStore } from "@/app/screens/metrics-extraction";
import { useCompanyDetailsMetricsQuery } from "@/app/service/metrics.gql.ts";

export const useMetricsHeader = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const selectedCompanyId = metricsStore.state?.selectedCompanyId || state?.companyId || "new";

  const { data, loading, error } = useCompanyDetailsMetricsQuery({
    variables: { id: selectedCompanyId },
    skip: selectedCompanyId === "new",
    fetchPolicy: "network-only",
  });

  const { name, image } = data?.lgCompany || {};

  useLayoutEffect(() => {
    metricsStore.setState({ selectedCompanyId });
  }, [selectedCompanyId]);

  return { loading, name, image, error, navigate, selectedCompanyId };
};
