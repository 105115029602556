import { useQuery } from "@apollo/client";
import { connectionsDictQuery } from "./connectionsDict.gql";
import { createContext, useCallback, useMemo } from "react";

type Connection = {
  initials: string;
  name: string;
  image?: string;
};

type ConnectionsContextType = {
  getConnection: (email: string) => Connection | null;
};

export const ConnectionsContext = createContext<ConnectionsContextType>({
  getConnection: () => null,
});

export function useConnectionsDict() {
  const { data, loading } = useQuery(connectionsDictQuery);
  
  const getConnection = useMemo(() => {
    const dict = {};
    function getConnection(email: string) {
      if (email in dict) return dict[email];
      return null;
    }
    if (data?.dict?.nodes) {
      for (const conn of data.dict.nodes) {
        dict[conn.email] = {
          initials: conn.initials,
          name: conn.name,
          image: conn.image,
        };
      }
    }
    
    return getConnection;
  }, data);

  return { getConnection };
}
