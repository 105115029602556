import { BtnToggle } from "@/app/components";

export const HistoryToggle = ({ value, setValue, activeLabel = "New", inActiveLabel = "Archived" }) => {
  return (
    <BtnToggle
      className="ml-auto"
      value={value}
      onChange={(val) => setValue(val)}
      labelLeft={activeLabel}
      labelRight={inActiveLabel}
      loading={false}
      size={"md"}
    />
  );
};
