import { useMemo } from "react";

import { Fund } from "@/app/screens/add-company/company-signals-step/company-signals-form.ts";
import { DealroomCompanyQuery, useDealroomCompanyQuery } from "@/app/service/dealroom-company.gql.ts";

type DealroomFundingRound =
  | "ACQUISITION"
  | "ANGEL"
  | "BANKRUPTCY"
  | "BUYOUT"
  | "CONVERTIBLE"
  | "CORPORATE SPINOUT"
  | "DEBT"
  | "EARLY VC"
  | "GRANT"
  | "GROWTH EQUITY NON VC"
  | "GROWTH EQUITY VC"
  | "ICO"
  | "IPO"
  | "LATE VC"
  | "LENDING CAPITAL"
  | "MEDIA FOR EQUITY"
  | "MERGER"
  | "POST IPO CONVERTIBLE"
  | "POST IPO DEBT"
  | "POST IPO EQUITY"
  | "POST IPO SECONDARY"
  | "PRIVATE PLACEMENT NON VC"
  | "PRIVATE PLACEMENT VC"
  | "PROJECT, REAL ESTATE, INFRASTRUCTURE FINANCE"
  | "SECONDARY"
  | "SEED"
  | "SERIES A"
  | "SERIES B"
  | "SERIES C"
  | "SERIES D"
  | "SERIES E"
  | "SERIES F"
  | "SERIES G"
  | "SERIES H"
  | "SERIES I"
  | "SPAC IPO"
  | "SPAC PRIVATE PLACEMENT"
  | "SPINOUT"
  | "SUPPORT PROGRAM";

const roundToFundMapping: Record<DealroomFundingRound, Fund> = {
  ACQUISITION: Fund.Pipeline,
  ANGEL: Fund.Lg,
  BANKRUPTCY: Fund.Pipeline,
  BUYOUT: Fund.Pipeline,
  CONVERTIBLE: Fund.Lg,
  "CORPORATE SPINOUT": Fund.Lg,
  DEBT: Fund.Lg,
  "EARLY VC": Fund.Lg,
  GRANT: Fund.Lg,
  "GROWTH EQUITY NON VC": Fund.Sr,
  "GROWTH EQUITY VC": Fund.Sr,
  ICO: Fund.Pipeline,
  IPO: Fund.Pipeline,
  "LATE VC": Fund.Lt,
  "LENDING CAPITAL": Fund.Lg,
  "MEDIA FOR EQUITY": Fund.Lg,
  MERGER: Fund.Pipeline,
  "POST IPO CONVERTIBLE": Fund.Pipeline,
  "POST IPO DEBT": Fund.Pipeline,
  "POST IPO EQUITY": Fund.Pipeline,
  "POST IPO SECONDARY": Fund.Pipeline,
  "PRIVATE PLACEMENT NON VC": Fund.Pipeline,
  "PRIVATE PLACEMENT VC": Fund.Pipeline,
  "PROJECT, REAL ESTATE, INFRASTRUCTURE FINANCE": Fund.Pipeline,
  SECONDARY: Fund.Lg,
  SEED: Fund.Lg,
  "SERIES A": Fund.Lt,
  "SERIES B": Fund.Lt,
  "SERIES C": Fund.Sr,
  "SERIES D": Fund.Sr,
  "SERIES E": Fund.Sr,
  "SERIES F": Fund.Sr,
  "SERIES G": Fund.Sr,
  "SERIES H": Fund.Sr,
  "SERIES I": Fund.Sr,
  "SPAC IPO": Fund.Pipeline,
  "SPAC PRIVATE PLACEMENT": Fund.Pipeline,
  SPINOUT: Fund.Lg,
  "SUPPORT PROGRAM": Fund.Lg,
};

export type RecommendedFundData = {
  fund: Fund;
  details?: DealroomCompanyQuery["dealroomCompany"];
};

export const useRecommendedFund = (companyId?: string | null): RecommendedFundData => {
  const { data: dealroomFundingData } = useDealroomCompanyQuery({
    variables: {
      companyId,
      includeFunding: true,
    },
    skip: !companyId,
  });

  return useMemo(() => {
    const latestRound = dealroomFundingData?.dealroomCompany?.fundingRounds?.nodes[0];

    if (!latestRound?.round) {
      return { fund: Fund.Lg };
    }

    const matchingFund: Fund = roundToFundMapping[latestRound.round];

    if (!matchingFund) {
      return { fund: Fund.Lg };
    }

    return { fund: matchingFund, details: dealroomFundingData?.dealroomCompany };
  }, [dealroomFundingData]);
};
