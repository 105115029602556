import { Fragment } from "react";

import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { observer } from "mobx-react";

import { cn } from "@/lib/utils.ts";

import { CompanyCopilot } from "@/app/screens/modal/copilot/company-copilot.tsx";
import globalModalStore, { SlideOverRoutesType } from "@/app/screens/modal/global-modal.store.ts";
import { MobileDrawerNavigation } from "@/app/screens/modal/mobile-drawer-navigation.tsx";
import { EmailView } from "@/app/screens/modal/notifications/email.tsx";

const SlideOverComponent: Record<
  SlideOverRoutesType,
  {
    Component: (({ ...args }: any) => JSX.Element) | null;
    width: string;
    title: string;
    direction: "right" | "left" | "";
  }
> = {
  "": { Component: null, width: "", title: "", direction: "" },
  Settings: {
    Component: MobileDrawerNavigation,
    width: "max-w-[70dvw]",
    direction: "right",
    title: "",
  },
  OpportunityEmail: {
    Component: EmailView,
    width: "max-w-[55dvw]",
    direction: "left",
    title: "Email Body",
  },
  CompanyCopilot: {
    Component: CompanyCopilot,
    width: "max-w-[50dvw]",
    direction: "left",
    title: "",
  },
};

export const SlideOver = observer(() => {
  const { isOpen: open, slideOverType, navState = {} } = globalModalStore.slideOverState;

  const { Component, title, width, direction } = SlideOverComponent[slideOverType];
  const setOpen = (isOpen: boolean) => globalModalStore.toggleSlideOver({ isOpen });

  return (
    <Transition show={open} as={"div"}>
      <Dialog as="div" className="relative z-20" onClose={setOpen}>
        <TransitionChild
          as={"div"}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 opacity-40 transition-opacity" />
        </TransitionChild>
        {direction === "right" ? (
          <RightContainer {...{ width, Component, navState, title, setOpen }} showX={slideOverType !== "Settings"} />
        ) : (
          <LeftContainer {...{ width, Component, navState, title, setOpen }} />
        )}
      </Dialog>
    </Transition>
  );
});

const RightContainer = ({ width, Component, navState, title, setOpen, showX }) => {
  return (
    <div className="fixed inset-0 overflow-hidden">
      <div className="absolute inset-0 overflow-hidden">
        <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10">
          <TransitionChild
            as={Fragment}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <DialogPanel className={cn("pointer-events-auto w-screen", `${width}`)}>
              <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <div className="items-start justify-between sm:hidden lg:flex">
                    <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                      <span className="text-2xl font-semibold">{title}</span>
                    </DialogTitle>
                    <div className="ml-3 hidden h-7 items-center lg:flex">
                      <button
                        type="button"
                        className="relative rounded-md bg-white text-gray-400 outline-none hover:text-gray-500 "
                        onClick={() => setOpen(false)}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        {showX && <XMarkIcon className="size-6" aria-hidden="true" />}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="relative flex-1 px-4 sm:px-6">{Component ? <Component {...navState} /> : null}</div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </div>
  );
};

const LeftContainer = ({ width, Component, navState }) => {
  console.log({ navState });
  return (
    <div className="fixed inset-0 overflow-hidden">
      <div className="absolute inset-0 overflow-hidden">
        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
          <TransitionChild
            as={"div"}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <DialogPanel className={cn("pointer-events-auto w-screen", `${width}`)}>
              <div className="flex h-screen flex-col overflow-y-scroll rounded-l-xs shadow-xl">
                <div className="relative flex-1">{Component ? <Component {...navState} /> : null}</div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </div>
  );
};
