import { observer } from "mobx-react";
import { useNavigate } from "react-router";

import { cn } from "@/lib/utils";

import { BreadCrumbs } from "@/app/components";
import { Routes } from "@/app/constants";

const navigationPath = { url: Routes.home, label: "Home" };

export const AddViaEmailHeader = observer(function AddViaEmailHeader({ className }: { className?: string }) {
  const navigate = useNavigate();

  const navigateBack = () => {
    const navigatePath = navigationPath?.url ?? Routes.home;
    navigate(navigatePath);
  };

  return (
    <div className={cn("flex w-full flex-row items-center justify-between bg-white py-2 backdrop-blur-md", className)}>
      <BreadCrumbs title={navigationPath?.label ?? "Opportunities via email"} action={navigateBack} />
    </div>
  );
});
