import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import z from "zod";

import { Button } from "@/app/components";
import { SignalFormFields } from "@/app/screens/add-company/company-signals-step/SignalFormFields.tsx"; // todo move ?

import { NetworkSignalType, sourceOptions } from "./network-signal-utils.ts";
import { NetworkSignalData } from "./network-signal.types.ts";

const companySignalSchema = z
  .object({
    fund: z.string().optional().nullable(),
    note: z.string().optional().nullable(),
    sourceType: z.string({ required_error: "Source type is required" }).min(1, "Source type is required"),
    sourceName: z.union([
      z
        .string({ invalid_type_error: "Please select valid type", required_error: "Source is required" })
        .min(1, "Source is required")
        .or(
          z.object({
            name: z.string(),
            email: z.string().min(1, "Email is required"),
          }),
        ),
      z.string().optional().nullable(),
    ]),
    introAvailable: z.boolean().optional().nullable(),
    highPriority: z.boolean().optional().nullable(),
    isDeepTech: z.boolean().optional().nullable(),
    alreadyMet: z.boolean().optional().nullable(),
    // ballInOurControl: z.boolean().optional().nullable(),
    activelyRaising: z.boolean().optional().nullable(),
  })
  .superRefine((data, ctx) => {
    const { sourceName, sourceType } = data;

    if (sourceName === null || sourceName === undefined || sourceName === "") {
      // Only allow empty values if source type is standalone(no name field)
      if (
        !sourceOptions
          .filter((option) => option.standalone)
          .map((option) => option.value)
          .includes(sourceType as NetworkSignalType)
      ) {
        ctx.addIssue({
          path: ["sourceName"],
          code: z.ZodIssueCode.custom,
          message: "Source is required",
        });
      }
    }
  });

export type CompanySignalSchema = z.infer<typeof companySignalSchema>;

// todo can be shared with second step of "Add Company" flow, but do we really want to do it?
export const useNetworkSignalForm = (defaultValues?: NetworkSignalData) => {
  const sourceNameValue = !defaultValues?.sourceName
    ? ""
    : defaultValues.sourceEmail
      ? {
          name: defaultValues.sourceName,
          email: defaultValues.sourceEmail,
        }
      : defaultValues.sourceName;

  return useForm<CompanySignalSchema>({
    resolver: zodResolver(companySignalSchema),
    mode: "onChange",
    defaultValues: {
      // fund: "",
      note: defaultValues?.note ?? "",
      sourceName: sourceNameValue,
      sourceType: defaultValues?.sourceType ?? "",
      introAvailable: defaultValues?.attributes.canConnect ?? false,
      highPriority: defaultValues?.attributes.isHighPriority ?? false,
      // ballInOurControl:  false, // todo is it still needed?
      isDeepTech: defaultValues?.attributes.isDeepTech ?? false, // todo is this the right field?
      activelyRaising: defaultValues?.attributes.isActivelyFundraising ?? false,
      alreadyMet: defaultValues?.attributes.alreadyMet ?? false,
    },
  });
};

export const NetworkSignalEdit = ({
  onClose,
  signal,
  onSubmit,
  loading,
}: {
  signal?: NetworkSignalData;
  onSubmit: (data: CompanySignalSchema) => void;
  onClose: () => void;
  loading?: boolean;
}) => {
  const form = useNetworkSignalForm(signal);

  const handleCancel = () => {
    form.reset();
    onClose();
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
      <SignalFormFields form={form} />

      <div className="flex justify-end gap-4">
        <Button className={"min-w-40"} variant="outline" size={"md"} onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>
        <Button
          className={"min-w-40"}
          size={"md"}
          variant="gradient"
          loading={loading}
          disabled={loading || !form.formState.isValid}
        >
          Save
        </Button>
      </div>
    </form>
  );
};
