import { Button } from "@/app/components";

import { useKanbanBoardContext } from "../kanban-board-context";
import { KanbanViewType } from "../kanban-board-types";

const tabs: { label: string; value: KanbanViewType }[] = [
  { label: "All", value: null },
  { label: "Picking", value: "picking" },
  { label: "Concentrating", value: "concentrating" },
];

export const KanbanBoardViewSwitcher = () => {
  const { selectedViewType, setSelectedViewType } = useKanbanBoardContext();

  return (
    <div className="flex gap-2">
      {tabs.map((tab) => (
        <Button
          key={tab.value}
          variant={selectedViewType === tab.value ? "secondary" : "outline"}
          size="sm"
          onClick={() => {
            setSelectedViewType(tab.value);
          }}
        >
          {tab.label}
        </Button>
      ))}
    </div>
  );
};
