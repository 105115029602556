import { Button } from "@/app/components";

import { useTextAreaCopilot } from "../hooks/use-send-message";

const suggestions = [
  { id: "blurb", label: "Generate Blurb" },
  { id: "sentiment", label: "Sentiment Analysis" },
  { id: "rejection_email", label: "Rejection Email" },
];

export function Suggestions() {
  const { handleSendMessage } = useTextAreaCopilot();
  return (
    <div className="mt-3 flex flex-wrap gap-2">
      {suggestions.map((suggestion, index) => (
        <Button
          key={index}
          variant="outline"
          size="sm"
          onClick={() => {
            handleSendMessage(suggestion.label).catch();
          }}
          className="bg-white text-sm text-gray-700 hover:scale-[1.03] hover:bg-gray-50 hover:shadow-xs"
        >
          {suggestion.label}
        </Button>
      ))}
    </div>
  );
}
