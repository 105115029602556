import React, { useState } from "react";

import { AnimatePresence, motion } from "framer-motion";

import { DealroomCompanyQuery } from "@/gql/graphql.ts";

import { getInitials, parseUrl } from "@/lib/utils.ts";

import { AvatarV2, Button, Icon, Popover, ResponsiveHorizontalList } from "@/app/components";
import { ChipV2 } from "@/app/components/v2/chip-v2";

import { FounderPreview } from "./company-founder-preview";

export const IdentifiedSources = ({
  identifiedDealroomCompany,
  onSelectSource,
}: {
  identifiedDealroomCompany: NonNullable<DealroomCompanyQuery["dealroomCompany"]>;
  onSelectSource: () => void;
}) => {
  const [isIdentifiedCompanyExpanded, setIsIdentifiedCompanyExpanded] = useState(false);

  return (
    <div>
      <div
        className="flex cursor-pointer items-center gap-2 rounded-sm border border-gray-300 bg-gray-100 px-3 py-2 transition-colors hover:bg-gray-200"
        onClick={() => setIsIdentifiedCompanyExpanded(!isIdentifiedCompanyExpanded)}
      >
        <Icon type={isIdentifiedCompanyExpanded ? "Chevron Down" : "Chevron Right"} className="size-4 text-gray-600" />
        <div className="select-none text-sm font-medium">Identified company details</div>
        <ChipV2 label="From Dealroom" color="gray" size="sm" className="ml-auto shrink-0" />
      </div>

      <AnimatePresence>
        {isIdentifiedCompanyExpanded && (
          <motion.div
            initial={{
              height: 0,
            }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.2 }}
            className="mt-2 flex flex-col gap-4 overflow-hidden rounded-sm border border-gray-200 p-3 md:flex-row md:items-center"
          >
            <div className="flex min-w-0 shrink flex-col gap-2">
              <div className="flex items-center gap-2">
                {identifiedDealroomCompany.image && (
                  <AvatarV2 src={parseUrl(identifiedDealroomCompany.image)} size="sm" type="company" />
                )}
                <div>{identifiedDealroomCompany.name}</div>
                {identifiedDealroomCompany.websiteUrl && (
                  <a
                    href={identifiedDealroomCompany.websiteUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center gap-1 text-sm text-blue-500 hover:underline"
                  >
                    {identifiedDealroomCompany.websiteUrl}
                    <Icon type="ExternalLink" className="size-3" />
                  </a>
                )}
              </div>

              {identifiedDealroomCompany.tagline && (
                <div className="line-clamp-2 text-sm text-gray-700">{identifiedDealroomCompany.tagline}</div>
              )}

              {!!identifiedDealroomCompany.founders?.nodes.length && (
                <>
                  <div className="text-sm font-medium">Founders</div>
                  <ResponsiveHorizontalList popoverProps={{ disabled: true }}>
                    {identifiedDealroomCompany.founders.nodes.map((founder) => (
                      <React.Fragment key={founder.member?.id}>
                        <Popover
                          placement="top-start"
                          childrenClassName="min-w-[300px]"
                          childrenContent={
                            <FounderPreview
                              founder={{
                                id: founder.member!.id,
                                linkedinUrl: founder.member?.linkedinUrl,
                                name: founder.member?.name ?? "",
                                image: founder.member?.image ?? "",
                                titles: founder.titles,
                              }}
                            />
                          }
                        >
                          <AvatarV2
                            src={parseUrl(founder.member?.image)}
                            size="sm"
                            type="user"
                            initials={getInitials(founder.member?.name ?? "")}
                          />
                        </Popover>
                      </React.Fragment>
                    ))}
                  </ResponsiveHorizontalList>
                </>
              )}
            </div>
            {!identifiedDealroomCompany.srcCompany?.company && (
              <Button
                text="Use this data"
                size="sm"
                variant="secondary"
                className="shrink-0 md:ml-auto"
                type="button"
                onClick={() => {
                  setIsIdentifiedCompanyExpanded(false);
                  onSelectSource();
                }}
              />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
