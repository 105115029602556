import { ReactNode } from "react";

import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import { Link } from "react-router-dom";

import { LgMember, UserRoleType } from "@/gql/graphql.ts";

import { cn, getFullName, getInitials } from "@/lib/utils.ts";

import { AvatarV2, Tooltip } from "@/app/components";
import { authStore } from "@/app/stores/auth.store.tsx";

import { getNetworkSignalSourceLabel, NetworkSignalType, sourceOptions } from "./network-signal-utils.ts";
import { NetworkSignalData } from "./network-signal.types.ts";

export type NetworkSignalView = {
  author: Pick<LgMember, "firstName" | "lastName" | "avatar">;
  note: string;
  attributes: {
    canConnect: boolean;
    isActivelyFundraising: boolean;
    isHighPriority: boolean;
    isDeepTech: boolean;
  };
};

const SourceName = ({ value }) => {
  // todo enable this after we launch network
  // const validator = z.string().email();
  // const emailType = validator.safeParse(value).success;

  const isEmailType = false;

  if (!isEmailType) {
    return value || "-";
  }

  return (
    <a target="_blank" href={`/network/contact/${value}`} rel="noreferrer">
      {value}
    </a>
  );
};

const SourceEmail = ({ signal, hasAccess }: { signal: NetworkSignalData; hasAccess: boolean }) => {
  if (!hasAccess) {
    return <span className="truncate text-sm font-normal text-gray-900">{signal.sourceEmail}</span>;
  }

  return (
    <Link className="truncate text-sm font-normal text-gray-900" to={`/network/contact/${signal.sourceEmail}`}>
      {signal.sourceEmail}
    </Link>
  );
};

const Source = ({ signal, className }: { signal: NetworkSignalData; className?: string }) => {
  const { userRoles: myRoles } = authStore;

  const sourceType = getNetworkSignalSourceLabel(signal.sourceType);

  const hasAccess = myRoles?.includes(UserRoleType.UserRoleNetwork) ?? false;

  return (
    <div className={cn("flex max-w-full flex-col gap-1 overflow-hidden", className)}>
      <div className={cn("truncate text-base font-semibold text-black")}>
        {sourceOptions.find((source) => source.value === signal.sourceType)?.standalone ? (
          <SourceName value={sourceType} />
        ) : signal.sourceType === NetworkSignalType.Other ? (
          <SourceName value="Other" />
        ) : (
          <>
            <SourceName value={signal.sourceName} />{" "}
            <span className="text-sm font-normal text-gray-600">({sourceType})</span>
          </>
        )}
      </div>
      {signal.sourceType === NetworkSignalType.Other && (
        <span className="truncate text-sm font-normal text-gray-900">{signal.sourceName}</span>
      )}
      {signal.sourceEmail && <SourceEmail signal={signal} hasAccess={hasAccess} />}
    </div>
  );
};

const SignalField = ({ label, value, className }: { label: string; value: ReactNode; className?: string }) => {
  return (
    <div className={cn("flex flex-col gap-2", className)}>
      <span className="text-sm text-gray-600">{label}</span>
      <span className="text-sm text-gray-900">{value}</span>
    </div>
  );
};

const Author = ({ signal, className }: { signal: NetworkSignalData; className?: string }) => {
  const { avatar, firstName, lastName } = signal.createdBy || {};
  const name = getFullName(firstName, lastName);

  return (
    <SignalField
      className={cn("gap-1", className)}
      label="Added by"
      value={
        <div className="flex items-center gap-2 overflow-hidden">
          <AvatarV2 type="user" src={avatar} size="xs" initials={getInitials(`${firstName} ${lastName}`)} />
          <span className="truncate">{name}</span>
        </div>
      }
    />
  );
};

const AddedOn = ({ signal, className }: { signal: NetworkSignalData; className?: string }) => {
  return (
    <SignalField
      className={className}
      label="Added on"
      value={format(new Date(signal.lastUpdatedUtc || signal.createdUtc), "dd MMMM yyyy", { locale: enGB })}
    />
  );
};

const SignalDataValue = ({ label, value }: { label: string; value: ReactNode }) => {
  return <SignalField label={label} value={value} />;
};

const Note = ({ note }: { note?: string | null }) => {
  if (!note) {
    return;
  }

  return (
    <Tooltip containerClassName="justify-start" text={note}>
      <p className={"line-clamp-3 cursor-default text-sm font-normal text-gray-800"}>&quot;{note}&quot;</p>
    </Tooltip>
  );
};

export const NetworkSignalView = ({ signal }: { signal: NetworkSignalData }) => {
  const { note } = signal;

  return (
    <div className="space-y-4 @container/signal">
      <div className="grid grid-cols-2 gap-x-4 gap-y-3 @2xl/signal:grid-cols-4 @4xl/signal:grid-cols-[1.5fr_1fr_1fr_1fr_1fr] @5xl/signal:grid-cols-[2fr_1fr_1fr_1fr_1fr] @5xl/signal:gap-x-6">
        <Source signal={signal} className="col-span-2 @2xl/signal:col-span-4 @4xl/signal:col-span-1" />
        <Author signal={signal} className="col-span-1 overflow-hidden" />
        <AddedOn signal={signal} className="col-span-1" />
        <SignalDataValue
          label={"Are they actively raising?"}
          value={signal.attributes.isActivelyFundraising ? "Yes" : "No"}
        />
        <SignalDataValue label={"Is this a high priority?"} value={signal.attributes.isHighPriority ? "Yes" : "No"} />
      </div>
      <Note note={note} />
    </div>
  );
};
