import { useEffect } from "react";

import { Modal, ProgressSpinner } from "@/app/components";
import modalStore from "@/app/stores/modal.store";

import { ModalHeader } from "../components";

import { ActionButtons } from "./components/action-buttons";
import { useSelectModal } from "./hooks";
import { ModalContent } from "./modal-content";

export const AddNewModal = ({ sentimentOnly, company, isOpen = true }) => {
  const { selected, founders, loading, fetchFounders, handleSelect, handleNext } = useSelectModal({ company });

  useEffect(() => {
    if (company?.id) fetchFounders({ variables: { companyId: company.id } });
  }, [company, fetchFounders]);

  return (
    <Modal
      isOpen={isOpen}
      handleClose={modalStore.close}
      bodyClassName="flex-none p-8"
      bodyContentClassName="p-0"
      footerContainerClassName="px-0 mb-0 pt-8"
      headerContainerClassName="px-0"
      closeIconStyle="size-5 right-0 top-5"
      header={
        <ModalHeader
          title={"Add New..."}
          subtitle='Please choose an option and then proceed by clicking on "Next".'
          containerClassName="text-left xl:mb-6 gap-4"
        />
      }
      footer={
        <ActionButtons
          disabled={!selected}
          actions={{
            handlePrimaryAction: handleNext,
            handleCancel: modalStore.close,
          }}
        />
      }
    >
      {loading ? (
        <ProgressSpinner className="mx-auto" />
      ) : (
        <ModalContent
          onSelect={handleSelect}
          sentimentOnly={sentimentOnly}
          disableFounders={company && founders.length === 0}
        />
      )}
    </Modal>
  );
};
