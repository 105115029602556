import { useCallback, useState } from "react";
import { Table } from "./lookupTable";
import { Column, SortDirection, sortPairValueFromString, sortPairValueToString } from "./columns";
import { useQuery } from "@apollo/client";
import { portfolioContactsLookupQuery } from "./contactsLookupQuery.gql";
import { useNavigate } from "react-router";
import { observer } from "mobx-react";
import { authStore } from "@/app/stores/auth.store";
import { ConnectionsContext, useConnectionsDict } from "./connectionsDict";
import { Button } from "@/app/components";

type NetworkPortfolioLookupProps = {
  companyId: string;
};
export const NetworkPortfolioLookup = observer(function NetworkPortfolioLookup(props: NetworkPortfolioLookupProps) {
  const { userRoles: myRoles } = authStore;
  const connectionsDict = useConnectionsDict();

  // not loaded yet
  if (myRoles === null) return null;
  if ((myRoles as any)?.includes("USER_FEAT_NETWORK_NEXT")) {
    return (
      <ConnectionsContext.Provider value={connectionsDict}>
        <NetworkPortfolioLookupInner companyId={props.companyId} />
      </ConnectionsContext.Provider>
    );
  }
  return null;
});

function NetworkPortfolioLookupInner(props: NetworkPortfolioLookupProps) {
  const { companyId } = props;
  const navigate = useNavigate();
  const [sortPair, setSortPair] = useState<[Column, SortDirection]>([Column.Connectivity, SortDirection.Desc]);
  const {
    data: data,
    previousData: prevData,
    loading: loading,
  } = useQuery(portfolioContactsLookupQuery, {
    variables: { companyId, sort: sortPairValueToString(...sortPair) },
  });

  const handleLookupTableClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    if (event.target instanceof Element) {
      // Perform sorting
      const nextPairString = event.target?.closest("[data-column-next-sort]")?.getAttribute("data-column-next-sort");
      if (nextPairString) {
        const nextPair = sortPairValueFromString(nextPairString);
        event.preventDefault();
        setSortPair(nextPair);
      }

      // Navigate to contact view
      const clickedContact = event.target?.closest("[data-contact-email]")?.getAttribute("data-contact-email");
      if (clickedContact) {
        event.preventDefault();
        const params: string =
          event.target.closest("[data-contact-view-search-params]")?.getAttribute("data-contact-view-search-params") ||
          "";
        navigate(`/network/contact/${clickedContact}?${params}`);
      }
    }
  }, []);
  
  const contactsToDisplay = data?.contacts?.nodes || prevData?.contacts?.nodes || [];
  const domain = contactsToDisplay[0]?.email?.split("@")?.[1];
  const handleVisitNetwork = useCallback(() => {
    navigate(`/network?q=${domain}`);
  }, [domain]);
  
  if (!domain) return;
  return (
    <div className="@container w-full" onClick={handleLookupTableClick}>
      <div onClick={handleLookupTableClick} className="rounded-sm bg-white"         data-lookup-search-offset="company-page">
        <div className="flex flex-row gap-3">
          <div className="flex w-full gap-2 p-4 pb-0 text-[20px] text-gray-900 font-semibold">
            Related contacts <span className="text-gray-600">{contactsToDisplay.length}</span>
          </div>
          <div className="flex items-center justify-end p-4">
<Button size="sm" iconLeft="Network" variant="outline" onClick={handleVisitNetwork}>
              Network
            </Button>

          </div>
        </div>

        <Table
          contacts={contactsToDisplay}
          sortColumn={sortPair[0]}
          sortDirection={sortPair[1]}
          loading={loading}
          noSearch={true}
          limitVisibleRows={5}
          noDataMessage={"No related contacts found."}
        />
      </div>
    </div>
  );
}
