// @ts-nocheck

import { useNavigate } from "react-router";

import { cn, formatFundingMil } from "@/lib/utils.ts";

import { Icon } from "@/app/components";
import { ChipV2 } from "@/app/components/v2/chip-v2.tsx";

const TableNA = ({ textStyle = "", containerClassName = "" }) => {
  return (
    <div className={containerClassName}>
      <span
        className={cn(
          "select-none whitespace-nowrap text-xxs font-medium leading-4 text-gray-400 md:text-sm",
          textStyle,
        )}
      >
        N/A
      </span>
    </div>
  );
};

export const TableText = ({
  text,
  className = "",
  containerClassName = "",
  onClick = () => null,
}: {
  text: string | unknown;
  onClick?: () => void;
  className?: string;
  containerClassName?: string;
}) => {
  if (!text || text === "N/A") return <TableNA containerClassName={containerClassName} />;
  return (
    <div className={cn(containerClassName)}>
      <span onClick={onClick} className={cn("select-none text-nowrap text-sm font-medium text-gray-900", className)}>
        {String(text)}
      </span>
    </div>
  );
};

export const TableLabel = ({ label, containerClassName = "", navigateTo = "" }) => {
  const navigate = useNavigate();
  if (!label) return <TableNA containerClassName={containerClassName} />;
  return (
    <div
      className={cn(containerClassName, navigateTo && "cursor-pointer")}
      onClick={navigateTo ? () => navigate(navigateTo) : undefined}
    >
      <ChipV2 color="gray" className="w-fit" label={String(label)} />
    </div>
  );
};

export const RowWithCurrency = ({
  label,
  currency = "GBP",
  containerClassName = "",
  labelClassName = "",
  unit = "M",
}: {
  label: number | string | unknown;
  currency?: "GBP" | "USD" | "EUR";
  containerClassName?: string;
  labelClassName?: string;
  unit?: "M" | "B" | "";
}) => {
  let val: string | number = Number(label);
  if (Number(label) === 0) return <TableNA containerClassName={containerClassName} />;

  if (unit === "") {
    val = formatNumber(label);
  }

  if (unit === "M") {
    val = formatFundingMil(Number(label) / 1_000_000);
  }

  if (unit === "B") {
    val = formatFundingMil(Number(label) / 1_000_000_000);
  }

  return (
    <div className={cn(containerClassName, "flex items-center text-nowrap")}>
      <Icon type={currency} className={"size-3.5 text-currency"} />
      &nbsp;
      <span className={cn("text-center text-sm font-medium text-gray-800", labelClassName)}>{String(val)}</span>
    </div>
  );
};

export const RowWithMultiplier = ({
  label,
  containerClassName = "",
}: {
  label: number | string | unknown;
  containerClassName?: string;
}) => {
  if (Number(label) === 0) return <TableNA containerClassName={containerClassName} />;
  return (
    <div className={cn(containerClassName, "text-nowrap")}>
      <span className={"text-nowrap text-sm text-gray-800"}>{`${formatNumber(Number(label))}x`}</span>
    </div>
  );
};

export const NumberRowWithFormat = ({
  label,
  decimals = 2,
  suffix = "%",
  prefix = "",
  containerClassName,
}: {
  decimals?: number;
  label: number | string | unknown;
  suffix?: string;
  prefix?: string;
  containerClassName?: string;
}) => {
  if (Number(label) === 0) return <TableNA containerClassName={containerClassName} />;

  return (
    <div className={cn(containerClassName, "text-nowrap")}>
      <span className="text-nowrap text-sm text-gray-800">
        {`${prefix}${formatNumber(Number(label), decimals)} ${suffix}`}
      </span>
    </div>
  );
};

export const RowListWithSeparator = ({
  list,
  containerClassName,
}: {
  list: Array<{ label: string; important: boolean }> | unknown;
  containerClassName?: string;
}) => {
  if (!Array.isArray(list) || !list.length) return <TableNA containerClassName={containerClassName} />;
  return (
    <div className={cn(containerClassName, "flex flex-wrap items-center")}>
      {list
        .sort((a) => (a.important ? -1 : 1))
        .map(({ label, important }, index) => (
          <div className={"flex items-center text-nowrap"} key={label}>
            {important && <Icon type={"Crown"} className={"mr-1.5 size-4"} />}
            <span
              className={cn(
                "text-sm",
                important ? "text-black" : "text-gray-900",
                important ? "font-bold" : "font-medium",
              )}
            >
              {String(label)}
            </span>
            {list.length - 1 > index && <Icon type={"DotIcon"} className={"-mx-1 size-8 text-[#D9D9D9]"} />}
          </div>
        ))}
    </div>
  );
};

export function formatNumber(num: number | string | unknown, decimals = 2) {
  let extension = "";
  if (num >= 1e9) {
    num /= 1e9;
    extension = "b";
  }
  if (num >= 1e6) {
    num /= 1e6;
    extension = "m";
  }
  if (typeof num !== "number") {
    num = Number(num);
  }
  const roundedNum = Math.round((num as number) * 10 ** decimals) / 10 ** decimals;
  const numStr = roundedNum.toString();
  const parts = numStr.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".") + extension;
}
