import React, { PropsWithChildren, ReactNode } from "react";

import { cn } from "@/lib/utils.ts";

import { Card } from "@/app/components";
import { AnimatedNumber, AnimatedNumberProps } from "@/app/components/animated-number/animated-number.tsx";
import { ChipV2 } from "@/app/components/v2/chip-v2.tsx";
import { normaliseDate } from "@/app/misc/helpers.ts";

type HighlightsBoxProps = {
  className?: string;
};

export const HighlightsCard = ({ className, children }: PropsWithChildren<HighlightsBoxProps>) => {
  return <Card className={cn("group relative h-[250px] select-none py-6 md:pt-10", className)}>{children}</Card>;
};

export const HighlightsCardBody = ({ className, children }: PropsWithChildren<HighlightsBoxProps>) => {
  return (
    <div className={cn("flex size-full flex-col items-center justify-between gap-y-4 md:gap-y-6", className)}>
      {children}
    </div>
  );
};

export const HighlightsCardTitle = ({ text, className }: { text: string; className?: string }) => {
  return <span className={cn("text-center text-sm font-semibold text-gray-900", className)}>{text}</span>;
};

export const HighlightsCardFooter = ({ children }: PropsWithChildren) => {
  return <div className="flex items-center">{children}</div>;
};

export const HighlightsCardEmptyState = ({ text, textClassName }: { text: string; textClassName?: string }) => {
  return (
    <div className="flex h-full items-center justify-center text-center">
      <span className={cn("select-none text-xl font-semibold text-gray-400", textClassName)}>{text}</span>
    </div>
  );
};

type HighlightsCardNumberProps<TAnimate extends boolean> = {
  value: TAnimate extends true ? number : ReactNode;
  options?: AnimatedNumberProps["options"];
  className?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  animate?: TAnimate;
};

export const HighlightsCardNumber = <TAnimate extends boolean = true>({
  value,
  options,
  className,
  prefix,
  suffix,
  animate,
}: HighlightsCardNumberProps<TAnimate>) => {
  // animate by default
  const shouldAnimate = animate ?? true;

  if (shouldAnimate) {
    return (
      <AnimatedNumber
        value={value as number}
        options={options}
        className={cn("text-3xl font-medium text-gray-800", className)}
        prefix={prefix}
        suffix={suffix}
      />
    );
  }

  return (
    <span className={cn("text-3xl font-medium text-gray-800", className)}>
      {prefix}
      {value}
      {suffix}
    </span>
  );
};

export const HighlightsCardLastUpdated = ({ date }: { date?: string | null }) => {
  const dateString = date && normaliseDate(date);
  if (!dateString) {
    return null;
  }

  return <ChipV2 label={dateString} color="gray" />;
};
