// @ts-nocheck

import React, { useState } from "react";

import { cn } from "@/lib/utils.ts";

import { Icon, Popover } from "@/app/components";

import { DashboardSection } from "../../components/dashboard-section.tsx";
import { HistoryToggle } from "../../components/history-toggle.tsx";
import { dashboardSections } from "../../dashboard.config.ts";
import { getListItemAnimation } from "../../utils.ts";

import { CompaniesForwardedItem } from "./companies-forwarded-item.tsx";
import { useCompaniesForwarded } from "./use-companies-forwarded.ts";

export const CompaniesForwardedSection = () => {
  const [showArchived, setShowArchived] = useState(false);
  const data = useCompaniesForwarded(showArchived);

  return (
    <DashboardSection
      id={dashboardSections.companiesForwarded.id}
      title={dashboardSections.companiesForwarded.label}
      count={data.totalCount}
      info={
        <Popover
          directionClassName={"border-white"}
          childrenClassName={"ml-4 overflow-visible border-0 w-[600px]"}
          childrenContent={
            <>
              <div className={"m-1 flex items-center gap-x-1.5 break-words"}>
                <p className={"select-none rounded-sm px-2 py-1.5 text-xs font-semibold text-neutral-600"}>
                  Send all opportunities to&nbsp;
                  <b className={"cursor-pointer"}>
                    <a href="mailto:opportunities@projectnazare.vc">opportunities@projectnazare.vc</a>
                  </b>
                  &nbsp; and we'll share them with the Nazare team. We'll handle the email processing and provide a
                  pre-filled opportunity template to review ✅ and share with your network.
                </p>
              </div>
            </>
          }
          placement={"right"}
        >
          <Icon type={"Info"} className={cn("size-4 text-gray-500")} />
        </Popover>
      }
      emptyPlaceholder={{ enabled: data.totalCount === 0 && !data.loading, message: "No companies forwarded" }}
      loading={data.loading}
      headerSlot={<HistoryToggle value={showArchived} setValue={() => setShowArchived(!showArchived)} />}
      loadMoreBtn={{
        enabled: !!data.pageInfo?.hasNextPage,
        onLoadMore: data.loadMore,
        loading: data.loadingMore,
      }}
      loadLessBtn={{
        enabled: !!data.pageInfo?.hasPreviousPage,
        onLoadLess: data.loadLess,
        loading: data.loadingLess,
      }}
    >
      <div className="flex flex-col gap-3">
        {data.items.map((item, index) => (
          <CompaniesForwardedItem key={item.id} notification={item} className={getListItemAnimation(index)} />
        ))}
      </div>
    </DashboardSection>
  );
};
