import { Card, Icon, ImageWithErrorFallback } from "app/components";
import { format } from "date-fns";
import Carousel from "react-multi-carousel";

import { DealroomNews } from "@/gql/graphql.ts";

import "react-multi-carousel/lib/styles.css";
import { cn } from "@/lib/utils.ts";

import { EmptySection } from "@/app/screens/opportunities/opportunity/components/empty-section.tsx";

import React from "react";

import { Link } from "react-router-dom";

import { useOpportunityContext } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";

type NewsProps = {
  news?: DealroomNews[];
};

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export const News = ({ news = [] }: NewsProps) => {
  if (!news.length) {
    return <EmptySection text={"No News Found"} />;
  }

  return (
    <div className="relative inline-flex w-full flex-nowrap">
      <Carousel
        responsive={responsive}
        className="!static w-full"
        customLeftArrow={
          <button className="absolute left-0 -translate-x-1/3 cursor-pointer select-none rounded-full border-2 border-white bg-gray-300 p-2.5 hover:bg-gray-400 active:shadow-inner md:p-4">
            <Icon className="size-5" type="Chevron Left" />
          </button>
        }
        customRightArrow={
          <button className="absolute right-0 translate-x-1/3 cursor-pointer select-none rounded-full border-2 border-white bg-gray-300 p-2.5 hover:bg-gray-400 active:shadow-inner md:p-4">
            <Icon className="size-5" type="Chevron Right" />
          </button>
        }
      >
        {news.map((item) => (
          <Card key={item.title} className={"group relative flex h-full flex-col overflow-hidden md:mr-2"}>
            <div className="h-[200px] w-full overflow-hidden rounded-xs">
              <ImageWithErrorFallback
                className={cn("flex size-full items-center justify-center bg-gray-300 object-cover")}
                iconClassName={cn("!size-20 bg-gray-300 text-gray-500")}
                src={item.image}
                alt={"News Image"}
              />
            </div>
            <div className="mt-4 flex flex-1 flex-col overflow-hidden">
              <span className="line-clamp-2 font-semibold">{item.title ?? ""}</span>
              <p className="mt-3 line-clamp-5 flex-1 overflow-hidden text-sm font-medium">{item.summary ?? ""}</p>
              <div className="mt-4 flex justify-between">
                {/*todo avatar*/}
                {item.author?.name && <span className="text-sm font-semibold text-gray-800">{item.author.name}</span>}
                <span className="ml-auto text-sm font-medium text-gray-500">
                  {format(new Date(item.pubDate), "LLL dd, YYY")}
                </span>
              </div>
            </div>
            <div className="opacity-0 transition-opacity group-hover:opacity-100">
              <div className="absolute left-0 top-0 size-full bg-black opacity-15"></div>
              {item.link != null && (
                <Link
                  className="bg-gray-700/60 hover:bg-gray-700/70 absolute right-6 top-6 rounded-sm p-2"
                  to={item.link}
                  target={"_blank"}
                >
                  <Icon className="size-6 text-white" type={"ExternalLink"} />
                </Link>
              )}
            </div>
          </Card>
        ))}
      </Carousel>
    </div>
  );
};
